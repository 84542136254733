import styled from 'styled-components';
import UnitType from '../../constants/UnitType';
import BookingDateSelector from '../../components/BookingDateSelector';
import BookingProductDateSelector from '../../components/BookingProductDateSelector';

const BookingDateChangeModal = ({ unitData, bookingId, bookingDate, onChangeBookingDate, onClose }) => {
	const renderBookingDateSelector = () => {
		return (
			<BookingDateSelector
				unitData={unitData}
				bookingId={bookingId}
				bookingDate={bookingDate}
				bookingDateChange={{ onClose: onClose, onChange: onChangeBookingDate }}
			/>
		);
	};

	const renderBookingProductDateSelector = () => {
		return (
			<BookingProductDateSelector
				unitData={unitData}
				bookingId={bookingId}
				bookingDate={bookingDate}
				bookingDateChange={{ onClose: onClose, onChange: onChangeBookingDate }}
			/>
		);
	};

	const renderModalBody = () => {
		const unitType = unitData.unit_type_name;

		switch (unitType) {
			case UnitType.ROOM:
			case UnitType.CONSULT:
				return <>{renderBookingDateSelector()}</>;
			case UnitType.PRODUCT:
				return <>{renderBookingProductDateSelector()}</>;
		}
	};

	return (
		<ModalContainer>
			<Content>{renderModalBody()}</Content>
		</ModalContainer>
	);
};

const ModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		padding: 15px;
	}
`;

const Content = styled.div`
	position: relative;
	width: 480px;
	max-height: 90%;
	overflow-y: scroll;

	background-color: white;

	background: #ffffff;
	border: 1px solid #777777;
	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding: 15px 10px;
	}
`;

export default BookingDateChangeModal;
