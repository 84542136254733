import { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import UnitType from '../constants/UnitType';
import LoadingIndicator from '../components/LoadingIndicator';

import { UserContext } from '../contexts/UserContext';

import CheckIconImage from '../assets/images/screens/Booking/check.svg';

import * as api from '../apis';
import * as utils from '../utils';

const BookingProductDateSelector = ({
	unitData,
	bookingId = null,
	bookingDate = null,
	bookingDateChange = null, // <BookingDateChangeModal /> 컴포넌트에서 사용되는 props
}) => {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);

	// bookingDate는 yyyy-mm-dd ~yyyy-mm-dd 형식의 문자열
	const [usageStartDate, setUsageStartDate] = useState(bookingDate ? bookingDate.split(' ~')[0] : null);
	const [usageEndDate, setUsageEndDate] = useState(bookingDate ? bookingDate.split(' ~')[1] : null);
	const [systemBlockScheduleList, setSystemBlockDateList] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		getSystemSchedulesFromApi();
	}, []);

	const getSystemSchedulesFromApi = () => {
		api.getSystemSchedules()
			.then((res) => {
				if (res && res.data) {
					let systemBlockScheduleList = res.data.filter((schedule) => schedule.allow_status === false);
					setSystemBlockDateList(systemBlockScheduleList);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const convertMinuteToDayStr = (minute) => {
		let day = parseInt(minute / 60 / 24);
		return `${day}일`;
	};

	const checkEnableDay = (dateStr) => {
		let selectedDay = new Date(dateStr).getDay();
		if (
			unitData?.enable_schedule?.booking_weekday &&
			!unitData?.enable_schedule?.booking_weekday.includes(selectedDay.toString())
		) {
			alert(
				`예약가능한 요일이 아닙니다.\n예약가능 요일: ${utils.getEnabledWeekdayStr(
					unitData?.enable_schedule?.booking_weekday
				)}`
			);
			return false;
		}

		return true;
	};

	const checkEnableSchedule = (dateStr) => {
		let selectedDate = new Date(dateStr);
		let enableSchedule = true;
		// 시스템 제한 날짜를 확인한다.
		systemBlockScheduleList?.forEach((systemBlockSchedule) => {
			if (
				new Date(systemBlockSchedule.start_date) <= selectedDate &&
				new Date(systemBlockSchedule.end_date) >= selectedDate
			) {
				enableSchedule = false;
			}
		});

		// Unit 제한 날짜를 확인한다.
		unitData.disable_schedules?.forEach((unitDisableSchedule) => {
			if (
				new Date(unitDisableSchedule.start_date) <= selectedDate &&
				new Date(unitDisableSchedule.end_date) >= selectedDate
			) {
				enableSchedule = false;
			}
		});

		if (!enableSchedule) {
			alert(`예약가능한 날짜가 아닙니다.\n\n${getDisabledScheduleStr(selectedDate)}`);
			return false;
		}

		return true;
	};

	const getDisabledScheduleStr = (selectedDate) => {
		let disableScheduleList = [];
		systemBlockScheduleList?.forEach((systemBlockSchedule) => {
			if (
				new Date(systemBlockSchedule.start_date) <= selectedDate &&
				new Date(systemBlockSchedule.end_date) >= selectedDate
			) {
				disableScheduleList.push(
					`${systemBlockSchedule.start_date}~${systemBlockSchedule.end_date} : ${systemBlockSchedule.name}`
				);
			}
		});
		// 시스템 설정에 걸리는 예약 기간이 없는 경우
		if (disableScheduleList.length === 0) {
			unitData.disable_schedules?.forEach((unitDisableSchedule) => {
				if (
					new Date(unitDisableSchedule.start_date) <= selectedDate &&
					new Date(unitDisableSchedule.end_date) >= selectedDate
				) {
					disableScheduleList.push(
						`${unitDisableSchedule.start_date}~${unitDisableSchedule.end_date} : ${unitDisableSchedule.name}`
					);
				}
			});
		}
		return disableScheduleList.sort().join('\n');
	};

	const getBookingScheduleDays = () => {
		if (!usageStartDate || !usageEndDate) {
			return '이용일자를 선택해 주세요';
		}

		const startDate = new Date(usageStartDate);
		const endDate = new Date(usageEndDate);

		// 시작일과 종료일을 포함한 전체 일수를 계산하기 위해 1일을 추가
		const diffDay = utils.getDayDifference(startDate, endDate) + 1;

		return `${diffDay}일`;
	};

	const getBookingScheduleDate = () => {
		if (!usageStartDate || !usageEndDate) {
			return null;
		}

		return `(${usageStartDate} ~ ${usageEndDate})`;
	};

	// 잔여 장비 개수를 확인한다.
	const getAvailableProductCount = async () => {
		try {
			setIsLoading(true);
			const filterParams = [`date=${usageStartDate}`, `date_to=${usageEndDate}`];

			if (bookingId) {
				filterParams.push(`booking_id=${bookingId}`);
			}

			const res = await api.getUnitSchedule(unitData.id, filterParams);

			// 응답 데이터가 있을 경우에만 값을 전달
			return res ? res.data : null;
		} catch (err) {
			console.error(err);
			return null;
		} finally {
			setIsLoading(false);
		}
	};

	const validateBookingSchedule = async () => {
		if (!usageStartDate) {
			alert('선택한 대여일이 없습니다.');
			return false;
		}
		if (!usageEndDate) {
			alert('선택한 반납일이 없습니다.');
			return false;
		}

		const startDate = new Date(usageStartDate);
		const endDate = new Date(usageEndDate);

		// 시작일과 종료일을 포함한 전체 일수를 계산하기 위해 1일을 추가
		const diffDay = utils.getDayDifference(startDate, endDate) + 1;

		if (diffDay > parseInt(unitData?.enable_schedule?.max_usage_time / 24 / 60)) {
			alert('최대 대여일수를 초과하였습니다.');
			return false;
		}

		const availableProductCount = await getAvailableProductCount();
		if (!availableProductCount || availableProductCount === 0) {
			alert('예약 가능한 장비가 없습니다.');
			return false;
		}

		return true;
	};

	const onClickConfirmBookingDateChange = async () => {
		const isValid = await validateBookingSchedule();
		if (!isValid || !bookingId) {
			return;
		}

		const data = {
			booking_start_date: utils.convertParameterDate(usageStartDate, '00:00'),
			booking_end_date: utils.convertParameterDate(usageEndDate, '23:59'),
		};

		setIsLoading(true);
		api.updateBooking(bookingId, data)
			.then((res) => {
				if (res && res.data) {
					alert('이용 기간을 변경하였습니다.');

					const newBookingDate = `${usageStartDate} ~${usageEndDate}`;
					bookingDateChange.onChange(newBookingDate);
					bookingDateChange.onClose();
				}
			})
			.catch((err) => {
				console.error(err);
				alert('이용 기간 변경에 실패하였습니다.');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const onClickPrev = () => {
		navigate('/booking', {
			state: {
				unit_type: UnitType.PRODUCT,
			},
		});
	};

	const onClickNext = async () => {
		const isValid = await validateBookingSchedule();
		if (!isValid) {
			return;
		}

		navigate('/booking/request', {
			state: {
				unit_id: unitData.id,
				filter: {
					usage_start_date: usageStartDate,
					usage_end_date: usageEndDate,
				},
			},
		});
	};

	return (
		<Content sticky={!bookingDateChange}>
			<LoadingIndicator size={50} loading={isLoading} />
			<BookingInfoContent>
				<BookingTitle>예약 신청</BookingTitle>
				<section>
					<BookingSubTitleContainer>
						<BookingSubTitle>이용일자</BookingSubTitle>
						<BookingEnableDayContainer>
							<CheckIcon src={CheckIconImage} />
							<BookingEnableDayTitle>{`예약 가능 요일 : `}</BookingEnableDayTitle>
							<BookingEnableDays>
								{utils.getEnabledWeekdayStr(unitData.enable_schedule?.booking_weekday)}
							</BookingEnableDays>
						</BookingEnableDayContainer>
					</BookingSubTitleContainer>
					<SearchDateContainer>
						<SearchDateInput
							type="date"
							value={usageStartDate ?? ''}
							min={
								utils.checkProductAdminRole(user, unitData.id)
									? null
									: utils.convertDateToStr(new Date())
							}
							max={
								utils.checkProductAdminRole(user, unitData.id)
									? null
									: unitData?.enable_schedule?.usage_end_date
							}
							onChange={(e) => {
								// 예약 가능한 요일인지 판단한다.
								if (!checkEnableDay(e.currentTarget.value)) {
									return;
								}
								// 제외 기간에 포함되는지 확인한다.
								if (!checkEnableSchedule(e.currentTarget.value)) {
									return;
								}
								if (usageEndDate && usageEndDate < e.currentTarget.value) {
									alert('대여일은 반납일보다 이후일 수 없습니다.');
									return;
								}
								setUsageStartDate(e.currentTarget.value);
							}}
						/>
						<SearchDateInputSeparator>~</SearchDateInputSeparator>
						<SearchDateInput
							type="date"
							value={usageEndDate ?? ''}
							min={
								utils.checkProductAdminRole(user, unitData.id)
									? null
									: utils.convertDateToStr(new Date())
							}
							max={
								utils.checkProductAdminRole(user, unitData.id)
									? null
									: unitData?.enable_schedule?.usage_end_date
							}
							onChange={(e) => {
								// 예약 가능한 요일인지 판단한다.
								if (!checkEnableDay(e.currentTarget.value)) {
									return;
								}
								// 제외 기간에 포함되는지 확인한다.
								if (!checkEnableSchedule(e.currentTarget.value)) {
									return;
								}
								if (usageStartDate && usageStartDate > e.currentTarget.value) {
									alert('반납일은 대여일보다 이전일 수 없습니다.');
									return;
								}
								setUsageEndDate(e.currentTarget.value);
							}}
						/>
					</SearchDateContainer>
					<BookingEnableDayInformation>{`* 이용시작/종료일은 예약 가능 요일 중에서 지정할 수 있습니다.`}</BookingEnableDayInformation>
				</section>
				<section>
					<BookingBlockTimeContainer>
						<BookingSubTitleContainer inline className="mt-0">
							<BookingSubTitle>이용기간</BookingSubTitle>
						</BookingSubTitleContainer>
						<BookingSubTitleDescription>{`최대 대여일수 : ${convertMinuteToDayStr(
							unitData?.enable_schedule?.max_usage_time ?? 0
						)}`}</BookingSubTitleDescription>
					</BookingBlockTimeContainer>
					<BookingBlockTime>
						<BookingBlockTimeText>{getBookingScheduleDays()}</BookingBlockTimeText>
						{getBookingScheduleDate() !== null && (
							<BookingBlockTimeInfoText>{getBookingScheduleDate()}</BookingBlockTimeInfoText>
						)}
					</BookingBlockTime>
				</section>
			</BookingInfoContent>
			{bookingDateChange ? (
				<BookingDateChangeButtonContainer>
					<CancelButton onClick={bookingDateChange.onClose}>취소</CancelButton>
					<OkButton onClick={onClickConfirmBookingDateChange}>확인</OkButton>
				</BookingDateChangeButtonContainer>
			) : (
				<ButtonContainer>
					<StepButton onClick={onClickPrev}>
						<StepButtonPrev>{'<'}</StepButtonPrev>
						<span>이전단계</span>
					</StepButton>
					<StepButton onClick={onClickNext}>
						<StepButtonNext>{'>'}</StepButtonNext>
						<span>다음단계</span>
					</StepButton>
				</ButtonContainer>
			)}
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	background-color: #ffffff;
	border: 1px solid #dddddd;

	${(props) =>
		props.sticky &&
		css`
			position: sticky;
			top: 110px;
			margin-top: 20px;

			@media only screen and (max-width: 767.98px) {
				margin-top: 10px;
			}
		`}
`;

const BookingInfoContent = styled.div`
	padding: 25px 30px 30px 30px;

	@media only screen and (max-width: 767.98px) {
		padding: 15px 10px;
	}
`;

const BookingTitle = styled.div`
	padding-bottom: 20px;
	border-bottom: 1px solid #dddddd;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 26px;
	line-height: 31px;
	color: #333333;

	@media only screen and (max-width: 767.98px) {
		padding-bottom: 12px;
		font-size: 20px;
		line-height: 24px;
	}
`;

const BookingSubTitleContainer = styled.div`
	width: 100%;
	margin-top: 20px;
	margin-bottom: 11px;
	display: flex;
	align-items: center;

	${(props) =>
		props.inline &&
		css`
			width: auto;
			margin-bottom: 0;
		`}

	@media only screen and (max-width: 767.98px) {
		margin-top: 15px;
	}
`;

const BookingSubTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #023169;

	@media only screen and (max-width: 767.98px) {
		font-size: 16px;
		line-height: 19px;
	}
`;

const BookingEnableDayContainer = styled.div`
	margin-left: 9px;
	display: flex;
	align-items: center;
`;

const CheckIcon = styled.img`
	width: 15px;
	height: 15px;
`;

const BookingEnableDayTitle = styled.span`
	margin-left: 3px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
		line-height: 17px;
	}
`;

const BookingEnableDays = styled.span`
	margin-left: 3px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #e76f00;

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
		line-height: 17px;
	}
`;

const BookingEnableDayInformation = styled.div`
	margin-top: 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #22499d;
`;

const SearchDateContainer = styled.div``;

const SearchDateInput = styled.input`
	width: 157px;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;
	border-radius: 4px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		width: 40%;
	}
`;

const SearchDateInputSeparator = styled.span`
	margin: 0 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #000000;
`;

const BookingBlockTimeContainer = styled.div`
	display: flex;
	width: 100%;
	margin-top: 30px;
	align-items: center;
	justify-content: space-between;
`;

const BookingSubTitleDescription = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	text-align: right;
	color: #666666;
`;

const BookingBlockTime = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	width: 100%;
	padding: 15px 0;
	margin-top: 18px;
	background-color: #f0f4fd;
	border-radius: 5px;
`;

const BookingBlockTimeText = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #22499d;

	@media only screen and (max-width: 767.98px) {
		font-size: 16px;
		line-height: 19px;
	}
`;

const BookingBlockTimeInfoText = styled.div`
	margin-top: 7px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	color: #4a4a4a;

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
		line-height: 17px;
	}
`;

const BookingDateChangeButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 25px;
`;

const CancelButton = styled.button`
	width: 125px;
	height: 50px;
	border: 1px solid #22499d;
	border-radius: 5px;
	background-color: #ffffff;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #22499d;

	&:hover {
		background-color: #0c438d;
		color: #ffffff;
	}
`;

const OkButton = styled.div`
	width: 125px;
	height: 50px;
	margin-left: 10px;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	background-color: #22499d;
	border-radius: 5px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #ffffff;

	&:hover {
		background-color: #0c438d;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;

const StepButton = styled.div`
	position: relative;
	width: 50%;
	height: 64px;
	border: 1px solid #8f8f8f;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #000000;

	& + & {
		border-left: 0px;
	}

	&:hover {
		background-color: #22499d;
		color: #ffffff;
	}

	@media only screen and (max-width: 767.98px) {
		height: 46px;
		font-size: 16px;
		line-height: 19px;
	}
`;

const StepButtonPrev = styled.div`
	position: absolute;
	top: 20px;
	left: 20px;

	@media only screen and (max-width: 767.98px) {
		top: 12px;
	}
`;

const StepButtonNext = styled.div`
	position: absolute;
	top: 20px;
	right: 20px;

	@media only screen and (max-width: 767.98px) {
		top: 12px;
	}
`;

export default BookingProductDateSelector;
