import React from 'react';
import styled from 'styled-components';
import AdminSectionTitle from '../components/admin/AdminSectionTitle';
import RoomLocationGuideModal from '../custom/modal/RoomLocationGuideModal';

const LocationGuide = () => {
	return (
		<Container>
			<Content>
				<AdminSectionTitle title="스튜디오 위치 안내도" />
				<RoomLocationGuideModal standalone />
			</Content>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	height: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	background-color: #f5f5f5;
`;

const Content = styled.div`
	width: 100%;
	height: 100%;
	max-width: 1240px;
	padding: 20px 20px;
	border-radius: 8px;
	background-color: white;
`;

export default LocationGuide;
