import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import AdminSectionTitle from '../../../components/admin/AdminSectionTitle';
import UnitType from '../../../constants/UnitType';
import UserRole from '../../../constants/UserRole';
import EmailSenderType from '../../../constants/EmailSenderType';
import ApprovalStatus from '../../../constants/ApprovalStatus';
import RentalStatus from '../../../constants/RentalStatus';
import { UserContext } from '../../../contexts/UserContext';
import { usePrompt } from '../../../hooks/blocker';
import useAdminSizeDetector from '../../../hooks/useAdminSizeDetector';
import * as api from '../../../apis';

const EmailNotificationSetting = () => {
	const navigate = useNavigate();
	const { isDesktop, isTabletHorizontal, isTabletVertical, isMobile } = useAdminSizeDetector();
	const { user } = useContext(UserContext);
	const [unitType, setUnitType] = useState(null);
	const [emailNotificationSetting, setEmailNotificationSetting] = useState(null);
	const [emailNotificationMessageList, setEmailNotificationMessageList] = useState(null);
	const [isModify, setIsModify] = useState(false);

	useEffect(() => {
		let defaultUnitType = UnitType.ROOM;
		// 사용자 권한에 따른 기본 UnitType 처리
		if (UserRole.isSystemAdminRole(user?.user_role) || UserRole.isRoomAdminRole(user?.user_role)) {
			defaultUnitType = UnitType.ROOM;
		} else if (UserRole.isProductAdminRole(user?.user_role)) {
			defaultUnitType = UnitType.PRODUCT;
		} else if (UserRole.isConsultAdminRole(user?.user_role)) {
			defaultUnitType = UnitType.CONSULT;
		}

		setUnitType(defaultUnitType);
		getEmailNotificationSetting(defaultUnitType);
		getEmailNotificationMessageList(defaultUnitType);
	}, []);

	usePrompt('알림 설정을 저장하지 않고\n페이지를 벗어나시겠습니까?', isModify);

	/**
	 * 이메일 알림 설정을 얻는다.
	 */
	const getEmailNotificationSetting = (targetUnitType) => {
		api.getEmailNotificationSetting(targetUnitType)
			.then((res) => {
				if (res && res.data) {
					setEmailNotificationSetting(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	/**
	 * 이메일 알림 메세지를 얻는다.
	 */
	const getEmailNotificationMessageList = (targetUnitType) => {
		api.getEmailNotificationMessageList(targetUnitType)
			.then((res) => {
				if (res && res.data) {
					setEmailNotificationMessageList(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	/**
	 * 이메일 알림 메세지의 특정 key의 값을 구한다.
	 */
	const getMessageData = (status, target, key) => {
		const message = emailNotificationMessageList?.find(
			(message) => message.status === status && message.send_target === target
		);
		if (!message) {
			return '';
		}
		return message[key] ?? '';
	};

	/**
	 * 이메일 알림 메세지의 특정 key의 값을 변경한다.
	 */
	const changeMessageData = (status, target, key, value) => {
		setIsModify(true);
		setEmailNotificationMessageList(
			emailNotificationMessageList.map((message) => {
				if (message.status === status && message.send_target === target) {
					return {
						...message,
						[key]: value,
					};
				}
				return message;
			})
		);
	};

	const onUnitTypeChange = (changedUnitType) => {
		setUnitType(changedUnitType);
		getEmailNotificationSetting(changedUnitType);
		getEmailNotificationMessageList(changedUnitType);
	};

	const onClickSave = () => {
		Promise.all([
			api.updateEmailNotificationSetting(unitType, emailNotificationSetting),
			api.updateEmailNotificationMessageList(unitType, emailNotificationMessageList),
		])
			.then((res) => {
				setIsModify(false);
				alert('저장되었습니다.');
			})
			.catch((err) => {
				console.error(err);
				alert('저장에 실패하였습니다.');
			});
	};

	const onClickNotificationList = () => {
		navigate(`/admin/settings/notifications/${unitType}/list`);
	};

	const renderEmailNotificationMessageTableHeader = () => {
		if (isDesktop || isTabletHorizontal) {
			return (
				<tr>
					<th style={{ width: '216px' }}>발송 항목</th>
					<th style={{ width: '116px' }}>발송 시점</th>
					<th style={{ width: '72px' }}>발송 대상</th>
					<th>제목</th>
					<th>내용</th>
					<th style={{ width: '100px' }}>자동발송여부</th>
				</tr>
			);
		}

		if (isTabletVertical) {
			return (
				<tr>
					<th style={{ minWidth: '128px' }}>발송 항목</th>
					<th style={{ minWidth: '88px' }}>발송 시점</th>
					<th style={{ minWidth: '64px' }}>발송 대상</th>
					<th style={{ minWidth: '180px' }}>제목</th>
					<th style={{ minWidth: '180px' }}>내용</th>
					<th style={{ minWidth: '88px' }}>자동발송여부</th>
				</tr>
			);
		}

		if (isMobile) {
			return (
				<tr>
					<th style={{ minWidth: '128px' }}>발송 항목</th>
					<th style={{ minWidth: '88px' }}>발송 시점</th>
					<th style={{ minWidth: '64px' }}>발송 대상</th>
					<th style={{ minWidth: '200px' }}>제목</th>
					<th style={{ minWidth: '200px' }}>내용</th>
					<th style={{ minWidth: '88px' }}>자동발송여부</th>
				</tr>
			);
		}
	};

	const renderRoomEmailNotificationMessage = () => {
		return (
			<EmailNotificationMessageContainer>
				<AdminSectionTitle title="예약 및 승인 알림" />
				<MessageTableContainer>
					<MessageTable>
						<thead>{renderEmailNotificationMessageTableHeader()}</thead>
						<tbody>
							<tr>
								<StatusTableData>
									<StatusTitle>승인 대기</StatusTitle>
									<StatusListText>예약신청 완료상태</StatusListText>
									<StatusListText>승인 대기 상태로 접수 시</StatusListText>
								</StatusTableData>
								<td>승인상태 변경</td>
								<td>신청자</td>
								{renderMessageData(ApprovalStatus.PENDING, 'user')}
							</tr>
							<tr>
								<StatusTableData>
									<StatusTitle>승인 거부</StatusTitle>
									<StatusSubTitle>관리자가 신청 승인 거부</StatusSubTitle>
								</StatusTableData>
								<td>승인상태 변경</td>
								<td>신청자</td>
								{renderMessageData(ApprovalStatus.REJECTED, 'user')}
							</tr>
							<tr>
								<StatusTableData>
									<StatusTitle>예약 취소</StatusTitle>
									<StatusSubTitle>본인/관리자가 예약 취소</StatusSubTitle>
								</StatusTableData>
								<td>승인상태 변경</td>
								<td>신청자</td>
								{renderMessageData(ApprovalStatus.CANCELED, 'user')}
							</tr>
							<tr>
								<StatusTableData>
									<StatusTitle>승인 완료</StatusTitle>
									<StatusSubTitle>관리자/자동 신청 승인</StatusSubTitle>
								</StatusTableData>
								<td>승인상태 변경</td>
								<td>신청자</td>
								{renderMessageData(ApprovalStatus.APPROVED, 'user')}
							</tr>
							<tr>
								<StatusTableData>
									<StatusTitle>[운영자] 승인 대기</StatusTitle>
									<StatusListText>예약신청 완료상태</StatusListText>
									<StatusListText>승인 대기 상태로 접수 시</StatusListText>
								</StatusTableData>
								<td>승인상태 변경</td>
								<td>운영자</td>
								{renderMessageData(ApprovalStatus.PENDING, 'admin')}
							</tr>
						</tbody>
					</MessageTable>
				</MessageTableContainer>
			</EmailNotificationMessageContainer>
		);
	};

	const renderProductEmailNotificationMessage = () => {
		return (
			<EmailNotificationMessageContainer>
				<AdminSectionTitle title="수령 및 반납 알림" />
				<MessageTableContainer>
					<MessageTable>
						<thead>{renderEmailNotificationMessageTableHeader()}</thead>
						<tbody>
							<tr>
								<StatusTableData>
									<StatusTitle>수령 지연</StatusTitle>
									<StatusSubTitle>수령일 1일 후 자동 발송</StatusSubTitle>
								</StatusTableData>
								<td>수령/반납 상태 변경</td>
								<td>신청자</td>
								{renderMessageData(RentalStatus.RENTAL_DELAY, 'user')}
							</tr>
							<tr>
								<StatusTableData>
									<StatusTitle>수령 완료/반납 안내</StatusTitle>
									<StatusSubTitle>수령 완료 상태로 변경시</StatusSubTitle>
								</StatusTableData>
								<td>수령/반납 상태 변경</td>
								<td>신청자</td>
								{renderMessageData(RentalStatus.RENTAL_COMPLETE, 'user')}
							</tr>
							<tr>
								<StatusTableData>
									<StatusTitle>반납 지연</StatusTitle>
									<StatusSubTitle>반납기일 후 자동 발송</StatusSubTitle>
								</StatusTableData>
								<td>수령/반납 상태 변경</td>
								<td>신청자</td>
								{renderMessageData(RentalStatus.RETURN_DELAY, 'user')}
							</tr>
							<tr>
								<StatusTableData>
									<StatusTitle>반납 완료</StatusTitle>
									<StatusSubTitle>관리자가 수령/반납 상태를</StatusSubTitle>
									<StatusSubTitle>`반납완료`로 변경함</StatusSubTitle>
								</StatusTableData>
								<td>수령/반납 상태 변경</td>
								<td>신청자</td>
								{renderMessageData(RentalStatus.RETURN_COMPLETE, 'user')}
							</tr>
							<tr>
								<StatusTableData>
									<StatusTitle>[운영자] 수령 지연</StatusTitle>
									<StatusSubTitle>수령일 1일 후 자동 발송</StatusSubTitle>
								</StatusTableData>
								<td>수령/반납 상태 변경</td>
								<td>운영자</td>
								{renderMessageData(RentalStatus.RENTAL_DELAY, 'admin')}
							</tr>
							<tr>
								<StatusTableData>
									<StatusTitle>[운영자] 반납 지연</StatusTitle>
									<StatusSubTitle>반납기일 후 자동 발송</StatusSubTitle>
								</StatusTableData>
								<td>수령/반납 상태 변경</td>
								<td>운영자</td>
								{renderMessageData(RentalStatus.RETURN_DELAY, 'admin')}
							</tr>
						</tbody>
					</MessageTable>
				</MessageTableContainer>
			</EmailNotificationMessageContainer>
		);
	};

	const renderMessageData = (status, target) => {
		return (
			<>
				<MessageTableData>
					<MessageTextArea
						rows={4}
						value={getMessageData(status, target, 'title')}
						onChange={(e) => changeMessageData(status, target, 'title', e.currentTarget.value)}
					></MessageTextArea>
				</MessageTableData>
				<MessageTableData>
					<MessageTextArea
						rows={isTabletVertical ? 5 : 4}
						value={getMessageData(status, target, 'content')}
						onChange={(e) => changeMessageData(status, target, 'content', e.currentTarget.value)}
					></MessageTextArea>
				</MessageTableData>
				<td>
					<Checkbox
						type="checkBox"
						checked={getMessageData(status, target, 'use_message') ?? false}
						onChange={(e) => changeMessageData(status, target, 'use_message', e.currentTarget.checked)}
					/>
				</td>
			</>
		);
	};

	return (
		<Content>
			<SectionTitle>알림 관리</SectionTitle>
			<UnitTypeSelectContainer>
				<LeftCol>
					<SubTitle>예약 유형 선택</SubTitle>
					<UnitTypeSelect onChange={(e) => onUnitTypeChange(e.currentTarget.value)}>
						{(UserRole.isSystemAdminRole(user?.user_role) || UserRole.isRoomAdminRole(user?.user_role)) && (
							<option value={UnitType.ROOM}>시설</option>
						)}
						{JSON.parse(process.env.REACT_APP_USE_TYPE_PRODUCT) &&
							(UserRole.isSystemAdminRole(user?.user_role) ||
								UserRole.isProductAdminRole(user?.user_role)) && (
								<option value={UnitType.PRODUCT}>장비</option>
							)}
						{JSON.parse(process.env.REACT_APP_USE_TYPE_CONSULT) &&
							(UserRole.isSystemAdminRole(user?.user_role) ||
								UserRole.isConsultAdminRole(user?.user_role)) && (
								<option value={UnitType.CONSULT}>상담</option>
							)}
					</UnitTypeSelect>
				</LeftCol>
				<RightCol>
					<Button onClick={onClickNotificationList}>발송 내역 조회</Button>
				</RightCol>
			</UnitTypeSelectContainer>
			<Separator />
			<EmailSettingContainer>
				<LeftCol>
					<RadioBoxGroupRow>
						<RadioBoxGroup>
							<input
								type="radio"
								id={'senderType-system'}
								name={'senderType-system'}
								checked={emailNotificationSetting?.sender_type === EmailSenderType.SYSTEM}
								onChange={(e) => {
									setIsModify(true);
									setEmailNotificationSetting({
										...emailNotificationSetting,
										sender_type: EmailSenderType.SYSTEM,
									});
								}}
							/>
							<label htmlFor={'senderType-system'}>공통 운영 메일 주소로 발송</label>
						</RadioBoxGroup>
						<RadioBoxGroup>
							<input
								type="radio"
								id={'senderType-admin'}
								name={'senderType-admin'}
								checked={emailNotificationSetting?.sender_type === EmailSenderType.ADMIN}
								onChange={(e) => {
									setIsModify(true);
									setEmailNotificationSetting({
										...emailNotificationSetting,
										sender_type: EmailSenderType.ADMIN,
									});
								}}
							/>
							<label htmlFor={'senderType-admin'}>
								예약항목 관리자의 메일 주소로 발송 (관리자 미지정 시 공통 운영메일 주소로 발송)
							</label>
						</RadioBoxGroup>
					</RadioBoxGroupRow>
					<TextInputRow>
						<TextInputContainer>
							<TextInputLabel>발송 메일 주소</TextInputLabel>
							<TextInput
								type="text"
								value={emailNotificationSetting?.sender_email ?? ''}
								placeholder="이메일을 입력해주세요"
								onChange={(e) => {
									setIsModify(true);
									setEmailNotificationSetting({
										...emailNotificationSetting,
										sender_email: e.currentTarget.value,
									});
								}}
							/>
						</TextInputContainer>
						<TextInputContainer>
							<TextInputLabel>발송자 이름</TextInputLabel>
							<TextInput
								type="text"
								value={emailNotificationSetting?.sender_name ?? ''}
								placeholder="이름을 입력해주세요"
								onChange={(e) => {
									setIsModify(true);
									setEmailNotificationSetting({
										...emailNotificationSetting,
										sender_name: e.currentTarget.value,
									});
								}}
							/>
						</TextInputContainer>
					</TextInputRow>
				</LeftCol>
				<RightCol>
					<Button onClick={onClickSave}>저장</Button>
				</RightCol>
			</EmailSettingContainer>
			{renderRoomEmailNotificationMessage()}
			{unitType === UnitType.PRODUCT && renderProductEmailNotificationMessage()}
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 40px;
	background-color: white;

	@media only screen and (max-width: 1023.98px) {
		padding: 40px 20px;
	}

	@media only screen and (max-width: 479.98px) {
		padding: 20px 10px;
	}
`;

const SectionTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;
`;

const UnitTypeSelectContainer = styled.div`
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	width: 100%;
	padding: 8px 20px;
	background-color: #f7f7f7;
	border: 1px solid #dddddd;
`;

const SubTitle = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #000000;
`;

const UnitTypeSelect = styled.select`
	max-width: 210px;
	width: 100%;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	border-radius: 5px;

	appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, #000000 50%),
		linear-gradient(135deg, #000000 50%, transparent 50%);
	background-position: calc(100% - 20px) calc(1em + 0px), calc(100% - 15px) calc(1em + 0px), calc(100% - 2.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1px 1.5em;
	background-repeat: no-repeat;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
`;

const Separator = styled.hr`
	color: #6f6f6f;
	margin-block-start: 20px;
	margin-block-end: 20px;
`;

const EmailSettingContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 6px;
	width: 100%;
	padding: 8px 20px;

	@media only screen and (max-width: 767.98px) {
		gap: 10px;
	}

	@media only screen and (max-width: 479.98px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const LeftCol = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const RightCol = styled.div`
	display: flex;
	align-items: center;
`;

const RadioBoxGroupRow = styled.div`
	height: 35px;
	display: flex;
	align-items: center;
	gap: 20px;

	@media only screen and (max-width: 767.98px) {
		height: auto;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
	}
`;

const RadioBoxGroup = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	input {
		cursor: pointer;
		width: 15px;
		height: 15px;
	}

	label {
		cursor: pointer;
		margin-left: 10px;
		color: #666666;
		font-family: 'Noto Sans';
		font-size: 15px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: -0.45px;
		line-height: 18px;
	}
`;

const TextInputRow = styled.div`
	margin-top: 8px;
	display: flex;

	@media only screen and (max-width: 1023.98px) {
		width: 100%;
	}

	@media only screen and (max-width: 479.98px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const TextInputContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	& + & {
		margin-left: 40px;
	}

	@media only screen and (max-width: 1023.98px) {
		& + & {
			margin-left: 10px;
		}
	}

	@media only screen and (max-width: 479.98px) {
		& + & {
			margin-left: 0px;
			margin-top: 10px;
		}
	}
`;

const TextInputLabel = styled.div`
	width: 120px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
	flex-shrink: 0;

	@media only screen and (max-width: 767.98px) {
		width: auto;
		margin-right: 10px;
	}

	@media only screen and (max-width: 479.98px) {
		width: 120px;
		margin-right: 0;
	}
`;

const TextInput = styled.input`
	width: 300px;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;

	@media only screen and (max-width: 1023.98px) {
		max-width: 300px;
		width: 100%;
	}
`;

const Button = styled.div`
	height: 36px;
	padding: 0 15px;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: #454655;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	white-space: nowrap;
	color: #ffffff;

	&:hover {
		background-color: #1d1e31;
	}
`;

const EmailNotificationMessageContainer = styled.div`
	margin-top: 20px;
	margin-bottom: 30px;
`;

const MessageTableContainer = styled.div`
	width: 100%;

	@media only screen and (max-width: 767.98px) {
		max-width: 728px;
		overflow-x: scroll;
	}
`;

const MessageTable = styled.table`
	width: 100%;
	margin-top: 20px;

	border-collapse: collapse;
	border-top: 2px solid #0e498f;

	text-align: center;
	thead th {
		height: 45px;
		background-color: #f6f8fb;
		border-right: 1px solid #dddddd;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #0e498f;

		:first-child {
			border-left: 1px solid #dddddd;
		}
	}
	thead tr {
		border-bottom: 1px solid #dcdcdc;
	}

	tbody td {
		height: 45px;
		border-right: 1px solid #dddddd;
		padding: 6px;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #333333;

		word-break: break-all;
		:first-child {
			padding: 0 12px;
			border-left: 1px solid #dddddd;
		}

		:nth-child(2),
		:nth-child(3) {
			padding: 0 2px;
		}
	}
	tbody tr {
		border-bottom: 1px solid #dddddd;
	}

	tfoot {
		height: 45px;
		background: #f7f7f7;
		border-bottom: 1px solid #dddddd;
	}
`;

const StatusTableData = styled.td`
	text-align: left;
	padding-left: 12px !important;
`;

const StatusTitle = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
`;

const StatusSubTitle = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
`;

const StatusListText = styled.li`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
`;

const MessageTableData = styled.td`
	padding: 15px !important;

	@media only screen and (max-width: 479.98px) {
		padding: 5px !important;
	}
`;

const MessageTextArea = styled.textarea`
	width: 100%;
	padding: 8px;
	border: 1px solid #dddddd;
	border-radius: 5px;
	resize: none;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const Checkbox = styled.input`
	width: 15px;
	height: 15px;
	border: 1px solid #c5c5c5;
	margin: 0 11px 0 17px;
	cursor: pointer;
`;

export default EmailNotificationSetting;
