import UnitType from '../constants/UnitType';

export default [
	{
		name: '시설 분류 관리',
		link: '/admin/settings/unit/room/categories',
		tab_unit: UnitType.ROOM,
	},
	{
		name: '장비 분류 관리',
		link: '/admin/settings/unit/product/categories',
		tab_unit: UnitType.PRODUCT,
		use_product: true,
	},
	{
		name: '상담 분류 관리',
		link: '/admin/settings/unit/consult/categories',
		tab_unit: UnitType.CONSULT,
		use_consult: true,
	},
	{
		name: '게시판 분류 관리',
		link: '/admin/settings/board/categories',
		tab_unit: 'admin',
	},
	{
		name: '학교 일정 관리',
		link: '/admin/settings/schedules',
		tab_unit: 'admin',
	},
	{
		name: '알림 관리',
		link: '/admin/settings/notifications',
	},
	{
		name: '팝업 관리',
		link: '/admin/settings/popups',
		tab_unit: 'admin',
	},
	{
		name: '상담 서비스 설정',
		link: '/admin/settings/consult',
		tab_unit: UnitType.CONSULT,
		use_consult: true,
	},
];
