import React, { useEffect, useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import useSizeDetector from '../hooks/useSizeDetector';
import ApprovalStatus from '../constants/ApprovalStatus';
import BoardSlug from '../constants/BoardSlug';
import BookingCalendarModal from '../components/modal/BookingCalendarModal';
import RoomLocationGuideModal from '../custom/modal/RoomLocationGuideModal';

import BannerImg from '../custom/images/banner.png';
import BoardSummaryMoreBtnImg from '../custom/images/boardSummaryMore.svg';
import MyStatusBackgroundImg from '../custom/images/myStatusBackground.png';
import BookingCalendarImg from '../custom/images/bookingCalendar.png';
import BookingLocationImg from '../custom/images/bookingLocation.png';
import StudioBookingBackgroundImg from '../custom/images/studioBookingBackground.png';
import AdditionalBookingBackgroundImg from '../custom/images/additionalBookingBackground.png';
import BookingMoreBtnImg from '../custom/images/bookingMore.svg';
import Row1BookingBackgroundImg from '../custom/images/row1BookingBackground.png';
import Row2BookingBackgroundImg from '../custom/images/row2BookingBackground.png';
import Row1ArrowImg from '../custom/images/row1Arrow.png';
import Row2ArrowImg from '../custom/images/row2Arrow.png';
import FirstBookingIconImg from '../custom/images/firstBookingIcon.png';
import SecondBookingIconImg from '../custom/images/secondBookingIcon.png';
import Row2FirstBookingIconImg from '../custom/images/row2FirstBookingIcon.png';
import Row2SecondBookingIconImg from '../custom/images/row2SecondBookingIcon.png';
import LoginImg from '../custom/images/login.png';

import * as api from '../apis';

const Main = () => {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);
	const { isDesktop, Desktop, Mobile } = useSizeDetector();

	const [noticeBoard, setNoticeBoard] = useState(null);
	const [postList, setPostList] = useState(null);
	const [bookingList, setBookingList] = useState(null);
	const [bookingCalendarModalData, setBookingCalendarModalData] = useState({ show: false });
	const [roomLocationGuideModalData, setRoomLocationGuideModalData] = useState({ show: false });

	useEffect(() => {
		if (user) {
			let filterParams = [`page_size=2`];
			api.getMyBooking(filterParams).then((res) => {
				if (res && res.data) {
					setBookingList(res.data);
				}
			});
		}

		api.getBoards().then((res) => {
			if (res && res.data) {
				let noticeBoard = res.data.find((board) => board.slug === BoardSlug.NOTICE);
				setNoticeBoard(noticeBoard);
				api.getPosts(noticeBoard.id, [`page_size=4`]).then((res) => {
					if (res && res.data) {
						setPostList(res.data.items);
					}
				});
			}
		});
	}, [user]);

	const onClickLogin = () => {
		navigate('/login');
	};

	const onClickMypage = () => {
		navigate('/mypage');
	};

	const onClickNoticeBoard = () => {
		navigate('/boards/notice');
	};

	const onClickPostContents = (postId) => {
		navigate(`/boards/${noticeBoard.id}/posts/${postId}`);
	};

	const onClickBooking = (type) => {
		if (!user) {
			navigate('/login');
			return;
		}
		navigate(`/booking?type_category_id=${type}`);
	};

	const onClickUnitBooking = (unitId) => {
		if (!user) {
			navigate('/login');
			return;
		}
		navigate(`/booking/schedule/${unitId}`);
	};

	const renderBoardContentsRow = (post, idx) => {
		return (
			<BoardContentsRow key={idx} onClick={() => onClickPostContents(post.id)}>
				<BoardCategory type={`category-${post.category?.id ?? 0}`}>{post.category?.name ?? '-'}</BoardCategory>
				<BoardContentTitle>{post.title}</BoardContentTitle>
				<Desktop>
					<BoardContentDate>
						{post.created_at ? new Date(post.created_at).toLocaleDateString() : '-'}
					</BoardContentDate>
				</Desktop>
			</BoardContentsRow>
		);
	};

	const renderMyBooking = () => {
		if (!bookingList || bookingList.items.length === 0) {
			return <NoBookingList>예약 내역이 없습니다.</NoBookingList>;
		}
		return (
			<div>
				{bookingList?.items.map((booking, idx) => {
					return renderBookingDetailRow(
						idx,
						booking.approval_status,
						booking.booking_date.split('~')[0],
						`${booking.unit.name} ${booking.room_item.name}`
					);
				})}
			</div>
		);
	};

	const renderBookingDetailRow = (idx, status, calendarDetail, locationDetail) => {
		return (
			<MyContentsRow key={idx}>
				<BookingStatusBox status={status}>
					<span>{ApprovalStatus.toString(status)}</span>
				</BookingStatusBox>
				<BookingDetailContainer>
					<BookingDetailRow>
						<BookingDetailIcon src={BookingCalendarImg} />
						<BookingCalendarText>{calendarDetail ?? '-'}</BookingCalendarText>
					</BookingDetailRow>
					<BookingDetailRow>
						<BookingDetailIcon src={BookingLocationImg} />
						<BookingLocationText>{locationDetail ?? '-'}</BookingLocationText>
					</BookingDetailRow>
				</BookingDetailContainer>
			</MyContentsRow>
		);
	};

	const renderRowBookingButton = (isFirstRow, header, title, iconImg, onClickFunc) => {
		return (
			<RowBookingButton
				background={isFirstRow ? Row1BookingBackgroundImg : Row2BookingBackgroundImg}
				onClick={onClickFunc}
			>
				<div>
					<Mobile>
						<ButtonButtonHeader firstRow={isFirstRow}>{header}</ButtonButtonHeader>
					</Mobile>
					<BookingButtonTitle>{title}</BookingButtonTitle>
					<Desktop>
						<RowBookingDetailContainer isFirstRow={isFirstRow}>
							<span>자세히보기</span>
							<RowBookingMoreIcon src={isFirstRow ? Row1ArrowImg : Row2ArrowImg} />
						</RowBookingDetailContainer>
					</Desktop>
				</div>
				<RowBookingIcon src={iconImg} />
			</RowBookingButton>
		);
	};

	return (
		<Container>
			<BannerBackground src={BannerImg}>
				<TopContainer>
					<BannerContainer>
						<BoardContainer>
							<BoardTopContainer>
								<BoardTitle>공지사항</BoardTitle>
								<BoardMoreContainer onClick={() => onClickNoticeBoard()}>
									<span>더보기</span>
									<MoreBtn src={BoardSummaryMoreBtnImg} />
								</BoardMoreContainer>
							</BoardTopContainer>
							<BoardContentsContainer>
								{postList?.map((post, idx) => {
									return renderBoardContentsRow(post, idx);
								})}
							</BoardContentsContainer>
						</BoardContainer>
						<MyBookingContainer>
							<MyContainer src={MyStatusBackgroundImg}>
								<MyTopContainer>
									<MyStatusTitle>내 예약현황</MyStatusTitle>
									{user && (
										<MyStatusMoreContainer onClick={onClickMypage}>
											<span>마이페이지</span>
											<MoreBtn src={BoardSummaryMoreBtnImg} />
										</MyStatusMoreContainer>
									)}
								</MyTopContainer>
								<MyContentsContainer>
									{user ? (
										renderMyBooking()
									) : (
										<div>
											<LoginContainer>
												<LoginMainText>로그인을 해주세요.</LoginMainText>
												<LoginSubText>LOGIN</LoginSubText>
											</LoginContainer>
											<Desktop>
												<MyContentsRowSeperator />
											</Desktop>
											<LoginButtonContainer onClick={onClickLogin}>
												<LoginButtonIcon src={LoginImg} />
												<span>로그인</span>
											</LoginButtonContainer>
										</div>
									)}
								</MyContentsContainer>
							</MyContainer>
							{user && (
								<MyBottomButtonContainer>
									<MyBookingCalendarButton
										onClick={() => setBookingCalendarModalData({ show: true })}
									>
										나의 예약 캘린더
									</MyBookingCalendarButton>
									<RoomLocationGuideButton
										onClick={() => setRoomLocationGuideModalData({ show: true })}
									>
										스튜디오 위치 안내도
									</RoomLocationGuideButton>
								</MyBottomButtonContainer>
							)}
						</MyBookingContainer>
					</BannerContainer>
				</TopContainer>
			</BannerBackground>
			<BodyContainer>
				<BodyContainerRow>
					<Desktop>
						<GroupBookingButton
							type="studio"
							background={StudioBookingBackgroundImg}
							onClick={() => onClickBooking(1)}
						>
							<GroupBookingTitle>스튜디오 예약</GroupBookingTitle>
							<GroupBookingDetail>
								<GroupBookingDetailText>STUDIO RESERVATION</GroupBookingDetailText>
								<GroupBookingMoreIcon src={BookingMoreBtnImg} />
							</GroupBookingDetail>
						</GroupBookingButton>
					</Desktop>
					{renderRowBookingButton(true, '스튜디오 예약', '가좌 이러닝 스튜디오', FirstBookingIconImg, () =>
						onClickUnitBooking(1)
					)}
					{renderRowBookingButton(true, '스튜디오 예약', '칠암 이러닝 스튜디오', SecondBookingIconImg, () =>
						onClickUnitBooking(10)
					)}
				</BodyContainerRow>
				<BodyContainerRow>
					<Desktop>
						<GroupBookingButton
							type="additional"
							background={AdditionalBookingBackgroundImg}
							onClick={() => onClickBooking(2)}
						>
							<GroupBookingTitle>부가시설 예약</GroupBookingTitle>
							<GroupBookingDetail>
								<GroupBookingDetailText>Additional facilities RESERVATION</GroupBookingDetailText>
								<GroupBookingMoreIcon src={BookingMoreBtnImg} />
							</GroupBookingDetail>
						</GroupBookingButton>
					</Desktop>
					{renderRowBookingButton(false, '부가시설 예약', '공동활용 스튜디오', Row2FirstBookingIconImg, () =>
						alert('공동활용 스튜디오는 http://studio.rec.ac.kr에서 예약 신청이 가능합니다.')
					)}
					{renderRowBookingButton(false, '부가시설 예약', '소그룹 세미나실', Row2SecondBookingIconImg, () =>
						alert(
							'소그룹 세미나실은 경상국립대학교 통합서비스 https://my.gnu.ac.kr 에서 예약 신청이 가능합니다.'
						)
					)}
				</BodyContainerRow>
			</BodyContainer>
			{bookingCalendarModalData.show && (
				<BookingCalendarModal onClose={() => setBookingCalendarModalData({ show: false })} />
			)}
			{roomLocationGuideModalData.show && (
				<RoomLocationGuideModal
					onClose={() => setRoomLocationGuideModalData({ show: false })}
				></RoomLocationGuideModal>
			)}
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	height: 100%;
	margin-bottom: 90px;
`;

const BannerBackground = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	position: relative;
	width: 100%;
	height: calc(540 * 100vw / 1920);
	min-height: 540px;
	top: 0px;

	${(props) =>
		props.src &&
		css`
			background-image: url(${props.src});
			background-repeat: no-repeat;
			background-size: cover;
		`}

	@media only screen and (max-width: 767.98px) {
		height: 589px;
		min-height: 589px;
	}
`;

const TopContainer = styled.div`
	display: flex;
	align-items: center;
`;

const BannerContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: center;
	align-items: flex-start;
	height: 100%;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column-reverse;
	}
`;

const BoardContainer = styled.div`
	border: 3px solid rgba(255, 255, 255, 0.2);

	max-height: 360px;
	width: 780px;
	box-sizing: border-box;
	border-radius: 5px;

	padding: 45px;

	@media only screen and (max-width: 767.98px) {
		width: 320px;
		height: 230px;
		padding: 25px;
		margin-top: 15px;
	}
`;

const BoardTopContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	height: 25px;
`;

const BoardContentsContainer = styled.div`
	margin-top: 25px;
	width: 100%;
	height: 216px;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	box-sizing: border-box;

	@media only screen and (max-width: 767.98px) {
		margin-top: 10px;
		height: 160px;
	}
`;

const BoardContentsRow = styled.div`
	height: 54px;
	& + & {
		border-top: 1px solid rgba(255, 255, 255, 0.2);
	}

	display: flex;
	justify-content: flex-start;
	align-items: center;
	box-sizing: border-box;
	cursor: pointer;

	@media only screen and (max-width: 767.98px) {
		height: 40px;
	}
`;

const BoardTitle = styled.span`
	color: #ffffff;
	font-family: 'S-CoreDream';
	font-size: 25px;
	font-weight: 400;
	letter-spacing: -0.38px;

	@media only screen and (max-width: 767.98px) {
		font-size: 16px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: -0.24px;
	}
`;

const BoardMoreContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
	span {
		color: #ffffff;
		font-family: 'Noto Sans';
		font-size: 15px;
		font-weight: 500;
		letter-spacing: -0.23px;
	}

	@media only screen and (max-width: 767.98px) {
		span {
			font-size: 12px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: -0.18px;
		}
	}
`;

const MoreBtn = styled.img`
	margin-left: 20px;
	width: 6px;
	height: 8px;

	@media only screen and (max-width: 767.98px) {
		margin-left: 13px;
	}
`;

const BoardCategory = styled.div`
	width: 76px;
	height: 30px;

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 500;
	letter-spacing: -0.42px;
	border-radius: 15px;

	display: flex;
	justify-content: center;
	align-items: center;

	${(props) => {
		switch (props.type) {
			case 'category-1':
				return css`
					background-color: #609de8;
				`;
			case 'category-2':
				return css`
					background-color: #09a0a9;
				`;
			case 'category-3':
				return css`
					background-color: #e33d65;
				`;
			default:
				return css`
					background-color: #666666;
				`;
		}
	}}

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@media only screen and (max-width: 767.98px) {
		width: 60px;
		height: 26px;
		font-size: 13px;
		font-weight: 500;
		font-style: normal;
		letter-spacing: -0.39px;
	}
`;

const BoardContentTitle = styled.span`
	max-width: 439px;
	margin-left: 19px;
	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 18px;
	font-weight: 400;
	line-height: 55px;
	letter-spacing: -0.54px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	flex: 1;

	cursor: pointer;

	@media only screen and (max-width: 767.98px) {
		max-width: 175px;
		margin-left: 9px;
		font-size: 14px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: -0.42px;
		line-height: normal;
	}
`;

const BoardContentDate = styled.span`
	opacity: 0.4;
	color: #ffffff;
	font-size: 18px;
	font-weight: 400;
	letter-spacing: -0.54px;
	line-height: 100%;
	margin-left: 57px;
`;

const MyBookingContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 35px;

	@media only screen and (max-width: 767.98px) {
		margin-left: 0;
	}
`;

const MyContainer = styled.div`
	width: 420px;
	height: 360px;
	background-color: #2956a6;
	box-sizing: border-box;
	${(props) =>
		props.src &&
		css`
			background-image: url(${props.src});
			background-size: contain;
			background-repeat: no-repeat;
		`}
	border-radius: 5px;
	padding: 35px;

	@media only screen and (max-width: 767.98px) {
		width: 320px;
		height: 245px;
		margin-left: 0;
		padding: 24px;
	}
`;

const MyTopContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 24px;
`;

const MyContentsContainer = styled.div`
	width: 100%;
	height: 240px;
	background-color: white;
	margin-top: 30px;
	border-radius: 5px;
	padding: 25px;

	@media only screen and (max-width: 767.98px) {
		height: 170px;
		margin-top: 14px;
		padding: 15px;
	}
`;

const MyContentsRow = styled.div`
	height: 80px;
	display: flex;
	box-sizing: content-box;

	& + & {
		border-top: 1px solid #dddddd;
		margin-top: 15px;
		padding-top: 15px;
	}

	@media only screen and (max-width: 767.98px) {
		height: 60px;
		& + & {
			margin-top: 10px;
			padding-top: 10px;
		}
	}
`;

const MyContentsRowSeperator = styled.div`
	width: 100%;
	height: 1px;
	background-color: #dddddd;
	margin-top: 15px;
	margin-bottom: 15px;
`;

const MyStatusTitle = styled.span`
	color: #ffffff;
	font-family: 'S-CoreDream';
	font-size: 25px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.38px;

	@media only screen and (max-width: 767.98px) {
		color: #ffffff;
		font-family: 'S-CoreDream';
		font-size: 16px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: -0.24px;
	}
`;

const MyStatusMoreContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	span {
		color: #ffffff;
		font-family: 'Noto Sans';
		font-size: 15px;
		font-weight: 500;
		font-style: normal;
		letter-spacing: -0.23px;
	}
	cursor: pointer;

	@media only screen and (max-width: 767.98px) {
		span {
			font-size: 12px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: -0.18px;
		}
	}
`;

const NoBookingList = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;

	color: #666666;
	font-size: 18px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: normal;
	letter-spacing: -0.27px;
	text-align: center;
`;

const BookingStatusBox = styled.div`
	width: 80px;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;

	span {
		width: 40px;
		color: #ffffff;
		font-family: 'Noto Sans';
		font-size: 18px;
		font-weight: 500;
		font-style: normal;
		letter-spacing: normal;
		letter-spacing: -0.27px;
		text-align: center;
	}

	${(props) => {
		switch (props.status) {
			case ApprovalStatus.APPROVED:
				return css`
					background-color: #6e9ed8;
				`;
			case ApprovalStatus.PENDING:
			case ApprovalStatus.CANCELED:
			case ApprovalStatus.REJECTED:
				return css`
					background-color: #6d7784;
				`;
			default:
				break;
		}
	}}

	@media only screen and (max-width: 767.98px) {
		width: 60px;
		height: 60px;

		span {
			color: #ffffff;
			font-size: 15px;
			font-weight: 500;
			font-style: normal;
			letter-spacing: -0.29px;
		}
	}
`;

const BookingDetailContainer = styled.div`
	margin-left: 25px;
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;

	@media only screen and (max-width: 767.98px) {
		margin-left: 15px;
	}
`;

const BookingDetailRow = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	& + & {
		margin-top: 14px;
	}

	@media only screen and (max-width: 767.98px) {
		& + & {
			margin-top: 5px;
		}
	}
`;

const BookingDetailIcon = styled.img``;

const BookingCalendarText = styled.span`
	margin-left: 10px;
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 18px;
	font-weight: 700;
	letter-spacing: -0.27px;

	@media only screen and (max-width: 767.98px) {
		margin-left: 5px;
		font-size: 16px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: -0.31px;
	}
`;

const BookingLocationText = styled.span`
	margin-left: 9px;
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 16px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: normal;
	letter-spacing: -0.24px;

	@media only screen and (max-width: 767.98px) {
		margin-left: 5px;
		font-size: 14px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: -0.27px;
	}
`;

const MyBottomButtonContainer = styled.div`
	width: 100%;
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	gap: 10px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 10px;
	}
`;

const MyBottomButton = styled.button`
	width: 200px;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #00b1a6;
	outline: none;
	border: none;
	border-radius: 5px;
	color: #ffffff;
	font-weight: 600;
	font-size: 15px;
	white-space: nowrap;
	cursor: pointer;

	@media only screen and (max-width: 767.98px) {
		flex: 1 1 auto;
		width: 120px;
		font-size: 12px;
	}
`;

const MyBookingCalendarButton = styled(MyBottomButton)`
	background-color: #00b1a6;
`;

const RoomLocationGuideButton = styled(MyBottomButton)`
	background-color: #609ee9;
`;

const BodyContainer = styled.div`
	width: 100%;
	margin-top: 60px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		margin-top: 20px;
	}
`;

const BodyContainerRow = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	& + & {
		margin-top: 20px;
	}

	@media only screen and (max-width: 767.98px) {
		& + & {
			margin-top: 10px;
		}
	}
`;

const GroupBookingButton = styled.div`
	height: 160px;
	width: 400px;
	cursor: pointer;
	${(props) =>
		props.background &&
		css`
			background-image: url(${props.background});
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
		`}

	padding: 44px 31px 43px 45px;
	border-radius: 10px;
`;

const GroupBookingTitle = styled.span`
	height: 23px;
	color: #ffffff;
	font-family: 'S-CoreDream';
	font-size: 24px;
	font-weight: 400;
	letter-spacing: -0.72px;
`;

const GroupBookingDetail = styled.div`
	margin-top: 19px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
`;

const GroupBookingDetailText = styled.span`
	opacity: 0.5;
	color: #ffffff;
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: -0.42px;
	line-height: 11px;
`;

const GroupBookingMoreIcon = styled.img`
	width: 8px;
	height: 12px;
`;

const RowBookingButton = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	width: 400px;
	height: 160px;
	margin-left: 20px;
	padding: 44px 41px 30px 41px;

	cursor: pointer;
	${(props) =>
		props.background &&
		css`
			background-image: url(${props.background});
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
		`}

	border-radius: 10px;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		width: 155px;
		height: 155px;
		margin-left: 0;
		padding: 25px 25px 20px 20px;
		& + & {
			margin-left: 10px;
		}
	}
`;

const ButtonButtonHeader = styled.div`
	color: #48bbc7;
	font-family: 'Noto Sans';
	font-size: 12px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.36px;
	line-height: normal;
	margin-bottom: 5px;

	${(props) =>
		props.firstRow &&
		css`
			color: #6e9ed8;
		`}
`;

const BookingButtonTitle = styled.div`
	width: 211px;
	height: 32px;
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 24px;
	font-weight: 500;
	letter-spacing: -0.72px;

	@media only screen and (max-width: 767.98px) {
		width: 80px;
		height: 38px;
		font-size: 16px;
		font-weight: 500;
		font-style: normal;
		letter-spacing: -0.48px;
		line-height: normal;
		word-break: keep-all;
	}
`;

const RowBookingDetailContainer = styled.div`
	margin-top: 15px;
	span {
		height: 14px;

		font-size: 14px;
		font-weight: 500;
		font-style: normal;
		letter-spacing: normal;
		letter-spacing: -0.42px;
		${(props) =>
			props.isFirstRow
				? css`
						color: #357dc7;
				  `
				: css`
						color: #13b2b8;
				  `}
	}
`;

const RowBookingMoreIcon = styled.img`
	margin-left: 26px;
`;

const RowBookingIcon = styled.img`
	align-self: flex-end;

	@media only screen and (max-width: 767.98px) {
		max-width: 42px;
		max-height: 36px;
	}
`;

const LoginContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const LoginMainText = styled.span`
	margin-top: 30px;
	color: #858585;
	font-size: 18px;
	font-weight: 700;
	letter-spacing: -0.27px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 15px;
	}
`;

const LoginSubText = styled.span`
	color: #dadada;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: -0.26px;
	margin-bottom: 30px;

	@media only screen and (max-width: 767.98px) {
		margin-bottom: 15px;
	}
`;

const LoginButtonContainer = styled.div`
	cursor: pointer;
	display: flex;
	width: 100%;
	height: 62px;
	justify-content: center;
	align-items: center;
	align-self: stretch;
	margin-top: 10px;
	border-radius: 7px;
	background-color: #09a0a9;

	span {
		display: flex;
		flex-grow: 1;
		color: #ffffff;
		font-size: 18px;
		font-weight: 700;
		line-height: 18px;
		letter-spacing: -0.54px;
		justify-content: center;
		align-items: center;
		margin-right: 24px;
	}

	@media only screen and (max-width: 767.98px) {
		margin-top: 0;
	}
`;

const LoginButtonIcon = styled.img`
	margin-left: 24px;
`;

export default Main;
