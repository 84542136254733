import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

const Setting = () => {
	const navigate = useNavigate();
	useEffect(() => {
		navigate('/admin/settings/unit/room/categories');
	}, []);

	return <></>;
};

export default Setting;
