import React, { useEffect, useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import useSizeDetector from '../hooks/useSizeDetector';
import UnitTypeTab from '../components/UnitTypeTab';
import CategoryTab from '../components/CategoryTab';
import CategorySlug from '../constants/CategorySlug';
import UnitType from '../constants/UnitType';

import * as api from '../apis';

import TitleTailImg from '../assets/images/common/title_tail.svg';
import TitleTailMobileImg from '../assets/images/common/title_tail_mobile.svg';
import BreadcrumbHomeImg from '../assets/images/common/breadcrumb_home.svg';
import BreadcrumbSeparatorImg from '../assets/images/common/breadcrumb_separator.svg';
import CalendarImg from '../assets/images/screens/Introduce/calendar.svg';
import NoUnitThumbnailImg from '../assets/images/common/no_unit_thumbnail.png';

const Introduce = () => {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);
	const { isDesktop } = useSizeDetector();
	const [unitType, setUnitType] = useState(UnitType.ROOM);
	const [categoryList, setCategoryList] = useState(null);
	const [typeCategoryItemList, setTypeCategoryItemList] = useState([]);
	const [typeCategoryItemId, setTypeCategoryItemId] = useState(null);
	const [unitList, setUnitList] = useState(null);
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		api.getCategories()
			.then((res) => {
				if (res && res.data) {
					setCategoryList(res.data);
					setUnitType(UnitType.ROOM);
					setInitialized(true);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(() => {
		if (!categoryList) {
			return;
		}
		let categorySlug = CategorySlug.TYPE;
		switch (unitType) {
			case UnitType.ROOM:
				categorySlug = CategorySlug.TYPE;
				break;
			case UnitType.PRODUCT:
				categorySlug = CategorySlug.PRODUCT_TYPE;
				break;
			case UnitType.CONSULT:
				categorySlug = CategorySlug.CONSULT_TYPE;
				break;
		}
		let typeCategory = categoryList.find((category) => category.slug === categorySlug);
		setTypeCategoryItemList(typeCategory.items);
		setTypeCategoryItemId(typeCategory.items[0].id);
	}, [unitType, categoryList]);

	useEffect(() => {
		if (!typeCategoryItemId) {
			return;
		}

		let filterParams = [
			`category_item_ids[][]=${typeCategoryItemId}`,
			`published[]=true`,
			`page_size=${Number.MAX_SAFE_INTEGER}`,
		];
		api.getUnits(filterParams)
			.then((res) => {
				if (res && res.data) {
					setUnitList(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, [typeCategoryItemId]);

	const getTypeCategoryName = (typeCategoryId) => {
		return typeCategoryItemList.find((typeCategory) => typeCategory.id == typeCategoryId)?.name || '';
	};

	const getUnitTypeTabs = () => {
		let unitTypeTabs = [{ key: UnitType.ROOM, text: '시설소개' }];
		if (JSON.parse(process.env.REACT_APP_USE_TYPE_PRODUCT ?? false)) {
			unitTypeTabs.push({ key: UnitType.PRODUCT, text: '장비소개' });
		}
		if (JSON.parse(process.env.REACT_APP_USE_TYPE_CONSULT ?? false)) {
			unitTypeTabs.push({ key: UnitType.CONSULT, text: '상담소개' });
		}
		return unitTypeTabs;
	};

	const getShortDescriptionTitle = () => {
		switch (unitType) {
			case UnitType.ROOM:
				return '시설소개';
			case UnitType.PRODUCT:
				return '요약소개';
			case UnitType.CONSULT:
				return '상담프로그램 소개';
		}

		return '시설소개';
	};

	const getPurposeTitle = () => {
		switch (unitType) {
			case UnitType.ROOM:
			case UnitType.PRODUCT:
				return '사용용도';
			case UnitType.CONSULT:
				return '상담활용';
		}

		return '사용용도';
	};

	const getEqipmentTitle = () => {
		switch (unitType) {
			case UnitType.ROOM:
				return '제작장비';
			case UnitType.PRODUCT:
				return '제공장비';
			case UnitType.CONSULT:
				return '키워드';
		}

		return '제작장비';
	};

	const onClickHome = () => {
		navigate('/');
	};

	const onClickBooking = (unitId) => {
		if (!user) {
			navigate('/login');
			return;
		}

		switch (unitType) {
			case UnitType.ROOM:
				navigate(`/booking/schedule/${unitId}`);
				break;
			case UnitType.PRODUCT:
				navigate(`/booking/product/schedule/${unitId}`);
				break;
			case UnitType.CONSULT:
				navigate(`/booking/consult/schedule/${unitId}`);
				break;
		}
	};

	if (!initialized) return null;

	return (
		<Content>
			<TitleContainer>
				<Title>예약유형 소개</Title>
				<TitleTail src={isDesktop ? TitleTailImg : TitleTailMobileImg} />
			</TitleContainer>
			{/* 장비, 상담을 사용하는 경우에만 UnitTypeTab 표시 */}
			{(JSON.parse(process.env.REACT_APP_USE_TYPE_PRODUCT) ||
				JSON.parse(process.env.REACT_APP_USE_TYPE_CONSULT)) && (
				<UnitTypeTab
					tabs={getUnitTypeTabs()}
					value={unitType}
					onChange={(changedType) => setUnitType(changedType)}
				/>
			)}
			<StyledCategoryTab
				tabs={typeCategoryItemList.map((typeCategory) => {
					return {
						key: typeCategory.id,
						text: typeCategory.name,
					};
				})}
				value={typeCategoryItemId}
				onChange={(changedCategoryId) => {
					setTypeCategoryItemId(changedCategoryId);
				}}
			/>
			<Breadcrumb>
				<HomeIcon src={BreadcrumbHomeImg} onClick={onClickHome} />
				<BreadcrumbSeparator src={BreadcrumbSeparatorImg} />
				<BreadcrumbText>안내 및 소개</BreadcrumbText>
				<BreadcrumbSeparator src={BreadcrumbSeparatorImg} />
				<BreadcrumbText>예약 유형 소개</BreadcrumbText>
				<BreadcrumbSeparator src={BreadcrumbSeparatorImg} />
				<BreadcrumbText current>{`${UnitType.toString(unitType)}소개`}</BreadcrumbText>
			</Breadcrumb>
			<UnitContainer>
				<CategoryTitle>{getTypeCategoryName(typeCategoryItemId)}</CategoryTitle>
				{unitList?.items.map((unit, _) => {
					return (
						<UnitRow key={_}>
							{unit.thumbnail ? (
								<Thumbnail
									src={`${process.env.REACT_APP_API_URL}/storage/files/${unit.thumbnail?.filename}`}
								/>
							) : (
								<Thumbnail src={NoUnitThumbnailImg} />
							)}
							<UnitDetailContainer>
								<UnitDetailWrapperContainer>
									<UnitDetailTitleText>{unit.name}</UnitDetailTitleText>
									<BookingButton onClick={() => onClickBooking(unit.id)}>
										<CalendarIcon src={CalendarImg} />
										<span>예약하기</span>
									</BookingButton>
								</UnitDetailWrapperContainer>
								<UnitDetailBodyContainer>
									<UnitDetailRow>
										<UnitDetailRowTitle width={unitType === UnitType.CONSULT ? '120px' : '80px'}>
											{getShortDescriptionTitle()}
										</UnitDetailRowTitle>
										<UnitDetailRowDescription>{unit.short_description}</UnitDetailRowDescription>
									</UnitDetailRow>
									<UnitDetailRow>
										<UnitDetailRowTitle width={unitType === UnitType.CONSULT ? '120px' : '80px'}>
											{getPurposeTitle()}
										</UnitDetailRowTitle>
										<UnitDetailRowDescription>{unit.purpose}</UnitDetailRowDescription>
									</UnitDetailRow>
									<UnitDetailRow>
										<UnitDetailRowTitle width={unitType === UnitType.CONSULT ? '120px' : '80px'}>
											{getEqipmentTitle()}
										</UnitDetailRowTitle>
										<UnitDetailRowDescription>{unit.equipment}</UnitDetailRowDescription>
									</UnitDetailRow>
								</UnitDetailBodyContainer>
							</UnitDetailContainer>
						</UnitRow>
					);
				})}
			</UnitContainer>
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	margin-bottom: 151px;
	background-color: white;

	@media only screen and (max-width: 767.98px) {
		margin: 20px 0 53px 0;
		overflow: hidden;
	}
`;

const TitleContainer = styled.div`
	margin-top: 65px;
	margin-bottom: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		margin-top: 0;
		margin-bottom: 25px;
	}
`;

const Title = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 42px;
	line-height: 50px;
	text-align: center;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		font-size: 24px;
		line-height: 29px;
	}
`;

const TitleTail = styled.img`
	margin-top: 13px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 5px;
	}
`;

const StyledCategoryTab = styled(CategoryTab)`
	margin-top: 30px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 10px;
	}
`;

const Breadcrumb = styled.div`
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;

	@media only screen and (max-width: 767.98px) {
		justify-content: center;
	}
`;

const HomeIcon = styled.img`
	cursor: pointer;
`;

const BreadcrumbSeparator = styled.img`
	margin: 0 10px;
`;

const BreadcrumbText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;

	${(props) =>
		props.current &&
		css`
			color: #22499d;
		`}

	@media only screen and (max-width: 767.98px) {
		font-size: 12px;
		line-height: 14px;
	}
`;

const UnitContainer = styled.div`
	margin-top: 20px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 10px;
	}
`;

const CategoryTitle = styled.div`
	margin-bottom: 40px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 45px;
	line-height: 54px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		margin-bottom: 20px;
		width: 100%;
		text-align: center;
		font-size: 25px;
		line-height: 30px;
	}
`;

const UnitRow = styled.div`
	display: flex;
	height: auto;
	border: 1px solid #dddddd;
	padding: 25px;
	box-sizing: border-box;
	& + & {
		margin-top: 30px;
	}

	@media only screen and (max-width: 767.98px) {
		flex-direction: column-reverse;
		width: calc(100% - 20px);
		height: auto;
		margin: 0 10px;
		padding: 10px;

		& + & {
			margin-top: 10px;
		}
	}
`;

const Thumbnail = styled.img`
	width: 480px;
	min-width: 480px;
	height: 280px;
	object-fit: contain;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		min-width: 100%;
	}
`;

const EmptyThumbnail = styled.div`
	width: 480px;
	min-width: 480px;
	height: 280px;
	background-color: #dddddd;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		min-width: 100%;
	}
`;

const UnitDetailContainer = styled.div`
	margin-left: 40px;
	width: 100%;
	display: flex;
	flex-direction: column;

	@media only screen and (max-width: 767.98px) {
		margin-left: 0;
	}
`;

const UnitDetailWrapperContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

const UnitDetailTitleText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 36px;
	color: #000000;
	width: 550px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@media only screen and (max-width: 767.98px) {
		font-size: 18px;
		line-height: 22px;
		width: auto;
	}
`;

const BookingButton = styled.div`
	flex-shrink: 0;
	width: 105px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #454655;
	border-radius: 5px;
	cursor: pointer;

	span {
		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		display: flex;
		align-items: center;
		color: #ffffff;
	}

	&:hover {
		background-color: #1d1e31;
	}

	@media only screen and (max-width: 767.98px) {
		width: 84px;
		height: 31px;
		span {
			font-size: 13px;
			line-height: 16px;
		}
	}
`;

const CalendarIcon = styled.img`
	margin-right: 5px;

	@media only screen and (max-width: 767.98px) {
		width: 14px;
	}
`;

const UnitDetailBodyContainer = styled.div`
	height: auto;
	min-height: 228px;
	margin-top: 15px;
	padding: 10px 25px;
	display: flex;
	flex-direction: column;
	border: 5px solid #eeeeee;

	@media only screen and (max-width: 767.98px) {
		height: auto;
		margin-top: 0;
		margin-bottom: 8px;
		padding: 0;
		border: none;
	}
`;

const UnitDetailRow = styled.div`
	box-sizing: border-box;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 15px 0;
	flex-grow: 1;

	&:not(:last-child) {
		border-bottom: 1px solid #cccccc;
	}

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		padding: 12px 0;
	}
`;

const UnitDetailRowTitle = styled.div`
	width: ${(props) => props.width};
	align-self: flex-start;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #22499d;

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
		line-height: 17px;
	}
`;

const UnitDetailRowDescription = styled.div`
	align-self: flex-start;
	max-width: 500px;
	margin-left: 25px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #333333;
	word-break: keep-all;

	@media only screen and (max-width: 767.98px) {
		margin-left: 0;
		margin-top: 5px;
		font-size: 13px;
		line-height: 16px;
	}
`;

export default Introduce;
