import styled, { css } from 'styled-components';

const ApprovalStatusLegend = () => {
	return (
		<Container>
			<ApprovalStatusContainer>
				<ApprovalStatusCircle status="approved"></ApprovalStatusCircle>
				<ApprovalStatusText>승인됨</ApprovalStatusText>
			</ApprovalStatusContainer>
			<ApprovalStatusContainer>
				<ApprovalStatusCircle status="pending"></ApprovalStatusCircle>
				<ApprovalStatusText>승인대기</ApprovalStatusText>
			</ApprovalStatusContainer>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
`;

const ApprovalStatusContainer = styled.div`
	display: flex;
	align-items: center;
	& + & {
		margin-left: 20px;
	}
`;

const ApprovalStatusCircle = styled.div`
	width: 8px;
	height: 8px;
	border: 4px solid;
	border-radius: 4px;

	${(props) =>
		props.status == 'approved' &&
		css`
			border-color: #3788d8;
		`}
	${(props) =>
		props.status == 'pending' &&
		css`
			border-color: #fead0a;
		`}
`;

const ApprovalStatusText = styled.span`
	margin-left: 4px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 12px;
	color: #000000;
`;

export default ApprovalStatusLegend;
