import { useMediaQuery } from 'react-responsive';

const useAdminSizeDetector = () => {
	const isDesktop = useMediaQuery({ minWidth: 1024 });
	const isTabletHorizontal = useMediaQuery({ maxWidth: 1023.98, minWidth: 768 });
	const isTabletVertical = useMediaQuery({ maxWidth: 767.98, minWidth: 480 });
	const isMobile = useMediaQuery({ maxWidth: 479.98 });

	const Desktop = ({ children }) => {
		return isDesktop ? children : null;
	};

	const TabletHorizontal = ({ children }) => {
		return isTabletHorizontal ? children : null;
	};

	const TabletVertical = ({ children }) => {
		return isTabletVertical ? children : null;
	};

	const Mobile = ({ children }) => {
		return isMobile ? children : null;
	};

	return {
		isDesktop,
		isTabletHorizontal,
		isTabletVertical,
		isMobile,
		Desktop,
		TabletHorizontal,
		TabletVertical,
		Mobile,
	};
};

export default useAdminSizeDetector;
