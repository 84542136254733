import React from 'react';
import styled from 'styled-components';

import TitlePrefixImg from '../../assets/images/common/title_prefix.png';

const AdminSectionTitle = ({ title, subTitle }) => {
	return (
		<Title>
			<TitlePrefixContainer src={TitlePrefixImg} />
			<TitleText>{title}</TitleText>
			{subTitle && <SubTitleText>{subTitle}</SubTitleText>}
		</Title>
	);
};

const Title = styled.div`
	display: flex;
	align-items: center;
`;

const TitlePrefixContainer = styled.img`
	width: 12px;
	height: 12px;
`;

const TitleText = styled.span`
	margin-left: 9px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	color: #09386f;
	word-break: break-all;

	@media only screen and (max-width: 767.98px) {
		font-size: 18px;
		margin-left: 10px;
	}
`;

const SubTitleText = styled.span`
	margin-left: 17px;
	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	text-align: left;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

export default AdminSectionTitle;
