import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import useSizeDetector from '../hooks/useSizeDetector';

import * as api from '../apis';
import ApprovalStatus from '../constants/ApprovalStatus';

import BookingCompleteIconImage from '../assets/images/screens/Booking/booking_complete.svg';
import BookingApprovalWaitIconImage from '../assets/images/screens/Booking/booking_approval_wait.svg';
import ProgressCircleActiveIconImage from '../assets/images/screens/Booking/progress_circle_active.svg';
import ProgressCircleInactiveIconImage from '../assets/images/screens/Booking/progress_circle_inactive.svg';

const BookingComplete = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { isDesktop } = useSizeDetector();

	const [bookingData, setBookingData] = useState(null);
	const [isWait, setIsWait] = useState(false);

	useEffect(() => {
		if (!location.state?.booking_id) {
			alert('예약 정보가 없습니다.');
			navigate('/booking');
			return;
		}

		api.getBooking(location.state?.booking_id)
			.then((res) => {
				if (res && res.data) {
					setBookingData(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(() => {
		if (bookingData) {
			setIsWait(bookingData?.approval_status !== ApprovalStatus.APPROVED);
		}
	}, [bookingData]);

	const onClickConfirm = () => {
		navigate('/mypage', {
			state: {
				unit_type: bookingData.unit.unit_type_name,
			},
		});
	};

	return (
		<Content>
			<BookingCompleteIcon
				isWait={isWait}
				src={isWait ? BookingApprovalWaitIconImage : BookingCompleteIconImage}
			/>
			<Title>{isWait ? '신청 승인 대기' : '예약 신청 완료'}</Title>
			<BookingId>{`예약 번호 #${bookingData?.id ?? '0'}`}</BookingId>
			<ProgressContainer>
				<ProgressBarContainer>
					<ProgressBarCircle />
					<ProgressBarLine />
					<ProgressBarCircle />
					<ProgressBarLine deActivate={isWait} />
					<ProgressBarCircle deActivate={isWait} />
				</ProgressBarContainer>
				<ProgressTextContainer>
					<ProgressText>예약 신청</ProgressText>
					<ProgressText>신청 내역 확인</ProgressText>
					<ProgressText deActivate={isWait}>예약 승인</ProgressText>
				</ProgressTextContainer>
			</ProgressContainer>
			{isWait && (
				<ApprovalWaitInformation>
					※ 신청 내역에 대한 담당자 승인 완료 이후 예약 신청이 완료됩니다.
				</ApprovalWaitInformation>
			)}
			<ContactContainer isWait={isWait}>
				<ContactTitle>예약 관련 문의</ContactTitle>
				<ContactNumber>{location.state?.contact_number ?? '-'}</ContactNumber>
			</ContactContainer>
			<ButtonSeparator />
			<ConfirmButton onClick={onClickConfirm}>완료</ConfirmButton>
		</Content>
	);
};

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 100%;
	max-width: 1240px;
	margin-top: 50px;
	margin-bottom: 145px;
	padding: 110px 0;
	background-color: white;

	@media only screen and (max-width: 767.98px) {
		width: calc(100% - 20px);
		margin: 10px;
		padding: 48px 10px;
	}
`;

const BookingCompleteIcon = styled.img`
	width: 60px;
	height: 60px;

	@keyframes rotate {
		from {
			-webkit-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		to {
			-webkit-transform: rotate(360deg);
			-o-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}

	${(props) =>
		props.isWait &&
		css`
			animation: rotate 3s linear infinite;
		`}

	@media only screen and (max-width: 767.98px) {
		width: 50px;
		height: 50px;
	}
`;

const Title = styled.span`
	margin-top: 26px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	line-height: 43px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		margin-top: 10px;
		font-size: 24px;
		line-height: 29px;
	}
`;

const BookingId = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	padding: 15px 30px;
	margin-top: 40px;
	border: 1px solid #22499d;
	border-radius: 5px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	color: #22499d;

	@media only screen and (max-width: 767.98px) {
		margin-top: 20px;
		padding: 10px 20px;
		font-size: 16px;
		line-height: 19px;
	}
`;

const ProgressContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	width: 570px;
	height: 55px;
	margin-top: 75px;

	@media only screen and (max-width: 767.98px) {
		width: 228px;
		margin-top: 40px;
	}
`;

const ProgressBarContainer = styled.div`
	display: flex;
	align-items: center;
	position: relative;
`;

const ProgressBarCircle = styled.i`
	width: 26px;
	height: 26px;
	background-image: url(${ProgressCircleActiveIconImage});

	& ~ & {
		margin-left: 209px;
	}

	${(props) =>
		props.deActivate &&
		css`
			background-image: url(${ProgressCircleInactiveIconImage});
		`}

	@media only screen and (max-width: 767.98px) {
		& ~ & {
			margin-left: 83px;
		}
	}
`;

const ProgressBarLine = styled.div`
	position: absolute;
	left: 26px;
	width: 209px;
	height: 5px;
	background-color: #22499d;

	& ~ & {
		left: 261px;
	}
	${(props) =>
		props.deActivate &&
		css`
			background-color: #d2d2d2;
		`}

	@media only screen and (max-width: 767.98px) {
		width: 83px;
		& ~ & {
			left: 135px;
		}
	}
`;

const ProgressTextContainer = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	width: 100%;
	margin-top: 15px;

	@media only screen and (max-width: 767.98px) {
		margin-left: -50px;
		margin-top: 10px;
	}
`;

const ProgressText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;

	${(props) =>
		props.deActivate &&
		css`
			color: #a0a0a0;
		`}
	& + & {
		margin-left: 135px;
	}

	@media only screen and (max-width: 767.98px) {
		flex-shrink: 0;
		font-size: 16px;
		line-height: 19px;
		& + & {
			margin-left: 34px;
		}
	}
`;

const ApprovalWaitInformation = styled.div`
	margin-top: 35px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #009bcb;

	@media only screen and (max-width: 767.98px) {
		padding: 0 12px;
		font-size: 16px;
		line-height: 19px;
	}
`;

const ContactContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 546px;
	margin-top: 50px;
	padding: 39px 0;
	background-color: #f0f4fd;
	border-radius: 5px;

	${(props) =>
		props.isWait &&
		css`
			margin-top: 30px;
		`}

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: auto;
		padding: 22px 17px;
		flex-direction: column;
	}
`;

const ContactTitle = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		font-size: 16px;
		line-height: 19px;
	}
`;

const ContactNumber = styled.div`
	margin-left: 20px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 36px;
	color: #22499d;

	@media only screen and (max-width: 767.98px) {
		margin-left: 0;
		margin-top: 5px;
		font-size: 22px;
		line-height: 26px;
	}
`;

const ButtonSeparator = styled.div`
	margin: 50px 0;
	width: 546px;
	height: 1px;
	background-color: #dddddd;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		margin: 30px 0;
	}
`;

const ConfirmButton = styled.div`
	width: 125px;
	height: 50px;
	background-color: #22499d;
	border-radius: 5px;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #ffffff;

	&:hover {
		background-color: #0c438d;
	}
`;

export default BookingComplete;
