export default {
	COMMON: 'common',
	SSO: 'sso',
	toString: (val) => {
		switch (val) {
			case 'common':
				return '일반';
			case 'sso':
				return '통합';
			default:
				return '-';
		}
	},
};
