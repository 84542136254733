import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SystemScheduleModal from '../../../components/modal/SystemScheduleModal';

import * as api from '../../../apis';

const ScheduleSetting = () => {
	const [scheduleList, setScheduleList] = useState([]);

	const [scheduleModalData, setScheduleModalData] = useState({
		show: false,
		id: null,
		title: null,
		startDate: null,
		endDate: null,
		allowStatus: true,
	});

	const [allChecked, setAllChecked] = useState(false);

	useEffect(() => {
		getSchedulesFromApi();
	}, []);

	useEffect(() => {
		setScheduleList(
			scheduleList.map((data) => {
				return {
					...data,
					selected: allChecked,
				};
			})
		);
	}, [allChecked]);

	const getSchedulesFromApi = () => {
		api.getSystemSchedules()
			.then((res) => {
				if (res && res.data) {
					setScheduleList(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const onAddButtonClick = () => {
		setScheduleModalData({
			show: true,
			id: null,
			name: null,
			startDate: null,
			endDate: null,
			allowStatus: true,
		});
	};

	const onModifyButtonClick = (data) => {
		setScheduleModalData({
			show: true,
			id: data.id,
			name: data.name,
			startDate: data.start_date,
			endDate: data.end_date,
			allowStatus: data.allow_status,
		});
	};

	const onClickDeleteSelected = () => {
		const selectedSchedule = scheduleList.filter((val) => val.selected);
		if (selectedSchedule.length === 0) {
			alert('선택한 일정이 없습니다.');
			return;
		}

		if (!window.confirm(`선택한 ${selectedSchedule.length}개의 스케쥴을 정말 삭제하시겠습니까?`)) return;

		let promiseArr = [];
		selectedSchedule.map((schedule) => {
			promiseArr.push(api.deleteSystemSchedule(schedule.id));
		});
		Promise.all(promiseArr).then(() => {
			alert('선택한 스케쥴을 정상적으로 삭제하였습니다.');
			getSchedulesFromApi();
		});
	};

	const onAddSystemSchedule = (name, startDate, endDate, allowStatus) => {
		const params = {
			name: name,
			start_date: startDate,
			end_date: endDate,
			allow_status: allowStatus,
		};
		if (scheduleModalData.id) {
			api.updateSystemSchedule(scheduleModalData.id, params)
				.then((res) => {
					if (res && res.data) {
						getSchedulesFromApi();
					}
				})
				.catch((err) => {
					console.error(err);
				});
		} else {
			api.createSystemSchedule(params)
				.then((res) => {
					if (res && res.data) {
						getSchedulesFromApi();
					}
				})
				.catch((err) => {
					console.error(err);
				});
		}

		setScheduleModalData({ show: false });
	};

	return (
		<Content>
			<TopContainer>
				<SectionTitleContainer>
					<SectionTitle>학교 일정 관리</SectionTitle>
					<SectionSubTitle>{`전체 ${scheduleList.length}개`}</SectionSubTitle>
				</SectionTitleContainer>
				<AddButton onClick={onAddButtonClick}>일정 등록</AddButton>
			</TopContainer>
			<section>
				<ScheduleListTableContainer>
					<ScheduleListTable>
						<thead>
							<tr>
								<th style={{ minWidth: '50px' }}>
									<input
										type="checkbox"
										checked={allChecked}
										onChange={(e) => setAllChecked(e.currentTarget.checked)}
									/>
								</th>
								<th style={{ minWidth: '50px' }}>번호</th>
								<th style={{ minWidth: '150px' }}>제목</th>
								<th style={{ minWidth: '180px' }}>기간</th>
								<th>예약허용</th>
								<th style={{ minWidth: '180px' }}>등록일</th>
								<th style={{ minWidth: '72px' }}>수정</th>
							</tr>
						</thead>
						<tbody>
							{scheduleList?.map((data, idx) => {
								return (
									<tr key={idx}>
										<td>
											<input
												type="checkbox"
												checked={data.selected}
												onChange={(e) =>
													setScheduleList(
														scheduleList.map((data, dataIdx) => {
															if (idx === dataIdx) {
																return {
																	...data,
																	selected: e.currentTarget.checked,
																};
															}
															return data;
														})
													)
												}
											/>
										</td>
										<td>{idx + 1}</td>
										<td>{data.name ?? '-'}</td>
										<td>{`${data.start_date ?? '-'} ~ ${data.end_date ?? '-'}`}</td>
										<td>{data.allow_status ? '허용' : '비허용'}</td>
										<td>{data.created_at ? new Date(data.created_at).toLocaleString() : '-'}</td>
										<td>
											<ModifyButton onClick={() => onModifyButtonClick(data)}>수정</ModifyButton>
										</td>
									</tr>
								);
							})}
						</tbody>
					</ScheduleListTable>
				</ScheduleListTableContainer>
				<ScheduleListTableBottomContainer>
					<DeleteButton onClick={onClickDeleteSelected}>선택삭제</DeleteButton>
				</ScheduleListTableBottomContainer>
			</section>

			{scheduleModalData.show && (
				<SystemScheduleModal
					defaultData={scheduleModalData}
					onConfirm={(name, startDate, endDate, allowStatus) =>
						onAddSystemSchedule(name, startDate, endDate, allowStatus)
					}
					onClose={() => setScheduleModalData({ show: false })}
				/>
			)}
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 40px;
	background-color: white;

	@media only screen and (max-width: 1023.98px) {
		padding: 40px 20px;
	}

	@media only screen and (max-width: 479.98px) {
		padding: 20px 10px;
	}
`;

const TopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const SectionTitleContainer = styled.div`
	display: flex;
	align-items: center;
`;

const SectionTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;
`;

const SectionSubTitle = styled.span`
	margin-left: 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
	color: #666666;
`;

const AddButton = styled.div`
	height: 36px;
	padding: 0 15px;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: #454655;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #ffffff;

	&:hover {
		background-color: #1d1e31;
	}
`;

const ScheduleListTableContainer = styled.div`
	max-width: 1160px;
	overflow-x: scroll;
`;

const ScheduleListTable = styled.table`
	width: 100%;

	border-collapse: collapse;
	border-top: 2px solid #0e498f;

	text-align: center;
	margin-top: 20px;
	thead th {
		height: 45px;
		background-color: #f6f8fb;
		border-right: 1px solid #dddddd;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #0e498f;
		white-space: nowrap;

		:first-child {
			border-left: 1px solid #dddddd;
		}
	}
	thead tr {
		border-bottom: 1px solid #dcdcdc;
	}

	tbody td {
		height: 45px;

		border-right: 1px solid #dddddd;
		padding: 6px 2px;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #333333;

		word-break: break-all;
		:first-child {
			border-left: 1px solid #dddddd;
		}
	}
	tbody tr {
		border-bottom: 1px solid #dddddd;
	}
`;

const ScheduleListTableBottomContainer = styled.div`
	height: 45px;
	display: flex;
	align-items: center;
	background: #f7f7f7;
	border-bottom: 1px solid #dddddd;
`;

const DeleteButton = styled.button`
	width: 60px;
	height: 28px;
	margin-left: 8px;

	background-color: #ffffff;
	border: 1px solid #333333;
	border-radius: 5px;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;

	white-space: nowrap;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #333333;

	&:hover {
		border: 1px solid #2b62ab;
		color: #2b62ab;
	}
`;

const ModifyButton = styled.div`
	width: 43px;
	height: 26px;

	display: inline-flex;
	align-items: center;
	justify-content: center;

	background-color: #2f5a8c;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #ffffff;
`;

export default ScheduleSetting;
