export default {
	PARTICIPATION_READY: 'participation_ready', // 상담대기
	PARTICIPATION_COMPLETED: 'participation_completed', // 상담 완료
	PARTICIPATION_ABSENT: 'participation_absent', // 불참
	PARTICIPATION_PENDING: 'participation_pending', // 보류
	PARTICIPATION_SURVEY_COMPLETED: 'participation_survey_completed', // 설문 참여
	PARTICIPATION_SURVEY_ABSENT: 'participation_survey_absent', // 설문 미참여

	toString: function (value) {
		switch (value) {
			case this.PARTICIPATION_READY:
				return '상담대기';
			case this.PARTICIPATION_COMPLETED:
				return '상담완료';
			case this.PARTICIPATION_ABSENT:
				return '불참';
			case this.PARTICIPATION_PENDING:
				return '보류';
			case this.PARTICIPATION_SURVEY_COMPLETED:
				return '설문참여';
			case this.PARTICIPATION_SURVEY_ABSENT:
				return '설문미참여';
			default:
				return '-';
		}
	},
};
