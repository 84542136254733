import React from 'react';
import styled, { css } from 'styled-components';

import CategoryTabShadowImg from '../assets/images/common/category_tab_shadow.svg';

const CategoryTab = ({ tabs, value, onChange, ...props }) => {
	return (
		<Container {...props}>
			{tabs?.map((tab, idx) => {
				return (
					<TabItem key={idx} selected={tab.key === value} onClick={() => onChange(tab.key)}>
						{tab.text}
						<CategoryTabShadow src={CategoryTabShadowImg} />
					</TabItem>
				);
			})}
		</Container>
	);
};

const Container = styled.div`
	display: flex;

	@media only screen and (max-width: 767.98px) {
		flex-wrap: wrap;
	}
`;

const TabItem = styled.div`
	position: relative;
	width: 100%;
	height: 60px;

	display: flex;
	align-items: center;
	justify-content: center;

	border: solid #dddddd;
	border-width: 1px 0px 1px 1px;
	background-color: #f5f5f5;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #000000;

	&:last-child {
		border-width: 1px;
	}

	${(props) =>
		props.selected &&
		css`
			background-color: #ffffff;
			border-top: 3px solid #22499d;

			&:not(:last-child) {
				& > ${CategoryTabShadow} {
					display: block;
				}
			}
		`}

	@media only screen and (max-width: 767.98px) {
		width: 33.33%;
		height: 45px;
		font-size: 16px;
		line-height: 19px;
	}
`;

const CategoryTabShadow = styled.img`
	display: none;
	position: absolute;
	top: 0;
	right: -14px;
	z-index: 98;

	@media only screen and (max-width: 767.98px) {
		right: -11px;
		height: 41px;
	}
`;

export default CategoryTab;
