export default {
	RENTAL_READY: 'rental_ready',
	RENTAL_COMPLETE: 'rental_complete',
	RENTAL_DELAY: 'rental_delay',
	RETURN_COMPLETE: 'return_complete',
	RETURN_DELAY: 'return_delay',
	toString: (value) => {
		switch (value) {
			case 'rental_ready':
				return '수령대기';
			case 'rental_complete':
				return '수령완료';
			case 'rental_delay':
				return '수령지연';
			case 'return_complete':
				return '반납완료';
			case 'return_delay':
				return '반납지연';
			default:
				return '-';
		}
	},
	toShortString: (value) => {
		switch (value) {
			case 'rental_ready':
				return '수령대기';
			case 'rental_complete':
				return '수령완료';
			case 'rental_delay':
				return '수령지연';
			case 'return_complete':
				return '반납완료';
			case 'return_delay':
				return '반납지연';
			default:
				return '-';
		}
	},
};
