import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';

import BoardSlug from '../../../constants/BoardSlug';
import CategoryItemModal from '../../../components/modal/CategoryItemModal';

import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

import { usePrompt } from '../../../hooks/blocker';

import * as api from '../../../apis';

const BoardCategorySetting = () => {
	const [categoryItemModalData, setCategoryItemModalData] = useState({
		show: false,
		board_id: null,
		category_item_id: null,
		category_item_name: null,
	});

	const [boards, setBoards] = useState(null);

	useEffect(() => {
		refreshCategories();
	}, []);

	const refreshCategories = () => {
		api.getBoards()
			.then((res) => {
				if (res && res.data) {
					setBoards(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const getCategoryModified = () => {
		if (!boards) return false;
		return boards.some((board) => board.modified === true);
	};

	usePrompt('저장하지 않은 분류가 있습니다.\n페이지를 벗어나시겠습니까?', getCategoryModified());

	const onUpdateCategory = (boardId) => {
		let postCategories = boards.find((board) => board.id === boardId).post_categories;
		postCategories = postCategories.map((postCategory, index) => {
			return {
				...postCategory,
				id: `${postCategory.id}`.includes('new') ? null : postCategory.id,
				order: index + 1,
			};
		});

		api.updatePostCategories(boardId, { categories: postCategories })
			.then((res) => {
				if (res && (res.data || res.status === 204)) {
					alert('분류를 저장하였습니다.');
					refreshCategories();
					return;
				}
				alert('분류 저장에 실패하였습니다.');
			})
			.catch((err) => {
				alert('분류 저장에 실패하였습니다.');
				console.error(err);
			});
	};

	const onShowAddRootCategoryItemModal = (boardId) => {
		setCategoryItemModalData({
			show: true,
			board_id: boardId,
			category_item_id: null,
			category_item_name: null,
		});
	};

	const onAddRootCategoryItem = (categoryName) => {
		setBoards(
			boards.map((board) => {
				let boardPostCategories = board.post_categories;
				if (categoryItemModalData.board_id === board.id) {
					boardPostCategories.push({
						id: `new-${Math.floor(Math.random() * 100)}`,
						name: categoryName,
						depth: 1,
						order: boardPostCategories.length + 1,
					});
				}
				return { ...board, modified: true, post_categories: boardPostCategories };
			})
		);

		setCategoryItemModalData({ show: false, board_id: null, category_item_id: null, category_item_name: null });
	};

	const onEditRootCategoryItem = (categoryItemId, categoryName) => {
		setBoards(
			boards.map((board) => {
				let boardPostCategories = board.post_categories.map((postCategory) => {
					if (postCategory.id === categoryItemId) {
						return { ...postCategory, name: categoryName };
					}
					return postCategory;
				});
				return { ...board, modified: true, post_categories: boardPostCategories };
			})
		);

		setCategoryItemModalData({ show: false, board_id: null, category_item_id: null, category_item_name: null });
	};

	const onEditCategoryItem = (categoryItemId, name) => {
		setCategoryItemModalData({
			show: true,
			board_id: null,
			category_item_id: categoryItemId,
			category_item_name: name,
		});
	};

	const onRemoveCategoryItem = (categoryItemId) => {
		if (!window.confirm('정말 삭제하시겠습니까?')) {
			return;
		}
		setBoards(
			boards.map((board) => {
				let boardPostCategories = board.post_categories.filter((postCategory) => {
					return postCategory.id != categoryItemId;
				});
				return { ...board, modified: true, post_categories: boardPostCategories };
			})
		);
	};

	const renderBoardPostCategory = (board) => {
		return (
			<>
				<CategoryNameContainer>
					<CategoryName>{board.name}</CategoryName>
					<CategoryUpdateButtonContainer>
						<CategoryAddButton onClick={() => onShowAddRootCategoryItemModal(board.id)}>
							추가
						</CategoryAddButton>
						<CategorySaveButton onClick={() => onUpdateCategory(board.id)}>저장</CategorySaveButton>
					</CategoryUpdateButtonContainer>
				</CategoryNameContainer>
				<section className="mt-10px">
					<TreeView
						defaultCollapseIcon={<StyledArrowDropDownIcon />}
						defaultExpandIcon={<StyledArrowRightIcon />}
						sx={{ maxWidth: 350 }}
					>
						{renderCategoryChildren(board.post_categories)}
					</TreeView>
				</section>
			</>
		);
	};

	const renderCategoryChildren = (children) => {
		return children.map((child, _) => {
			return (
				<StyledTreeItem
					key={_}
					nodeId={`${child.id}`}
					label={renderCategoryItem(child.id, child.name)}
				></StyledTreeItem>
			);
		});
	};

	const renderCategoryItem = (categoryItemId, name) => {
		return (
			<CategoryItemContainer className="category-item-container" title={name}>
				<CategoryItemName>{name}</CategoryItemName>
				<CategoryButtonContainer>
					<StyledEditIcon
						onClick={(e) => {
							e.stopPropagation();
							onEditCategoryItem(categoryItemId, name);
						}}
					/>
					<StyledRemoveIcon
						onClick={(e) => {
							e.stopPropagation();
							onRemoveCategoryItem(categoryItemId);
						}}
					/>
				</CategoryButtonContainer>
			</CategoryItemContainer>
		);
	};

	if (!boards) return null;

	return (
		<Content>
			<SectionTitle>게시판 분류</SectionTitle>
			<CategoryContainerRow>
				<CategoryContainer>
					{renderBoardPostCategory(boards.find((board) => board.slug === BoardSlug.NOTICE))}
				</CategoryContainer>
				<CategoryContainer>
					{renderBoardPostCategory(boards.find((board) => board.slug === BoardSlug.QNA))}
				</CategoryContainer>
			</CategoryContainerRow>
			{categoryItemModalData.show && (
				<CategoryItemModal
					defaultName={categoryItemModalData.category_item_name}
					onConfirm={(name) => {
						if (categoryItemModalData.category_item_id) {
							onEditRootCategoryItem(categoryItemModalData.category_item_id, name);
						} else {
							onAddRootCategoryItem(name);
						}
					}}
					onClose={() =>
						setCategoryItemModalData({
							show: false,
							board_id: null,
							category_item_id: null,
							category_item_name: null,
						})
					}
				/>
			)}
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 40px;
	background-color: white;

	@media only screen and (max-width: 1023.98px) {
		padding: 40px 20px;
	}

	@media only screen and (max-width: 479.98px) {
		padding: 20px 10px;
	}
`;

const SectionTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;
`;

const CategoryContainerRow = styled.div`
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	gap: 44px;

	& + & {
		margin-top: 44px;
	}

	@media only screen and (max-width: 479.98px) {
		flex-direction: column;
	}
`;

const CategoryContainer = styled.div`
	width: 50%;
	flex-grow: 0;
	flex-shrink: 1;
	flex-basis: calc(50% - 22px);

	@media only screen and (max-width: 479.98px) {
		width: 100%;
	}
`;

const CategoryNameContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
	height: 54px;
	border-top: 1px solid #aeaeae;
	border-bottom: 1px solid #aeaeae;
`;

const CategoryName = styled.div`
	padding-left: 11px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #000000;
`;

const CategoryUpdateButtonContainer = styled.div`
	margin-right: 10px;
	display: flex;
	align-items: center;
`;

const CategoryAddButton = styled.div`
	width: 50px;
	height: 30px;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: #454655;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #ffffff;

	&:hover {
		background-color: #1d1e31;
	}
`;

const CategorySaveButton = styled.div`
	width: 50px;
	height: 30px;
	margin-left: 10px;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: #454655;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #ffffff;

	&:hover {
		background-color: #1d1e31;
	}
`;

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
	font-size: 24px !important;
	color: #000000;
`;

const StyledArrowRightIcon = styled(ArrowRightIcon)`
	font-size: 24px !important;
	color: #000000;
`;

const StyledTreeItem = muiStyled((props) => <TreeItem {...props} />)(({ theme }) => ({
	[`& .${treeItemClasses.content}`]: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
		'&:hover .category-item-container': {
			backgroundColor: '#f5f5f5;',
		},
		'&.Mui-selected:hover': {
			backgroundColor: 'transparent',
		},
		'&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
			backgroundColor: 'transparent',
		},
		'&.Mui-selected .category-item-container': {
			backgroundColor: '#acc5e3',
			color: '#ffffff',
		},
		'& .MuiTreeItem-iconContainer': {
			display: 'none',
		},
	},
	[`& .${treeItemClasses.group}`]: {
		marginLeft: 10,
		paddingLeft: 5,
	},
}));

const CategoryItemContainer = styled.div`
	display: flex;
	align-items: center;
	height: 35px;
	margin: 2px 0;
	padding: 0 8px;

	border-radius: 4px;
	border: 1px solid #dddddd;
	box-sizing: border-box;
	color: #666666;
`;

const CategoryItemName = styled.div`
	width: 200px;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	text-overflow: ellipsis;
	overflow: hidden;
	height: 35px;
	line-height: 35px;
	white-space: nowrap;
	word-break: break-all;
`;

const CategoryButtonContainer = styled.div`
	margin-left: auto;
	flex-shrink: 0;
`;

const StyledRemoveIcon = styled(RemoveCircleOutlineRoundedIcon)`
	font-size: 24px !important;
	margin-left: 4px;
`;

const StyledEditIcon = styled(ModeEditOutlineIcon)`
	font-size: 20px !important;
`;

export default BoardCategorySetting;
