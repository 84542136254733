import React, { useState } from 'react';
import styled from 'styled-components';

const SystemScheduleModal = ({ defaultData, onConfirm, onClose }) => {
	const [name, setName] = useState(defaultData.name);
	const [startDate, setStartDate] = useState(defaultData.startDate);
	const [endDate, setEndDate] = useState(defaultData.endDate);
	const [allowStatus, setAllowStatus] = useState(defaultData.allowStatus);

	const validateData = () => {
		if (!name) {
			alert('제목을 입력해 주십시오.');
			return false;
		}
		if (!startDate) {
			alert('시작일을 입력하여 주십시오.');
			return false;
		}
		if (!endDate) {
			alert('종료일을 입력하여 주십시오.');
			return false;
		}
		if (startDate > endDate) {
			alert('시작일은 종료일 이후일 수 없습니다.');
			return false;
		}

		return true;
	};

	return (
		<ModalContainer>
			<Content>
				<ModalTopContainer>
					<ModalTitle>학교 일정 관리</ModalTitle>
					<CloseButton onClick={onClose}>x</CloseButton>
				</ModalTopContainer>
				<ScheduleContainer>
					<Row>
						<Title>제목</Title>
						<Contents>
							<TextInput
								type="text"
								value={name}
								placeholder="제목을 입력해주세요"
								onChange={(e) => setName(e.currentTarget.value)}
							/>
						</Contents>
					</Row>
					<Row>
						<Title>기간</Title>
						<Contents>
							<DateInput
								type="date"
								value={startDate}
								onChange={(e) => setStartDate(e.currentTarget.value)}
							/>
							<DateInputSeparator>~</DateInputSeparator>
							<DateInput
								type="date"
								value={endDate}
								onChange={(e) => setEndDate(e.currentTarget.value)}
							/>
						</Contents>
					</Row>
					<Row>
						<Title>예약 허용 상태</Title>
						<Contents>
							<RadioBoxGroup>
								<input
									type="radio"
									id={'allowStatus-true'}
									name={'allowStatus-true'}
									checked={allowStatus === true}
									onChange={(e) => {
										setAllowStatus(true);
									}}
								/>
								<label htmlFor={'allowStatus-true'}>허용</label>
							</RadioBoxGroup>
							<RadioBoxGroup>
								<input
									type="radio"
									id={'allowStatus-false'}
									name={'allowStatus-false'}
									checked={allowStatus === false}
									onChange={(e) => {
										setAllowStatus(false);
									}}
								/>
								<label htmlFor={'allowStatus-false'}>비허용</label>
							</RadioBoxGroup>
						</Contents>
					</Row>
				</ScheduleContainer>
				<Footer>
					<CancelButton onClick={onClose}>취소</CancelButton>
					<OkButton
						onClick={() => {
							if (!validateData()) {
								return;
							}
							onConfirm(name, startDate, endDate, allowStatus);
						}}
					>
						확인
					</OkButton>
				</Footer>
			</Content>
		</ModalContainer>
	);
};

const ModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Content = styled.div`
	position: relative;
	width: 720px;
	max-height: 80%;
	overflow-y: scroll;

	background-color: white;
	border: 1px solid #777777;
	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);
`;

const ModalTopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 30px;
	border-bottom: 1px solid #cccccc;
`;

const ModalTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;
`;

const CloseButton = styled.div`
	width: 30px;
	height: 30px;
	display: flex;
	align-items: flex-end;
	justify-content: center;

	cursor: pointer;

	color: #000000;
	font-family: 'Noto Sans';
	font-size: 24px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -1.2px;
	line-height: 30px;
	padding-top: 0px;
`;

const ScheduleContainer = styled.div`
	width: 100%;
	margin-top: 32px;
	padding: 0px 30px;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	min-height: 45px;
	border-bottom: 1px solid #dddddd;

	&:first-child {
		border-top: 2px solid #333333;
	}
`;

const Title = styled.div`
	width: 120px;
	padding-left: 18px;

	display: flex;
	align-self: stretch;
	align-items: center;
	justify-content: flex-start;

	background-color: #f7f7f7;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #000000;
`;

const Contents = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-grow: 1;
	flex-flow: wrap;

	height: 100%;
	padding: 5px 10px;
`;

const TextInput = styled.input`
	width: 100%;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const DateInput = styled.input`
	width: 150px;
	height: 35px;
	margin: 7.5px 0;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const DateInputSeparator = styled.span`
	margin: 0 10px;
	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const RadioBoxGroup = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	& + & {
		margin-left: 20px;
	}

	input {
		cursor: pointer;
		width: 15px;
		height: 15px;
	}

	label {
		cursor: pointer;
		margin-left: 10px;
		color: #666666;
		font-family: 'Noto Sans';
		font-size: 15px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: -0.45px;
		line-height: 100%;
	}
`;

const Footer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px 0;
`;

const CancelButton = styled.div`
	width: 125px;
	height: 50px;
	border: 1px solid #22499d;
	border-radius: 5px;
	background-color: #ffffff;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #22499d;

	&:hover {
		background-color: #0c438d;
		color: #ffffff;
	}
`;

const OkButton = styled.div`
	width: 125px;
	height: 50px;
	margin-left: 10px;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	background-color: #22499d;
	border-radius: 5px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #ffffff;

	&:hover {
		background-color: #0c438d;
	}
`;

export default SystemScheduleModal;
