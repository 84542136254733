import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import AgreementPrivacyModal from '../components/modal/AgreementPrivacyModal';
import LoadingIndicator from '../components/LoadingIndicator';
import CategorySlug from '../constants/CategorySlug';
import UnitType from '../constants/UnitType';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import BreadcrumbHomeImg from '../assets/images/common/breadcrumb_home.svg';
import BreadcrumbSeparatorImg from '../assets/images/common/breadcrumb_separator.svg';

import * as api from '../apis';
import * as utils from '../utils';

const BookingRequest = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { user } = useContext(UserContext);

	const [userData, setUserdata] = useState(null);
	const [unitId, setUnitId] = useState(0);
	const [unitType, setUnitType] = useState(UnitType.ROOM);
	const [unitData, setUnitData] = useState(null);
	// Room: usageDate ~ categoryPurposeId
	const [usageDate, setUsageDate] = useState('');
	const [categoryPurposeList, setCategoryPurposeList] = useState(null);
	const [categoryPurposeId, setCategoryPurposeId] = useState(null);
	// Product: usageStartDate ~ usageEndDate
	const [usageStartDate, setUsageStartDate] = useState('');
	const [usageEndDate, setUsageEndDate] = useState('');
	const [bookingStartTime, setBookingStartTime] = useState('');
	const [bookingEndTime, setBookingEndTime] = useState('');
	const [usageCount, setUsageCount] = useState(1);
	const [purposeDescription, setPurposeDescription] = useState('');
	const [extraDescription, setExtraDescription] = useState('');
	const [attachments, setAttachments] = useState([]);
	const [agreementPrivacy, setAgreementPrivacy] = useState(false);
	const [agreementPrivacyShow, setAgreementPrivacyShow] = useState(false);
	const [contact, setContact] = useState('');
	// 운영자가 예약 대상자 정보를 변경하는 경우 사용
	const [proxyUserName, setProxyUserName] = useState('');
	const [proxyUserLogin, setProxyUserLogin] = useState('');
	const [proxyUserEmail, setProxyUserEmail] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setUnitId(location.state?.unit_id ?? 0);
		// For Room
		setUsageDate(location.state?.filter?.usage_date ?? '');
		setBookingStartTime(location.state?.filter?.booking_start_time ?? '');
		setBookingEndTime(location.state?.filter?.booking_end_time ?? '');
		// For Product
		setUsageStartDate(location.state?.filter?.usage_start_date ?? '');
		setUsageEndDate(location.state?.filter?.usage_end_date ?? '');

		setIsLoading(true);
		Promise.all([getUser(), getUnit(), getCategories()])
			.then((values) => {
				setUserdata(values[0]);
				setProxyUserName(values[0]?.name ?? '');
				setProxyUserLogin(values[0]?.user_login ?? '');
				setProxyUserEmail(values[0]?.email ?? '');
				setContact(values[0]?.phone ?? '');

				setUnitData(values[1]);
				setUnitType(values[1].unit_type_name);

				setCategoryPurposeList(values[2].find((category) => category.slug === CategorySlug.PURPOSE)?.items);
			})
			.catch((err) => console.error(err))
			.finally(() => setIsLoading(false));
	}, []);

	const getUser = () => {
		return new Promise((resolve, reject) => {
			api.getUser('self')
				.then((res) => {
					if (res && res.data) {
						resolve(res.data);
					}
				})
				.catch((err) => {
					console.error(err);
					reject();
				});
		});
	};

	const getUnit = () => {
		return new Promise((resolve, reject) => {
			api.getUnit(location.state?.unit_id)
				.then((res) => {
					if (res && res.data) {
						resolve(res.data);
					}
				})
				.catch((err) => {
					console.error(err);
					navigate(-1);
					reject();
				});
		});
	};

	const getCategories = () => {
		return new Promise((resolve, reject) => {
			api.getCategories()
				.then((res) => {
					if (res && res.data) {
						resolve(res.data);
					}
				})
				.catch((err) => {
					console.error(err);
					reject();
				});
		});
	};

	const onClickHome = () => {
		navigate('/');
	};

	const onClickPrev = () => {
		if (window.confirm('정말 취소하시겠습니까?')) {
			navigate('/');
		}
	};

	const onDeleteAttachmentFile = (deleteIndex) => {
		setAttachments(attachments.filter((attachment, idx) => idx !== deleteIndex));
	};

	const onClickRequest = () => {
		switch (unitType) {
			case UnitType.ROOM:
				requestRoomBooking();
				return;
			case UnitType.PRODUCT:
				requestProductBooking();
				break;
			case UnitType.CONSULT:
				requestRoomBooking();
				return;
		}
	};

	const requestRoomBooking = () => {
		if (!contact) {
			alert('연락처를 입력해주세요.');
			return;
		}

		// 숫자, 하이픈만 허용
		if (!/^(\d|-)+$/.test(contact)) {
			alert('숫자와 - 만 입력 가능합니다.');
			return;
		}

		// 연락처는 7자리 이상이어야 한다.
		if (contact.length < 7) {
			alert('연락처는 7자리 이상으로 입력해주세요.');
			return;
		}

		if (!usageCount) {
			switch (unitType) {
				case UnitType.ROOM:
					alert('사용 인원을 정확하게 입력하세요.');
					break;
				case UnitType.CONSULT:
					alert('참여 인원을 정확하게 입력하세요.');
					break;
			}
			return;
		}
		if (!agreementPrivacy) {
			alert('개인정보처리방침에 동의하셔야 합니다');
			return;
		}

		const params = new FormData();
		params.append('unit_id', unitId);
		params.append('booking_start_date', utils.convertParameterDate(usageDate, bookingStartTime));
		params.append('booking_end_date', utils.convertParameterDate(usageDate, bookingEndTime));
		params.append('usage_count', usageCount);
		params.append('purpose_description', purposeDescription);
		params.append('extra_description', extraDescription ?? '');
		params.append('agreement_privacy', agreementPrivacy ?? '');
		params.append('contact', contact);
		if (categoryPurposeId) {
			params.append('category_purpose_id', categoryPurposeId);
		}
		// 운영자 권한으로 설정하는 경우 예약 대상 사용자를 지정해서 전달할 수 있다.
		if (checkAdminRole()) {
			params.append('proxy_user_name', proxyUserName);
			params.append('proxy_user_login', proxyUserLogin);
			params.append('proxy_user_email', proxyUserEmail);
		}

		attachments.forEach((file) => {
			params.append('attachment_files[]', file);
		});

		setIsLoading(true);
		api.createBooking(params)
			.then((res) => {
				setIsLoading(false);
				if (res && res.data) {
					navigate('/booking/complete', {
						state: {
							booking_id: res.data?.id,
							contact_number: unitData?.contact_number,
						},
					});
				}
			})
			.catch((err) => {
				setIsLoading(false);
				console.error(err);
			});
	};

	const requestProductBooking = () => {
		if (!contact) {
			alert('연락처를 입력해주세요.');
			return;
		}

		// 숫자, 하이픈만 허용
		if (!/^(\d|-)+$/.test(contact)) {
			alert('숫자와 - 만 입력 가능합니다.');
			return;
		}

		// 연락처는 7자리 이상이어야 한다.
		if (contact.length < 7) {
			alert('연락처는 7자리 이상으로 입력해주세요.');
			return;
		}

		if (!usageCount) {
			alert('예약 수량을 정확하게 입력하세요.');
			return;
		}
		if (!agreementPrivacy) {
			alert('개인정보처리방침에 동의하셔야 합니다');
			return;
		}

		const params = new FormData();
		params.append('unit_id', unitId);
		params.append('booking_start_date', utils.convertParameterDate(usageStartDate, '00:00'));
		params.append('booking_end_date', utils.convertParameterDate(usageEndDate, '23:59'));
		params.append('usage_count', usageCount);
		params.append('purpose_description', purposeDescription);
		params.append('extra_description', extraDescription ?? '');
		params.append('agreement_privacy', agreementPrivacy ?? '');
		params.append('contact', contact);
		// 운영자 권한으로 설정하는 경우 예약 대상 사용자를 지정해서 전달할 수 있다.
		if (checkAdminRole()) {
			params.append('proxy_user_name', proxyUserName);
			params.append('proxy_user_login', proxyUserLogin);
			params.append('proxy_user_email', proxyUserEmail);
		}

		setIsLoading(true);
		api.createBooking(params)
			.then((res) => {
				setIsLoading(false);
				if (res && res.data) {
					navigate('/booking/complete', {
						state: {
							booking_id: res.data?.id,
							contact_number: unitData?.contact_number,
						},
					});
				}
			})
			.catch((err) => {
				setIsLoading(false);
				console.error(err);
			});
	};

	const getCategorySlugType = () => {
		let categorySlug = CategorySlug.TYPE;
		switch (unitType) {
			case UnitType.ROOM:
				categorySlug = CategorySlug.TYPE;
				break;
			case UnitType.PRODUCT:
				categorySlug = CategorySlug.PRODUCT_TYPE;
				break;
			case UnitType.CONSULT:
				categorySlug = CategorySlug.CONSULT_TYPE;
				break;
		}

		return categorySlug;
	};

	const getCategorySlugPlace = () => {
		let categorySlug = CategorySlug.PLACE;
		switch (unitType) {
			case UnitType.ROOM:
				categorySlug = CategorySlug.PLACE;
				break;
			case UnitType.PRODUCT:
				categorySlug = CategorySlug.PRODUCT_PLACE;
				break;
			case UnitType.CONSULT:
				categorySlug = CategorySlug.CONSULT_PLACE;
				break;
		}

		return categorySlug;
	};

	const getPurposeDescriptionTitle = () => {
		switch (unitType) {
			case UnitType.ROOM:
				return '촬영내용';
			case UnitType.PRODUCT:
				return '이용목적';
			case UnitType.CONSULT:
				return '상담내용';
		}

		return '촬영내용';
	};

	const categorySlugPlace = getCategorySlugPlace();
	const categorySlugType = getCategorySlugType();

	const checkAdminRole = () => {
		switch (unitType) {
			case UnitType.ROOM:
				return utils.checkRoomAdminRole(user, Number(unitId));
			case UnitType.PRODUCT:
				return utils.checkProductAdminRole(user, Number(unitId));
			case UnitType.CONSULT:
				return utils.checkConsultAdminRole(user, Number(unitId));
		}

		return false;
	};

	const renderEditableUserInfo = () => {
		return (
			<InfoContainer>
				<InfoRow>
					<InfoColumn col2>
						<InfoTitle>예약자</InfoTitle>
						<InfoDescription noPadding>
							<UserInfoInput
								type="input"
								value={proxyUserName}
								onChange={(e) => setProxyUserName(e.currentTarget.value)}
							/>
						</InfoDescription>
					</InfoColumn>
					<InfoColumn col2>
						<InfoTitle>학번/교직원 번호</InfoTitle>
						<InfoDescription noPadding>
							<UserInfoInput
								type="input"
								value={proxyUserLogin}
								onChange={(e) => setProxyUserLogin(e.currentTarget.value)}
							/>
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn col2>
						<InfoTitle>이메일</InfoTitle>
						<InfoDescription noPadding>
							<UserInfoInput
								type="input"
								value={proxyUserEmail}
								onChange={(e) => setProxyUserEmail(e.currentTarget.value)}
							/>
						</InfoDescription>
					</InfoColumn>
					<InfoColumn col2>
						<InfoTitle large>
							연락처<InfoRequirment>(필수)</InfoRequirment>
						</InfoTitle>
						<InfoDescription noPadding>
							<UserInfoInput
								type="input"
								value={contact}
								onChange={(e) => setContact(e.currentTarget.value)}
							/>
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
			</InfoContainer>
		);
	};

	const renderUserInfo = () => {
		return (
			<InfoContainer>
				<InfoRow>
					<InfoColumn col2>
						<InfoTitle>예약자</InfoTitle>
						<InfoDescription>{userData?.name ?? '-'}</InfoDescription>
					</InfoColumn>
					<InfoColumn col2>
						<InfoTitle>학번/교직원 번호</InfoTitle>
						<InfoDescription>{userData?.user_login ?? '-'}</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn col2>
						<InfoTitle>이메일</InfoTitle>
						<InfoDescription>{userData?.email ?? '-'}</InfoDescription>
					</InfoColumn>
					<InfoColumn col2>
						<InfoTitle large>
							연락처<InfoRequirment>(필수)</InfoRequirment>
						</InfoTitle>
						<InfoDescription noPadding>
							<UserInfoInput
								type="input"
								value={contact}
								onChange={(e) => setContact(e.currentTarget.value)}
							/>
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
			</InfoContainer>
		);
	};

	const renderDisabledBookingScheduleTimeWithReason = () => {
		if (unitData?.disable_schedule_times?.length === 0) {
			return null;
		}

		const bookingStartMinutes = utils.timeToMinutes(bookingStartTime);
		const bookingEndMinutes = utils.timeToMinutes(bookingEndTime);

		const disabledBookingScheduleTimeWithReasonText = unitData?.disable_schedule_times
			?.filter((data) =>
				utils.isBookingOverlappingWithDisabledTime(
					bookingStartTime,
					bookingEndMinutes - bookingStartMinutes,
					data.start_time,
					data.end_time
				)
			)
			?.map((data) => ` ${data.start_time} ~ ${data.end_time} 제외 - ${data.name}`)
			?.join(', ');

		if (!disabledBookingScheduleTimeWithReasonText) {
			return null;
		}

		return (
			<DisabledBookingScheduleText>(※ {disabledBookingScheduleTimeWithReasonText})</DisabledBookingScheduleText>
		);
	};

	return (
		<Content>
			<LoadingIndicator size={50} loading={isLoading} />
			<TitleContainer>
				<Title>신청서 작성</Title>
				<Breadcrumb>
					<HomeIcon src={BreadcrumbHomeImg} onClick={onClickHome} />
					<BreadcrumbSeparator src={BreadcrumbSeparatorImg} />
					<BreadcrumbText>예약/신청</BreadcrumbText>
					<BreadcrumbSeparator src={BreadcrumbSeparatorImg} />
					<BreadcrumbText>예약신청</BreadcrumbText>
					<BreadcrumbSeparator src={BreadcrumbSeparatorImg} />
					<BreadcrumbText>{`${UnitType.toString(unitType)}예약`}</BreadcrumbText>
					<BreadcrumbSeparator src={BreadcrumbSeparatorImg} />
					<BreadcrumbText current>신청서 작성</BreadcrumbText>
				</Breadcrumb>
			</TitleContainer>
			<Section>
				<SectionTitle>신청자 정보</SectionTitle>
				{checkAdminRole() ? renderEditableUserInfo() : renderUserInfo()}
			</Section>
			<Section>
				<SectionTitle>예약정보</SectionTitle>
				<InfoContainer>
					<InfoRow>
						<InfoColumn col3>
							<InfoTitle>{unitType === UnitType.CONSULT ? '분류-1단계' : '대학'}</InfoTitle>
							<InfoDescription>
								{utils.getUnitCategoryName(unitData, categorySlugPlace, 1)}
							</InfoDescription>
						</InfoColumn>
						<InfoColumn col3>
							<InfoTitle>{unitType === UnitType.CONSULT ? '분류-2단계' : '캠퍼스'}</InfoTitle>
							<InfoDescription>
								{utils.getUnitCategoryName(unitData, categorySlugPlace, 2)}
							</InfoDescription>
						</InfoColumn>
						<InfoColumn col3>
							<InfoTitle>{unitType === UnitType.CONSULT ? '분류-3단계' : '건물'}</InfoTitle>
							<InfoDescription>
								{utils.getUnitCategoryName(unitData, categorySlugPlace, 3)}
							</InfoDescription>
						</InfoColumn>
					</InfoRow>
					<InfoRow>
						<InfoColumn col3>
							<InfoTitle>{`${UnitType.toString(unitType)}유형`}</InfoTitle>
							<InfoDescription>
								{utils.getUnitCategoryName(unitData, categorySlugType, 2)}
							</InfoDescription>
						</InfoColumn>
						<InfoColumn col3>
							<InfoTitle>{`${UnitType.toString(unitType)}명`}</InfoTitle>
							<InfoDescription>{unitData?.name}</InfoDescription>
						</InfoColumn>
					</InfoRow>
					<InfoRow>
						<InfoColumn col1>
							<InfoTitle>이용기간</InfoTitle>
							{unitType === UnitType.ROOM && (
								<BookingScheduleContainer>
									<InfoDescription>{`${usageDate} ${bookingStartTime} ~ ${bookingEndTime}`}</InfoDescription>
									{renderDisabledBookingScheduleTimeWithReason()}
								</BookingScheduleContainer>
							)}
							{unitType === UnitType.PRODUCT && (
								<InfoDescription>{`(대여일) ${usageStartDate} ~ (반납일) ${usageEndDate}`}</InfoDescription>
							)}
							{unitType === UnitType.CONSULT && (
								<BookingScheduleContainer>
									<InfoDescription>{`${usageDate} ${bookingStartTime} ~ ${bookingEndTime}`}</InfoDescription>
									{renderDisabledBookingScheduleTimeWithReason()}
								</BookingScheduleContainer>
							)}
						</InfoColumn>
					</InfoRow>
				</InfoContainer>
			</Section>
			<Section>
				<SectionTitle>신청정보</SectionTitle>
				<InfoContainer>
					<InfoRow input>
						<InfoColumn col1>
							{unitType === UnitType.ROOM && (
								<>
									<InfoTitle>사용인원</InfoTitle>
									<UserInputContainer>
										<UsageCountInput
											type="number"
											value={usageCount}
											onChange={(e) => setUsageCount(e.currentTarget.value)}
										/>
										명
										<UserInputDescription>{`최대 사용 인원 : ${
											unitData?.room_detail?.max_usage_count ?? 0
										}명`}</UserInputDescription>
									</UserInputContainer>
								</>
							)}
							{unitType === UnitType.PRODUCT && (
								<>
									<InfoTitle>예약수량</InfoTitle>
									<UserInputContainer>
										<UsageCountInput
											type="number"
											value={usageCount}
											onChange={(e) => setUsageCount(e.currentTarget.value)}
										/>
										개
										<UserInputDescription>{`(최대 예약 가능 수량 : ${
											unitData?.product_detail?.max_rental_count ?? 0
										}개)`}</UserInputDescription>
									</UserInputContainer>
								</>
							)}
							{unitType === UnitType.CONSULT && (
								<>
									<InfoTitle>참여인원</InfoTitle>
									<UserInputContainer>
										<UsageCountInput
											type="number"
											value={usageCount}
											onChange={(e) => setUsageCount(e.currentTarget.value)}
										/>
										명
										<UserInputDescription>{`최대 인원 : ${
											unitData?.room_detail?.max_usage_count ?? 0
										}명`}</UserInputDescription>
									</UserInputContainer>
								</>
							)}
						</InfoColumn>
					</InfoRow>
					<InfoRow input>
						<InfoColumn col1>
							<InfoTitle>{getPurposeDescriptionTitle()}</InfoTitle>
							<UserInputContainer>
								<UserTextArea
									value={purposeDescription}
									rows={4}
									onChange={(e) => setPurposeDescription(e.currentTarget.value)}
								/>
							</UserInputContainer>
						</InfoColumn>
					</InfoRow>
					<InfoRow input>
						<InfoColumn col1>
							<InfoTitle>특이사항</InfoTitle>
							<UserInputContainer>
								<UserTextArea
									value={extraDescription}
									rows={4}
									onChange={(e) => setExtraDescription(e.currentTarget.value)}
								/>
							</UserInputContainer>
						</InfoColumn>
					</InfoRow>
					{(unitType === UnitType.ROOM || unitType === UnitType.CONSULT) && (
						<InfoRow input>
							<InfoColumn col1>
								<InfoTitle>{unitType === UnitType.ROOM ? '교안파일' : '첨부파일'}</InfoTitle>
								<UserInputContainer>
									<AttachmentContainer>
										<AttachmentInput
											type="file"
											onChange={(e) => {
												setAttachments([...attachments, ...e.currentTarget.files]);
												e.currentTarget.value = null;
											}}
										/>
										{attachments.map((val, idx) => {
											return (
												<AttachmentFile key={idx}>
													{val.name}
													<StyledHighlightOffIcon
														onClick={() => onDeleteAttachmentFile(idx)}
													/>
												</AttachmentFile>
											);
										})}
									</AttachmentContainer>
								</UserInputContainer>
							</InfoColumn>
						</InfoRow>
					)}
					{unitType === UnitType.ROOM && categoryPurposeList?.length > 0 && (
						<InfoRow>
							<InfoColumn col1>
								<InfoTitle>이용목적</InfoTitle>
								<CategoryPurposeContainer>
									<CategoryPurposeSelect
										value={categoryPurposeId}
										onChange={(e) => setCategoryPurposeId(e.currentTarget.value)}
									>
										<option value="">이용목적을 선택해주세요.</option>
										{categoryPurposeList.map((item) => (
											<option key={item.id} value={item.id}>
												{item.name}
											</option>
										))}
									</CategoryPurposeSelect>
								</CategoryPurposeContainer>
							</InfoColumn>
						</InfoRow>
					)}
				</InfoContainer>
			</Section>
			<Section>
				<SectionTitle>개인정보수집·제공</SectionTitle>
				<AgreementContainer>
					<Agreement onClick={() => setAgreementPrivacyShow(true)}>개인정보 수집·제공 동의</Agreement>
					<AgreementCheckboxContainer>
						<AgreementCheckbox
							id="agreement_privacy"
							type="checkbox"
							value={agreementPrivacy}
							onChange={(e) => setAgreementPrivacy(e.currentTarget.checked)}
						/>
						<label htmlFor={'agreement_privacy'}>모두 동의합니다.</label>
					</AgreementCheckboxContainer>
				</AgreementContainer>
			</Section>
			<ButtonContainer>
				<PrevButton onClick={onClickPrev}>취소</PrevButton>
				<RequestButton onClick={onClickRequest}>예약신청</RequestButton>
			</ButtonContainer>
			{agreementPrivacyShow && <AgreementPrivacyModal onClose={() => setAgreementPrivacyShow(false)} />}
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 40px 30px 75px 30px;
	background-color: white;
	margin-top: 50px;
	margin-bottom: 109px;

	@media only screen and (max-width: 767.98px) {
		width: calc(100% - 20px);
		margin: 10px;
		padding: 15px 10px;
	}
`;

const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
	}
`;

const Title = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	line-height: 43px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		align-self: flex-start;
		font-size: 24px;
		line-height: 29px;
	}
`;

const Breadcrumb = styled.div`
	display: flex;
	justify-content: flex-end;

	@media only screen and (max-width: 767.98px) {
		margin-top: 20px;
		justify-content: center;
	}
`;

const HomeIcon = styled.img`
	cursor: pointer;
`;

const BreadcrumbSeparator = styled.img`
	margin: 0 10px;

	@media only screen and (max-width: 767.98px) {
		margin: 0 5px;
	}
`;

const BreadcrumbText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;

	${(props) =>
		props.current &&
		css`
			color: #22499d;
		`}

	@media only screen and (max-width: 767.98px) {
		font-size: 12px;
		line-height: 14px;
	}
`;

const Section = styled.div`
	margin-top: 30px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 20px;
	}
`;

const SectionTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		font-size: 16px;
		line-height: 19px;
	}
`;

const InfoContainer = styled.div`
	width: 100%;
	margin-top: 20px;
	border-top: 2px solid #333333;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		margin-left: 0;
		margin-top: 10px;
	}
`;

const InfoRow = styled.div`
	display: flex;
	width: 100%;
	border-bottom: 1px solid #dddddd;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		padding: 0;
		border-bottom: none;
	}
`;

const InfoColumn = styled.div`
	display: flex;
	height: 100%;

	${(props) =>
		props.col1 &&
		css`
			width: 100%;
		`}
	${(props) =>
		props.col2 &&
		css`
			width: 50%;
		`}
	${(props) =>
		props.col3 &&
		css`
			width: 33.33%;
		`}

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		width: 100%;
		min-height: auto;
		border-bottom: 1px solid #dddddd;
	}
`;

const InfoTitle = styled.div`
	display: flex;
	align-items: center;
	padding: 15px 20px;
	width: 152px;
	background-color: #f7f7f7;

	${(props) =>
		props.top &&
		css`
			align-items: flex-start;
			padding-top: 5px;
		`}

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding: 10px 0 0 10px;
		background-color: #ffffff;
	}
`;

const InfoRequirment = styled.span`
	margin-left: 5px;
	color: #dc3545;
`;

const InfoDescription = styled.div`
	display: flex;
	align-items: center;
	padding: 15px 20px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;

	${(props) =>
		props.noPadding &&
		css`
			padding: 0 20px;
		`}

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding: 6px 0 11px 10px;
	}
`;

const UserInputContainer = styled.div`
	width: 90%;
	display: flex;
	align-items: center;
	padding: 5px 20px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding: 6px 10px 12px 10px;
	}
`;

const UserInput = styled.input`
	height: 33px;
	padding: 8px;
	border: 1px solid #dddddd;
	border-radius: 5px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const UsageCountInput = styled(UserInput)`
	width: 100px;
	margin-right: 5px;

	@media only screen and (max-width: 767.98px) {
		width: 50px;
		height: 32px;
		align-self: center;
	}
`;

const UserInfoInput = styled(UserInput)`
	width: 200px;

	@media only screen and (max-width: 767.98px) {
		width: 200px;
		height: 32px;
		align-self: center;
	}
`;

const UserInputDescription = styled.div`
	margin-left: 5px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #22499d;

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
		line-height: 17px;
	}
`;

const UserTextArea = styled.textarea`
	width: 100%;
	padding: 8px;
	border: 1px solid #dddddd;
	border-radius: 5px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;

	@media only screen and (max-width: 767.98px) {
		margin-top: 8px;
		margin-bottom: 8px;
	}
`;

const AttachmentContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;

	input:not(:first-child) {
		margin-top: 8px;
	}
`;

const AttachmentFile = styled.div`
	margin-top: 8px;
`;

const StyledHighlightOffIcon = styled(HighlightOffIcon)`
	margin-left: 8px;
	cursor: pointer;
`;

const AttachmentInput = styled.input`
	@media only screen and (max-width: 767.98px) {
		width: 100%;
		margin-top: 8px;
		& + & {
			margin-bottom: 8px;
		}
	}
`;

const CategoryPurposeContainer = styled.div`
	width: 90%;
	display: flex;
	align-items: center;
	padding: 5px 20px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding: 5px 10px;
	}
`;

const CategoryPurposeSelect = styled.select`
	width: 100%;
	padding: 8px;
	appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, gray 50%),
		linear-gradient(135deg, gray 50%, transparent 50%);
	background-position: calc(100% - 15px) calc(1em - 2px), calc(100% - 10px) calc(1em - 2px), calc(100% - 2.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1px 1.5em;
	background-repeat: no-repeat;
	border: 1px solid #dddddd;
	border-radius: 5px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const AgreementContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 5px 13px;
	width: 100%;
	margin-top: 20px;
	border-top: 2px solid #333333;
	border-bottom: 1px solid #dddddd;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
	}
`;

const Agreement = styled.div`
	display: flex;
	align-items: center;

	padding: 8px 15px;
	background-color: #2f5a8c;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #ffffff;
`;

const AgreementCheckboxContainer = styled.div`
	margin-left: 30px;
	display: flex;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		margin-left: 0;
		margin-top: 10px;
		margin-bottom: 5px;
	}
`;

const AgreementCheckbox = styled.input`
	& + label {
		margin-left: 9px;
		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #333333;
	}
`;

const ButtonContainer = styled.div`
	width: 100%;
	margin-top: 36px;
	display: flex;
	justify-content: center;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		margin-top: 30px;
	}
`;

const PrevButton = styled.div`
	width: 125px;
	height: 50px;
	background-color: #ffffff;
	border: 1px solid #22499d;
	border-radius: 5px;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #22499d;

	&:hover {
		background-color: #0c438d;
		color: #ffffff;
	}
`;

const RequestButton = styled.div`
	margin-left: 10px;
	width: 125px;
	height: 50px;
	background-color: #22499d;
	border-radius: 5px;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	color: #ffffff;

	&:hover {
		background-color: #0c438d;
	}
`;

const BookingScheduleContainer = styled.div`
	display: flex;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		align-items: flex-start;
		flex-direction: column;
	}
`;

const DisabledBookingScheduleText = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 19px;
	color: #7e7d7d;

	@media only screen and (max-width: 767.98px) {
		padding: 0 0 10px 11px;
	}
`;

export default BookingRequest;
