import { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import UnitType from '../../constants/UnitType';
import ApprovalStatus from '../../constants/ApprovalStatus';
import UserRole from '../../constants/UserRole';
import { UserContext } from '../../contexts/UserContext';
import Day from '../../constants/Day';
import LoadingIndicator from '../../components/LoadingIndicator';

import * as api from '../../apis';
import * as utils from '../../utils';

import RoomLocationBackgroundImg from '../../custom/images/roomLocationBackground.png';

const RoomLocationGuideModal = ({ onClose, standalone }) => {
	// 스튜디오 안내도 1행 시설
	const row1_1Room = { id: 2, name: '블랙 스튜디오', bookings: [] };
	const row1_2Room = { name: '조정실', admins: ['김치봉'] };
	const row1_3Room = { id: 3, name: '크로마 스튜디오', bookings: [] };
	const row1_4Room = { id: 6, name: '상담실', bookings: [] };
	const row1_5Room = { id: 4, name: '공개 스튜디오', bookings: [] };

	// 스튜디오 안내도 2행 시설
	const row2_1Room = { id: 99999999, name: '분장실' };
	const row2_2Room = { id: 5, name: '라운지', bookings: [] };

	// 스튜디오 안내도 3행 시설
	// 시설의 호실을 안내도에 직접 표시하는 경우에는 room_item_id를 지정한다.
	const row3_1Room = { id: 1, room_item_id: 15, name: '제작실 3', bookings: [] };
	const row3_2Room = { name: '개발실', admins: ['최나은', '김환홍'] };
	const row3_3Room = { id: 1, room_item_id: 2, name: '제작실 1', bookings: [] };

	// 스튜디오 안내도 4행 시설
	const row4_1Room = { id: 1, room_item_id: 99999999, name: '제작실 4' };
	const row4_2Room = { id: 1, room_item_id: 14, name: '제작실 2', bookings: [] };

	const { user } = useContext(UserContext);
	const [isLoading, setIsLoading] = useState(false);
	const [roomList, setRoomList] = useState([
		row1_1Room,
		row1_3Room,
		row1_4Room,
		row1_5Room,
		row2_1Room,
		row2_2Room,
		row3_1Room,
		row3_3Room,
		row4_1Room,
		row4_2Room,
	]);

	useEffect(() => {
		// 모달이 보일 경우에 body의 overflow 속성을 지정하여 모달 뒷 배경이 스크롤되지 않도록 한다.
		document.body.style.overflow = 'hidden';

		return () => {
			document.body.style.overflow = '';
		};
	}, []);

	useEffect(() => {
		setIsLoading(true);

		getBookings()
			.then((res) => {
				if (res.data.items.length === 0) {
					return;
				}

				let updatedRoomList = [...roomList];

				res.data.items.forEach((data) => {
					updatedRoomList = updatedRoomList.map((room) => {
						if (
							room.id === data.unit.id &&
							(!room.room_item_id || room.room_item_id === data.room_item.id)
						) {
							return { ...room, bookings: [...room.bookings, data] };
						}

						return room;
					});
				});

				setRoomList(updatedRoomList);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => setIsLoading(false));
	}, []);

	// 예약 정보 조회
	const getBookings = () => {
		const today = utils.convertDateToStr(new Date());

		let filterParams = [
			`unit_type=${UnitType.ROOM}`,
			`approval_statuses[]=${ApprovalStatus.APPROVED}`,
			`approval_statuses[]=${ApprovalStatus.PENDING}`,
			`page_size=${Number.MAX_SAFE_INTEGER}`,
			`from=${today}`,
			`to=${today}`,
		];

		// 운영자가 아닐 경우, 사용자 본인의 예약 현황을 조회한다.
		if (!UserRole.isAdminRole(user.user_type)) {
			return api.getMyBooking(filterParams);
		} else {
			return api.getBookings(filterParams);
		}
	};

	const getRoom = (id, roomItemId = null) => {
		return roomList.find((room) => room.id === id && (!roomItemId || room.room_item_id === roomItemId));
	};

	const renderRoom = (room, width, height, top, left) => {
		return (
			<RoomContainer width={width} height={height} top={top} left={left}>
				<RoomName>{room.name}</RoomName>
				<RoomBookingContainer>
					<RoomBookingContent>
						{room.bookings?.map((booking, idx) => {
							// booking_date는 yyyy-mm-dd hh:mm~hh:mm 형식
							const bookingDate = booking.booking_date.split(' ')[1].replace('~', '-');
							let bookingText = `${bookingDate}`;

							// 시설 호실이 저장되어 있지 않은 경우에만 시설 호실을 표시한다.
							if (!room.room_item_id) {
								bookingText = `${bookingText} / ${booking.room_item?.name}`;
							}

							// 운영자일 경우에는 예약한 사용자 이름을 추가로 표시한다.
							if (UserRole.isAdminRole(user.user_type)) {
								bookingText = `[${booking.user.name}] ${bookingText}`;
							}

							return (
								<RoomBookingContentText key={idx} title={bookingText}>
									{bookingText}
								</RoomBookingContentText>
							);
						})}
					</RoomBookingContent>
				</RoomBookingContainer>
			</RoomContainer>
		);
	};

	const renderAdminRoom = (room, width, height, top, left) => {
		return (
			<AdminRoomContainer width={width} height={height} top={top} left={left}>
				<AdminRoomName>{room.name}</AdminRoomName>
				<RoomBookingContainer>
					<AdminRoomBookingContent>
						{room.admins.map((admin, idx) => (
							<AdminRoomBookingContentText key={idx}>{admin}</AdminRoomBookingContentText>
						))}
					</AdminRoomBookingContent>
				</RoomBookingContainer>
			</AdminRoomContainer>
		);
	};

	const onOpenStandalone = () => {
		window.open(`/guide/location`);
	};

	return (
		<ModalContainer standalone={!!standalone}>
			<LoadingIndicator size={50} loading={isLoading} />
			<Content standalone={!!standalone}>
				{!standalone && (
					<ModalTopContainer>
						<ModalTopLeftContainer>
							<ModalTitle>스튜디오 위치 안내도</ModalTitle>
							{!standalone && (
								<OpenStandaloneButton onClick={onOpenStandalone}>새 창으로 보기</OpenStandaloneButton>
							)}
						</ModalTopLeftContainer>
						<ModalTopRightContainer>
							<CloseButton onClick={onClose}>x</CloseButton>
						</ModalTopRightContainer>
					</ModalTopContainer>
				)}
				<ModalBodyContainer>
					<Row>
						{renderRoom(getRoom(row1_1Room.id), 201, 135, 46, 42)}
						{renderAdminRoom(row1_2Room, 111, 135, 46, 263)}
						{renderRoom(getRoom(row1_3Room.id), 178, 135, 46, 392)}
						{renderRoom(getRoom(row1_4Room.id), 179, 135, 46, 589)}
						{renderRoom(getRoom(row1_5Room.id), 171, 135, 46, 840)}
					</Row>
					<Row>
						{renderRoom(getRoom(row2_1Room.id), 173, 58, 204, 42)}
						{renderRoom(getRoom(row2_2Room.id), 173, 58, 204, 840)}
					</Row>
					<Row>
						<div>
							{renderRoom(getRoom(row3_1Room.id, row3_1Room.room_item_id), 173, 128, 284, 42)}
							{renderRoom(getRoom(row4_1Room.id, row4_1Room.room_item_id), 173, 128, 435, 42)}
						</div>
						{renderAdminRoom(row3_2Room, 94, 279, 284, 256)}
						<div>
							{renderRoom(getRoom(row3_3Room.id, row3_3Room.room_item_id), 178, 128, 284, 392)}
							{renderRoom(getRoom(row4_2Room.id, row4_2Room.room_item_id), 178, 128, 435, 392)}
						</div>
						<DateContainer>
							<DateWrapper>
								<DateText>
									<YellowDateText>{new Date().getFullYear()}</YellowDateText>
									{'년 '}
									<YellowDateText>{new Date().getMonth() + 1}</YellowDateText>
									{'월 '}
									<YellowDateText>{new Date().getDate()}</YellowDateText>
									{'일 ('}
									<YellowDateText>{Day.toShortString(new Date().getDay())}</YellowDateText>
									{')'}
								</DateText>
							</DateWrapper>
							<Separator></Separator>
							<LogoContainer>
								<YellowLogoText>e-Learning</YellowLogoText>
								<WhiteLogoText>STUDIO</WhiteLogoText>
							</LogoContainer>
						</DateContainer>
					</Row>
				</ModalBodyContainer>
			</Content>
		</ModalContainer>
	);
};

const ModalContainer = styled.div`
	${(props) =>
		props.standalone &&
		css`
			display: flex;
			justify-content: center;
			margin-top: 20px;
		`}

	${(props) =>
		!props.standalone &&
		css`
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.7);
			z-index: 99999;
			display: flex;
			justify-content: center;
			align-items: center;
		`}
`;

const Content = styled.div`
	position: relative;
	width: 1056px;
	height: 677px;
	overflow-y: auto;

	${(props) =>
		!props.standalone &&
		css`
			background-color: white;
			border: 1px solid #777777;
			box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);
		`}
`;

const ModalTopContainer = styled.div`
	width: 1054px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 24px;
	border-bottom: 1px solid #cccccc;
`;

const ModalTopLeftContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const ModalTopRightContainer = styled.div`
	display: flex;
	align-items: center;
`;

const ModalTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #000000d9;
`;

const OpenStandaloneButton = styled.div`
	width: 110px;
	height: 33px;

	margin-left: 10px;
	background-color: #2b62ab;
	border-radius: 5px;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #ffffff;

	&:hover {
		background-color: #0c438d;
	}
`;

const CloseButton = styled.div`
	width: 30px;
	height: 30px;
	display: flex;
	align-items: flex-end;
	justify-content: center;

	cursor: pointer;

	color: #000000;
	font-family: 'Noto Sans';
	font-size: 24px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -1.2px;
	line-height: 30px;
	padding-top: 0px;
`;

const ModalBodyContainer = styled.div`
	position: relative;
	width: 1054px;
	height: 609px;
	padding: 24px;
	background-image: url(${RoomLocationBackgroundImg});
	background-position: 24px 24px;
	background-repeat: no-repeat;
	background-size: 1006px 562px;
`;

const Row = styled.div`
	display: flex;
`;

const BaseRoomContainer = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: ${(props) => `${props.width}px`};
	height: ${(props) => `${props.height}px`};
	top: ${(props) => `${props.top}px`};
	left: ${(props) => `${props.left}px`};
`;

const RoomContainer = styled(BaseRoomContainer)``;

const AdminRoomContainer = styled(BaseRoomContainer)``;

const BaseRoomName = styled.div`
	padding: 4px 0;
	font-family: 'Pretendard';
	font-size: 14px;
	font-weight: 700;
	line-height: 17px;
	text-align: center;
	color: #ffffff;
`;

const RoomName = styled(BaseRoomName)``;

const AdminRoomName = styled(BaseRoomName)``;

const RoomBookingContainer = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-y: auto;
`;

const BaseRoomBookingContent = styled.ul`
	width: 100%;
	max-height: 100%;
	margin: 0;
	padding: 5px 8px 5px 12px;
	list-style: none;
`;

const RoomBookingContent = styled(BaseRoomBookingContent)``;

const AdminRoomBookingContent = styled(BaseRoomBookingContent)`
	padding: 5px 8px;
`;

const BaseRoomBookingContentText = styled.li`
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-family: 'Pretendard';
	font-size: 14px;
	font-weight: 500;
	line-height: 16.71px;
	color: #262626;

	&:not(:first-of-type) {
		margin-top: 8px;
	}
`;

const RoomBookingContentText = styled(BaseRoomBookingContentText)``;

const AdminRoomBookingContentText = styled(BaseRoomBookingContentText)`
	list-style: none;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const DateContainer = styled.div`
	position: absolute;
	top: 284px;
	left: 588px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;
	width: 424px;
	height: 278px;
`;

const DateWrapper = styled.div`
	font-family: 'Pretendard';
	font-size: 32px;
	font-weight: 500;
	line-height: 38.19px;
`;

const DateText = styled.span`
	color: #ffffff;
`;

const YellowDateText = styled.span`
	color: #faad14;
`;

const Separator = styled.div`
	width: 270px;
	height: 2px;
	background-color: #d48806;
`;

const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
`;

const YellowLogoText = styled.span`
	font-size: 20px;
	line-height: 23.87px;
	font-family: 'Pretendard';
	font-weight: 400;
	color: #d48806;
`;

const WhiteLogoText = styled.span`
	font-family: Impact;
	font-size: 24px;
	font-weight: 400;
	line-height: 29.27px;
	color: #ffffff;
`;

export default RoomLocationGuideModal;
