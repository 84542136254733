import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import UserRole from '../constants/UserRole';
import UnitType from '../constants/UnitType';
import TimeSelect from '../components/TimeSelect';
import BookingStatusModal from '../components/modal/BookingStatusModal';
import LoadingIndicator from '../components/LoadingIndicator';

import { UserContext } from '../contexts/UserContext';

import ReloadBlueIconImage from '../assets/images/screens/Booking/reload_blue.svg';
import ReloadWhiteIconImage from '../assets/images/screens/Booking/reload_white.svg';

import * as api from '../apis';
import * as utils from '../utils';

const BookingDateSelector = ({
	unitData,
	bookingId = null,
	bookingDate,
	bookingDateChange = null, // <BookingDateChangeModal /> 컴포넌트에서 사용되는 props
}) => {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);

	// bookingData는 yyyy-mm-dd hh:mm~hh:mm 형식의 문자열
	const [usageDate, setUsageDate] = useState(bookingDate ? bookingDate.split(' ')[0] : null);
	const [startTime, setStartTime] = useState(null);
	const [blockTime, setBlockTime] = useState(unitData.enable_schedule?.block_time ?? 0);
	const [scheduleList, setScheduleList] = useState([]);
	const [bookingStatusModalData, setBookingStatusModalData] = useState({ show: false });
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (unitData.id && usageDate) {
			const filterParams = [`date=${usageDate}`];

			if (bookingId) {
				filterParams.push(`booking_id=${bookingId}`);
			}

			api.getUnitSchedule(unitData.id, filterParams)
				.then((res) => {
					if (res && res.data) {
						setScheduleList(res.data);
						checkBookingSchedule(res.data);
					}
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}, [usageDate]);

	useEffect(() => {
		checkBookingSchedule(scheduleList);
	}, [startTime, blockTime]);

	const checkBookingSchedule = (currentScheduleList) => {
		if (!startTime || currentScheduleList.length === 0) return;

		const bookingEndTime = calcBookingEndTime(Date.now(), startTime, blockTime);
		setScheduleList(
			currentScheduleList.map((schedule) => {
				return {
					...schedule,
					booked: schedule.from >= startTime && schedule.to <= bookingEndTime,
				};
			})
		);
	};

	/**
	 * 선택한 예약 날짜가 예약이 가능한지 확인한다.
	 */
	const validateBookingDate = (selectedDate) => {
		// 예약 가능한 요일인지 판단한다.
		const selectedDay = selectedDate.getDay();
		if (
			unitData.enable_schedule?.booking_weekday &&
			!unitData.enable_schedule?.booking_weekday.includes(selectedDay.toString())
		) {
			alert(
				`예약가능한 요일이 아닙니다.\n예약가능 요일: ${utils.getEnabledWeekdayStr(
					unitData.enable_schedule?.booking_weekday
				)}`
			);
			return false;
		}

		// 예약 신청이 가능한 날짜인지 판단한다. 신청 가능 시간이 0으로 되어 있는 경우 체크하지 않는다. (당일 예약 허용)
		const enableRequestDayBefore = unitData.restrict_day?.enable_request_day_before ?? 0;
		if (enableRequestDayBefore !== 0 && !utils.checkAvailableBookingDate(selectedDate, enableRequestDayBefore)) {
			alert(
				`신청 가능 기간이 아닙니다.\n- 신청 가능 기간 : 예약시작일 기준 ${enableRequestDayBefore}일 전까지 허용`
			);
			return false;
		}

		return true;
	};

	const getDisableScheduleTime = (checkBlockTime) => {
		if (!usageDate || !startTime || scheduleList.length === 0) {
			return 0;
		}

		const startMinutes = utils.timeToMinutes(startTime);
		const bookingEndMinutes = utils.timeToMinutes(calcBookingEndTime(usageDate, startTime, checkBlockTime));

		// 사용자가 예약하고자 하는 시간에 해당하는 스케줄 목록만 필터링
		const bookingScheduleList = scheduleList.filter((schedule) => {
			const fromToNumber = utils.timeToMinutes(schedule.from);
			const toToNumber = utils.timeToMinutes(schedule.to);

			if (startMinutes <= fromToNumber && bookingEndMinutes >= toToNumber) {
				return true;
			}

			return false;
		});

		// 예약 스케줄 목록에서 예약 불가능한 스케줄에 해당할 때, 예약 아이템(unitData)의 이용 시간 단위(unitData?.enable_schedule?.block_time)를 더한 값을 반환
		const scheduleBlockTime = 30;
		return bookingScheduleList.reduce((acc, schedule) => {
			if (!schedule.enabled || schedule.fulled) {
				return acc + scheduleBlockTime;
			}
			return acc;
		}, 0);
	};

	const convertMinuteToHourMinuteStr = (minute) => {
		// 예약 불가능한 시간을 빼고 minute을 보여주도록 함
		const disableScheduleTime = getDisableScheduleTime(minute);
		minute -= disableScheduleTime;

		let hour = parseInt(minute / 60);
		minute -= hour * 60;

		if (hour === 0) {
			return `${minute}분`;
		}
		return `${hour}시간 ${minute}분`;
	};

	const getBookingScheduleTime = (minute) => {
		if (!startTime) {
			return '(시작시간을 선택해 주세요)';
		}

		const disableScheduleTime = getDisableScheduleTime(minute);
		minute -= disableScheduleTime;

		// 0분이면 startTime만 표시한다
		if (minute === 0) {
			return `(${startTime}~${startTime})`;
		}

		const bookingEndTime = calcBookingEndTime(Date.now(), startTime, blockTime);
		return `(${startTime}~${bookingEndTime})`;
	};

	const calcBookingEndTime = (usageDate, startTime, block) => {
		const splited = startTime.split(':');

		let date = new Date(usageDate);
		date.setHours(splited[0]);
		date.setMinutes(splited[1]);

		let endTime = new Date(date.getTime() + block * 60 * 1000);
		endTime.toLocaleTimeString('en-US', { hour12: false });

		// 다음 날짜까지 예약을 하려는 경우 block한다
		if (date.getDay() !== endTime.getDay()) {
			alert('오늘 날짜를 초과해서 예약하실 수 없습니다.');
			return;
		}

		let endHour = endTime.getHours();
		let endMinutes = endTime.getMinutes();

		return `${String(endHour).padStart(2, '0')}:${String(endMinutes).padStart(2, '0')}`;
	};

	const validateBookingSchedule = () => {
		if (!usageDate) {
			alert('선택한 이용일자가 없습니다.');
			return false;
		}
		if (!startTime) {
			alert('선택한 시작 시간이 없습니다.');
			return false;
		}
		if (!blockTime) {
			alert('최소 이용시간을 설정하지 않았습니다.');
			return false;
		}
		if (blockTime < unitData.enable_schedule?.min_usage_time) {
			alert(`이용시간이 최소 이용시간보다 작습니다.`);
			return;
		}

		const bookingEndTime = calcBookingEndTime(usageDate, startTime, blockTime);
		if (!bookingEndTime) {
			return false;
		}

		// 시작 시간이 scheduleList의 첫번째 시간보다 이후여야 한다.
		if (scheduleList.length !== 0 && startTime < scheduleList[0].from) {
			alert('예약 가능 시간이 아닙니다.');
			return false;
		}
		// 종료 시간이 scheduleList의 마지막 시간보다 이전이어야 한다.
		if (scheduleList.length !== 0 && bookingEndTime > scheduleList[scheduleList.length - 1].to) {
			alert('예약 가능 시간이 아닙니다.');
			return false;
		}

		// 예약 마감 시간이 선택한 이용 시간에 포함되어 있는지 확인한다.
		let scheduleFulled = false;
		for (let idx in scheduleList) {
			let schedule = scheduleList[idx];
			if (startTime < schedule.to && bookingEndTime > schedule.from && schedule.fulled === true) {
				scheduleFulled = true;
				break;
			}
		}
		if (scheduleFulled) {
			alert('선택한 시간대에 다른 사용자가 예약한 시간이 포함되어 있어 예약할 수 없습니다.');
			return false;
		}

		// 선택한 시작 시간이 예약 불가 시간에 포함되어 있는지 확인한다.
		let scheduleEnabled = true;
		for (let idx in scheduleList) {
			let schedule = scheduleList[idx];
			if (startTime >= schedule.from && startTime < schedule.to && schedule.enabled === false) {
				scheduleEnabled = false;
				break;
			}
		}
		if (!scheduleEnabled) {
			alert('예약 가능 시간이 아닙니다.');
			return false;
		}

		return true;
	};

	const onClickConfirmBookingDateChange = () => {
		if (!validateBookingSchedule() || !bookingId) {
			return;
		}

		const bookingEndTime = calcBookingEndTime(usageDate, startTime, blockTime);

		const data = {
			booking_start_date: utils.convertParameterDate(usageDate, startTime),
			booking_end_date: utils.convertParameterDate(usageDate, bookingEndTime),
		};

		setIsLoading(true);
		api.updateBooking(bookingId, data)
			.then((res) => {
				if (res && res.data) {
					alert('이용 기간을 변경하였습니다.');

					// bookingData는 yyyy-mm-dd hh:mm~hh:mm 형식의 문자열
					const newBookingDate = `${usageDate} ${startTime}~${bookingEndTime}`;
					bookingDateChange.onChange(newBookingDate);
					bookingDateChange.onClose();
				}
			})
			.catch((err) => {
				console.error(err);
				alert('이용 기간 변경에 실패하였습니다.');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const onClickPrev = () => {
		navigate('/booking');
	};

	const onClickNext = () => {
		if (!validateBookingSchedule()) {
			return;
		}

		const bookingEndTime = calcBookingEndTime(usageDate, startTime, blockTime);

		navigate('/booking/request', {
			state: {
				unit_id: unitData.id,
				filter: {
					usage_date: usageDate,
					booking_start_time: startTime,
					booking_end_time: bookingEndTime,
				},
			},
		});
	};

	const onChangeUsageDate = (usageDate) => {
		// 운영자가 아닌 경우 예약 가능날짜를 확인한다.
		if (!utils.checkRoomAdminRole(user, unitData.id) && !validateBookingDate(new Date(usageDate))) {
			return;
		}

		setUsageDate(usageDate);
		setStartTime(null);
		setBlockTime(unitData.enable_schedule?.block_time ?? 0);
	};

	const onChangeStartTime = (startTime) => {
		if (!usageDate || scheduleList.length === 0) {
			return;
		}

		const startMinutes = utils.timeToMinutes(startTime);
		const bookingEndMinutes = utils.timeToMinutes(unitData?.enable_schedule?.booking_end_time);

		// 받은 시작 시간이 예약 가능 시간의 마지막 시간보다 이후일 경우, 해당 시간은 예약이 불가능한 시간이다.
		// 시작 시간이 예약이 불가능한 시간으로 설정되지 않도록, 이 경우에는 시작 시간을 예약 가능 시간의 마지막 시간으로 설정한다.
		setStartTime(startMinutes > bookingEndMinutes ? unitData?.enable_schedule?.booking_end_time : startTime);
		setBlockTime(unitData.enable_schedule?.block_time ?? 0);
	};

	const onClickAddBlockTime = () => {
		if (!startTime) {
			return;
		}

		// + 버튼을 눌렀을 때 변경되는 예약 시간은 현재 예약 시간 + 설정한 block_time으로 한다.
		let newBlockTime = blockTime + unitData?.enable_schedule?.block_time;
		const startMinutes = utils.timeToMinutes(startTime);
		const bookingEndMinutes = utils.timeToMinutes(unitData?.enable_schedule?.booking_end_time);

		// 기존 이용 시간(blockTime)이 {예약 가능 시간의 마지막 시간(unitData?.enable_schedule?.booking_end_time) - 시작 시간}을 초과하는 경우
		// 이용 시간이 예약 가능 시간의 마지막 시간 이후까지 설정되므로 새로운 이용 시간(newBlockTime)이 적용되지 않도록 한다.
		if (blockTime >= bookingEndMinutes - startMinutes) {
			return;
		}

		// 기존 이용 시간(blockTime)이 예약 마감 시간인 경우에 새로운 이용시간(newBlockTime)이 적용되지 않도록 한다.
		const bookingEndTime = calcBookingEndTime(usageDate, startTime, blockTime);
		if (scheduleList.some((schedule) => schedule.fulled && schedule.to === bookingEndTime)) {
			alert('예약 가능 시간이 아닙니다');
			return;
		}

		const newBookingEndTime = calcBookingEndTime(usageDate, startTime, newBlockTime);

		// 이용 시간에 예약 마감 혹은 예약 불가 시간대가 존재할 경우, 그 시간들을 제치고 예약 가능한 시간대의 to까지 이용 시간으로 설정하기 위해,
		// 새로운 이용시간(newBlockTime)을 기준으로 사용자가 예약하려는 시간의 종료 시간(newBookingEndTime)을 구해
		// 스케줄 목록에서 사용자가 예약하려는 시간의 종료 시간과 스케쥴의 to와 일치하여 가장 먼저 존재하는 예약 마감 혹은 예약 불가인 스케줄의 인덱스를 찾는다.
		const disableScheduleIndex = scheduleList.findIndex(
			(schedule) =>
				(!schedule.enabled || schedule.fulled) &&
				schedule.from < newBookingEndTime &&
				schedule.to >= newBookingEndTime
		);

		let isScheduleFulled = false;
		if (disableScheduleIndex !== -1) {
			// 예약 불가능한 스케줄부터 스케줄 목록을 순회하며 가장 먼저 예약 가능한 시간을 구해서 새로운 이용시간(newBlockTime)으로 설정한다.
			for (let i = disableScheduleIndex; i < scheduleList.length - 1; i++) {
				// 예약 마감인 스케줄인 경우에는 예약을 진행할 수 없으므로 순회를 멈춘다.
				if (scheduleList[i].fulled) {
					isScheduleFulled = true;
					break;
				}

				// 예약이 가능한 스케쥴을 찾는다.
				if (scheduleList[i].enabled) {
					const fromMinutes = utils.timeToMinutes(scheduleList[i].from);
					const startMinutes = utils.timeToMinutes(startTime);

					// 예약 불가능 스케쥴을 포함한 신규 예약 시간을 계산한다.
					newBlockTime = fromMinutes - startMinutes + unitData?.enable_schedule?.block_time;
					break;
				}
			}
		}

		// 예약 마감인 스케줄이 존재할 경우에 예약을 진행할 수 없어서 새로운 이용시간(newBlockTime)을 설정하지 않는다.
		if (isScheduleFulled) {
			alert('예약 가능 시간이 아닙니다');
			return;
		}

		// 새로운 이용시간(newBlockTime)은 예약 마감 혹은 예약 불가한 시간까지 포함되어 계산됐기 때문에
		// 예약 불가능한 시간대(disableBookingTime)를 제외한 새로운 이용시간(newBlockTime)이 최대 이용 시간보다 넘어서는 경우에는 새로운 이용 시간(newBlockTime)이 적용되지 않도록 한다.
		const disableBookingTime = getDisableScheduleTime(newBlockTime);
		if (newBlockTime - disableBookingTime > unitData?.enable_schedule?.max_usage_time) {
			return;
		}

		setBlockTime(newBlockTime ?? 0);
	};

	const onClickSubtractBlockTime = () => {
		if (!startTime) {
			return;
		}

		let newBlockTime = blockTime - unitData?.enable_schedule?.block_time;

		if (newBlockTime <= 0) {
			return;
		}

		const newBookingEndTime = calcBookingEndTime(usageDate, startTime, newBlockTime);

		// 이용 시간에 예약 마감 혹은 예약 불가 시간대가 존재할 경우, 그 시간들을 제치고 예약 가능한 시간대의 to로 이용 시간(blockTime)을 설정하기 위해,
		// 새로운 이용시간(newBlockTime)을 기준으로 사용자가 예약하려는 시간의 종료 시간(newBookingEndTime)을 구해
		// 스케줄 목록에서 사용자가 예약하려는 시간의 종료 시간과 예약 시간대의 to와 일치하여 가장 먼저 존재하는 예약 마감 혹은 예약 불가인 스케줄의 인덱스를 설정한다.
		const disableScheduleIndex = scheduleList.findIndex(
			(schedule) =>
				(!schedule.enabled || schedule.fulled) &&
				schedule.from < newBookingEndTime &&
				schedule.to >= newBookingEndTime
		);

		if (disableScheduleIndex !== -1) {
			// 예약 불가능한 스케줄부터 스케줄 목록을 역순으로 순회하며 가장 먼저 예약 가능한 시간을 구해서 새로운 이용시간(newBlockTime)으로 설정한다.
			for (let i = disableScheduleIndex; i >= 0; i--) {
				if (scheduleList[i].enabled && !scheduleList[i].fulled) {
					const toMinutes = utils.timeToMinutes(scheduleList[i].to);
					const startMinutes = utils.timeToMinutes(startTime);

					newBlockTime = toMinutes - startMinutes;
					break;
				}
			}
		}

		setBlockTime(newBlockTime ?? 0);
	};

	const onClickReset = () => {
		if (!startTime) {
			return;
		}

		setBlockTime(unitData.enable_schedule?.block_time ?? 0);
	};

	const renderDisabledBookingScheduleTimeWithReason = (minute) => {
		if (unitData?.disable_schedule_times.length === 0 || !startTime) {
			return null;
		}

		const disableScheduleTime = getDisableScheduleTime(minute);
		minute -= disableScheduleTime;

		// 0분이면 사유를 표시하지 않는다.
		if (minute === 0) {
			return null;
		}

		return unitData?.disable_schedule_times
			.map((data) => {
				if (utils.isBookingOverlappingWithDisabledTime(startTime, blockTime, data.start_time, data.end_time)) {
					return (
						<DisabledBookingScheduleText key={data.id}>
							{`※ ${data.start_time} ~ ${data.end_time} 제외 (${data.name})`}
						</DisabledBookingScheduleText>
					);
				}
				return null;
			})
			.filter((data) => data !== null);
	};

	const renderRoomBookingDateSelector = () => {
		return (
			<>
				<section>
					<BookingSubTitleContainer>
						<BookingSubTitle>이용일자</BookingSubTitle>
					</BookingSubTitleContainer>
					<SearchDateContainer>
						<SearchDateInput
							type="date"
							value={usageDate ?? ''}
							min={
								utils.checkRoomAdminRole(user, unitData.id) ? null : utils.convertDateToStr(new Date())
							}
							max={
								utils.checkRoomAdminRole(user, unitData.id)
									? null
									: unitData.enable_schedule?.usage_end_date
							}
							onChange={(e) => onChangeUsageDate(e.currentTarget.value)}
						/>
						<BookingStatusButton onClick={() => setBookingStatusModalData({ show: true })}>
							실시간 예약 현황
						</BookingStatusButton>
					</SearchDateContainer>
				</section>
				<section>
					<BookingAvailableContainer>
						<BookingSubTitleContainer inline className="mt-0">
							<BookingSubTitle>예약가능 시간</BookingSubTitle>
						</BookingSubTitleContainer>
						<BookingBlockContainer>
							<BookingBlockItem>
								<FulledBookingBlockSquare />
								<BookingBlockTitle>예약마감</BookingBlockTitle>
							</BookingBlockItem>
							<BookingBlockItem>
								<DisabledBookingBlockSquare />
								<BookingBlockTitle>예약불가</BookingBlockTitle>
							</BookingBlockItem>
						</BookingBlockContainer>
					</BookingAvailableContainer>
					<BookingAvailableTable>
						{scheduleList.map((schedule, idx) => {
							let text = schedule.from_time_str ?? '';
							let smallText = text.length === 1;
							let lastBox = idx === scheduleList.length - 1;
							if (lastBox) text = schedule.to_time_str ?? '';
							return (
								<ScheduleBox
									key={idx}
									enabled={schedule?.enabled}
									fulled={schedule?.fulled}
									booked={schedule?.booked}
									start={schedule.from_time_str ? 'true' : 'false'}
								>
									<ScheduleBoxText small={smallText} last={lastBox}>
										{text}
									</ScheduleBoxText>
								</ScheduleBox>
							);
						})}
					</BookingAvailableTable>
				</section>
				<section className="mt-40px">
					<BookingSubTitleContainer>
						<BookingSubTitle>시작시간</BookingSubTitle>
					</BookingSubTitleContainer>
					<TimeSelect
						time={startTime}
						minHour={parseInt(unitData.enable_schedule?.booking_start_time.substring(0, 2)) ?? 0}
						maxHour={parseInt(unitData.enable_schedule?.booking_end_time.substring(0, 2)) ?? 0}
						disabled={!usageDate || scheduleList.length === 0}
						onChange={(value) => onChangeStartTime(value)}
					></TimeSelect>
				</section>
				<section>
					<BookingBlockTimeContainer>
						<BookingSubTitleContainer inline className="mt-0">
							<BookingSubTitle>이용시간</BookingSubTitle>
						</BookingSubTitleContainer>
						<BookingSubTitleDescription>{`최소 이용시간: ${
							unitData.enable_schedule?.min_usage_time ?? 0
						}분`}</BookingSubTitleDescription>
					</BookingBlockTimeContainer>
					<BookingBlockTime>
						<BookingBlockTimeText>{convertMinuteToHourMinuteStr(blockTime ?? 0)}</BookingBlockTimeText>
						<BookingBlockTimeInfoText>{getBookingScheduleTime(blockTime ?? 0)}</BookingBlockTimeInfoText>
						{renderDisabledBookingScheduleTimeWithReason(blockTime ?? 0)}
					</BookingBlockTime>
					<BlockTimeUnitContainer>
						<BlockTimeUnit left onClick={() => onClickAddBlockTime()}>
							<span>{`+${unitData.enable_schedule?.block_time ?? '0'}분`}</span>
						</BlockTimeUnit>
						<BlockTimeUnit right onClick={() => onClickSubtractBlockTime()}>
							<span>{`-${unitData.enable_schedule?.block_time ?? '0'}분`}</span>
						</BlockTimeUnit>
						<BlockTimeUnit reset onClick={() => onClickReset()}>
							<ReloadBlueIcon />
							<ReloadWhiteIcon />
							<span>재설정</span>
						</BlockTimeUnit>
					</BlockTimeUnitContainer>
				</section>
			</>
		);
	};

	const renderConsultBookingDateSelector = () => {
		return (
			<>
				<section>
					<BookingSubTitleContainer>
						<BookingSubTitle>상담일자</BookingSubTitle>
					</BookingSubTitleContainer>
					<SearchDateContainer>
						<SearchDateInput
							type="date"
							value={usageDate ?? ''}
							min={
								utils.checkRoomAdminRole(user, unitData.id) ? null : utils.convertDateToStr(new Date())
							}
							max={
								utils.checkRoomAdminRole(user, unitData.id)
									? null
									: unitData.enable_schedule?.usage_end_date
							}
							onChange={(e) => onChangeUsageDate(e.currentTarget.value)}
						/>
					</SearchDateContainer>
				</section>
				<section>
					<BookingAvailableContainer>
						<BookingSubTitleContainer inline className="mt-0">
							<BookingSubTitle>예약가능 시간</BookingSubTitle>
						</BookingSubTitleContainer>
						<BookingBlockContainer>
							<BookingBlockItem>
								<FulledBookingBlockSquare />
								<BookingBlockTitle>예약마감</BookingBlockTitle>
							</BookingBlockItem>
							<BookingBlockItem>
								<DisabledBookingBlockSquare />
								<BookingBlockTitle>예약불가</BookingBlockTitle>
							</BookingBlockItem>
						</BookingBlockContainer>
					</BookingAvailableContainer>
					<BookingAvailableTable>
						{scheduleList.map((schedule, idx) => {
							let text = schedule.from_time_str ?? '';
							let smallText = text.length === 1;
							let lastBox = idx === scheduleList.length - 1;
							if (lastBox) text = schedule.to_time_str ?? '';
							return (
								<ScheduleBox
									key={idx}
									enabled={schedule?.enabled}
									fulled={schedule?.fulled}
									booked={schedule?.booked}
									start={schedule.from_time_str ? 'true' : 'false'}
								>
									<ScheduleBoxText small={smallText} last={lastBox}>
										{text}
									</ScheduleBoxText>
								</ScheduleBox>
							);
						})}
					</BookingAvailableTable>
				</section>
				<section className="mt-40px">
					<BookingSubTitleContainer>
						<BookingSubTitle>시작시간</BookingSubTitle>
					</BookingSubTitleContainer>
					<TimeSelect
						time={startTime}
						minHour={parseInt(unitData.enable_schedule?.booking_start_time.substring(0, 2)) ?? 0}
						maxHour={parseInt(unitData.enable_schedule?.booking_end_time.substring(0, 2)) ?? 0}
						disabled={!usageDate || scheduleList.length === 0}
						onChange={(value) => onChangeStartTime(value)}
					></TimeSelect>
				</section>
				<section>
					<BookingBlockTimeContainer>
						<BookingSubTitleContainer inline className="mt-0">
							<BookingSubTitle>상담시간</BookingSubTitle>
						</BookingSubTitleContainer>
						<BookingSubTitleDescription>{`최소시간: ${
							unitData.enable_schedule?.min_usage_time ?? 0
						}분`}</BookingSubTitleDescription>
					</BookingBlockTimeContainer>
					<BookingBlockTime>
						<BookingBlockTimeText>{convertMinuteToHourMinuteStr(blockTime ?? 0)}</BookingBlockTimeText>
						<BookingBlockTimeInfoText>{getBookingScheduleTime(blockTime ?? 0)}</BookingBlockTimeInfoText>
						{renderDisabledBookingScheduleTimeWithReason(blockTime ?? 0)}
					</BookingBlockTime>
					<BlockTimeUnitContainer>
						<BlockTimeUnit left onClick={() => onClickAddBlockTime()}>
							<span>{`+${unitData.enable_schedule?.block_time ?? '0'}분`}</span>
						</BlockTimeUnit>
						<BlockTimeUnit right onClick={() => onClickSubtractBlockTime()}>
							<span>{`-${unitData.enable_schedule?.block_time ?? '0'}분`}</span>
						</BlockTimeUnit>
						<BlockTimeUnit reset onClick={() => onClickReset()}>
							<ReloadBlueIcon />
							<ReloadWhiteIcon />
							<span>재설정</span>
						</BlockTimeUnit>
					</BlockTimeUnitContainer>
				</section>
			</>
		);
	};

	const renderBookingDateSelector = () => {
		const unitType = unitData.unit_type_name;

		switch (unitType) {
			case UnitType.ROOM:
				return <>{renderRoomBookingDateSelector()}</>;
			case UnitType.CONSULT:
				return <>{renderConsultBookingDateSelector()}</>;
		}
	};

	return (
		<Content sticky={!bookingDateChange && !bookingStatusModalData.show}>
			<LoadingIndicator size={50} loading={isLoading} />
			<BookingInfoContent>
				<BookingTitleContainer>
					<BookingTitle>예약 신청</BookingTitle>
					{/* 운영자 제외, 시설 예약 신청 가능 시간이 0일 전이 아닌 경우에만 당일 예약 불가를 표시 */}
					{unitData.restrict_day?.enable_request_day_before !== 0 &&
						!UserRole.isAdminRole(user?.user_type) && (
							<ImpossibleBookingText>* 당일 예약 불가</ImpossibleBookingText>
						)}
				</BookingTitleContainer>
				{renderBookingDateSelector()}
			</BookingInfoContent>
			{bookingDateChange ? (
				<BookingDateChangeButtonContainer>
					<CancelButton onClick={bookingDateChange.onClose}>취소</CancelButton>
					<OkButton onClick={onClickConfirmBookingDateChange}>확인</OkButton>
				</BookingDateChangeButtonContainer>
			) : (
				<ButtonContainer>
					<StepButton onClick={onClickPrev}>
						<StepButtonPrev>{'<'}</StepButtonPrev>
						<span>이전단계</span>
					</StepButton>
					<StepButton onClick={onClickNext}>
						<StepButtonNext>{'>'}</StepButtonNext>
						<span>다음단계</span>
					</StepButton>
				</ButtonContainer>
			)}
			{bookingStatusModalData.show && (
				<BookingStatusModal
					unitType={unitData.unit_type_name}
					calendarUnitId={unitData.id}
					onClose={() => setBookingStatusModalData({ show: false })}
				/>
			)}
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	background-color: #ffffff;
	border: 1px solid #dddddd;

	${(props) =>
		props.sticky &&
		css`
			position: sticky;
			top: 110px;
			margin-top: 20px;

			@media only screen and (max-width: 767.98px) {
				margin-top: 10px;
			}
		`}
`;

const BookingInfoContent = styled.div`
	padding: 25px 30px 30px 30px;

	@media only screen and (max-width: 767.98px) {
		padding: 15px 10px;
	}
`;

const BookingTitleContainer = styled.div`
	padding-bottom: 20px;
	border-bottom: 1px solid #dddddd;
	display: flex;
	align-items: center;
	gap: 10px;

	@media only screen and (max-width: 767.98px) {
		padding-bottom: 12px;
		line-height: 24px;
	}
`;

const BookingTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 26px;
	line-height: 31px;
	color: #333333;

	@media only screen and (max-width: 767.98px) {
		font-size: 20px;
	}
`;

const ImpossibleBookingText = styled.span`
	color: #4a4a4a;
	font-size: 14px;
`;

const BookingSubTitleContainer = styled.div`
	width: 100%;
	margin-top: 20px;
	margin-bottom: 11px;
	display: flex;
	align-items: center;

	${(props) =>
		props.inline &&
		css`
			width: auto;
			margin-bottom: 0;
		`}

	@media only screen and (max-width: 767.98px) {
		margin-top: 15px;
	}
`;

const BookingSubTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #023169;

	@media only screen and (max-width: 767.98px) {
		font-size: 16px;
		line-height: 19px;
	}
`;

const SearchDateContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const SearchDateInput = styled.input`
	width: 200px;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;
	border-radius: 4px;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
	}
`;

const BookingStatusButton = styled.button`
	flex-shrink: 0;
	padding: 7px 10px;
	box-sizing: border-box;
	background-color: #f5f5f5;
	border: 1px solid #bfbfbf;
	border-radius: 5px;
	cursor: pointer;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 19px;
	text-align: center;
	color: #4a4a4a;
`;

const BookingAvailableContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 30px;
	margin-bottom: 14px;
`;

const BookingBlockContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const BookingBlockItem = styled.div`
	display: flex;
	align-items: center;
`;

const BookingBlockSquare = styled.div`
	width: 15px;
	height: 15px;
`;

const FulledBookingBlockSquare = styled(BookingBlockSquare)`
	background-color: #a2a2a2;
`;

const DisabledBookingBlockSquare = styled(BookingBlockSquare)`
	background-color: #cccccc;
`;

const BookingBlockTitle = styled.span`
	margin-left: 7px;
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.42px;
	line-height: 100%;
`;

const BookingAvailableTable = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ScheduleBox = styled.div`
	width: 20px;
	height: 15px;
	border: 1px solid #6d9ed8;
	border-right: none;

	&:nth-last-child(1) {
		border-right: 1px solid #6d9ed8;
	}
	&:nth-child(2n + 1) {
		border-style: solid dashed solid solid;
		border-right: 1px dashed #dddddd;
	}
	&:nth-child(2n) {
		border-left: none;
	}
	${(props) =>
		props.booked &&
		css`
			background-color: #e4f0fa;
			border: 1px solid #6d9ed8;
			border-right: none;
		`}

	${(props) =>
		props.fulled &&
		css`
			background-color: #a2a2a2;
			border: 1px solid #6d9ed8;
			border-right: none;
		`}

	${(props) =>
		!props.enabled &&
		css`
			background-color: #cccccc;
			border: 1px solid #6d9ed8;
			border-right: none;
		`}
`;

const ScheduleBoxText = styled.div`
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.28px;
	line-height: 100%;

	position: relative;
	top: 17px;
	left: -7px;

	${(props) =>
		props.last &&
		css`
			left: 10px;
		`}
	${(props) =>
		props.small &&
		css`
			left: -2px;
		`}
`;

const BookingBlockTimeContainer = styled.div`
	display: flex;
	width: 100%;
	margin-top: 30px;
	align-items: center;
	justify-content: space-between;
`;

const BookingSubTitleDescription = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	text-align: right;
	color: #666666;
`;

const BookingBlockTime = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	width: 100%;
	padding: 15px 0;
	margin-top: 18px;
	background-color: #f0f4fd;
	border-radius: 5px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #22499d;
`;

const BookingBlockTimeText = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #22499d;

	@media only screen and (max-width: 767.98px) {
		font-size: 16px;
		line-height: 19px;
	}
`;

const BookingBlockTimeInfoText = styled.div`
	margin-top: 7px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	color: #4a4a4a;

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
		line-height: 17px;
	}
`;

const DisabledBookingScheduleText = styled.div`
	margin-top: 7px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 19px;
	text-align: center;
	color: #7e7d7d;
`;

const BlockTimeUnitContainer = styled.div`
	display: flex;
	width: 100%;
	margin-top: 10px;
`;

const ReloadBlueIcon = styled.i`
	display: block;
	width: 16px;
	height: 16px;
	margin-right: 9px;
	background-image: url(${ReloadBlueIconImage});
`;

const ReloadWhiteIcon = styled.i`
	display: none;
	width: 16px;
	height: 16px;
	margin-right: 9px;
	background-image: url(${ReloadWhiteIconImage});
`;

const BlockTimeUnit = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	width: 110px;
	height: 50px;
	border: 1px solid #333333;
	cursor: pointer;

	span {
		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		text-align: center;
		color: #4a4a4a;
	}

	&:hover {
		background-color: #e9e9e9;
	}
	${(props) =>
		props.left &&
		css`
			border-radius: 5px 0px 0px 5px;
			border-right: 0px;
		`}

	${(props) =>
		props.right &&
		css`
			border-radius: 0px 5px 5px 0px;
		`}

	${(props) =>
		props.reset &&
		css`
			margin-left: 10px;
			border: 1px solid #22499d;
			border-radius: 5px;
			span {
				color: #22499d;
			}

			&:hover {
				background-color: #0c438d;
				span {
					color: #ffffff;
				}

				& > ${ReloadBlueIcon} {
					display: none;
				}
				& > ${ReloadWhiteIcon} {
					display: block;
				}
			}
		`}

	@media only screen and (max-width: 767.98px) {
		span {
			font-size: 14px;
			line-height: 17px;
		}
	}
`;

const BookingDateChangeButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 25px;
`;

const CancelButton = styled.button`
	width: 125px;
	height: 50px;
	border: 1px solid #22499d;
	border-radius: 5px;
	background-color: #ffffff;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #22499d;

	&:hover {
		background-color: #0c438d;
		color: #ffffff;
	}
`;

const OkButton = styled.div`
	width: 125px;
	height: 50px;
	margin-left: 10px;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	background-color: #22499d;
	border-radius: 5px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #ffffff;

	&:hover {
		background-color: #0c438d;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;

const StepButton = styled.div`
	position: relative;
	width: 50%;
	height: 64px;
	border: 1px solid #8f8f8f;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #000000;

	& + & {
		border-left: 0px;
	}

	&:hover {
		background-color: #22499d;
		color: #ffffff;
	}

	@media only screen and (max-width: 767.98px) {
		height: 46px;
		font-size: 16px;
		line-height: 19px;
	}
`;

const StepButtonPrev = styled.div`
	position: absolute;
	top: 20px;
	left: 20px;

	@media only screen and (max-width: 767.98px) {
		top: 12px;
	}
`;

const StepButtonNext = styled.div`
	position: absolute;
	top: 20px;
	right: 20px;

	@media only screen and (max-width: 767.98px) {
		top: 12px;
	}
`;

export default BookingDateSelector;
