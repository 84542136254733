export default {
	COMMON: 0, // 일반 회원
	LEARNER: 1, // 학생
	GUEST: 2, // 일반 사용자
	MENTOR: 3, // 선생님
	STAFF: 4, // 스태프
	ASSISTANT: 5, // 조교
	INSTRUCTOR: 6, // 교수
	ADMINISTRATOR: 9, // 운영자

	toString: (val) => {
		switch (val) {
			case 0:
				return '일반회원';
			case 1:
				return '학생';
			case 2:
				return '일반 사용자';
			case 3:
				return '선생님';
			case 4:
				return '스태프';
			case 5:
				return '조교';
			case 6:
				return '교수';
			case 9:
				return '운영자';
			default:
				return '-';
		}
	},
};
