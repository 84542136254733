import React from 'react';
import styled, { css } from 'styled-components';

import ArrowDownImg from '../assets/images/common/arrow_down.svg';

const UnitTypeTab = ({ tabs, value, onChange }) => {
	return (
		<Container>
			{tabs?.map((tab, idx) => {
				return (
					<TabItem key={idx} selected={tab.key === value} onClick={() => onChange(tab.key)}>
						{tab.text}
						<ArrowDownIcon src={ArrowDownImg} />
					</TabItem>
				);
			})}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
`;

const TabItem = styled.div`
	position: relative;
	width: 50%;
	height: 60px;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: #e6ebf4;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #122a5e;

	${(props) =>
		props.selected &&
		css`
			color: #ffffff;
			background-color: #22499d;
			& > ${ArrowDownIcon} {
				display: block;
			}
		`}

	@media only screen and (max-width: 767.98px) {
		height: 45px;
	}
`;

const ArrowDownIcon = styled.img`
	display: none;
	position: absolute;
	top: 26px;
	right: 26px;

	@media only screen and (max-width: 767.98px) {
		top: 20px;
		right: 16px;
	}
`;

export default UnitTypeTab;
