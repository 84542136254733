import UnitType from './UnitType';

export default {
	COMMON: 0, // 일반 사용자
	ROOM_ADMINISTRATOR: 1, // 시설 관리자
	PRODUCT_ADMINISTRATOR: 2, // 장비 관리자
	PROGRAM_ADMINISTRATOR: 3, // 프로그램 관리자
	UNIT_ADMINISTRATOR: 4, // 예약 항목 관리자
	CONSULT_ADMINISTRATOR: 5, // 상담 관리자
	SYSTEM_ADMINISTRATOR: 9, // 시스템 관리자

	toString: (val) => {
		switch (val) {
			case 0:
				return '일반 사용자';
			case 1:
				return '시설 관리자';
			case 2:
				return '장비 관리자';
			case 3:
				return '프로그램 관리자';
			case 4:
				return '예약 항목 관리자';
			case 5:
				return '상담 관리자';
			case 9:
				return '시스템 관리자';
			default:
				return '-';
		}
	},

	isAdminRole: (userRole) => {
		return userRole === 1 || userRole === 2 || userRole === 3 || userRole === 5 || userRole === 9;
	},

	isSystemAdminRole: (userRole) => {
		return userRole === 9;
	},

	isRoomAdminRole: (userRole) => {
		return userRole === 1;
	},

	isProductAdminRole: (userRole) => {
		return userRole === 2;
	},

	isConsultAdminRole: (userRole) => {
		return userRole === 5;
	},

	isUnitAdminRole: (userRole) => {
		return userRole === 4;
	},

	isRoomUnitAdmin: (userRole, unitAdmins) => {
		return userRole === 4 && unitAdmins?.some((unit) => unit.unit_type === UnitType.ROOM);
	},

	isTargetRoomUnitAdmin: (userRole, unitAdmins, unitId) => {
		return (
			userRole === 4 && unitAdmins?.some((unit) => unit.unit_type === UnitType.ROOM && unit.unit_id === unitId)
		);
	},

	isProductUnitAdmin: (userRole, unitAdmins) => {
		return userRole === 4 && unitAdmins?.some((unit) => unit.unit_type === UnitType.PRODUCT);
	},

	isTargetProductUnitAdmin: (userRole, unitAdmins, unitId) => {
		return (
			userRole === 4 && unitAdmins?.some((unit) => unit.unit_type === UnitType.PRODUCT && unit.unit_id === unitId)
		);
	},

	isConsultUnitAdmin: (userRole, unitAdmins) => {
		return userRole === 4 && unitAdmins?.some((unit) => unit.unit_type === UnitType.CONSULT);
	},

	isTargetConsultUnitAdmin: (userRole, unitAdmins, unitId) => {
		return (
			userRole === 4 && unitAdmins?.some((unit) => unit.unit_type === UnitType.CONSULT && unit.unit_id === unitId)
		);
	},
};
