import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useLocation } from 'react-router-dom';
import * as api from '../apis';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Popup from '../components/Popup';

const FrontLayout = ({ children, background }) => {
	const location = useLocation();
	const [popupList, setPopupList] = useState([]);

	useEffect(() => {
		api.getEnabledPopups()
			.then((res) => {
				if (res && res.data) {
					setPopupList(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(() => {
		const scrollToTop = () => window.scrollTo(0, 0);
		return () => {
			scrollToTop();
		};
	}, [location]);

	return (
		<Container>
			<Header />
			<Content background={background}>{children}</Content>
			<Footer />
			{popupList.map((popup, idx) => {
				return <Popup key={idx} idx={idx} data={popup}></Popup>;
			})}
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	height: 100%;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 100%;
	min-height: calc(100vh - 180px);
	padding: 90px 0 0 0;

	background-color: #f1f3f6;

	${(props) =>
		props.background &&
		css`
			background-color: ${props.background};
		`}

	@media only screen and (max-width: 767.98px) {
		padding: 65px 0 0 0;
		min-height: calc(100vh - 140px);
	}
`;

export default FrontLayout;
