export default {
	ROOM: 'room',
	PRODUCT: 'product',
	CONSULT: 'consult',
	toString: (val) => {
		switch (val) {
			case 'room':
				return '시설';
			case 'product':
				return '장비';
			case 'consult':
				return '상담';
			default:
				return '-';
		}
	},
};
