const CustomResource = {
	univ_name: '경상국립대학교',
	site_title: '스튜디오 예약시스템',
	sso_login: '숭실대학교 LMS ID/PW로 로그인합니다.',
	address1: '52828 경상남도 진주시 진주대로 501 경상국립대학교 24-가동 2층 이러닝 스튜디오',
	address2: '',
	contact: '대표전화 : 055) 772-4866',
	copyright: '© GYEONGSANG NATIONAL University. All Right Resrerved.',
};

export default CustomResource;
