export default {
	SUNDAY: 0,
	MONDAY: 1,
	THUSDAY: 2,
	WENDSDAY: 3,
	THURSDAY: 4,
	FRIDAY: 5,
	SATURDAY: 6,
	toShortString: (value) => {
		switch (value) {
			case 0:
				return '일';
			case 1:
				return '월';
			case 2:
				return '화';
			case 3:
				return '수';
			case 4:
				return '목';
			case 5:
				return '금';
			case 6:
				return '토';
			default:
				return '-';
		}
	},
};
