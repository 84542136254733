import UnitType from '../constants/UnitType';

export default [
	{
		name: '시설',
		link: '/admin/dashboard/room',
		tab_unit: UnitType.ROOM,
	},
	{
		name: '장비',
		link: '/admin/dashboard/product',
		tab_unit: UnitType.PRODUCT,
		use_product: true,
	},
	{
		name: '상담',
		link: '/admin/dashboard/consult',
		tab_unit: UnitType.CONSULT,
		use_consult: true,
	},
];
