import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import * as api from '../../../apis';
import useAdminSizeDetector from '../../../hooks/useAdminSizeDetector';
import PopupPublishType from '../../../constants/PopupPublishType';

const PopupSetting = () => {
	const navigate = useNavigate();
	const { isDesktop, isTabletHorizontal } = useAdminSizeDetector();
	const [popupList, setPopupList] = useState([]);
	const [allChecked, setAllChecked] = useState(false);

	useEffect(() => {
		getPopupsFromApi();
	}, []);

	useEffect(() => {
		setPopupList(
			popupList.map((data) => {
				return {
					...data,
					selected: allChecked,
				};
			})
		);
	}, [allChecked]);

	const getPopupsFromApi = () => {
		api.getPopups()
			.then((res) => {
				if (res && res.data) {
					setPopupList(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const getPeriodString = (popup) => {
		switch (popup.publish_type) {
			case PopupPublishType.PERIOD:
				return `${popup.publish_start_date ?? '-'} ~ ${popup.publish_end_date ?? '-'}`;
			case PopupPublishType.ALWAYS:
				return '상시 게시';
			case PopupPublishType.NONE:
				return '비공개';
		}
		return '';
	};

	const onClickAddButton = () => {
		navigate('/admin/settings/popups/create');
	};

	const onClickModifyButton = (popup) => {
		navigate(`/admin/settings/popups/${popup.id}/edit`);
	};

	const onClickDeleteButton = (popup) => {
		if (!window.confirm('정말 삭제하시겠습니까?')) {
			return;
		}

		api.deletePopup(popup.id)
			.then((res) => {
				alert('팝업을 정상적으로 삭제하였습니다.');
				getPopupsFromApi();
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const onClickDeleteSelected = () => {
		const selectedPopup = popupList.filter((val) => val.selected);
		if (selectedPopup.length === 0) {
			alert('선택한 팝업이 없습니다.');
			return;
		}

		if (!window.confirm(`선택한 ${selectedPopup.length}개의 팝업을 정말 삭제하시겠습니까?`)) return;

		let promiseArr = [];
		selectedPopup.map((popup) => {
			promiseArr.push(api.deletePopup(popup.id));
		});
		Promise.all(promiseArr).then(() => {
			alert('선택한 팝업을 정상적으로 삭제하였습니다.');
			getPopupsFromApi();
		});
	};

	return (
		<Content>
			<TopContainer>
				<SectionTitleContainer>
					<SectionTitle>팝업 관리</SectionTitle>
					<SectionDescription>
						메인페이지에 사용되는 팝업의 제목, 내용 및 공개여부를 설정할 수 있습니다.
					</SectionDescription>
				</SectionTitleContainer>
				<ButtonContainer>
					<SectionSubTitle>{`팝업 개수 ${popupList.length}개`}</SectionSubTitle>
					<AddButton onClick={onClickAddButton}>신규 팝업 등록</AddButton>
				</ButtonContainer>
			</TopContainer>
			<section>
				<PopupListTableContainer>
					<PopupListTable>
						<thead>
							<tr>
								<th style={{ minWidth: '60px' }}>
									<input
										type="checkbox"
										checked={allChecked}
										onChange={(e) => setAllChecked(e.currentTarget.checked)}
									/>
								</th>
								<th style={{ minWidth: '190px' }}>제목</th>
								<th style={{ minWidth: '190px' }}>게시 기간</th>
								<th style={{ minWidth: '190px' }}>등록일</th>
								<th style={{ minWidth: '48px' }}>수정</th>
								<th style={{ minWidth: '48px' }}>삭제</th>
							</tr>
						</thead>
						<tbody>
							{popupList?.map((popup, idx) => {
								return (
									<tr key={idx}>
										<td>
											<input
												type="checkbox"
												checked={popup.selected}
												onChange={(e) =>
													setPopupList(
														popupList.map((popup, popupIdx) => {
															if (idx === popupIdx) {
																return {
																	...popup,
																	selected: e.currentTarget.checked,
																};
															}
															return popup;
														})
													)
												}
											/>
										</td>
										<td>{popup.title ?? '-'}</td>
										<td>{getPeriodString(popup)}</td>
										<td>{popup.created_at ? new Date(popup.created_at).toLocaleString() : '-'}</td>
										<td>
											<ModifyButton onClick={() => onClickModifyButton(popup)}>수정</ModifyButton>
										</td>
										<td>
											<DeleteButton onClick={() => onClickDeleteButton(popup)}>삭제</DeleteButton>
										</td>
									</tr>
								);
							})}
						</tbody>
					</PopupListTable>
				</PopupListTableContainer>
				<PopupListTableBottomContainer>
					<DeleteSelectedButton onClick={onClickDeleteSelected}>선택삭제</DeleteSelectedButton>
				</PopupListTableBottomContainer>
			</section>
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 40px;
	background-color: white;

	@media only screen and (max-width: 1023.98px) {
		padding: 40px 20px;
	}

	@media only screen and (max-width: 479.98px) {
		padding: 20px 10px;
	}
`;

const TopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
`;

const SectionTitleContainer = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 10px;
`;

const SectionTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;
`;

const SectionDescription = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 20px;
	color: #22499d;
`;

const ButtonContainer = styled.div`
	margin-top: 10px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const SectionSubTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
	color: #666666;
`;

const AddButton = styled.div`
	height: 36px;
	padding: 0 15px;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: #454655;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #ffffff;

	&:hover {
		background-color: #1d1e31;
	}
`;

const PopupListTableContainer = styled.div`
	max-width: 1160px;
	overflow-x: scroll;
`;

const PopupListTable = styled.table`
	width: 100%;

	border-collapse: collapse;
	border-top: 2px solid #0e498f;

	text-align: center;
	margin-top: 20px;
	thead th {
		height: 45px;
		background-color: #f6f8fb;
		border-right: 1px solid #dddddd;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #0e498f;

		:first-child {
			border-left: 1px solid #dddddd;
		}
	}
	thead tr {
		border-bottom: 1px solid #dcdcdc;
	}

	tbody td {
		height: 45px;

		border-right: 1px solid #dddddd;
		padding: 6px 2px;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #333333;

		word-break: break-all;
		:first-child {
			border-left: 1px solid #dddddd;
		}
	}
	tbody tr {
		border-bottom: 1px solid #dddddd;
	}
`;

const PopupListTableBottomContainer = styled.div`
	height: 45px;
	display: flex;
	align-items: center;
	background: #f7f7f7;
	border-bottom: 1px solid #dddddd;
`;

const DeleteSelectedButton = styled.button`
	width: 60px;
	height: 28px;
	margin-left: 8px;

	background-color: #ffffff;
	border: 1px solid #333333;
	border-radius: 5px;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;

	white-space: nowrap;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #333333;

	&:hover {
		border: 1px solid #2b62ab;
		color: #2b62ab;
	}
`;

const ModifyButton = styled.div`
	width: 43px;
	height: 26px;

	display: inline-flex;
	align-items: center;
	justify-content: center;

	background-color: #2f5a8c;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #ffffff;
`;

const DeleteButton = styled.div`
	width: 43px;
	height: 26px;

	display: inline-flex;
	align-items: center;
	justify-content: center;

	background-color: #2f5a8c;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #ffffff;
`;

export default PopupSetting;
