import React, { useState, useEffect, useRef, useContext } from 'react';
import styled, { css } from 'styled-components';
import useFilterHandler from '../../hooks/useFilterHandler';
import CategorySlug from '../../constants/CategorySlug';
import ApprovalStatus from '../../constants/ApprovalStatus';
import RentalStatus from '../../constants/RentalStatus';
import ParticipationStatus from '../../constants/ParticipationStatus';
import UnitType from '../../constants/UnitType';
import UserRole from '../../constants/UserRole';
import LoadingIndicator from '../LoadingIndicator';
import BookingDateChangeModal from './BookingDateChangeModal';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { UserContext } from '../../contexts/UserContext';

import * as api from '../../apis';
import * as utils from '../../utils';

const BookingItemModal = ({ isAdmin, unitType, booking, onSave, onClose }) => {
	const { user } = useContext(UserContext);
	const [approvalStatus, setApprovalStatus] = useState(booking?.approval_status);
	const [rentalStatus, setRentalStatus] = useState(booking?.rental_status);
	const [participationStatus, setParticipationStatus] = useState(booking?.participation_status);
	const [requestTitle, setRequestTitle] = useState(booking?.operator_request?.title);
	const [requestDescription, setRequestDescription] = useState(booking?.operator_request?.description);
	const [memoTitle, setMemoTitle] = useState(booking?.operator_memo?.title ?? '');
	const [memoDescription, setMemoDescription] = useState(booking?.operator_memo?.description ?? '');
	const [answerDescription, setAnswerDescription] = useState(booking?.operator_request?.answer);
	const { categoryFilter } = useFilterHandler({
		onFetchCompleted: () => {
			setInitialized(true);
		},
	});
	const [initialized, setInitialized] = useState(false);
	const [isInfoEdit, setIsInfoEdit] = useState(false);
	const [contact, setContact] = useState(booking?.contact ? booking?.contact : booking?.user?.phone);
	const [bookingDate, setBookingDate] = useState(booking?.booking_date);
	const [usageCount, setUsageCount] = useState(booking?.usage_count);
	const [purposeDescription, setPurposeDescription] = useState(booking?.purpose_description);
	const [extraDescription, setExtraDescription] = useState(booking?.extra_description);
	const [attachments, setAttachments] = useState(booking?.attachments);
	const [categoryPurpose, setCategoryPurpose] = useState(booking?.category_purpose);
	const [isLoading, setIsLoading] = useState(false);
	const [bookingDateChangeModalData, setBookingDateChangeModalData] = useState({ show: false });
	const modalTopContainerRef = useRef(null);

	useEffect(() => {
		// 모달이 보일 경우에 body의 overflow 속성을 지정하여 모달 뒷 배경이 스크롤되지 않도록 한다.
		document.body.style.overflow = 'hidden';

		return () => {
			document.body.style.overflow = '';
		};
	}, []);

	// 신청 내역 변경 저장
	const onClickSaveInfoEdit = () => {
		if (!validateInfoEdit()) {
			return;
		}

		const data = {
			contact,
			usage_count: usageCount,
			purpose_description: purposeDescription,
			extra_description: extraDescription,
			attachment_files: attachments,
		};

		// 시설인 경우 이용 목적을 함께 전달해서 업데이트 한다.
		if (unitType === UnitType.ROOM) {
			data.category_purpose_id = categoryPurpose?.id ?? 0; // 이용 목적을 선택하지 않은 경우 0을 전달해서 삭제한다.
		}

		setIsLoading(true);
		api.updateBooking(booking.id, data)
			.then((res) => {
				if (res && res.data) {
					alert('신청 내역을 변경하였습니다.');
					onSave(res.data);
					setIsInfoEdit(false);
				}
			})
			.catch((err) => {
				console.error(err);
				alert('신청 내역 변경에 실패하였습니다.');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const validateInfoEdit = () => {
		switch (unitType) {
			case UnitType.ROOM:
				return validateRoomInfoEdit();
			case UnitType.PRODUCT:
				return validateProductInfoEdit();
			case UnitType.CONSULT:
				return validateConsultInfoEdit();
		}

		return false;
	};

	const validateRoomInfoEdit = () => {
		if (!contact) {
			alert('연락처를 입력해주세요.');
			return false;
		}

		// 숫자, 하이픈만 허용
		if (!/^(\d|-)+$/.test(contact)) {
			alert('숫자와 - 만 입력 가능합니다.');
			return false;
		}

		// 연락처는 7자리 이상이어야 한다.
		if (contact.length < 7) {
			alert('연락처는 7자리 이상으로 입력해주세요.');
			return false;
		}

		if (!usageCount || usageCount == 0) {
			alert('사용 인원을 정확하게 입력하세요.');
			return false;
		}

		if (usageCount > booking?.unit.room_detail.max_usage_count) {
			alert('최대 사용 인원을 초과하였습니다.');
			return false;
		}

		return true;
	};

	const validateProductInfoEdit = () => {
		if (!contact) {
			alert('연락처를 입력해주세요.');
			return false;
		}

		// 숫자, 하이픈만 허용
		if (!/^(\d|-)+$/.test(contact)) {
			alert('숫자와 - 만 입력 가능합니다.');
			return false;
		}

		// 연락처는 7자리 이상이어야 한다.
		if (contact.length < 7) {
			alert('연락처는 7자리 이상으로 입력해주세요.');
			return false;
		}

		if (!usageCount || usageCount == 0) {
			alert('예약 수량을 정확하게 입력하세요.');
			return false;
		}

		if (usageCount > booking?.unit.product_detail.max_rental_count) {
			alert('최대 예약 가능 수량을 초과하였습니다.');
			return false;
		}

		return true;
	};

	const validateConsultInfoEdit = () => {
		if (!contact) {
			alert('연락처를 입력해주세요.');
			return false;
		}

		// 숫자, 하이픈만 허용
		if (!/^(\d|-)+$/.test(contact)) {
			alert('숫자와 - 만 입력 가능합니다.');
			return false;
		}

		// 연락처는 7자리 이상이어야 한다.
		if (contact.length < 7) {
			alert('연락처는 7자리 이상으로 입력해주세요.');
			return false;
		}

		if (!usageCount || usageCount == 0) {
			alert('참여 인원을 정확하게 입력하세요.');
			return false;
		}

		if (usageCount > booking?.unit.room_detail.max_usage_count) {
			alert('최대 인원을 초과하였습니다.');
			return false;
		}

		return true;
	};

	const scrollToModalTop = () => {
		if (modalTopContainerRef.current) {
			modalTopContainerRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	const onClickEdit = () => {
		setIsInfoEdit(true);
		scrollToModalTop();
	};

	// 신청 내역 변경 취소
	const onClickCancelInfoEdit = () => {
		// 신청 내역 변경을 취소하는 경우 초기 값으로 되돌린다.
		setContact(booking?.contact ? booking?.contact : booking?.user?.phone);
		setUsageCount(booking?.usage_count);
		setPurposeDescription(booking?.purpose_description);
		setExtraDescription(booking?.extra_description);
		setAttachments(booking?.attachments);
		setCategoryPurpose(booking?.category_purpose);
		setIsInfoEdit(false);
	};

	const onClickConfirm = async () => {
		try {
			if (isAdmin) {
				if (booking?.approval_status !== approvalStatus) {
					const params = {
						booking_ids: [booking?.id],
						approval_status: approvalStatus,
					};

					await api.updateApprovalStatus(params);
				}

				if (unitType === UnitType.PRODUCT && booking?.rental_status !== rentalStatus) {
					const params = {
						booking_ids: [booking?.id],
						rental_status: rentalStatus,
					};

					await api.updateRentalStatus(params);
				}

				// 상담인 경우 참여 여부 변경 처리
				if (unitType === UnitType.CONSULT && booking?.participation_status !== participationStatus) {
					const params = {
						booking_ids: [booking?.id],
						participation_status: participationStatus,
					};

					await api.updateParticipationStatus(params);
				}

				if (requestTitle && requestDescription) {
					const params = {
						title: requestTitle,
						description: requestDescription,
					};

					await api.createBookingOperatorRequest(booking.id, params);
				}

				if (memoTitle) {
					const params = {
						title: memoTitle,
						description: memoDescription,
					};

					await api.createBookingOperatorMemo(booking.id, params);
				}
			}

			onClose(true);
		} catch (err) {
			console.error(err);
		}
	};

	const getCategoryPurposeItems = () => {
		return (
			categoryFilter?.find((category) => category.slug === CategorySlug.PURPOSE).filters[0].category_items ?? []
		);
	};

	const renderDisabledBookingScheduleTimeWithReason = () => {
		if (booking?.unit?.disable_schedule_times?.length === 0 || !bookingDate) {
			return null;
		}

		// bookingDate는 yyyy-mm-dd hh:mm~hh:mm 형식
		const [startTime, endTime] = bookingDate.split(' ')[1].split('~');

		const bookingStartMinutes = utils.timeToMinutes(startTime);
		const bookingEndMinutes = utils.timeToMinutes(endTime);

		const disabledBookingScheduleTimeWithReasonText = booking?.unit?.disable_schedule_times
			?.filter((data) =>
				utils.isBookingOverlappingWithDisabledTime(
					startTime,
					bookingEndMinutes - bookingStartMinutes,
					data.start_time,
					data.end_time
				)
			)
			?.map((data) => ` ${data.start_time} ~ ${data.end_time} 제외 - ${data.name}`)
			?.join(', ');

		if (!disabledBookingScheduleTimeWithReasonText) {
			return null;
		}

		return (
			<DisabledBookingScheduleText>(※ {disabledBookingScheduleTimeWithReasonText})</DisabledBookingScheduleText>
		);
	};

	const renderBookingRoomSection = () => {
		return (
			<>
				<InfoRow>
					<InfoColumn>
						<InfoTitle>{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PLACE, 1)}</InfoTitle>
						<InfoDescription>
							{utils.getUnitCategoryName(booking?.unit, CategorySlug.PLACE, 1)}
						</InfoDescription>
					</InfoColumn>
					<InfoColumn>
						<InfoTitle>{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PLACE, 2)}</InfoTitle>
						<InfoDescription>
							{utils.getUnitCategoryName(booking?.unit, CategorySlug.PLACE, 2)}
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn>
						<InfoTitle>{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PLACE, 3)}</InfoTitle>
						<InfoDescription>
							{utils.getUnitCategoryName(booking?.unit, CategorySlug.PLACE, 3)}
						</InfoDescription>
					</InfoColumn>
					<InfoColumn>
						<InfoTitle>{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.TYPE, 2)}</InfoTitle>
						<InfoDescription>
							{utils.getUnitCategoryName(booking?.unit, CategorySlug.TYPE, 2)}
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn>
						<InfoTitle>시설명</InfoTitle>
						<InfoDescription>{booking?.unit?.name ?? '-'}</InfoDescription>
					</InfoColumn>
					<InfoColumn>
						<InfoTitle>호실</InfoTitle>
						<InfoDescription>{booking?.room_item?.name ?? '-'}</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>이용기간</InfoTitle>
						<InfoDescription noShrink>{bookingDate ?? '-'}</InfoDescription>
						{renderDisabledBookingScheduleTimeWithReason()}
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>사용 인원</InfoTitle>
						<InfoDescription>
							{isInfoEdit ? (
								<>
									<UsageCountInput
										type="number"
										min={1}
										max={booking?.unit.room_detail.max_usage_count}
										value={usageCount}
										onChange={(e) => setUsageCount(e.currentTarget.value)}
									></UsageCountInput>
									<UsageCountText>명</UsageCountText>
									<MaxUsageCountText>{`최대 사용 인원 : ${booking?.unit.room_detail.max_usage_count}명`}</MaxUsageCountText>
								</>
							) : (
								<>{`${usageCount ?? '-'}명`}</>
							)}
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>촬영내용</InfoTitle>
						<InfoDescription className="w-100">
							{isInfoEdit ? (
								<InfoDescriptionTextArea
									value={purposeDescription ?? ''}
									onChange={(e) => setPurposeDescription(e.currentTarget.value)}
								></InfoDescriptionTextArea>
							) : (
								<>{purposeDescription ?? '-'}</>
							)}
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>특이사항</InfoTitle>
						<InfoDescription className="w-100">
							{isInfoEdit ? (
								<InfoDescriptionTextArea
									value={extraDescription ?? ''}
									onChange={(e) => setExtraDescription(e.currentTarget.value)}
								></InfoDescriptionTextArea>
							) : (
								<>{extraDescription ?? '-'}</>
							)}
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>교안 파일</InfoTitle>
						{!isInfoEdit && attachments.length === 0 && <InfoDescription>-</InfoDescription>}
						<AttachmentContainer>
							{isInfoEdit ? (
								<AttachmentInputContainer>
									<input
										type="file"
										onChange={(e) => {
											const attachment = {
												file: e.currentTarget.files[0],
												org_filename: e.currentTarget.files[0].name,
											};
											setAttachments([...attachments, attachment]);
											e.currentTarget.value = null;
										}}
									></input>
									{attachments.map((file, idx) => {
										return (
											<AttachmentFile key={idx}>
												{file.org_filename}
												<StyledHighlightOffIcon
													onClick={() =>
														setAttachments(
															attachments.filter((_, fileIdx) => fileIdx !== idx)
														)
													}
												/>
											</AttachmentFile>
										);
									})}
								</AttachmentInputContainer>
							) : (
								attachments?.map((file, idx) => {
									return (
										<InfoDescription key={idx}>
											<InfoDescriptionText>
												{file.filename ? (
													<a
														href={`${process.env.REACT_APP_API_URL}/storage/files/${file.filename}`}
														download={file.org_filename ?? '-'}
													>
														{file.org_filename ?? '-'}
													</a>
												) : (
													<>{file.org_filename ?? '-'}</>
												)}
											</InfoDescriptionText>
										</InfoDescription>
									);
								})
							)}
						</AttachmentContainer>
					</InfoColumn>
				</InfoRow>
				{getCategoryPurposeItems().length > 0 && (
					<InfoRow>
						<InfoColumn className="w-100">
							<InfoTitle>이용목적</InfoTitle>
							{isInfoEdit ? (
								<CategoryPurposeContainer>
									<CategoryPurposeSelect
										value={categoryPurpose?.id}
										onChange={(e) => {
											const categoryPurpose = getCategoryPurposeItems().find(
												(item) => item.id == e.currentTarget.value
											);
											setCategoryPurpose(categoryPurpose);
										}}
									>
										<option value="">이용목적을 선택해주세요.</option>
										{getCategoryPurposeItems().map((item) => {
											return (
												<option key={item.id} value={item.id}>
													{item.name}
												</option>
											);
										})}
									</CategoryPurposeSelect>
								</CategoryPurposeContainer>
							) : (
								<InfoDescription>{categoryPurpose?.name ?? '-'}</InfoDescription>
							)}
						</InfoColumn>
					</InfoRow>
				)}
			</>
		);
	};

	const renderBookingProductSection = () => {
		return (
			<>
				<InfoRow>
					<InfoColumn>
						<InfoTitle>
							{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PRODUCT_PLACE, 1)}
						</InfoTitle>
						<InfoDescription>
							{utils.getUnitCategoryName(booking?.unit, CategorySlug.PRODUCT_PLACE, 1)}
						</InfoDescription>
					</InfoColumn>
					<InfoColumn>
						<InfoTitle>
							{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PRODUCT_PLACE, 2)}
						</InfoTitle>
						<InfoDescription>
							{utils.getUnitCategoryName(booking?.unit, CategorySlug.PRODUCT_PLACE, 2)}
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn>
						<InfoTitle>
							{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PRODUCT_PLACE, 3)}
						</InfoTitle>
						<InfoDescription>
							{utils.getUnitCategoryName(booking?.unit, CategorySlug.PRODUCT_PLACE, 3)}
						</InfoDescription>
					</InfoColumn>
					<InfoColumn>
						<InfoTitle>
							{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PRODUCT_TYPE, 2)}
						</InfoTitle>
						<InfoDescription>
							{utils.getUnitCategoryName(booking?.unit, CategorySlug.PRODUCT_TYPE, 2)}
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn>
						<InfoTitle>장비명</InfoTitle>
						<InfoDescription>{booking?.unit?.name ?? '-'}</InfoDescription>
					</InfoColumn>
					<InfoColumn>
						<InfoTitle>장비번호</InfoTitle>
						<InfoDescription>
							{booking?.product_items?.map((productItem) => productItem.name).join(',') ?? '-'}
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>이용기간</InfoTitle>
						<InfoDescription>{bookingDate ?? '-'}</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>예약수량</InfoTitle>
						<InfoDescription>
							{isInfoEdit ? (
								<>
									<UsageCountInput
										type="number"
										min={1}
										max={booking?.unit.product_detail.max_rental_count}
										value={usageCount}
										onChange={(e) => setUsageCount(e.currentTarget.value)}
									></UsageCountInput>
									<UsageCountText>개</UsageCountText>
									<MaxUsageCountText>{`최대 예약 가능 수량 : ${booking?.unit.product_detail.max_rental_count}개`}</MaxUsageCountText>
								</>
							) : (
								`${usageCount ?? '-'}개`
							)}
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>이용목적</InfoTitle>
						<InfoDescription className="w-100">
							{isInfoEdit ? (
								<InfoDescriptionTextArea
									value={purposeDescription ?? ''}
									onChange={(e) => setPurposeDescription(e.currentTarget.value)}
								></InfoDescriptionTextArea>
							) : (
								<>{purposeDescription ?? '-'}</>
							)}
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>특이사항</InfoTitle>
						<InfoDescription className="w-100">
							{isInfoEdit ? (
								<InfoDescriptionTextArea
									value={extraDescription ?? ''}
									onChange={(e) => setExtraDescription(e.currentTarget.value)}
								></InfoDescriptionTextArea>
							) : (
								<>{extraDescription ?? '-'}</>
							)}
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
			</>
		);
	};

	const renderBookingConsultSection = () => {
		return (
			<>
				<InfoRow>
					<InfoColumn>
						<InfoTitle>
							{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.CONSULT_PLACE, 1)}
						</InfoTitle>
						<InfoDescription>
							{utils.getUnitCategoryName(booking?.unit, CategorySlug.CONSULT_PLACE, 1)}
						</InfoDescription>
					</InfoColumn>
					<InfoColumn>
						<InfoTitle>
							{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.CONSULT_PLACE, 2)}
						</InfoTitle>
						<InfoDescription>
							{utils.getUnitCategoryName(booking?.unit, CategorySlug.CONSULT_PLACE, 2)}
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn>
						<InfoTitle>
							{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.CONSULT_PLACE, 3)}
						</InfoTitle>
						<InfoDescription>
							{utils.getUnitCategoryName(booking?.unit, CategorySlug.CONSULT_PLACE, 3)}
						</InfoDescription>
					</InfoColumn>
					<InfoColumn>
						<InfoTitle>
							{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.CONSULT_TYPE, 2)}
						</InfoTitle>
						<InfoDescription>
							{utils.getUnitCategoryName(booking?.unit, CategorySlug.CONSULT_TYPE, 2)}
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn>
						<InfoTitle>상담명</InfoTitle>
						<InfoDescription>{booking?.unit?.name ?? '-'}</InfoDescription>
					</InfoColumn>
					<InfoColumn>
						<InfoTitle>상담자</InfoTitle>
						<InfoDescription>{booking?.room_item?.name ?? '-'}</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>상담일정</InfoTitle>
						<InfoDescription noShrink>{bookingDate ?? '-'}</InfoDescription>
						{renderDisabledBookingScheduleTimeWithReason()}
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>참여인원</InfoTitle>
						<InfoDescription>
							{isInfoEdit ? (
								<>
									<UsageCountInput
										type="number"
										min={1}
										max={booking?.unit.room_detail.max_usage_count}
										value={usageCount}
										onChange={(e) => setUsageCount(e.currentTarget.value)}
									></UsageCountInput>
									<UsageCountText>명</UsageCountText>
									<MaxUsageCountText>{`최대 인원 : ${booking?.unit.room_detail.max_usage_count}명`}</MaxUsageCountText>
								</>
							) : (
								<>{`${usageCount ?? '-'}명`}</>
							)}
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>상담내용</InfoTitle>
						<InfoDescription className="w-100">
							{isInfoEdit ? (
								<InfoDescriptionTextArea
									value={purposeDescription ?? ''}
									onChange={(e) => setPurposeDescription(e.currentTarget.value)}
								></InfoDescriptionTextArea>
							) : (
								<>{purposeDescription ?? '-'}</>
							)}
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>특이사항</InfoTitle>
						<InfoDescription className="w-100">
							{isInfoEdit ? (
								<InfoDescriptionTextArea
									value={extraDescription ?? ''}
									onChange={(e) => setExtraDescription(e.currentTarget.value)}
								></InfoDescriptionTextArea>
							) : (
								<>{extraDescription ?? '-'}</>
							)}
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>첨부파일</InfoTitle>
						{!isInfoEdit && attachments.length === 0 && <InfoDescription>-</InfoDescription>}
						<AttachmentContainer>
							{isInfoEdit ? (
								<AttachmentInputContainer>
									<input
										type="file"
										onChange={(e) => {
											const attachment = {
												file: e.currentTarget.files[0],
												org_filename: e.currentTarget.files[0].name,
											};
											setAttachments([...attachments, attachment]);
											e.currentTarget.value = null;
										}}
									></input>
									{attachments.map((file, idx) => {
										return (
											<AttachmentFile key={idx}>
												{file.org_filename}
												<StyledHighlightOffIcon
													onClick={() =>
														setAttachments(
															attachments.filter((_, fileIdx) => fileIdx !== idx)
														)
													}
												/>
											</AttachmentFile>
										);
									})}
								</AttachmentInputContainer>
							) : (
								attachments?.map((file, idx) => {
									return (
										<InfoDescription key={idx}>
											<InfoDescriptionText>
												{file.filename ? (
													<a
														target={'_blank'}
														href={`${process.env.REACT_APP_API_URL}/storage/files/${file.filename}`}
														download={file.org_filename ?? '-'}
													>
														{file.org_filename ?? '-'}
													</a>
												) : (
													<>{file.org_filename ?? '-'}</>
												)}
											</InfoDescriptionText>
										</InfoDescription>
									);
								})
							)}
						</AttachmentContainer>
					</InfoColumn>
				</InfoRow>
			</>
		);
	};

	const renderAdminSection = () => {
		return (
			<InfoContainer>
				<SectionTitle>관리 내역</SectionTitle>
				<InfoRow first>
					<InfoColumn className="w-100">
						<InfoTitle>예약 상태</InfoTitle>
						<InfoDescription>
							<StatusChangeSelect
								value={approvalStatus}
								onChange={(e) => setApprovalStatus(e.currentTarget.value)}
							>
								<option value={ApprovalStatus.APPROVED}>
									{ApprovalStatus.toString(ApprovalStatus.APPROVED)}
								</option>
								<option value={ApprovalStatus.REJECTED}>
									{ApprovalStatus.toString(ApprovalStatus.REJECTED)}
								</option>
								<option value={ApprovalStatus.CANCELED}>
									{ApprovalStatus.toString(ApprovalStatus.CANCELED)}
								</option>
								<option value={ApprovalStatus.PENDING}>
									{ApprovalStatus.toString(ApprovalStatus.PENDING)}
								</option>
							</StatusChangeSelect>
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				{unitType === UnitType.PRODUCT && (
					<InfoRow>
						<InfoColumn className="w-100">
							<InfoTitle>수령/반납 상태</InfoTitle>
							<InfoDescription>
								<StatusChangeSelect
									value={rentalStatus}
									onChange={(e) => setRentalStatus(e.currentTarget.value)}
								>
									<option value={RentalStatus.RENTAL_READY}>
										{RentalStatus.toString(RentalStatus.RENTAL_READY)}
									</option>
									<option value={RentalStatus.RENTAL_COMPLETE}>
										{RentalStatus.toString(RentalStatus.RENTAL_COMPLETE)}
									</option>
									<option value={RentalStatus.RENTAL_DELAY}>
										{RentalStatus.toString(RentalStatus.RENTAL_DELAY)}
									</option>
									<option value={RentalStatus.RETURN_COMPLETE}>
										{RentalStatus.toString(RentalStatus.RETURN_COMPLETE)}
									</option>
									<option value={RentalStatus.RETURN_DELAY}>
										{RentalStatus.toString(RentalStatus.RETURN_DELAY)}
									</option>
								</StatusChangeSelect>
							</InfoDescription>
						</InfoColumn>
					</InfoRow>
				)}
				{unitType === UnitType.CONSULT && (
					<InfoRow>
						<InfoColumn className="w-100">
							<InfoTitle>참여 여부</InfoTitle>
							<InfoDescription>
								<StatusChangeSelect
									value={participationStatus ?? ParticipationStatus.PARTICIPATION_READY}
									onChange={(e) => setParticipationStatus(e.currentTarget.value)}
								>
									<option value={ParticipationStatus.PARTICIPATION_READY}>
										{ParticipationStatus.toString(ParticipationStatus.PARTICIPATION_READY)}
									</option>
									<option value={ParticipationStatus.PARTICIPATION_COMPLETED}>
										{ParticipationStatus.toString(ParticipationStatus.PARTICIPATION_COMPLETED)}
									</option>
									<option value={ParticipationStatus.PARTICIPATION_ABSENT}>
										{ParticipationStatus.toString(ParticipationStatus.PARTICIPATION_ABSENT)}
									</option>
									<option value={ParticipationStatus.PARTICIPATION_PENDING}>
										{ParticipationStatus.toString(ParticipationStatus.PARTICIPATION_PENDING)}
									</option>
									<option value={ParticipationStatus.PARTICIPATION_SURVEY_COMPLETED}>
										{ParticipationStatus.toString(
											ParticipationStatus.PARTICIPATION_SURVEY_COMPLETED
										)}
									</option>
									<option value={ParticipationStatus.PARTICIPATION_SURVEY_ABSENT}>
										{ParticipationStatus.toString(ParticipationStatus.PARTICIPATION_SURVEY_ABSENT)}
									</option>
								</StatusChangeSelect>
							</InfoDescription>
						</InfoColumn>
					</InfoRow>
				)}
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>관리자 요청사항</InfoTitle>
						<RequestContainer>
							<RequestTitleInput
								value={requestTitle ?? ''}
								placeholder="요청사항 제목을 작성해주세요"
								onChange={(e) => setRequestTitle(e.currentTarget.value)}
							/>
							<RequestDescriptionInput
								value={requestDescription}
								placeholder="요청사항 내용을 작성해주세요"
								onChange={(e) => setRequestDescription(e.currentTarget.value)}
							/>
						</RequestContainer>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>예약자 답변</InfoTitle>
						<AnswerContainer>
							<AnswerText>{answerDescription ?? '답변이 존재하지 않습니다.'}</AnswerText>
						</AnswerContainer>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>관리자 메모</InfoTitle>
						<MemoContainer>
							<MemoTitleInput
								value={memoTitle ?? ''}
								placeholder="제목을 작성해주세요"
								onChange={(e) => setMemoTitle(e.currentTarget.value)}
							/>
							<MemoDescriptionInput
								value={memoDescription ?? ''}
								placeholder="내용을 작성해주세요"
								onChange={(e) => setMemoDescription(e.currentTarget.value)}
							/>
						</MemoContainer>
					</InfoColumn>
				</InfoRow>
			</InfoContainer>
		);
	};

	const renderFooterButton = () => {
		// 편집 모드인 경우 취소/저장 버튼
		if (isInfoEdit) {
			return (
				<>
					<CancelButton onClick={onClickCancelInfoEdit}>취소</CancelButton>
					<OkButton onClick={onClickSaveInfoEdit}>저장</OkButton>
				</>
			);
		}

		// 운영자이거나 신청 상태가 승인 대기인 경우에만 수정이 가능하다.
		if (UserRole.isAdminRole(user?.user_role) || approvalStatus === ApprovalStatus.PENDING) {
			return (
				<>
					<EditButton onClick={onClickEdit}>신청 내역 변경</EditButton>
					<EditButton onClick={() => setBookingDateChangeModalData({ show: true })}>
						이용 기간 변경
					</EditButton>
					<OkButton onClick={onClickConfirm}>확인</OkButton>
				</>
			);
		}

		return (
			<>
				<CancelButton onClick={() => onClose(false)}>취소</CancelButton>
				<OkButton onClick={onClickConfirm}>확인</OkButton>
			</>
		);
	};

	if (!initialized) return null;

	return (
		<ModalContainer>
			<LoadingIndicator size={50} loading={isLoading} />
			<Content>
				<ModalTopContainer ref={modalTopContainerRef}>
					<ModalTitle>신청 내역 확인</ModalTitle>
					<CloseButton onClick={() => onClose(false)}>x</CloseButton>
				</ModalTopContainer>
				<InfoContainer>
					<InfoRow first>
						<InfoColumn>
							<InfoTitle>예약번호</InfoTitle>
							<InfoDescription>{`#${booking.id ?? '0'}`}</InfoDescription>
						</InfoColumn>
						<InfoColumn>
							<InfoTitle>신청일</InfoTitle>
							<InfoDescription>
								{booking.created_at ? new Date(booking.created_at).toLocaleString() : '-'}
							</InfoDescription>
						</InfoColumn>
					</InfoRow>
					<InfoRow>
						<InfoColumn>
							<InfoTitle>예약자</InfoTitle>
							<InfoDescription>{booking?.user?.name ?? '-'}</InfoDescription>
						</InfoColumn>
						<InfoColumn>
							<InfoTitle>학번/교직원 번호</InfoTitle>
							<InfoDescription>{booking?.user?.user_login ?? '-'}</InfoDescription>
						</InfoColumn>
					</InfoRow>
					<InfoRow>
						<InfoColumn>
							<InfoTitle>이메일</InfoTitle>
							<InfoDescription>{booking?.user?.email ?? '-'}</InfoDescription>
						</InfoColumn>
						<InfoColumn>
							<InfoTitle>연락처</InfoTitle>
							<InfoDescription>
								{isInfoEdit ? (
									<InfoDescriptionInput
										value={contact ?? ''}
										onChange={(e) => setContact(e.currentTarget.value)}
									></InfoDescriptionInput>
								) : (
									<>{contact ?? '-'}</>
								)}
							</InfoDescription>
						</InfoColumn>
					</InfoRow>
					{unitType === UnitType.ROOM && renderBookingRoomSection()}
					{unitType === UnitType.PRODUCT && renderBookingProductSection()}
					{unitType === UnitType.CONSULT && renderBookingConsultSection()}
				</InfoContainer>
				{isAdmin && renderAdminSection()}
				<Footer>{renderFooterButton()}</Footer>
			</Content>
			{bookingDateChangeModalData.show && (
				<BookingDateChangeModal
					unitData={booking?.unit}
					bookingId={booking?.id}
					bookingDate={bookingDate}
					onChangeBookingDate={(value) => setBookingDate(value)}
					onClose={() => setBookingDateChangeModalData({ show: false })}
				/>
			)}
		</ModalContainer>
	);
};

const ModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		padding: 15px;
	}
`;

const Content = styled.div`
	position: relative;
	width: 720px;
	max-height: 90%;
	overflow-y: scroll;

	background-color: white;

	background: #ffffff;
	border: 1px solid #777777;
	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding: 15px 10px;
	}
`;

const ModalTopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 30px;
	border-bottom: 1px solid #cccccc;

	@media only screen and (max-width: 767.98px) {
		padding: 0 0 10px 0;
	}
`;

const ModalTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		font-size: 24px;
		line-height: 29px;
	}
`;

const CloseButton = styled.div`
	width: 30px;
	height: 30px;
	display: flex;
	align-items: flex-end;
	justify-content: center;

	cursor: pointer;

	color: #000000;
	font-family: 'Noto Sans';
	font-size: 24px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -1.2px;
	line-height: 30px;
	padding-top: 0px;
`;

const InfoContainer = styled.div`
	padding: 20px 30px 0px 30px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 20px;
		padding: 0;
	}
`;

const InfoRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;

	min-height: 45px;
	border-bottom: 1px solid #dddddd;

	${(props) =>
		props.first &&
		css`
			border-top: 2px solid #333333;
		`}

	@media only screen and (max-width: 767.98px) {
		min-height: auto;
		flex-direction: column;
		border-bottom: none;
	}
`;

const InfoColumn = styled.div`
	display: flex;
	width: 50%;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		flex-direction: column;
		border-bottom: 1px solid #dddddd;
		padding: 10px;
	}
`;

const InfoTitle = styled.span`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: 18px;

	width: 120px;
	min-width: 120px;

	background-color: #f7f7f7;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding-left: 0;
		background-color: #ffffff;
	}
`;

const InfoDescription = styled.span`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	padding: 5px 15px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
	word-break: break-all;

	${(props) =>
		props.noShrink &&
		css`
			flex-shrink: 0;
		`}

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		margin-top: 5px;
		padding: 0;
	}
`;

const InfoDescriptionText = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	width: 100%;
	height: 100%;
	padding: 8px;
	border: none;
	border-radius: 4px;
	background-color: #f7f7f7;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const InfoDescriptionInput = styled.input`
	width: 180px;
	height: 33px;
	padding: 8px;
	border: 1px solid #dddddd;
	border-radius: 5px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const InfoDescriptionTextArea = styled.textarea`
	width: 100%;
	padding: 8px;
	border: 1px solid #dddddd;
	border-radius: 5px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const UsageCountInput = styled(InfoDescriptionInput)`
	width: 100px;
`;

const UsageCountText = styled.div`
	margin-left: 5px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const MaxUsageCountText = styled.div`
	margin-left: 5px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #22499d;
`;

const AttachmentContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;

	input:not(:first-child) {
		margin-top: 8px;
	}
`;

const AttachmentInputContainer = styled.div`
	padding: 5px 15px;

	@media only screen and (max-width: 767.98px) {
		padding: 5px 0;
	}
`;

const AttachmentFile = styled.div`
	margin-top: 8px;
`;

const StyledHighlightOffIcon = styled(HighlightOffIcon)`
	margin-left: 8px;
	cursor: pointer;
`;

const CategoryPurposeContainer = styled.div`
	width: 100%;
	height: 100%;
	padding: 5px 15px;

	@media only screen and (max-width: 767.98px) {
		padding: 5px 0;
	}
`;

const CategoryPurposeSelect = styled.select`
	width: 100%;
	padding: 8px;
	appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, gray 50%),
		linear-gradient(135deg, gray 50%, transparent 50%);
	background-position: calc(100% - 15px) calc(1em - 2px), calc(100% - 10px) calc(1em - 2px), calc(100% - 2.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1px 1.5em;
	background-repeat: no-repeat;
	border: 1px solid #dddddd;
	border-radius: 5px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const SectionTitle = styled.div`
	margin-top: 20px;
	margin-bottom: 20px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;
`;

const Footer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px 0;

	@media only screen and (max-width: 767.98px) {
		margin-bottom: 0;
	}
`;

const CancelButton = styled.div`
	width: 125px;
	height: 50px;
	border: 1px solid #22499d;
	border-radius: 5px;
	background-color: #ffffff;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #22499d;

	&:hover {
		background-color: #0c438d;
		color: #ffffff;
	}
`;

const EditButton = styled(CancelButton)`
	&:not(:first-of-type) {
		margin-left: 10px;
	}
`;

const OkButton = styled.div`
	width: 125px;
	height: 50px;
	margin-left: 10px;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	background-color: #22499d;
	border-radius: 5px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #ffffff;

	&:hover {
		background-color: #0c438d;
	}
`;

const StatusChangeSelect = styled.select`
	width: 90px;
	height: 28px;
	padding-left: 8px;

	border-radius: 4px;
	border: 1px solid #dddddd;

	appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, gray 50%),
		linear-gradient(135deg, gray 50%, transparent 50%);
	background-position: calc(100% - 15px) calc(1em - 2px), calc(100% - 10px) calc(1em - 2px), calc(100% - 2.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1px 1.5em;
	background-repeat: no-repeat;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #666666;
`;

const RequestContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 175px;
	margin: 5px 15px;
	padding: 5px 0;

	@media only screen and (max-width: 767.98px) {
		margin: 0;
	}
`;

const RequestTitleInput = styled.input`
	width: 100%;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const RequestDescriptionInput = styled.textarea`
	width: 100%;
	height: 135px;

	margin-top: 8px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const MemoContainer = styled(RequestContainer)``;

const MemoTitleInput = styled(RequestTitleInput)``;

const MemoDescriptionInput = styled(RequestDescriptionInput)``;

const AnswerContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100px;
	background: #f7f7f7;
	border-radius: 4px;
	margin: 5px 15px;
	padding: 10px;

	@media only screen and (max-width: 767.98px) {
		margin: 0;
	}
`;

const AnswerText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const DisabledBookingScheduleText = styled.div`
	padding: 5px 15px 5px 0;
	display: flex;
	align-items: center;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 19px;
	color: #7e7d7d;
`;

export default BookingItemModal;
