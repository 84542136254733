import React, { useState } from 'react';
import styled from 'styled-components';

const AdditionalLinkModal = ({ onConfirm, onClose }) => {
	const [link, setLink] = useState('');

	return (
		<ModalContainer>
			<Content>
				<ModalTopContainer>
					<ModalTitle>{`동영상 추가 (링크입력)`}</ModalTitle>
					<CloseButton onClick={onClose}>x</CloseButton>
				</ModalTopContainer>
				<InputContainer>
					<TextInput type="text" value={link} onChange={(e) => setLink(e.currentTarget.value)} />
				</InputContainer>
				<Footer>
					<CancelButton onClick={onClose}>취소</CancelButton>
					<OkButton onClick={() => onConfirm(link)}>확인</OkButton>
				</Footer>
			</Content>
		</ModalContainer>
	);
};

const ModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Content = styled.div`
	position: relative;
	width: 720px;
	max-height: 80%;
	overflow-y: scroll;

	background-color: white;
	border: 1px solid #777777;
	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);
`;

const ModalTopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 30px;
	border-bottom: 1px solid #cccccc;
`;

const ModalTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;
`;

const CloseButton = styled.div`
	width: 30px;
	height: 30px;
	display: flex;
	align-items: flex-end;
	justify-content: center;

	cursor: pointer;

	color: #000000;
	font-family: 'Noto Sans';
	font-size: 24px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -1.2px;
	line-height: 30px;
	padding-top: 0px;
`;

const InputContainer = styled.div`
	margin-top: 20px;
	padding: 0px 30px;
`;

const TextInput = styled.input`
	width: 100%;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const Footer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px 0;
`;

const CancelButton = styled.div`
	width: 125px;
	height: 50px;
	border: 1px solid #22499d;
	border-radius: 5px;
	background-color: #ffffff;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #22499d;

	&:hover {
		background-color: #0c438d;
		color: #ffffff;
	}
`;

const OkButton = styled.div`
	width: 125px;
	height: 50px;
	margin-left: 10px;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	background-color: #22499d;
	border-radius: 5px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #ffffff;

	&:hover {
		background-color: #0c438d;
	}
`;

export default AdditionalLinkModal;
