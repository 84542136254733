import React, { useEffect, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import styled from 'styled-components';

import * as api from '../apis';

const LoginCallback = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const result = searchParams.get('result');
	const { user, login } = useContext(UserContext);

	useEffect(() => {
		if (user) {
			loginCompleted();
			return;
		}
		if (result) {
			loginSso(result);
		}
	}, []);

	const loginSso = async (result) => {
		try {
			const res = await api.loginSsoCallback(result);
			if (res && res.data) {
				login(res.data);
				loginCompleted();
			}
		} catch (err) {
			console.error(err);
		}
	};

	const loginCompleted = () => {
		navigate('/');
	};

	return <Container></Container>;
};

const Container = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export default LoginCallback;
