import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const TablePagination = ({ currentPage, lastPage, onPageChange }) => {
	const pageSetSize = 10;
	const currentPageSetIndex = parseInt((currentPage - 1) / pageSetSize);
	const [pageIndex, setPageIndex] = useState(currentPage);
	const [pageSet, setPageSet] = useState([]);

	useEffect(() => {
		if (!currentPage || !lastPage) return;

		let newPageSet = [];
		for (let i = 1; i <= pageSetSize; i++) {
			let page = currentPageSetIndex * pageSetSize + i;
			newPageSet.push(page);
			if (page === lastPage) {
				break;
			}
		}
		setPageSet(newPageSet);
		setPageIndex(currentPage);
	}, [currentPage, lastPage]);

	const onPrevButtonClick = () => {
		if (currentPageSetIndex === 0) return;
		onPageChange((currentPageSetIndex - 1) * pageSetSize + 1);
	};

	const onNextButtonClick = () => {
		if ((currentPageSetIndex + 1) * pageSetSize >= lastPage) return;
		onPageChange((currentPageSetIndex + 1) * pageSetSize + 1);
	};

	const onPageButtonClick = (page) => {
		if (page === pageIndex) return;

		setPageIndex(page);
		onPageChange(page);
	};

	if (pageSet.length === 0) return null;

	return (
		<PaginationContainer>
			<PrevButton disabled={currentPageSetIndex === 0} onClick={onPrevButtonClick} />
			{pageSet.map((page, idx) => {
				return (
					<PageButton key={idx} onClick={(e) => onPageButtonClick(page)} selected={page === pageIndex}>
						{page}
					</PageButton>
				);
			})}
			<NextButton disabled={(currentPageSetIndex + 1) * pageSetSize >= lastPage} onClick={onNextButtonClick} />
		</PaginationContainer>
	);
};

const PaginationContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const PrevButton = styled(ArrowBackIosNewIcon)`
	width: 30px;
	height: 30px;
	font-size: 16px !important;
	color: #8c8c8c;
	margin-right: 10px;
	cursor: pointer;

	${(props) =>
		props.disabled &&
		css`
			color: #dddddd;
		`}
`;

const NextButton = styled(ArrowForwardIosIcon)`
	width: 30px;
	height: 30px;
	font-size: 16px !important;
	color: #8c8c8c;
	margin-left: 10px;
	cursor: pointer;

	${(props) =>
		props.disabled &&
		css`
			color: #dddddd;
		`}
`;

const PageButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
	width: 30px;
	height: 30px;
	border: 1px solid #dddddd;
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.39px;
	line-height: 100%;

	${(props) =>
		props.selected &&
		css`
			border: 1px solid #0e498f;
			color: #0e498f;
		`}

	& + & {
		margin-left: 10px;
	}
`;

export default TablePagination;
