import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import useSizeDetector from '../../hooks/useSizeDetector';
import UnitType from '../../constants/UnitType';

import * as api from '../../apis';
import * as utils from '../../utils';

const OperatorRequestModal = ({ unitType, data, onClose }) => {
	const [answerDescription, setAnswerDescription] = useState('');
	const { Desktop, Mobile } = useSizeDetector();

	const onClickConfirm = async () => {
		try {
			if (!data?.operator_request?.answer) {
				await api.createBookingOperatorRequestAnswer(data?.id, {
					answer: answerDescription,
				});
				alert('답변이 등록되었습니다.');
				onClose(true);
			} else {
				onClose(false);
			}
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<ModalContainer>
			<Content>
				<ModalTopContainer>
					<ModalTitleContainer>
						<ModalTitle>운영자 요청사항</ModalTitle>
						<ModalSubTitle>{`예약번호 #${data.id ?? '0'}`}</ModalSubTitle>
					</ModalTitleContainer>
					<CloseButton onClick={onClose}>x</CloseButton>
				</ModalTopContainer>
				{unitType === UnitType.ROOM && (
					<InfoContainer>
						<InfoRow first>
							<InfoColumn>
								<InfoTitle>시설명</InfoTitle>
								<InfoDescription>{data?.unit?.name ?? '-'}</InfoDescription>
							</InfoColumn>
							<InfoColumn>
								<InfoTitle>호실</InfoTitle>
								<InfoDescription>{data?.room_item?.name ?? '-'}</InfoDescription>
							</InfoColumn>
						</InfoRow>
						<InfoRow>
							<InfoColumn>
								<InfoTitle>이용기간</InfoTitle>
								<InfoDescription>{data?.booking_date ?? '-'}</InfoDescription>
							</InfoColumn>
						</InfoRow>
					</InfoContainer>
				)}
				{unitType === UnitType.PRODUCT && (
					<InfoContainer>
						<InfoRow first>
							<InfoColumn>
								<InfoTitle>장비명</InfoTitle>
								<InfoDescription>{data?.unit?.name ?? '-'}</InfoDescription>
							</InfoColumn>
							<InfoColumn>
								<InfoTitle>장비번호</InfoTitle>
								<InfoDescription>
									{data.product_items?.map((productItem) => productItem.name).join(',') ?? '-'}
								</InfoDescription>
							</InfoColumn>
						</InfoRow>
						<InfoRow>
							<InfoColumn>
								<InfoTitle>이용기간</InfoTitle>
								<InfoDescription>{data?.booking_date ?? '-'}</InfoDescription>
							</InfoColumn>
							<InfoColumn>
								<InfoTitle>예약수량</InfoTitle>
								<InfoDescription>{`${data.product_items.length}(개)`}</InfoDescription>
							</InfoColumn>
						</InfoRow>
					</InfoContainer>
				)}
				{unitType === UnitType.CONSULT && (
					<InfoContainer>
						<InfoRow first>
							<InfoColumn>
								<InfoTitle>상담명</InfoTitle>
								<InfoDescription>{data?.unit?.name ?? '-'}</InfoDescription>
							</InfoColumn>
							<InfoColumn>
								<InfoTitle>상담자</InfoTitle>
								<InfoDescription>{data?.room_item?.name ?? '-'}</InfoDescription>
							</InfoColumn>
						</InfoRow>
						<InfoRow>
							<InfoColumn>
								<InfoTitle>상담기간</InfoTitle>
								<InfoDescription>{data?.booking_date ?? '-'}</InfoDescription>
							</InfoColumn>
						</InfoRow>
					</InfoContainer>
				)}
				<MessageContainer>
					<Desktop>다음과 같은 요청사항을 전달드립니다. 확인 후 답변 부탁드립니다.</Desktop>
					<Mobile>
						다음과 같은 요청사항을 전달드립니다.
						<br />
						확인 후 답변 부탁드립니다.
					</Mobile>
				</MessageContainer>
				<RequestContainer>
					<RequestRow>
						<RequestColumn>
							<RequestTitle>요청사항</RequestTitle>
							<RequestDescriptionContainer>
								<RequestText strong>{`[${data?.operator_request?.title ?? '-'}]`}</RequestText>
								<RequestText
									dangerouslySetInnerHTML={utils.convertTextToLink(
										data?.operator_request?.description ?? '-'
									)}
								></RequestText>
							</RequestDescriptionContainer>
						</RequestColumn>
					</RequestRow>
					<RequestRow>
						<RequestColumn>
							<RequestTitle>답변</RequestTitle>
							{data?.operator_request?.answer ? (
								<RequestDescriptionContainer>
									<RequestText>{data?.operator_request?.answer}</RequestText>
								</RequestDescriptionContainer>
							) : (
								<AnswerDescriptionInput
									value={answerDescription}
									placeholder="답변을 입력해주세요"
									onChange={(e) => setAnswerDescription(e.currentTarget.value)}
								/>
							)}
						</RequestColumn>
					</RequestRow>
				</RequestContainer>
				<Footer>
					<CancelButton onClick={onClose}>취소</CancelButton>
					<OkButton onClick={onClickConfirm}>{data?.operator_request?.answer ? '확인' : '답변등록'}</OkButton>
				</Footer>
			</Content>
		</ModalContainer>
	);
};

const ModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		padding: 15px;
	}
`;

const Content = styled.div`
	position: relative;
	width: 720px;
	max-height: 80%;
	overflow-y: scroll;

	background-color: white;
	border: 1px solid #777777;
	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding: 15px 10px;
	}
`;

const ModalTopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 30px;
	border-bottom: 1px solid #cccccc;

	@media only screen and (max-width: 767.98px) {
		padding: 0 0 10px 0;
		margin-bottom: 20px;
	}
`;

const ModalTitleContainer = styled.div`
	display: flex;
	align-items: flex-end;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const ModalTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		font-size: 24px;
		line-height: 29px;
	}
`;

const ModalSubTitle = styled.span`
	margin-left: 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #666666;

	@media only screen and (max-width: 767.98px) {
		margin-left: 0;
	}
`;

const CloseButton = styled.div`
	width: 30px;
	height: 30px;
	display: flex;
	align-items: flex-end;
	justify-content: center;

	cursor: pointer;

	color: #000000;
	font-family: 'Noto Sans';
	font-size: 24px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -1.2px;
	line-height: 30px;
	padding-top: 0px;
`;

const InfoContainer = styled.div`
	padding: 20px 30px 0px 30px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 10px;
		padding: 0;
	}
`;

const InfoRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;

	min-height: 45px;
	border-bottom: 1px solid #dddddd;

	${(props) =>
		props.first &&
		css`
			border-top: 2px solid #333333;
		`}

	@media only screen and (max-width: 767.98px) {
		min-height: auto;
		flex-direction: column;
		border-bottom: none;
	}
`;

const InfoColumn = styled.div`
	display: flex;
	width: 50%;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		flex-direction: column;
		border-bottom: 1px solid #dddddd;
		padding: 10px;
	}
`;

const InfoTitle = styled.span`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: 18px;

	width: 120px;
	min-width: 120px;

	background-color: #f7f7f7;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding-left: 0;
		background-color: #ffffff;
	}
`;

const InfoDescription = styled.span`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	padding: 5px 15px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
	word-break: break-all;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		margin-top: 5px;
		padding: 0;
	}
`;

const MessageContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 71px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #0e498f;

	@media only screen and (max-width: 767.98px) {
		height: 82px;
		font-size: 14px;
		text-align: center;
	}
`;

const RequestContainer = styled.div`
	padding: 0px 30px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 10px;
		padding: 0;
	}
`;

const RequestRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;

	min-height: 45px;
	border-bottom: 1px solid #dddddd;

	&:first-child {
		border-top: 1px solid #dddddd;
	}

	@media only screen and (max-width: 767.98px) {
		min-height: auto;
		flex-direction: column;
		border-bottom: none;
	}
`;

const RequestColumn = styled.div`
	display: flex;
	width: 100%;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		flex-direction: column;
		border-bottom: 1px solid #dddddd;
		padding: 10px;
	}
`;

const RequestTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: 18px;

	width: 120px;
	min-width: 120px;
	background-color: #f7f7f7;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding-left: 0;
		background-color: #ffffff;
	}
`;

const RequestDescriptionContainer = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	margin: 10px;
	padding: 8px;
	border-radius: 4px;

	@media only screen and (max-width: 767.98px) {
		margin: 14px 10px 13px 0;
		padding: 0;
	}
`;

const RequestText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
	white-space: pre-wrap;

	& > a:link {
		color: #0d6efd;
	}

	${(props) =>
		props.strong &&
		css`
			font-weight: 500;
			color: #333333;
		`}
`;

const AnswerDescriptionInput = styled.textarea`
	width: 100%;
	height: 135px;

	margin: 10px;
	padding: 8px;

	border: 1px solid #666666;
	border-radius: 4px;
	background-color: white;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
		height: 64px;
		margin: 14px 10px 13px 0;
	}
`;

const Footer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px 0;
`;

const CancelButton = styled.div`
	width: 125px;
	height: 50px;
	border: 1px solid #22499d;
	border-radius: 5px;
	background-color: #ffffff;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #22499d;

	&:hover {
		background-color: #0c438d;
		color: #ffffff;
	}
`;

const OkButton = styled.div`
	width: 125px;
	height: 50px;
	margin-left: 10px;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	background-color: #22499d;
	border-radius: 5px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #ffffff;

	&:hover {
		background-color: #0c438d;
	}
`;

export default OperatorRequestModal;
