import React, { useState } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { convertDateToStr } from '../utils';
import PopupCloseType from '../constants/PopupCloseType';

const Popup = ({ idx, data }) => {
	const [noMore, setNoMore] = useState(false);
	const [closed, setClosed] = useState(false);

	const checkNoMoreCookie = () => {
		// 팝업의 쿠키를 얻어서 있는 경우 들어 있는 value의 날짜가 오늘 날짜보다 큰 경우에는 표시하지 않는다.
		const nowDate = convertDateToStr(new Date());
		const popupCookieKey = `popup_${data.id}_no_more`;
		const popupDisableDate = Cookies.get(popupCookieKey);
		if (popupDisableDate && popupDisableDate >= nowDate) {
			return true;
		}
		return false;
	};

	const onClickClose = () => {
		// 닫기 유형에 따라 처리한다.
		if (noMore) {
			const popupCookieKey = `popup_${data.id}_no_more`;
			const popupDisableDate =
				data.close_type === PopupCloseType.NO_MORE_TODAY ? convertDateToStr(new Date()) : '2100-01-01';
			Cookies.set(popupCookieKey, popupDisableDate, { expires: 365 });
		}

		setClosed(true);
	};

	// 팝업에 다시 보지 않기 쿠키나 오늘 하루 보지 않기 쿠키가 설정되어 있으면 화면에 그리지 않는다.
	if (closed || checkNoMoreCookie()) {
		return null;
	}

	return (
		<Container idx={idx} left={data.left} top={data.top}>
			<ContentContainer width={data.width} height={data.height}>
				<Content dangerouslySetInnerHTML={{ __html: data?.description ?? '-' }} />
			</ContentContainer>
			<Footer>
				<CloseButton onClick={onClickClose}>X 닫기</CloseButton>
				{data.close_type === PopupCloseType.NO_MORE_TODAY && (
					<NoMoreButton>
						<NoMoreCheckbox
							id="no_more_today"
							type="checkbox"
							value={noMore}
							onChange={(e) => setNoMore(e.currentTarget.checked)}
						/>
						<label htmlFor={'no_more_today'}>오늘 하루 보지 않기</label>
					</NoMoreButton>
				)}
				{data.close_type === PopupCloseType.NEVER && (
					<NoMoreButton>
						<NoMoreCheckbox
							id="never"
							type="checkbox"
							value={noMore}
							onChange={(e) => setNoMore(e.currentTarget.checked)}
						/>
						<label htmlFor={'never'}>다시 보지 않기</label>
					</NoMoreButton>
				)}
			</Footer>
		</Container>
	);
};

const Container = styled.div`
	position: fixed;
	z-index: ${(props) => props.idx + 500};
	left: ${(props) => props.left}px;
	top: ${(props) => props.top}px;
	box-shadow: 0 0 3px 2px #333;
`;

const ContentContainer = styled.div`
	width: ${(props) => props.width}px;
	height: ${(props) => props.height}px;
	background-color: white;
	border-style: solid;
	border-color: #e1e1e1;
	border-width: 4px 4px 0;
`;

const Content = styled.div`
	width: 100%;
	font-size: 16px;
	margin-top: 10px;
	overflow-x: auto;
`;

const Footer = styled.div`
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: space-between;
	padding: 6px;
	background-color: #e1e1e1;
`;

const NoMoreButton = styled.div`
	display: flex;
	align-items: center;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
`;

const NoMoreCheckbox = styled.input`
	width: 16px;
	height: 16px;
	cursor: pointer;
	& + label {
		cursor: pointer;
		margin-left: 6px;
		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #333333;
	}
`;

const CloseButton = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	cursor: pointer;
`;
export default Popup;
