import React, { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import AdminHeader from '../components/admin/AdminHeader';
import Footer from '../components/Footer';
import AdminTab from '../components/admin/AdminTab';

import UserRole from '../constants/UserRole';

import { UserContext } from '../contexts/UserContext';

const AdminLayout = ({ tabs, tabIndex, children }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const { user } = useContext(UserContext);

	useEffect(() => {
		// 관리자 권한이 있거나, Unit에 관리자로 할당받는 사용자만 admin 페이지를 사용할 수 있다.
		if (!UserRole.isAdminRole(user?.user_role) && !UserRole.isUnitAdminRole(user?.user_role)) {
			navigate('/');
		}
	}, []);

	useEffect(() => {
		const scrollToTop = () => window.scrollTo(0, 0);
		return () => {
			scrollToTop();
		};
	}, [location]);

	return (
		<Container>
			<AdminHeader userRole={user?.user_role} unitAdmins={user?.unit_admins} />
			<Content>
				{tabs ? (
					<AdminTab tabs={tabs} tabIndex={tabIndex} userRole={user?.user_role} unitAdmins={user?.unit_admins}>
						{children}
					</AdminTab>
				) : (
					children
				)}
			</Content>
			<Footer />
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	height: 100%;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 100%;
	min-height: calc(100vh - 180px);
	padding: 140px 0 109px 0;

	background-color: #f1f3f6;

	@media only screen and (max-width: 479.98px) {
		padding-top: 110px;
	}
`;

export default AdminLayout;
