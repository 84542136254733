import React from 'react';
import styled from 'styled-components';
import AdminSectionTitle from '../../components/admin/AdminSectionTitle';

const AgreementPrivacyModal = ({ onClose }) => {
	return (
		<ModalContainer>
			<Content>
				<section>
					<ModalTopContainer>
						<AdminSectionTitle title="개인정보 수집·제공 동의" />
						<CloseButton onClick={onClose}>x</CloseButton>
					</ModalTopContainer>
				</section>
				<section>
					<MessageContainer>
						{`
						법령에 따라 개인을 고유하게 구별하기 위하여 부여된 모든 식별정보(성명, 소속, 학번/교직원 번호, 휴대폰, 이메일 등)의 수집, 이용에 대한 동의를 받고 있습니다. 
						신청 시 기재되는 모든 개인정보는 사업 진행을 위하여 수집 및 이용될 수 있습니다. 또한 참여 확인 및 대학평가관련 자료 요청 시 교내 관련부서에 자료가 제공될 수 있으며, 철저하게 관리될 예정입니다.
						
						수집된 개인정보는 3년 경과(대학 평가 관련 자료 요청 기간) 후 즉시 파기됩니다.
						위와 관련하여 본인의 개인/고유식별정보 수집, 이용에 관한 내용을 숙지하셨고 이에 동의하시면 해당란에 체크하여 주십시오.
						
						해당 예약시스템을 이용하고자 하시면 기재된 모든 개인정보 수집, 이용에 동의하셔야 합니다.
						`}
					</MessageContainer>
				</section>
			</Content>
		</ModalContainer>
	);
};

const ModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		padding: 15px;
	}
`;

const Content = styled.div`
	position: relative;
	width: 720px;
	max-height: 80%;
	overflow-y: scroll;

	background-color: white;
	padding: 40px;

	background: #ffffff;
	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);
	border-radius: 10px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding: 20px 15px 40px 15px;
	}
`;

const ModalTopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const CloseButton = styled.button`
	display: flex;
	align-items: flex-end;
	justify-content: center;

	width: 30px;
	height: 30px;

	border-radius: 8px;
	border: none;
	background-color: #333333;

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 24px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -1.2px;
	line-height: 30px;
	padding-top: 0px;
`;

const MessageContainer = styled.div`
	width: 100%;
	margin-top: 20px;

	color: #333333;
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.45px;
	white-space: pre-line;

	@media only screen and (max-width: 767.98px) {
		font-size: 13px;
	}
`;

export default AgreementPrivacyModal;
