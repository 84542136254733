export default {
	APPROVED: 'approved',
	REJECTED: 'rejected',
	CANCELED: 'canceled',
	PENDING: 'pending',
	toString: (value) => {
		switch (value) {
			case 'approved':
				return '승인완료';
			case 'rejected':
				return '승인거부';
			case 'canceled':
				return '예약취소';
			case 'pending':
				return '승인대기';
			default:
				return '-';
		}
	},
	toShortString: (value) => {
		switch (value) {
			case 'approved':
				return '승인';
			case 'rejected':
				return '미승인';
			case 'canceled':
				return '취소';
			case 'pending':
				return '대기';
			default:
				return '-';
		}
	},
};
