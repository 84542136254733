import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { usePrompt } from '../../../hooks/blocker';
import UnitType from '../../../constants/UnitType';
import * as api from '../../../apis';

const ConsultServiceSetting = () => {
	const [unitType, setUnitType] = useState(UnitType.CONSULT);
	const [unitServiceSetting, setUnitServiceSetting] = useState({
		use_restrict_application: false,
		restrict_application_count: 2,
	});
	const [isModify, setIsModify] = useState(false);

	useEffect(() => {
		getUnitServiceSetting(unitType);
	}, []);

	usePrompt('설정을 저장하지 않고\n페이지를 벗어나시겠습니까?', isModify);

	/**
	 * Unit 서비스 설정을 얻는다.
	 */
	const getUnitServiceSetting = (targetUnitType) => {
		api.getUnitServiceSetting(targetUnitType)
			.then((res) => {
				if (res && res.data) {
					setUnitServiceSetting(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const onClickSave = () => {
		api.updateUnitServiceSetting(unitType, unitServiceSetting)
			.then((res) => {
				setIsModify(false);
				alert('저장되었습니다.');
			})
			.catch((err) => {
				console.error(err);
				alert('저장에 실패하였습니다.');
			});
	};

	return (
		<Content>
			<TopContainer>
				<SectionTitleContainer>
					<SectionTitle>상담 서비스 설정</SectionTitle>
				</SectionTitleContainer>
				<SaveButton onClick={onClickSave}>저장</SaveButton>
			</TopContainer>
			<ConsultSettingTableContainer>
				<ConsultSettingTable>
					<thead>
						<TableHeadRow>
							<TableHead style={{ minWidth: '160px' }}>구분</TableHead>
							<TableHead style={{ minWidth: '150px', width: '500px' }}>옵션</TableHead>
							<TableHead style={{ minWidth: '150px', width: '500px' }}>설명</TableHead>
						</TableHeadRow>
					</thead>
					<tbody>
						<TableBodyRow>
							<TableData>상담 구분 단위로 신청 횟수 제한 설정</TableData>
							<TableData>
								<OptionContainer>
									<CheckBoxContainer>
										<CheckBox
											id="max_booking_count"
											type="checkbox"
											onChange={(e) => {
												setIsModify(true);
												setUnitServiceSetting({
													...unitServiceSetting,
													use_restrict_application: e.currentTarget.checked,
												});
											}}
											checked={unitServiceSetting.use_restrict_application}
										></CheckBox>
										<label htmlFor={'max_booking_count'}>신청 횟수 제한 설정 사용 여부</label>
									</CheckBoxContainer>
									{unitServiceSetting.use_restrict_application && (
										<MaxBookingContainer>
											<span>상담일 기준 1일 간 예약 횟수 최대</span>
											<TextInput
												type="number"
												min={2}
												value={unitServiceSetting.restrict_application_count}
												onChange={(e) => {
													setIsModify(true);
													setUnitServiceSetting({
														...unitServiceSetting,
														restrict_application_count: e.currentTarget.value,
													});
												}}
											></TextInput>
											<span>회 신청 가능</span>
										</MaxBookingContainer>
									)}
								</OptionContainer>
							</TableData>
							<TableData>
								{`상담 구분 단위로 해당 구분 내 예약 항목들에 대하여 개인당 N일간 최대 예약 횟수를 N회로
							제한하는 설정입니다. 옵션 체크 시, `}
								<TextUnderLine>상담일 기준 1일 최대 N회차로 신청을 제한</TextUnderLine>할 수 있습니다.
							</TableData>
						</TableBodyRow>
					</tbody>
				</ConsultSettingTable>
			</ConsultSettingTableContainer>
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 40px;
	background-color: white;

	@media only screen and (max-width: 1023.98px) {
		padding: 40px 20px;
	}

	@media only screen and (max-width: 479.98px) {
		padding: 20px 10px;
	}
`;

const TopContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const SectionTitleContainer = styled.div`
	display: flex;
	align-items: flex-end;
`;

const SectionTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;
`;

const SaveButton = styled.div`
	height: 36px;
	padding: 0 15px;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: #454655;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #ffffff;

	&:hover {
		background-color: #1d1e31;
	}
`;

const ConsultSettingTableContainer = styled.div`
	@media only screen and (max-width: 479.98px) {
		max-width: 460px;
		overflow-x: scroll;
	}
`;

const ConsultSettingTable = styled.table`
	width: 100%;
	border-collapse: collapse;
	border-top: 2px solid #0e498f;
	margin-top: 20px;
`;

const TableHeadRow = styled.tr`
	border-bottom: 1px solid #dcdcdc;
`;

const TableHead = styled.th`
	text-align: center;
	height: 45px;
	background-color: #f6f8fb;
	border-right: 1px solid #dddddd;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #0e498f;
	:first-child {
		border-left: 1px solid #dddddd;
	}
`;

const TableBodyRow = styled.tr`
	border-bottom: 1px solid #dddddd;
`;

const TableData = styled.td`
	height: 88px;
	border-right: 1px solid #dddddd;
	padding: 15px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
	word-break: break-all;
	:first-child {
		font-weight: 500;
		border-left: 1px solid #dddddd;
	}
	:nth-child(2) {
		color: #666666;
	}
`;

const OptionContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
`;

const CheckBoxContainer = styled.div`
	display: flex;
	gap: 5px;
`;

const CheckBox = styled.input`
	cursor: pointer;
`;

const MaxBookingContainer = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 5px;
`;

const TextInput = styled.input`
	width: 100px;
	height: 35px;
	padding: 8px;
	border: 1px solid #dddddd;
	background-color: #ffffff;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;

	@media only screen and (max-width: 1023.98px) {
		width: 50px;
	}
`;

const TextUnderLine = styled.span`
	text-decoration: underline;
`;

export default ConsultServiceSetting;
