import React, { useState, useEffect, useRef, useContext } from 'react';
import styled, { css } from 'styled-components';
import { useLocation } from 'react-router-dom';
import useFilterHandler from '../../hooks/useFilterHandler';
import useAdminSizeDetector from '../../hooks/useAdminSizeDetector';
import FilterType from '../../constants/FilterType';
import ApprovalStatus from '../../constants/ApprovalStatus';
import RentalStatus from '../../constants/RentalStatus';
import CategorySlug from '../../constants/CategorySlug';
import UnitType from '../../constants/UnitType';
import UserRole from '../../constants/UserRole';
import BookingItemModal from '../../components/modal/BookingItemModal';
import TablePagination from '../../components/TablePagination';
import LoadingIndicator from '../../components/LoadingIndicator';
import { UserContext } from '../../contexts/UserContext';

import * as api from '../../apis';
import * as utils from '../../utils';

const BookingProductList = () => {
	const location = useLocation();
	const { user } = useContext(UserContext);
	const {
		categoryFilter,
		approvalStatusFilter,
		setApprovalStatusFilter,
		approvalMethodFilter,
		rentalStatusFilter,
		setRentalStatusFilter,
		onChangeFilterCheckbox,
		getBookingProductFilterParams,
	} = useFilterHandler({
		onFetchCompleted: () => {
			if (
				location.state?.filter.approval_status ||
				location.state?.filter.rental_status ||
				location.state?.filter.usage_date
			) {
				let filterParams = [`unit_type=${UnitType.PRODUCT}`, `page=${pageIndex}`];
				if (location.state?.filter.approval_status) {
					let initApprovalStatus = location.state.filter.approval_status;
					setApprovalStatusFilter(
						approvalStatusFilter.map((filter) => {
							return {
								...filter,
								selected: filter.value === initApprovalStatus,
							};
						})
					);
					filterParams.push(`approval_statuses[]=${initApprovalStatus}`);
				}
				if (location.state?.filter.rental_status) {
					let initRentalStatus = location.state.filter.rental_status;
					setRentalStatusFilter(
						rentalStatusFilter.map((filter) => {
							return {
								...filter,
								selected: filter.value === initRentalStatus,
							};
						})
					);
					filterParams.push(`rental_statuses[]=${initRentalStatus}`);
				}
				if (location.state?.filter.usage_date) {
					let initUsageDate = location.state.filter.usage_date;
					setUsageStartDate(initUsageDate);
					setUsageEndDate(initUsageDate);
					filterParams.push(`booking_start_date=${initUsageDate}`);
					filterParams.push(`booking_end_date=${initUsageDate}`);
				}

				getBookingsFromApi(filterParams);
				window.history.replaceState({}, '');
			}
		},
	});
	const { isMobile } = useAdminSizeDetector();

	const [usageStartDate, setUsageStartDate] = useState('');
	const [usageEndDate, setUsageEndDate] = useState('');
	const [searchTarget, setSearchTarget] = useState('name');
	const [searchKeyword, setSearchKeyword] = useState('');
	const [pageIndex, setPageIndex] = useState(1);

	const [bookingList, setBookingList] = useState(null);
	const [allChecked, setAllChecked] = useState(false);
	const [changeApprovalStatus, setChangeApprovalStatus] = useState(ApprovalStatus.APPROVED);
	const [initialized, setInitialized] = useState(false);

	const [bookingItemModalData, setBookingItemModalData] = useState(null);
	const [bookingItemModalVisibility, setBookingItemModalVisibility] = useState(false);

	const tableTopRef = useRef(null);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		// 초기 상태를 지정한 경우
		if (location.state?.filter.approval_status || location.state?.filter.usage_date) {
			return;
		}
		getBookingsWithFilter();
	}, [pageIndex]);

	useEffect(() => {
		if (!bookingList) return;

		setBookingList({
			...bookingList,
			items: bookingList.items.map((booking) => {
				return {
					...booking,
					selected: allChecked,
				};
			}),
		});
	}, [allChecked]);

	const getBookingsFromApi = (filterParams) => {
		// 사용자가 관리자 권한이 없는 경우 Unit 관리 대상만 얻는다.
		if (!UserRole.isAdminRole(user?.user_role)) {
			user.unit_admins.forEach((unit) => filterParams.push(`unit_ids[]=${unit.unit_id}`));
		}
		api.getBookings(filterParams)
			.then((res) => {
				if (res && res.data) {
					if (!initialized) setInitialized(true);
					setBookingList({
						...res.data,
						items: res.data.items.map((item) => {
							return {
								...item,
								selected: false,
							};
						}),
					});
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const getBookingsWithFilter = () => {
		const filterParams = getFilterParams();
		getBookingsFromApi(filterParams);
	};

	const getFilterParams = () => {
		let filterParams = getBookingProductFilterParams();
		filterParams.push(`unit_type=${UnitType.PRODUCT}`);
		if (pageIndex) filterParams.push(`page=${pageIndex}`);
		if (usageStartDate) filterParams.push(`booking_start_date=${usageStartDate}`);
		if (usageEndDate) filterParams.push(`booking_end_date=${usageEndDate}`);
		if (searchKeyword) filterParams.push(`${searchTarget}=${searchKeyword}`);

		return filterParams;
	};

	const onClickSearch = () => {
		if (usageStartDate && !usageEndDate) {
			alert('종료일을 입력해야 합니다.');
			return;
		}
		if (!usageStartDate && usageEndDate) {
			alert('시작일을 입력해야 합니다.');
			return;
		}
		if (usageStartDate && usageEndDate && usageStartDate > usageEndDate) {
			alert('이용기간 시작일은 종료일 이후일 수 없습니다.');
			return;
		}
		getBookingsWithFilter();
	};

	const onClickDetail = (booking) => {
		setBookingItemModalData(booking);
		setBookingItemModalVisibility(true);
	};

	const onChangeApprovalStatus = (bookingId, status) => {
		const params = {
			booking_ids: [bookingId],
			approval_status: status,
		};
		setIsLoading(true);
		api.updateApprovalStatus(params)
			.then((res) => {
				setIsLoading(false);
				if (res && res.data) {
					alert('예약 상태를 변경하였습니다.');
					setBookingList({
						...bookingList,
						items: bookingList.items.map((booking) => {
							if (booking.id === bookingId) {
								return {
									...booking,
									approval_status: status,
								};
							}
							return booking;
						}),
					});
				}
			})
			.catch((err) => {
				setIsLoading(false);
				console.error(err);
				alert('예약 상태 변경에 실패하였습니다.');
			});
	};

	const onChangeRentalStatus = (bookingId, status) => {
		const params = {
			booking_ids: [bookingId],
			rental_status: status,
		};
		setIsLoading(true);
		api.updateRentalStatus(params)
			.then((res) => {
				setIsLoading(false);
				if (res && res.data) {
					alert('수령/반납 상태를 변경하였습니다.');
					setBookingList({
						...bookingList,
						items: bookingList.items.map((booking) => {
							if (booking.id === bookingId) {
								return {
									...booking,
									rental_status: status,
								};
							}
							return booking;
						}),
					});
				}
			})
			.catch((err) => {
				setIsLoading(false);
				console.error(err);
				alert('수령/반납 상태 변경에 실패하였습니다.');
			});
	};

	const onClickChangeStatusSelected = () => {
		const selectedBookingIdList = bookingList.items.filter((val) => val.selected).map((booking) => booking.id);
		if (selectedBookingIdList.length === 0) {
			alert('선택한 예약 항목이 없습니다.');
			return;
		}

		if (!window.confirm(`선택한 ${selectedBookingIdList.length}개의 예약 항목의 상태를 일괄 변경하시겠습니까?`))
			return;

		const params = {
			booking_ids: selectedBookingIdList,
			approval_status: changeApprovalStatus,
		};
		api.updateApprovalStatus(params)
			.then((res) => {
				if (res && res.data) {
					alert('예약 상태를 일괄 변경하였습니다.');
					setAllChecked(false);
					getBookingsWithFilter();
				}
			})
			.catch((err) => {
				console.error(err);
				alert('예약 상태 변경에 실패하였습니다.');
			});
	};

	const onClickExcelDownload = () => {
		const date = new Date();
		const fileName = `장비 예약 현황_${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}.xlsx`;

		let filterParams = getFilterParams();
		setIsLoading(true);
		api.downloadBookings(filterParams)
			.then((res) => {
				if (res.status === 202) {
					alert('엑셀 다운로드에 실패하였습니다.');
				}

				// Blob 형태로 reponse 받은 응답을 다운로드 처리한다.
				const blob = new Blob([res.data], { type: res.headers['content-type'] });
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileName);
				document.body.appendChild(link);
				link.click();
				link.remove();
				window.URL.revokeObjectURL(url);
			})
			.catch((err) => {
				console.error(err);
				alert('엑셀 다운로드에 실패하였습니다.');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const onPageChange = (page) => {
		setAllChecked(false);
		setPageIndex(page);
		tableTopRef.current.scrollIntoView();
	};

	const onSaveBookingItemModal = (booking) => {
		setBookingList({
			...bookingList,
			items: bookingList.items.map((item) => {
				if (item.id === booking.id) {
					return booking;
				}
				return item;
			}),
		});
		setBookingItemModalData(booking);
	};

	const renderCategoryFilter = (slug, depth) => {
		if (categoryFilter.length === 0) return null;
		let filter = categoryFilter
			.find((category) => category.slug === slug)
			.filters.find((categoryFilter) => categoryFilter.depth === depth);

		return (
			<FilterRow>
				<FilterTitle>{filter.title}</FilterTitle>
				<FilterContents>{renderFilterCheckbox(filter.category_items, FilterType.CATEGORY)}</FilterContents>
			</FilterRow>
		);
	};

	const renderFilterCheckbox = (filterList, type) => {
		return filterList?.map((filter, idx) => {
			const id = `${type}_${filter.id}_${filter.name}`;
			return (
				<FilterCheckboxGroup key={idx}>
					<FilterCheckbox
						key={idx}
						type="checkBox"
						name={id}
						id={id}
						checked={filter?.selected}
						onChange={(e) => {
							onChangeFilterCheckbox(type, filter.id, e.currentTarget.checked);
						}}
					/>
					<label htmlFor={id}>{filter.name}</label>
				</FilterCheckboxGroup>
			);
		});
	};

	const renderFilterList = () => {
		return (
			<>
				<FilterRow>
					<FilterTitle>예약 상태</FilterTitle>
					<FilterContents>
						{renderFilterCheckbox(approvalStatusFilter, FilterType.APPROVAL_STATUS)}
					</FilterContents>
				</FilterRow>
				<FilterRow>
					<FilterTitle>수령/반납상태</FilterTitle>
					<FilterContents>
						{renderFilterCheckbox(rentalStatusFilter, FilterType.RENTAL_STATUS)}
					</FilterContents>
				</FilterRow>
				{renderCategoryFilter(CategorySlug.PRODUCT_TYPE, 1)}
				{renderCategoryFilter(CategorySlug.PRODUCT_TYPE, 2)}
				{renderCategoryFilter(CategorySlug.PRODUCT_PLACE, 1)}
				{renderCategoryFilter(CategorySlug.PRODUCT_PLACE, 2)}
				{renderCategoryFilter(CategorySlug.PRODUCT_PLACE, 3)}
				<FilterRow>
					<FilterTitle>승인 유형</FilterTitle>
					<FilterContents>
						{renderFilterCheckbox(approvalMethodFilter, FilterType.APPROVAL_METHOD)}
					</FilterContents>
				</FilterRow>
				<FilterRow>
					<FilterTitle>이용 기간</FilterTitle>
					<FilterContents>
						<SearchDateInput
							type="date"
							value={usageStartDate}
							onChange={(e) => setUsageStartDate(e.currentTarget.value)}
						/>
						<SearchDateInputSeparator>~</SearchDateInputSeparator>
						<SearchDateInput
							type="date"
							value={usageEndDate}
							onChange={(e) => setUsageEndDate(e.currentTarget.value)}
						/>
					</FilterContents>
				</FilterRow>
				<FilterRow>
					<FilterTitle>검색</FilterTitle>
					<FilterContents>
						<SearchTargetSelect
							value={searchTarget}
							onChange={(e) => setSearchTarget(e.currentTarget.value)}
						>
							<option value={'name'}>이름</option>
							<option value={'phone'}>연락처</option>
							<option value={'booking_id'}>예약 번호</option>
							<option value={'memo_title'}>메모 제목</option>
							<option value={'memo_description'}>메모 내용</option>
						</SearchTargetSelect>
						<SearchKeywordInput
							type="text"
							value={searchKeyword}
							onChange={(e) => setSearchKeyword(e.currentTarget.value)}
							onKeyDown={(event) => {
								if (event.key == 'Enter') {
									onClickSearch();
								}
							}}
							placeholder="검색어를 입력해주세요."
						/>
					</FilterContents>
				</FilterRow>
			</>
		);
	};

	const renderDesktopSearchResultTable = () => {
		return (
			<SearchResultTableContainer>
				<SearchResultTable>
					<thead>
						<tr>
							<th style={{ minWidth: '32px' }}>
								<FilterCheckbox
									type="checkbox"
									checked={allChecked}
									onChange={(e) => setAllChecked(e.currentTarget.checked)}
								/>
							</th>
							<th style={{ minWidth: '50px' }}>ID</th>
							<th style={{ minWidth: '80px' }}>
								{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PRODUCT_TYPE, 1)}
							</th>
							<th style={{ minWidth: '80px' }}>
								{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PRODUCT_TYPE, 2)}
							</th>
							<th style={{ minWidth: '78px' }}>
								{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PRODUCT_PLACE, 1)}
							</th>
							<th style={{ minWidth: '87px' }}>
								{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PRODUCT_PLACE, 2)}
							</th>
							<th style={{ minWidth: '88px' }}>
								{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PRODUCT_PLACE, 3)}
							</th>
							<th style={{ minWidth: '98px' }}>장비명</th>
							<th style={{ minWidth: '62px' }}>장비번호</th>
							<th style={{ minWidth: '62px' }}>신청자</th>
							<th style={{ minWidth: '108px' }}>이용기간</th>
							<th style={{ minWidth: '95px' }}>신청일</th>
							<th style={{ minWidth: '83px' }}>예약상태</th>
							<th style={{ minWidth: '82px' }}>수령/반납상태</th>
							<th style={{ minWidth: '65px' }}>항목보기</th>
						</tr>
					</thead>
					<tbody>
						{bookingList?.items.map((booking, idx) => {
							return (
								<tr key={idx}>
									<td>
										<BookingItemCheckbox
											type="checkbox"
											checked={booking.selected}
											onChange={(e) => {
												setBookingList({
													...bookingList,
													items: bookingList.items.map((booking, bookingIdx) => {
														if (idx === bookingIdx) {
															return {
																...booking,
																selected: e.currentTarget.checked,
															};
														} else {
															return booking;
														}
													}),
												});
											}}
										/>
									</td>
									<td>{booking.id}</td>
									<td>{utils.getUnitCategoryName(booking.unit, CategorySlug.PRODUCT_TYPE, 1)}</td>
									<td>{utils.getUnitCategoryName(booking.unit, CategorySlug.PRODUCT_TYPE, 2)}</td>
									<td>{utils.getUnitCategoryName(booking.unit, CategorySlug.PRODUCT_PLACE, 1)}</td>
									<td>{utils.getUnitCategoryName(booking.unit, CategorySlug.PRODUCT_PLACE, 2)}</td>
									<td>{utils.getUnitCategoryName(booking.unit, CategorySlug.PRODUCT_PLACE, 3)}</td>
									<td>
										<div>{booking.unit?.name ?? '-'}</div>
									</td>
									<td>
										<div>
											{booking.product_items?.map((productItem) => productItem.name).join(',') ??
												'-'}
										</div>
									</td>
									<td>{booking.user?.name ?? '-'}</td>
									<td>
										{booking.booking_date
											? booking.booking_date
													.split(' ')
													.map((line, _) => <div key={_}>{line}</div>)
											: '-'}
									</td>
									<td>
										{booking.created_at ? new Date(booking.created_at).toLocaleDateString() : '-'}
									</td>
									<td>
										<ApprovalStatusChangeSelect
											value={booking.approval_status}
											onChange={(e) => onChangeApprovalStatus(booking.id, e.currentTarget.value)}
										>
											{approvalStatusFilter.map((approvalStatus, idx) => {
												return (
													<option key={idx} value={approvalStatus.value}>
														{approvalStatus.name ?? '-'}
													</option>
												);
											})}
										</ApprovalStatusChangeSelect>
									</td>
									<td>
										<RentalStatusChangeSelect
											value={booking.rental_status}
											onChange={(e) => onChangeRentalStatus(booking.id, e.currentTarget.value)}
										>
											{rentalStatusFilter.map((rentalStatus, idx) => {
												return (
													<option key={idx} value={rentalStatus.value}>
														{rentalStatus.name ?? '-'}
													</option>
												);
											})}
										</RentalStatusChangeSelect>
									</td>
									<td>
										<DetailButton onClick={() => onClickDetail(booking)}>보기</DetailButton>
									</td>
								</tr>
							);
						})}
					</tbody>
				</SearchResultTable>
			</SearchResultTableContainer>
		);
	};

	const renderMobileSearchResultTable = () => {
		return (
			<div>
				{bookingList?.items.map((booking, idx) => {
					return (
						<MobileSearchResultContainer key={idx}>
							<BookingItemCheckbox
								type="checkbox"
								checked={booking.selected}
								onChange={(e) => {
									setBookingList({
										...bookingList,
										items: bookingList.items.map((booking, bookingIdx) => {
											if (idx === bookingIdx) {
												return {
													...booking,
													selected: e.currentTarget.checked,
												};
											} else {
												return booking;
											}
										}),
									});
								}}
							/>
							<MobileSearchResultInfoContainer>
								<BookingItemUnitName>
									<BookingItemId>#{booking.id}</BookingItemId>
									<span>{`${booking.unit?.name ?? '-'} (${
										booking.product_items?.map((productItem) => productItem.name).join(',') ?? '-'
									})`}</span>
								</BookingItemUnitName>
								<BookingItemBookingDate>{booking.booking_date}</BookingItemBookingDate>
								<BookingItemUserName>{`신청자 ${booking.user?.name ?? '-'}`}</BookingItemUserName>
								<BookingItemCreatedDate>{`신청일 ${
									booking.created_at ? new Date(booking.created_at).toLocaleDateString() : '-'
								}`}</BookingItemCreatedDate>
								<BookingItemBottomContainer>
									<BookingItemBottomWrapper>
										<ApprovalStatusChangeSelect
											value={booking.approval_status}
											onChange={(e) => onChangeApprovalStatus(booking.id, e.currentTarget.value)}
										>
											{approvalStatusFilter.map((approvalStatus, idx) => {
												return (
													<option key={idx} value={approvalStatus.value}>
														{approvalStatus.name ?? '-'}
													</option>
												);
											})}
										</ApprovalStatusChangeSelect>
										<RentalStatusChangeSelect
											value={booking.rental_status}
											onChange={(e) => onChangeRentalStatus(booking.id, e.currentTarget.value)}
										>
											{rentalStatusFilter.map((rentalStatus, idx) => {
												return (
													<option key={idx} value={rentalStatus.value}>
														{rentalStatus.name ?? '-'}
													</option>
												);
											})}
										</RentalStatusChangeSelect>
									</BookingItemBottomWrapper>
									<BookingItemDetailButton
										onClick={() => onClickDetail(booking)}
									>{`상세보기 >`}</BookingItemDetailButton>
								</BookingItemBottomContainer>
							</MobileSearchResultInfoContainer>
						</MobileSearchResultContainer>
					);
				})}
			</div>
		);
	};

	const renderSearchResultTable = () => {
		if (isMobile) {
			return renderMobileSearchResultTable();
		} else {
			return renderDesktopSearchResultTable();
		}
	};

	if (!initialized) return null;

	return (
		<Content>
			<LoadingIndicator size={50} loading={isLoading} />
			<section>
				<SectionTitle>예약 검색</SectionTitle>
				<SearchFilterContainer>{renderFilterList()}</SearchFilterContainer>
				<SearchButtonContainer>
					<SearchButton onClick={onClickSearch}>검색</SearchButton>
				</SearchButtonContainer>
			</section>
			<ScrollSection className="mt-50px" ref={tableTopRef}>
				<SectionTitleContainer>
					<SectionTitleWrapper>
						<SectionTitle>예약 목록</SectionTitle>
						<SectionSubTitle>{`전체 ${bookingList?.pagination.total ?? '0'}개`}</SectionSubTitle>
					</SectionTitleWrapper>
					<ExcelDownloadButton onClick={onClickExcelDownload}>엑셀 다운로드</ExcelDownloadButton>
				</SectionTitleContainer>
				<TableTopContainer>
					<TableTopContainerLeft>
						<ApprovalStatusChangeSelect
							value={changeApprovalStatus}
							onChange={(e) => setChangeApprovalStatus(e.currentTarget.value)}
						>
							{approvalStatusFilter.map((approvalStatus, idx) => {
								return (
									<option key={idx} value={approvalStatus.value}>
										{approvalStatus.name ?? '-'}
									</option>
								);
							})}
						</ApprovalStatusChangeSelect>
						<StatusChangeButton onClick={onClickChangeStatusSelected}>변경</StatusChangeButton>
						<StatusChangeInfo>선택항목 예약상태 일괄 변경</StatusChangeInfo>
					</TableTopContainerLeft>
				</TableTopContainer>
				{renderSearchResultTable()}
				<TableBottomContainer>
					<ApprovalStatusChangeSelect
						value={changeApprovalStatus}
						onChange={(e) => setChangeApprovalStatus(e.currentTarget.value)}
					>
						{approvalStatusFilter.map((approvalStatus, idx) => {
							return (
								<option key={idx} value={approvalStatus.value}>
									{approvalStatus.name ?? '-'}
								</option>
							);
						})}
					</ApprovalStatusChangeSelect>
					<StatusChangeButton onClick={onClickChangeStatusSelected}>변경</StatusChangeButton>
					<StatusChangeInfo>선택항목 예약상태 일괄 변경</StatusChangeInfo>
				</TableBottomContainer>
				<div className="mt-24px">
					<TablePagination
						currentPage={bookingList?.pagination.current_page}
						lastPage={bookingList?.pagination.last_page}
						onPageChange={onPageChange}
					/>
				</div>
			</ScrollSection>
			{bookingItemModalVisibility && (
				<BookingItemModal
					unitType={UnitType.PRODUCT}
					booking={bookingItemModalData}
					onSave={(booking) => onSaveBookingItemModal(booking)}
					onClose={(isEdit) => {
						setBookingItemModalVisibility(false);
						if (isEdit) {
							getBookingsWithFilter();
						}
					}}
					isAdmin={true}
				/>
			)}
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 40px;
	background-color: white;

	@media only screen and (max-width: 1023.98px) {
		padding: 40px 20px;
	}

	@media only screen and (max-width: 479.98px) {
		padding: 20px 10px;
	}
`;

const SectionTitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const SectionTitleWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const SectionTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;
`;

const SectionSubTitle = styled.span`
	margin-left: 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 22px;
	color: #666666;
`;

const SearchFilterContainer = styled.div`
	width: 100%;
	border-top: 4px solid #333333;
	margin-top: 20px;
`;

const FilterRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;

	min-height: 46px;
	border-bottom: 1px solid #dddddd;
`;

const FilterTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;

	width: 140px;
	padding-left: 20px;
	background-color: #f7f7f7;
	border-left: 1px solid #dddddd;
	border-right: 1px solid #dddddd;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
`;

const FilterContents = styled.ul`
	display: flex;
	flex: 1;
	justify-content: flex-start;
	align-items: center;
	flex-grow: 1;
	flex-flow: wrap;

	border-right: 1px solid #dddddd;
	padding: 5px 15px;

	margin-block-start: 0;
	margin-block-end: 0;
	padding-inline-start: 15px;
	gap: 10px;
`;

const FilterCheckboxGroup = styled.li`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	label {
		margin-left: 5px;
		margin-bottom: 1px;
		cursor: pointer;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #666666;
	}
`;

const FilterCheckbox = styled.input`
	width: 15px;
	height: 15px;
	border: 1px solid #c5c5c5;
	border-radius: 3px;
	cursor: pointer;
`;

const BookingItemCheckbox = styled.input`
	cursor: pointer;
`;

const SearchTargetSelect = styled.select`
	width: 100px;
	height: 35px;
	padding-left: 8px;

	border-radius: 4px;
	border: 1px solid #dddddd;

	appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, gray 50%),
		linear-gradient(135deg, gray 50%, transparent 50%);
	background-position: calc(100% - 15px) calc(1em - 2px), calc(100% - 10px) calc(1em - 2px), calc(100% - 2.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1px 1.5em;
	background-repeat: no-repeat;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const SearchKeywordInput = styled.input`
	max-width: 300px;
	width: 100%;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;

	@media only screen and (max-width: 479.98px) {
		max-width: 178px;
	}
`;

const SearchButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	margin-top: 20px;
`;

const SearchButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100px;
	height: 40px;
	background-color: #00b1a6;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #ffffff;

	&:hover {
		background-color: #008f87;
	}
`;

const SearchDateInput = styled.input`
	width: 150px;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;

	@media only screen and (max-width: 479.98px) {
		width: 130px;
	}
`;

const SearchDateInputSeparator = styled.span`
	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const ScrollSection = styled.section`
	scroll-margin-top: 110px;
`;

const TableTopContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 45px;
	margin-top: 20px;
	padding: 0 10px;
	background: #f7f7f7;
	border-top: 1px solid #dddddd;
`;

const TableTopContainerLeft = styled.div`
	display: flex;
	align-items: center;
`;

const SearchResultTableContainer = styled.div`
	max-width: 1160px;
	overflow-x: scroll;
`;

const SearchResultTable = styled.table`
	width: 100%;

	border-collapse: collapse;
	border-top: 2px solid #0e498f;

	text-align: center;
	thead th {
		height: 45px;
		background-color: #f6f8fb;
		border-right: 1px solid #dddddd;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #0e498f;

		:first-child {
			border-left: 1px solid #dddddd;
		}
	}
	thead tr {
		border-bottom: 1px solid #dcdcdc;
	}

	tbody td {
		height: 45px;
		border-right: 1px solid #dddddd;
		padding: 6px 2px;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #333333;

		word-break: break-all;
		:first-child {
			border-left: 1px solid #dddddd;
		}
	}
	tbody tr {
		border-bottom: 1px solid #dddddd;
	}
`;

const TableBottomContainer = styled.div`
	height: 45px;
	background: #f7f7f7;
	border-bottom: 1px solid #dddddd;
	display: flex;
	align-items: center;
	padding: 0 10px;
`;

const DetailButton = styled.div`
	width: 43px;
	height: 26px;

	display: inline-flex;
	align-items: center;
	justify-content: center;

	background-color: #2f5a8c;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #ffffff;
`;

const ApprovalStatusChangeSelect = styled.select`
	width: 80px;
	height: 28px;
	padding-left: 8px;

	border-radius: 4px;
	border: 1px solid #dddddd;

	appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, gray 50%),
		linear-gradient(135deg, gray 50%, transparent 50%);
	background-position: calc(100% - 15px) calc(1em - 2px), calc(100% - 10px) calc(1em - 2px), calc(100% - 2.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1px 1.5em;
	background-repeat: no-repeat;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #666666;
`;

const RentalStatusChangeSelect = styled.select`
	width: 80px;
	height: 28px;
	padding-left: 8px;

	border-radius: 4px;
	border: 1px solid #dddddd;

	appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, gray 50%),
		linear-gradient(135deg, gray 50%, transparent 50%);
	background-position: calc(100% - 15px) calc(1em - 2px), calc(100% - 10px) calc(1em - 2px), calc(100% - 2.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1px 1.5em;
	background-repeat: no-repeat;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #666666;

	${(props) =>
		(props.value == RentalStatus.RENTAL_DELAY || props.value == RentalStatus.RETURN_DELAY) &&
		css`
			border: 1px solid #d22020;
			color: #d22020;
			background-image: linear-gradient(45deg, transparent 50%, #d22020 50%),
				linear-gradient(135deg, #d22020 50%, transparent 50%);
		`}
`;

const StatusChangeButton = styled.div`
	width: 60px;
	height: 28px;
	margin-left: 8px;

	background-color: #ffffff;
	border: 1px solid #333333;
	border-radius: 5px;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #333333;

	&:hover {
		border: 1px solid #2b62ab;
		color: #2b62ab;
	}
`;

const StatusChangeInfo = styled.span`
	margin-left: 11px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #000000;
`;

const ExcelDownloadButton = styled.div`
	width: 100px;
	height: 28px;
	margin-left: 8px;

	background-color: #ffffff;
	border: 1px solid #333333;
	border-radius: 5px;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #333333;

	&:hover {
		border: 1px solid #2b62ab;
		color: #2b62ab;
	}
`;

const MobileSearchResultContainer = styled.div`
	padding: 12px 20px 12px 10px;
	border: 1px solid #dddddd;
	display: flex;
	align-items: flex-start;
	gap: 7px;

	&:not(:first-of-type) {
		border-top: none;
	}
`;

const MobileSearchResultInfoContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 4px;
	font-family: 'Pretendard';
`;

const BookingItemUnitName = styled.div`
	display: flex;
	gap: 8px;
	color: #333333;
	font-size: 16px;
	font-weight: 600;
	line-height: 19.09px;
`;

const BookingItemId = styled.div`
	color: #3788d8;
	font-size: 16px;
	font-weight: 600;
	line-height: 19.09px;
`;

const BookingItemBookingDate = styled.div`
	color: #0e498f;
	font-size: 15px;
	font-weight: 600;
	line-height: 17.9px;
`;

const BookingItemText = styled.div`
	color: #666666;
	font-size: 14px;
	font-weight: 400;
	line-height: 16.71px;
`;

const BookingItemUserName = styled(BookingItemText)``;

const BookingItemCreatedDate = styled(BookingItemText)``;

const BookingItemBottomContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
`;

const BookingItemBottomWrapper = styled.div`
	display: flex;
	gap: 8px;
`;

const BookingItemDetailButton = styled.div`
	color: #0e498f;
	font-size: 14px;
	font-weight: 400;
	line-height: 16.71px;
	cursor: pointer;
`;

export default BookingProductList;
