import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TablePagination from '../TablePagination';
import AdminSectionTitle from '../admin/AdminSectionTitle';
import UserRole from '../../constants/UserRole';
import * as api from '../../apis';

const AdminUserAddModal = ({ onConfirm, onClose }) => {
	const [keyword, setKeyword] = useState('');
	const [userList, setUserList] = useState(null);
	const [pageIndex, setPageIndex] = useState(1);

	useEffect(() => {
		getUsersWithFilter();
	}, [pageIndex]);

	const getUsersWithFilter = () => {
		if (!keyword) {
			return;
		}

		let filterParams = [
			`user_roles[]=${UserRole.UNIT_ADMINISTRATOR}`,
			`page=${pageIndex}`,
			`page_size=5`,
			`keyword=${keyword}`,
		];
		api.getUsers(filterParams)
			.then((res) => {
				if (res && res.data) {
					setUserList(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const onSearch = () => {
		if (pageIndex === 1) {
			getUsersWithFilter(1);
		} else {
			setPageIndex(1);
		}
	};

	const onClickAddAdmin = (adminUser) => {
		onConfirm(adminUser);
	};

	const onPageChange = (page) => {
		setPageIndex(page);
	};

	return (
		<ModalContainer>
			<Content>
				<ModalTopContainer>
					<ModalTitle>관리자 추가</ModalTitle>
					<CloseButton onClick={onClose}>x</CloseButton>
				</ModalTopContainer>
				<SectionTitleContainer>
					<AdminSectionTitle title="사용자 선택" />
				</SectionTitleContainer>
				<SearchContainer>
					<TextInput
						type="text"
						value={keyword}
						placeholder="사용자를 검색해주세요 (이름/아이디)"
						onChange={(e) => setKeyword(e.currentTarget.value)}
						onKeyDown={(event) => {
							if (event.key == 'Enter') {
								onSearch();
							}
						}}
					/>
					<SearchButton onClick={onSearch}>검색</SearchButton>
				</SearchContainer>
				<UserListContainer>
					<UserListTable>
						<thead>
							<tr>
								<th style={{ minWidth: '150px' }}>이름</th>
								<th style={{ minWidth: '200px' }}>아이디</th>
								<th style={{ minWidth: '100px' }}>관리</th>
							</tr>
						</thead>
						<tbody>
							{userList?.items.map((adminUser, idx) => {
								return (
									<tr key={idx}>
										<td>{adminUser.name ?? '-'}</td>
										<td>{adminUser.user_login ?? '-'}</td>
										<td>
											<UserAddButton onClick={() => onClickAddAdmin(adminUser)}>
												추가
											</UserAddButton>
										</td>
									</tr>
								);
							})}
						</tbody>
					</UserListTable>
					<div className="mt-24px">
						<TablePagination
							currentPage={userList?.pagination.current_page}
							lastPage={userList?.pagination.last_page}
							onPageChange={onPageChange}
						/>
					</div>
				</UserListContainer>
			</Content>
		</ModalContainer>
	);
};

const ModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Content = styled.div`
	position: relative;
	width: 542px;
	max-height: 60%;
	overflow-y: scroll;

	background-color: white;
	border: 1px solid #777777;
	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);
`;

const ModalTopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 30px;
	border-bottom: 1px solid #cccccc;
`;

const ModalTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;
`;

const CloseButton = styled.div`
	width: 30px;
	height: 30px;
	display: flex;
	align-items: flex-end;
	justify-content: center;

	cursor: pointer;

	color: #000000;
	font-family: 'Noto Sans';
	font-size: 24px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -1.2px;
	line-height: 30px;
	padding-top: 0px;
`;

const SectionTitleContainer = styled.div`
	margin-top: 20px;
	margin-bottom: 20px;
	padding-left: 30px;
`;

const SearchContainer = styled.div`
	display: flex;
	width: 100%;
	padding: 0px 30px;
`;

const TextInput = styled.input`
	width: 100%;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const SearchButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 122px;
	height: 36px;
	margin-left: 12px;

	background-color: #454655;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #ffffff;

	&:hover {
		background-color: #1d1e31;
	}
`;

const UserListContainer = styled.div`
	width: 100%;
	height: 350px;
	padding: 0 30px;
`;

const UserListTable = styled.table`
	width: 100%;

	border-collapse: collapse;
	border-top: 2px solid #0e498f;

	text-align: center;
	margin-top: 15px;
	thead th {
		height: 45px;
		background-color: #f6f8fb;
		border-right: 1px solid #dddddd;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #0e498f;

		:first-child {
			border-left: 1px solid #dddddd;
		}
	}
	thead tr {
		border-bottom: 1px solid #dcdcdc;
	}

	tbody td {
		height: 45px;

		border-right: 1px solid #dddddd;
		padding: 6px 2px;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #333333;

		word-break: break-all;
		:first-child {
			border-left: 1px solid #dddddd;
		}
	}
	tbody tr {
		border-bottom: 1px solid #dddddd;
	}

	tfoot {
		height: 45px;
		background: #f7f7f7;
		border-bottom: 1px solid #dddddd;
	}
`;

const UserAddButton = styled.div`
	width: 43px;
	height: 26px;

	display: inline-flex;
	align-items: center;
	justify-content: center;

	background-color: #2f5a8c;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #ffffff;
`;

export default AdminUserAddModal;
