import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import useSizeDetector from '../hooks/useSizeDetector';
import { UserContext } from '../contexts/UserContext';
import UserRole from '../constants/UserRole';

import DeleteIconImg from '../assets/images/common/delete.svg';
import LockIcon from '@mui/icons-material/Lock';

import * as api from '../apis';

const Post = () => {
	const { board_id, post_id } = useParams();
	const navigate = useNavigate();
	const { user } = useContext(UserContext);
	const { isDesktop } = useSizeDetector();

	const [post, setPost] = useState(null);
	const [comment, setComment] = useState('');

	useEffect(() => {
		getPostWithApi();
	}, []);

	const getPostWithApi = () => {
		api.getPost(board_id, post_id)
			.then((res) => {
				if (res && res.data) {
					if (!hasReadPermission(res.data)) {
						alert('게시글에 대한 접근 권한이 없습니다.');
						navigate(-1);
						return;
					}
					setPost(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const hasReadPermission = (post) => {
		if (!post.secret) {
			return true;
		}

		if (!user) {
			return false;
		}

		if (user && UserRole.isAdminRole(user.user_role)) {
			return true;
		}

		if (user && UserRole.isUnitAdminRole(user.user_role)) {
			return true;
		}

		if (user && user.id === post.user.id) {
			return true;
		}

		return false;
	};

	const onClickEditPost = () => {
		navigate(`/boards/${board_id}/posts/${post_id}/edit`);
	};

	const onClickDeletePost = () => {
		if (!window.confirm('정말 삭제하시겠습니까?')) {
			return;
		}

		api.deletePost(board_id, post_id)
			.then((res) => {
				alert('게시글을 삭제하였습니다.');
				navigate(-1);
			})
			.catch((err) => {
				console.error(err);
				alert('게시글 삭제에 실패하였습니다.');
			});
	};

	const onClickDeleteComment = (commentId) => {
		if (!window.confirm('정말 삭제하시겠습니까?')) {
			return;
		}

		api.deleteComment(board_id, post_id, commentId)
			.then((res) => {
				alert('댓글을 삭제하였습니다.');
				getPostWithApi();
			})
			.catch((err) => {
				console.error(err);
				alert('댓글 삭제에 실패하였습니다.');
			});
	};

	const onClickWriteComment = () => {
		api.createComment(board_id, post_id, { comment })
			.then((res) => {
				if (res && res.data) {
					alert('등록되었습니다.');
					setComment('');
					getPostWithApi();
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const onClickConfirm = () => {
		navigate(`/boards/${board_id}`);
	};

	const hasEditPermission = () => {
		if (!user || !post) {
			return false;
		}

		if (user && UserRole.isAdminRole(user.user_role)) {
			return true;
		}

		if (user && UserRole.isUnitAdminRole(user.user_role)) {
			return true;
		}

		if (post.user.id === user.id) {
			return true;
		}

		return false;
	};

	const hasCommentDeletePermission = (comment) => {
		if (!user || !post) {
			return false;
		}

		if (user && UserRole.isAdminRole(user.user_role)) {
			return true;
		}

		if (user && UserRole.isUnitAdminRole(user.user_role)) {
			return true;
		}

		if (comment.user.id === user.id) {
			return true;
		}

		return false;
	};

	const hasCommentPermission = () => {
		if (!user || !post) {
			return false;
		}

		return true;
	};

	if (!post) return null;

	return (
		<Content>
			<ButtonContainer>
				{hasEditPermission() && <PostEditButton onClick={onClickEditPost}>수정</PostEditButton>}
				{hasEditPermission() && <PostDeleteButton onClick={onClickDeletePost}>삭제</PostDeleteButton>}
			</ButtonContainer>
			<PostContainer>
				<PostTitleContainer>
					<PostCategory>{`[${post?.category?.name ?? '미분류'}]`}</PostCategory>
					<PostTitle>{post?.title ?? '-'}</PostTitle>
					{post?.secret && <StyledLockIcon />}
				</PostTitleContainer>
				<PostInfoContainer>
					<PostUserName>{post?.user.name}</PostUserName>
					<PostCreatedAt>
						{post?.created_at ? new Date(post.created_at).toLocaleDateString() : '-'}
					</PostCreatedAt>
				</PostInfoContainer>
				<ViewerContainer>
					<PostViewer dangerouslySetInnerHTML={{ __html: post?.description ?? '-' }} />
				</ViewerContainer>
				<section className="mt-20px">
					<CommentTitle>댓글</CommentTitle>
					<CommentContainer>
						{post?.comments.map((comment, idx) => {
							return (
								<CommentItem key={idx}>
									<CommentRow background>
										<CommentCol flexCol={!isDesktop}>
											<CommentUser>{comment.user.name}</CommentUser>
											<CommentCreatedAt>
												{comment.created_at
													? new Date(comment.created_at).toLocaleString()
													: '-'}
											</CommentCreatedAt>
										</CommentCol>
										<CommentCol>
											{hasCommentDeletePermission(comment) && (
												<CommentDeleteButton
													onClick={(e) => onClickDeleteComment(comment.id)}
												/>
											)}
										</CommentCol>
									</CommentRow>
									<CommentRow>
										<Comment>{comment.comment}</Comment>
									</CommentRow>
								</CommentItem>
							);
						})}
					</CommentContainer>
					{hasCommentPermission() && (
						<CommentInputContainer>
							<CommentInput
								value={comment}
								placeholder="댓글을 입력해주세요"
								onChange={(e) => setComment(e.currentTarget.value)}
							/>
							<CommentWriteButton onClick={onClickWriteComment}>등록</CommentWriteButton>
						</CommentInputContainer>
					)}
				</section>
				<section>
					<FooterContainer>
						<OkButton onClick={onClickConfirm}>확인</OkButton>
					</FooterContainer>
				</section>
			</PostContainer>
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	margin-top: 50px;
	margin-bottom: 93px;
	padding: 40px 30px;
	background-color: white;

	@media only screen and (max-width: 767.98px) {
		width: calc(100% - 20px);
		margin: 10px;
		padding: 15px 0 40px 0;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;

	@media only screen and (max-width: 767.98px) {
		padding-right: 10px;
		align-self: flex-end;
	}
`;

const PostContainer = styled.div`
	@media only screen and (max-width: 767.98px) {
		padding: 0 10px;
	}
`;

const PostEditButton = styled.div`
	width: 57px;
	height: 36px;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: #2f5a8c;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #ffffff;

	&:hover {
		background-color: #0d3a76;
	}

	@media only screen and (max-width: 767.98px) {
		width: 57px;
		height: 30px;
		font-size: 14px;
		line-height: 17px;
	}
`;

const PostDeleteButton = styled.div`
	width: 57px;
	height: 36px;
	margin-left: 10px;

	display: flex;
	align-items: center;
	justify-content: center;

	border: 1px solid #2f5a8c;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #2f5a8c;

	&:hover {
		background-color: #0d3a76;
		border: 1px solid #2f5a8c;
		color: #ffffff;
	}

	@media only screen and (max-width: 767.98px) {
		width: 57px;
		height: 30px;
		font-size: 14px;
		line-height: 17px;
	}
`;

const PostTitleContainer = styled.div`
	margin-top: 12px;
	display: flex;
	align-items: center;
	padding: 15px 20px;
	background: #f7f7f7;
	border-top: 2px solid #333333;
	border-bottom: 1px solid #dddddd;

	@media only screen and (max-width: 767.98px) {
		padding: 10px;
		flex-direction: column;
		align-items: flex-start;
	}
`;

const PostCategory = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	color: #22499d;

	@media only screen and (max-width: 767.98px) {
		font-size: 16px;
		line-height: 19px;
	}
`;

const PostTitle = styled.span`
	margin-left: 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		margin-left: 0;
		margin-top: 5px;
		font-size: 16px;
		line-height: 19px;
	}
`;

const StyledLockIcon = styled(LockIcon)`
	font-size: 18px !important;
	margin-left: 8px;
	margin-bottom: 1px;
`;

const PostInfoContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 15px 20px;
	border-bottom: 1px solid #dddddd;

	@media only screen and (max-width: 767.98px) {
		padding: 14px 11px;
	}
`;

const PostUserName = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
`;

const PostCreatedAt = styled.div`
	margin-left: 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
`;

const ViewerContainer = styled.div`
	width: 100%;
	padding: 15px 20px;
	border-bottom: 1px solid #333333;

	@media only screen and (max-width: 767.98px) {
		padding: 14px 11px;
	}
`;

const PostViewer = styled.div`
	width: 100%;
	font-size: 16px;
	margin-top: 10px;
	overflow-x: auto;

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
		line-height: 17px;
	}
`;

const CommentTitle = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		font-size: 16px;
		line-height: 19px;
	}
`;

const CommentContainer = styled.div`
	margin-top: 10px;
`;

const CommentItem = styled.div`
	& + & {
		margin-top: 10px;
	}
`;

const CommentRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 10px 10px 20px;

	${(props) =>
		props.background &&
		css`
			background-color: #f0f0f0;
			border-top: 1px solid #d6d6d6;
			border-bottom: 1px solid #d6d6d6;
		`}

	@media only screen and (max-width: 767.98px) {
		padding: 10px;
	}
`;

const CommentCol = styled.div`
	display: flex;

	${(props) =>
		props.flexCol &&
		css`
			flex-direction: column;
		`}
`;

const Comment = styled.div`
	margin-top: 4px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #000000;
`;

const CommentUser = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #000000;
`;

const CommentCreatedAt = styled.div`
	margin-left: 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;

	@media only screen and (max-width: 767.98px) {
		margin-left: 0;
	}
`;

const CommentDeleteButton = styled.i`
	width: 24px;
	height: 24px;
	background-image: url(${DeleteIconImg});
	cursor: pointer;
`;

const CommentInputContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 12px;
`;

const CommentInput = styled.input`
	flex-grow: 1;
	height: 40px;
	padding: 8px;
	border-radius: 5px;
	border: 1px solid #dddddd;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const CommentWriteButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 114px;
	height: 38px;
	margin-left: 10px;

	background-color: #00b1a6;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #ffffff;

	&:hover {
		background-color: #008f87;
	}
`;

const FooterContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
`;

const OkButton = styled.div`
	width: 125px;
	height: 50px;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: #22499d;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #ffffff;

	&:hover {
		background-color: #0c438d;
	}
`;

export default Post;
