export default {
	AUTO: 'auto',
	MANUAL: 'manual',
	toString: (value) => {
		switch (value) {
			case 'auto':
				return '자동';
			case 'manual':
				return '수동';
			default:
				return '-';
		}
	},
};
