import React from 'react';
import styled from 'styled-components';

const EmailNotificationReceiverModal = ({ emailNotificationReceivers, onClose }) => {
	return (
		<ModalContainer>
			<Content>
				<ModalTopContainer>
					<ModalTitle>수신자 리스트</ModalTitle>
					<CloseButton onClick={onClose}>x</CloseButton>
				</ModalTopContainer>
				<NotificationContentContainer>
					<NotificationTitle>
						수신자 - <ReceiverCount>{`${emailNotificationReceivers?.length ?? 0}명`}</ReceiverCount>
					</NotificationTitle>
					<NotificationReceiverContainer>
						<NotificationReceiverList>
							{emailNotificationReceivers?.map((receiver, idx) => {
								return (
									<NotificationReceiver key={idx}>{`${receiver.user?.name ?? '-'}(${
										receiver.user?.user_login ?? '-'
									})`}</NotificationReceiver>
								);
							})}
						</NotificationReceiverList>
					</NotificationReceiverContainer>
				</NotificationContentContainer>
				<Footer>
					<CancelButton onClick={onClose}>닫기</CancelButton>
				</Footer>
			</Content>
		</ModalContainer>
	);
};

const ModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Content = styled.div`
	position: relative;
	width: 534px;
	max-height: 80%;
	overflow-y: scroll;

	background-color: white;
	border: 1px solid #777777;
	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);
`;

const ModalTopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 30px;
	border-bottom: 1px solid #cccccc;
`;

const ModalTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;
`;

const CloseButton = styled.div`
	width: 30px;
	height: 30px;
	display: flex;
	align-items: flex-end;
	justify-content: center;

	cursor: pointer;

	color: #000000;
	font-family: 'Noto Sans';
	font-size: 24px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -1.2px;
	line-height: 30px;
	padding-top: 0px;
`;

const NotificationContentContainer = styled.div`
	width: 100%;
	margin-top: 20px;
	padding: 0px 30px;
`;

const NotificationTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #000000;
`;

const ReceiverCount = styled.span`
	color: #0e498f;
`;

const NotificationReceiverContainer = styled.div`
	margin-top: 22px;
	padding: 20px;
	white-space: pre-line;
	background-color: #f5f5f5;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
`;

const NotificationReceiverList = styled.ul`
	margin: 0;
`;

const NotificationReceiver = styled.li`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
`;

const Footer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px 0 20px 0;
`;

const CancelButton = styled.div`
	width: 125px;
	height: 50px;
	border: 1px solid #22499d;
	border-radius: 5px;
	background-color: #ffffff;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #22499d;

	&:hover {
		background-color: #0c438d;
		color: #ffffff;
	}
`;

export default EmailNotificationReceiverModal;
