import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import AdminSectionTitle from '../../components/admin/AdminSectionTitle';
import LoadingIndicator from '../../components/LoadingIndicator';

import LoginType from '../../constants/LoginType';
import UserType from '../../constants/UserType';
import UserRole from '../../constants/UserRole';

import * as utils from '../../utils';
import * as api from '../../apis';

const UserInfo = () => {
	const navigate = useNavigate();
	const { user_id } = useParams();
	const [userInfo, setUserInfo] = useState({
		user_login: '',
		name: '',
		email: '',
		phone: '',
		dept_name: '',
		user_type: UserType.COMMON,
		user_role: UserRole.COMMON,
		password: '',
		password_confirm: '',
	});
	const [userLoginChecked, setUserLoginChecked] = useState(false);
	const [initialized, setInitialized] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const isEdit = user_id ? true : false;

	useEffect(() => {
		if (isEdit) {
			api.getUser(user_id)
				.then((res) => {
					if (res && res.data) {
						setUserInfo(res.data);
						setInitialized(true);
					}
				})
				.catch((err) => {
					console.error(err);
				});
		} else {
			setInitialized(true);
		}
	}, []);

	const onClickDeleteUser = () => {
		if (window.confirm('사용자를 삭제하시겠습니까?\n삭제 시, 사용자의 예약 내역이 모두 삭제됩니다.')) {
			api.deleteUser(user_id)
				.then((res) => {
					if (res && res.status === 204) {
						alert('사용자를 삭제하였습니다.');
						navigate('/admin/users');
					} else {
						alert('사용자 삭제에 실패하였습니다.');
					}
				})
				.catch((err) => {
					console.error(err);
					alert('사용자 삭제에 실패하였습니다.');
				});
		}
	};

	const onClickUserLoginCheck = () => {
		api.checkUserLogin({ user_login: userInfo.user_login })
			.then((res) => {
				if (res && res.status === 204) {
					setUserLoginChecked(true);
					alert('사용 가능한 아이디입니다.');
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const onClickSave = () => {
		if (!validateData(userInfo, isEdit)) {
			return;
		}

		if (isEdit) {
			setIsLoading(true);
			api.updateUser(user_id, userInfo)
				.then((res) => {
					if ((res && res.data) || res.status === 204) {
						alert('수정되었습니다.');
						navigate('/admin/users');
					}
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			setIsLoading(true);
			api.createUser(userInfo)
				.then((res) => {
					if (res && res.data) {
						alert('생성되었습니다.');
						navigate('/admin/users');
					}
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	const validateData = (userInfo, isEdit) => {
		if (!userInfo.user_login) {
			alert('사용자 아이디를 입력하여 주십시오.');
			return false;
		}
		if (!isEdit && !userLoginChecked) {
			alert('아이디 중복확인을 진행해 주십시오.');
			return false;
		}
		if (!userInfo.name) {
			alert('사용자 이름을 입력하여 주십시오.');
			return false;
		}
		if (!userInfo.email) {
			alert('사용자 이메일을 입력하여 주십시오.');
			return false;
		}
		if (!isEdit && (!userInfo.password || !userInfo.password_confirm)) {
			alert('비밀번호를 입력하여 주십시오.');
			return false;
		}
		if (!isEdit && userInfo.password !== userInfo.password_confirm) {
			alert('입력하신 비밀번호가 다릅니다.');
			return false;
		}

		return true;
	};

	if (!initialized) return null;

	return (
		<>
			<LoadingIndicator size={50} loading={isLoading} />
			<Content>
				<TitleText>{isEdit ? '사용자 수정' : '사용자 등록'}</TitleText>
				<AdminSectionTitleContainer className="mt-40px">
					<AdminSectionTitle title="사용자 기본 정보" />
					{isEdit && <DeleteButton onClick={onClickDeleteUser}>삭제</DeleteButton>}
				</AdminSectionTitleContainer>
				<section>
					<BasicInfoContainer className="mt-24px">
						<BasicInfoRow>
							<BasicInfoTitle required>사용자 유형</BasicInfoTitle>
							<BasicInfoContents>
								<BasicInfoTextInput
									type="text"
									value={UserType.toString(userInfo.user_type)}
									disabled={true}
								/>
							</BasicInfoContents>
						</BasicInfoRow>

						<BasicInfoRow>
							<BasicInfoTitle required>사용자 역할</BasicInfoTitle>
							<BasicInfoContents>
								<BasicInfoSelect
									value={userInfo.user_role}
									onChange={(e) => {
										const newUserRole = parseInt(e.currentTarget.value);
										if (
											UserRole.isAdminRole(newUserRole) ||
											UserRole.isUnitAdminRole(newUserRole)
										) {
											setUserInfo({
												...userInfo,
												user_role: newUserRole,
												user_type: UserType.ADMINISTRATOR,
											});
										} else {
											setUserInfo({
												...userInfo,
												user_role: newUserRole,
												user_type: UserType.COMMON,
											});
										}
									}}
								>
									<option value={UserRole.COMMON}>{UserRole.toString(UserRole.COMMON)}</option>
									<option value={UserRole.ROOM_ADMINISTRATOR}>
										{UserRole.toString(UserRole.ROOM_ADMINISTRATOR)}
									</option>
									{/* 장비 관리자는 .env에 REACT_APP_USE_TYPE_PRODUCT가 false이면 표시하지 않는다. */}
									{JSON.parse(process.env.REACT_APP_USE_TYPE_PRODUCT) && (
										<option value={UserRole.PRODUCT_ADMINISTRATOR}>
											{UserRole.toString(UserRole.PRODUCT_ADMINISTRATOR)}
										</option>
									)}
									{/* 상담 관리자는 .env에 REACT_APP_USE_TYPE_CONSULT가 false이면 표시하지 않는다. */}
									{JSON.parse(process.env.REACT_APP_USE_TYPE_CONSULT) && (
										<option value={UserRole.CONSULT_ADMINISTRATOR}>
											{UserRole.toString(UserRole.CONSULT_ADMINISTRATOR)}
										</option>
									)}
									<option value={UserRole.UNIT_ADMINISTRATOR}>
										{UserRole.toString(UserRole.UNIT_ADMINISTRATOR)}
									</option>
									<option value={UserRole.SYSTEM_ADMINISTRATOR}>
										{UserRole.toString(UserRole.SYSTEM_ADMINISTRATOR)}
									</option>
								</BasicInfoSelect>
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle required>아이디</BasicInfoTitle>
							<BasicInfoContents>
								<BasicInfoTextInput
									type="text"
									value={isEdit ? utils.getUserLoginStr(userInfo) : userInfo.user_login}
									disabled={isEdit}
									onChange={(e) => setUserInfo({ ...userInfo, user_login: e.currentTarget.value })}
								/>
								{!isEdit && <CheckButton onClick={onClickUserLoginCheck}>중복확인</CheckButton>}
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle required>이름</BasicInfoTitle>
							<BasicInfoContents>
								<BasicInfoTextInput
									type="text"
									value={userInfo.name}
									disabled={userInfo.login_type === LoginType.SSO}
									onChange={(e) => setUserInfo({ ...userInfo, name: e.currentTarget.value })}
								/>
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle required>이메일</BasicInfoTitle>
							<BasicInfoContents>
								<BasicInfoTextInput
									type="text"
									value={userInfo.email}
									disabled={userInfo.login_type === LoginType.SSO}
									onChange={(e) => setUserInfo({ ...userInfo, email: e.currentTarget.value })}
								/>
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle>소속(학과 및 부서)</BasicInfoTitle>
							<BasicInfoContents>
								<BasicInfoTextInput
									type="text"
									value={userInfo.dept_name}
									disabled={userInfo.login_type === LoginType.SSO}
									onChange={(e) => setUserInfo({ ...userInfo, dept_name: e.currentTarget.value })}
								/>
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle>전화번호</BasicInfoTitle>
							<BasicInfoContents>
								<BasicInfoTextInput
									type="text"
									value={userInfo.phone}
									onChange={(e) => setUserInfo({ ...userInfo, phone: e.currentTarget.value })}
								/>
							</BasicInfoContents>
						</BasicInfoRow>
						{userInfo.login_type !== LoginType.SSO && (
							<>
								<BasicInfoRow>
									<BasicInfoTitle required>비밀번호</BasicInfoTitle>
									<BasicInfoContents>
										<BasicInfoTextInput
											type="password"
											value={userInfo.password}
											onChange={(e) =>
												setUserInfo({ ...userInfo, password: e.currentTarget.value })
											}
										/>
									</BasicInfoContents>
								</BasicInfoRow>
								<BasicInfoRow>
									<BasicInfoTitle required>비밀번호 확인</BasicInfoTitle>
									<BasicInfoContents>
										<BasicInfoTextInput
											type="password"
											value={userInfo.password_confirm}
											onChange={(e) =>
												setUserInfo({ ...userInfo, password_confirm: e.currentTarget.value })
											}
										/>
									</BasicInfoContents>
								</BasicInfoRow>
							</>
						)}
					</BasicInfoContainer>
				</section>
				<section className="mt-30px">
					<FooterContainer>
						<CancelButton onClick={() => navigate(-1)}>취소</CancelButton>
						<OkButton onClick={onClickSave}>저장</OkButton>
					</FooterContainer>
				</section>
			</Content>
		</>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 40px;
	background-color: white;
`;

const TitleText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;
`;

const AdminSectionTitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const DeleteButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 122px;
	height: 36px;

	background-color: #454655;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #ffffff;

	&:hover {
		background-color: #1d1e31;
	}
`;

const BasicInfoContainer = styled.div`
	width: 100%;
	border-top: 2px solid #333333;
`;

const BasicInfoRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;

	min-height: 45px;
	border-bottom: 1px solid #dddddd;
	border-left: 1px solid #dddddd;
	border-right: 1px solid #dddddd;
`;

const BasicInfoTitle = styled.div`
	width: 150px;
	flex-shrink: 0;
	padding-left: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	background-color: #f7f7f7;
	border-right: 1px solid #dddddd;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #333333;

	${(props) =>
		props.required &&
		css`
			::after {
				content: '*';
				color: #dc3545;
				margin-left: 2px;
			}
		`}
`;

const BasicInfoContents = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	padding: 8px 15px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
`;

const BasicInfoSelect = styled.select`
	width: 250px;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;

	appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, #000000 50%),
		linear-gradient(135deg, #000000 50%, transparent 50%);
	background-position: calc(100% - 20px) calc(1em + 0px), calc(100% - 15px) calc(1em + 0px), calc(100% - 2.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1px 1.5em;
	background-repeat: no-repeat;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const BasicInfoTextInput = styled.input`
	width: 250px;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const CheckButton = styled.div`
	width: 70px;
	height: 30px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: 10px;
	border: 1px solid #333333;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	display: flex;
	align-items: center;

	&:hover {
		border: 1px solid #2b62ab;
		color: #2b62ab;
	}
`;

const FooterContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const CancelButton = styled.div`
	width: 100px;
	height: 40px;

	border: 1px solid #00b1a6;
	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #009289;
`;

const OkButton = styled.div`
	width: 100px;
	height: 40px;
	margin-left: 10px;

	background-color: #00b1a6;
	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #ffffff;

	&:hover {
		background-color: #008f87;
	}
`;

export default UserInfo;
