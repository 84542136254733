import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import BookingCalendar from '../../components/BookingCalendar';
import ApprovalStatusLegend from '../../components/ApprovalStatusLegend';
import UnitType from '../../constants/UnitType';
import UserRole from '../../constants/UserRole';

import { UserContext } from '../../contexts/UserContext';

import * as api from '../../apis';

const DashboardCalendar = ({ unitType, standalone }) => {
	const { user } = useContext(UserContext);
	const [unitList, setUnitList] = useState(null);
	const [calendarUnitId, setCalendarUnitId] = useState(null);

	useEffect(() => {
		let filterParams = [`unit_type=${unitType}`, `page_size=${Number.MAX_SAFE_INTEGER}`];
		// 사용자가 관리자 권한이 없는 경우 Unit 관리 대상만 얻는다.
		if (!UserRole.isAdminRole(user?.user_role)) {
			user.unit_admins.forEach((unit) => filterParams.push(`unit_ids[]=${unit.unit_id}`));
		}

		api.getUnits(filterParams)
			.then((res) => {
				if (res && res.data) {
					setUnitList(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	const onOpenStandalone = () => {
		window.open(`/admin/calendar/${unitType}`);
	};

	return (
		<>
			<UnitSelectContainer>
				<UnitSelectTitle>{`${UnitType.toString(unitType)} 선택`}</UnitSelectTitle>
				<UnitSelect value={calendarUnitId ?? ''} onChange={(e) => setCalendarUnitId(e.currentTarget.value)}>
					<option value="">전체</option>
					{unitList?.items.map((unit, idx) => {
						return (
							<option key={idx} value={unit.id}>
								{unit.name ?? '-'}
							</option>
						);
					})}
				</UnitSelect>
				{!standalone && <OpenStandaloneButton onClick={onOpenStandalone}>새 창으로 보기</OpenStandaloneButton>}
			</UnitSelectContainer>
			<ApprovalStatusLegend />
			<BookingCalendar standalone={standalone} unitType={unitType} calendarUnitId={calendarUnitId} />
		</>
	);
};

const UnitSelectContainer = styled.div`
	display: flex;
	align-items: center;
	margin: 20px 0;
`;

const UnitSelectTitle = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #000000;
`;

const UnitSelect = styled.select`
	width: 250px;
	height: 35px;
	padding: 8px;
	margin-left: 14px;

	border: 1px solid #dddddd;
	border-radius: 5px;

	appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, #000000 50%),
		linear-gradient(135deg, #000000 50%, transparent 50%);
	background-position: calc(100% - 20px) calc(1em + 0px), calc(100% - 15px) calc(1em + 0px), calc(100% - 2.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1px 1.5em;
	background-repeat: no-repeat;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
`;

const OpenStandaloneButton = styled.div`
	width: 110px;
	height: 33px;

	margin-left: 10px;
	background-color: #2b62ab;
	border-radius: 5px;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #ffffff;

	&:hover {
		background-color: #0c438d;
	}
`;

export default DashboardCalendar;
