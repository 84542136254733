import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import UnitType from '../../constants/UnitType';
import UserRole from '../../constants/UserRole';

import TabArrowDownIconImg from '../../assets/images/common/admin_tab_arrow_down.svg';

const AdminTab = ({ tabs, tabIndex, userRole, unitAdmins, children }) => {
	const navigate = useNavigate();

	const onClickTab = (link) => {
		if (link) {
			navigate(link);
		}
	};
	return (
		<Container>
			<Content>
				{tabs?.map((tab, idx) => {
					const selected = idx === tabIndex;
					// 장비관련 탭인 경우 .env에 REACT_APP_USE_TYPE_PRODUCT가 false이면 표시하지 않는다.
					if (tab?.use_product && !JSON.parse(process.env.REACT_APP_USE_TYPE_PRODUCT)) {
						return null;
					}
					// 상담관련 탭인 경우 .env에 REACT_APP_USE_TYPE_CONSULT가 false이면 표시하지 않는다.
					if (tab?.use_consult && !JSON.parse(process.env.REACT_APP_USE_TYPE_CONSULT ?? false)) {
						return null;
					}
					// 운영자 권한에 따라 탭 표시 여부를 결정한다.
					if (tab?.tab_unit === 'admin' && !UserRole.isSystemAdminRole(userRole)) {
						return null;
					} else if (
						tab?.tab_unit === UnitType.ROOM &&
						!UserRole.isSystemAdminRole(userRole) &&
						!UserRole.isRoomAdminRole(userRole) &&
						!UserRole.isRoomUnitAdmin(userRole, unitAdmins)
					) {
						return null;
					} else if (
						tab?.tab_unit === UnitType.PRODUCT &&
						!UserRole.isSystemAdminRole(userRole) &&
						!UserRole.isProductAdminRole(userRole) &&
						!UserRole.isProductUnitAdmin(userRole, unitAdmins)
					) {
						return null;
					} else if (
						tab?.tab_unit === UnitType.CONSULT &&
						!UserRole.isSystemAdminRole(userRole) &&
						!UserRole.isConsultAdminRole(userRole) &&
						!UserRole.isConsultUnitAdmin(userRole, unitAdmins)
					) {
						return null;
					}
					return (
						<Tab key={idx} onClick={() => onClickTab(tab.link)} selected={selected}>
							<span>{tab.name ?? '-'}</span>
							{selected && <TabArrowDownIcon />}
						</Tab>
					);
				})}
			</Content>
			{children}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const Content = styled.div`
	display: flex;
	width: 100%;
	max-width: 1240px;
	height: 53px;
	overflow-x: auto;
`;

const Tab = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 310px;
	height: 53px;
	padding: 0 15px;
	box-sizing: border-box;
	cursor: pointer;

	background-color: #2f5a8c;
	border-top: 2px solid #2f5a8c;
	border-right: 1px solid #bec2c8;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 18px;
	color: #d0dbe7;
	white-space: nowrap;

	${(props) =>
		props.selected &&
		css`
			color: #22499d;
			background-color: #ffffff;
			border-top: 2px solid #22499d;
			border-right: 1px solid #ffffff;
		`}

	@media only screen and (max-width: 1023.98px) {
		justify-content: center;
		padding: 0;
	}

	@media only screen and (max-width: 767.98px) {
		min-width: 95px;
		font-size: 14px;
		line-height: 17px;
	}

	@media only screen and (max-width: 479.98px) {
		padding: 0 10px;
	}
`;

const TabArrowDownIcon = styled.i`
	width: 24px;
	height: 24px;
	background-image: url(${TabArrowDownIconImg});

	@media only screen and (max-width: 1023.98px) {
		display: none;
	}
`;

export default AdminTab;
