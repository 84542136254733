import { useState, useEffect } from 'react';
import styled from 'styled-components';
import UnitType from '../../constants/UnitType';
import BookingCalendar from '../BookingCalendar';
import ApprovalStatusLegend from '../ApprovalStatusLegend';

const BookingCalendarModal = ({ onClose }) => {
	const [unitType, setUnitType] = useState(UnitType.ROOM);

	useEffect(() => {
		// 모달이 보일 경우에 body의 overflow 속성을 지정하여 모달 뒷 배경이 스크롤되지 않도록 한다.
		document.body.style.overflow = 'hidden';

		return () => {
			document.body.style.overflow = '';
		};
	}, []);

	const onOpenStandalone = () => {
		window.open(`/calendar`);
	};

	return (
		<ModalContainer>
			<Content>
				<ModalTopContainer>
					<ModalTopLeftContainer>
						<ModalTitle>예약 캘린더</ModalTitle>
						<OpenStandaloneButton onClick={onOpenStandalone}>새 창으로 보기</OpenStandaloneButton>
					</ModalTopLeftContainer>
					<ModalTopRightContainer>
						<CloseButton onClick={onClose}>x</CloseButton>
					</ModalTopRightContainer>
				</ModalTopContainer>
				<ModalBodyContainer>
					<CalendarTopContainer>
						<ApprovalStatusLegend />
						<UnitSelect onChange={(e) => setUnitType(e.currentTarget.value)}>
							<option value={UnitType.ROOM}>{UnitType.toString(UnitType.ROOM)}</option>
							{JSON.parse(process.env.REACT_APP_USE_TYPE_PRODUCT ?? false) && (
								<option value={UnitType.PRODUCT}>{UnitType.toString(UnitType.PRODUCT)}</option>
							)}
							{JSON.parse(process.env.REACT_APP_USE_TYPE_CONSULT ?? false) && (
								<option value={UnitType.CONSULT}>{UnitType.toString(UnitType.CONSULT)}</option>
							)}
						</UnitSelect>
					</CalendarTopContainer>
					<BookingCalendar standalone={true} unitType={unitType} />
				</ModalBodyContainer>
			</Content>
		</ModalContainer>
	);
};

const ModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Content = styled.div`
	position: relative;
	width: 1240px;
	height: 95dvh;
	overflow-y: auto;

	background-color: white;
	border: 1px solid #777777;
	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);
`;

const ModalTopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 30px;
	border-bottom: 1px solid #cccccc;
`;

const ModalTopLeftContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const ModalTopRightContainer = styled.div`
	display: flex;
	align-items: center;
`;

const OpenStandaloneButton = styled.div`
	width: 110px;
	height: 33px;

	margin-left: 10px;
	background-color: #2b62ab;
	border-radius: 5px;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #ffffff;

	&:hover {
		background-color: #0c438d;
	}
`;

const ModalTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;
`;

const CloseButton = styled.div`
	width: 30px;
	height: 30px;
	display: flex;
	align-items: flex-end;
	justify-content: center;

	cursor: pointer;

	color: #000000;
	font-family: 'Noto Sans';
	font-size: 24px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -1.2px;
	line-height: 30px;
	padding-top: 0px;
`;

const ModalBodyContainer = styled.div`
	padding: 20px 30px;
`;

const CalendarTopContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const UnitSelect = styled.select`
	width: 124px;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	border-radius: 5px;

	appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, #000000 50%),
		linear-gradient(135deg, #000000 50%, transparent 50%);
	background-position: calc(100% - 20px) calc(1em + 0px), calc(100% - 15px) calc(1em + 0px), calc(100% - 2.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1px 1.5em;
	background-repeat: no-repeat;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
`;

export default BookingCalendarModal;
