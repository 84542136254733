import { useEffect } from 'react';
import styled from 'styled-components';
import BookingCalendar from '../BookingCalendar';
import ApprovalStatusLegend from '../ApprovalStatusLegend';

const BookingStatusModal = ({ unitType, calendarUnitId, onClose }) => {
	useEffect(() => {
		// 모달이 보일 경우에 body의 overflow 속성을 지정하여 모달 뒷 배경이 스크롤되지 않도록 한다.
		document.body.style.overflow = 'hidden';

		return () => {
			document.body.style.overflow = '';
		};
	}, []);

	return (
		<ModalContainer>
			<Content>
				<ModalTopContainer>
					<ModalTitle>실시간 예약 현황</ModalTitle>
					<CloseButton onClick={onClose}>x</CloseButton>
				</ModalTopContainer>
				<ModalBodyContainer>
					<Description>
						날짜별 예약 현황을 확인하여 시설 이용 혼잡도가 적은 날짜를 선택하면 쾌적하게 이용하실 수
						있습니다.
					</Description>
					<ApprovalStatusLegend />
					<BookingCalendar
						standalone={true}
						unitType={unitType}
						calendarUnitId={calendarUnitId}
						bookingStatus={true}
					/>
				</ModalBodyContainer>
			</Content>
		</ModalContainer>
	);
};

const ModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Content = styled.div`
	position: relative;
	width: 1240px;
	height: 95dvh;
	overflow-y: auto;

	background-color: white;
	border: 1px solid #777777;
	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);
`;

const ModalTopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 30px;
	border-bottom: 1px solid #cccccc;
`;

const ModalTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;
`;

const CloseButton = styled.div`
	width: 30px;
	height: 30px;
	display: flex;
	align-items: flex-end;
	justify-content: center;

	cursor: pointer;

	color: #000000;
	font-family: 'Noto Sans';
	font-size: 24px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -1.2px;
	line-height: 30px;
	padding-top: 0px;
`;

const ModalBodyContainer = styled.div`
	padding: 20px 30px;
`;

const Description = styled.div`
	color: #22499d;
	margin-bottom: 10px;
`;

export default BookingStatusModal;
