import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import TablePagination from '../../../components/TablePagination';
import EmailNotificationContentModal from '../../../components/modal/EmailNotificationContentModal';
import EmailNotificationReceiverModal from '../../../components/modal/EmailNotificationReceiverModal';
import * as api from '../../../apis';

const EmailNotificationList = () => {
	const navigate = useNavigate();
	const { unit_type } = useParams();
	const [pageIndex, setPageIndex] = useState(1);
	const [searchKeyword, setSearchKeyword] = useState('');
	const [emailNotificationList, setEmailNotificationList] = useState(null);
	const [emailNotificationContentModalData, setEmailNotificationContentModalData] = useState({
		show: false,
		emailNotification: null,
	});
	const [emailNotificationReceiverModalData, setEmailNotificationReceiverModalData] = useState({
		show: false,
		emailNotificationReceivers: null,
	});

	const pageSize = 20;
	const tableTopRef = useRef(null);

	useEffect(() => {
		getEmailNotificationsWithFilter();
	}, [pageIndex]);

	const getEmailNotificationsWithFilter = () => {
		let filterParams = [`unit_type=${unit_type}`, `page=${pageIndex}`, `page_size=${pageSize}`];
		if (searchKeyword) filterParams.push(`keyword=${searchKeyword}`);
		getEmailNotificationsFromApi(filterParams);
	};

	const getEmailNotificationsFromApi = (filterParams) => {
		api.getEmailNotifications(filterParams)
			.then((res) => {
				if (res && res.data) {
					setEmailNotificationList(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const onPageChange = (page) => {
		setPageIndex(page);
		tableTopRef.current.scrollIntoView();
	};

	const onClickSearch = () => {
		getEmailNotificationsWithFilter();
	};

	const onClickNotificationSettings = () => {
		navigate(`/admin/settings/notifications`);
	};

	const onClickShowNotificaitonContent = (emailNotification) => {
		setEmailNotificationContentModalData({ show: true, emailNotification });
	};

	const onClickShowNotificaitonReceivers = (emailNotification) => {
		setEmailNotificationReceiverModalData({ show: true, emailNotificationReceivers: emailNotification.receivers });
	};

	return (
		<Content>
			<NavigateButton onClick={onClickNotificationSettings}>알림 관리로 돌아가기</NavigateButton>
			<ScrollSection ref={tableTopRef}>
				<TableTopContainer>
					<SectionTitleContainer>
						<SectionTitle>발송 내역 조회</SectionTitle>
						<SectionSubTitle>{`전체 ${emailNotificationList?.pagination.total ?? '0'}개`}</SectionSubTitle>
					</SectionTitleContainer>
					<SearchContainer>
						<SearchKeywordInput
							type="text"
							value={searchKeyword}
							onChange={(e) => setSearchKeyword(e.currentTarget.value)}
							onKeyDown={(event) => {
								if (event.key == 'Enter') {
									onClickSearch();
								}
							}}
							placeholder="사용자를 검색해주세요 (이름/아이디)"
						/>
						<SearchButton onClick={onClickSearch}>검색</SearchButton>
					</SearchContainer>
				</TableTopContainer>
				<EmailNotificationTable>
					<thead>
						<tr>
							<th style={{ minWidth: '80px' }}>NO</th>
							<th style={{ minWidth: '100px' }}>발송 시간</th>
							<th style={{ minWidth: '300px' }}>제목 및 내용</th>
							<th style={{ minWidth: '80px' }}>발송 총 건수</th>
							<th style={{ minWidth: '80px' }}>성공</th>
							<th style={{ minWidth: '80px' }}>실패</th>
							<th style={{ minWidth: '80px' }}>수신자</th>
						</tr>
					</thead>
					<tbody>
						{emailNotificationList?.items.map((emailNotification, idx) => {
							return (
								<tr key={idx}>
									<td>{(emailNotificationList?.pagination.current_page - 1) * pageSize + idx + 1}</td>
									<td>{new Date(emailNotification.sent_at).toLocaleString()}</td>
									<td>
										<EmailNotificationTitle
											onClick={() => onClickShowNotificaitonContent(emailNotification)}
										>
											{emailNotification.title ?? '-'}
										</EmailNotificationTitle>
									</td>
									<td>{emailNotification.send_count ?? 0}</td>
									<td>{emailNotification.success_count ?? 0}</td>
									<td>{emailNotification.fail_count ?? 0}</td>
									<td>
										<ReceiverDetailButton
											onClick={() => onClickShowNotificaitonReceivers(emailNotification)}
										>{`${emailNotification.receivers?.length ?? 0}명`}</ReceiverDetailButton>
									</td>
								</tr>
							);
						})}
					</tbody>
				</EmailNotificationTable>
				<div className="mt-24px">
					<TablePagination
						currentPage={emailNotificationList?.pagination.current_page}
						lastPage={emailNotificationList?.pagination.last_page}
						onPageChange={onPageChange}
					/>
				</div>
			</ScrollSection>

			{emailNotificationContentModalData.show && (
				<EmailNotificationContentModal
					emailNotification={emailNotificationContentModalData.emailNotification}
					onClose={() => setEmailNotificationContentModalData({ show: false })}
				/>
			)}
			{emailNotificationReceiverModalData.show && (
				<EmailNotificationReceiverModal
					emailNotificationReceivers={emailNotificationReceiverModalData.emailNotificationReceivers}
					onClose={() => setEmailNotificationReceiverModalData({ show: false })}
				/>
			)}
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 40px;
	background-color: white;
`;

const NavigateButton = styled.div`
	inline-size: fit-content;
	padding: 9px 16px;
	height: 36px;

	display: flex;
	align-items: center;
	justify-content: center;

	border: 1px solid #333333;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;

	&:hover {
		border: 1px solid #2b62ab;
		color: #2b62ab;
	}
`;

const ScrollSection = styled.section`
	margin-top: 20px;
	scroll-margin-top: 180px;
`;

const TableTopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const SectionTitleContainer = styled.div`
	display: flex;
	align-items: flex-end;
`;

const SectionTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;
`;

const SectionSubTitle = styled.span`
	margin-left: 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #808080;
`;

const SearchContainer = styled.div`
	display: flex;
	align-items: center;
`;

const SearchKeywordInput = styled.input`
	width: 300px;
	height: 35px;
	padding: 8px;
	margin-right: 10px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const SearchButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	padding: 9px 16px;
	height: 36px;

	background-color: #454655;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #ffffff;

	&:hover {
		background-color: #1d1e31;
	}
`;

const EmailNotificationTable = styled.table`
	width: 100%;
	margin-top: 20px;

	border-collapse: collapse;
	border-top: 2px solid #0e498f;

	text-align: center;
	thead th {
		height: 45px;
		background-color: #f6f8fb;
		border-right: 1px solid #dddddd;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #0e498f;

		:first-child {
			border-left: 1px solid #dddddd;
		}
	}
	thead tr {
		border-bottom: 1px solid #dcdcdc;
	}

	tbody td {
		height: 45px;
		border-right: 1px solid #dddddd;
		padding: 6px 2px;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #333333;

		word-break: break-all;
		:first-child {
			border-left: 1px solid #dddddd;
		}
	}
	tbody tr {
		border-bottom: 1px solid #dddddd;
	}

	tfoot {
		height: 45px;
		background: #f7f7f7;
		border-bottom: 1px solid #dddddd;
	}
`;

const EmailNotificationTitle = styled.span`
	color: #025ac2;
	cursor: pointer;
`;

const ReceiverDetailButton = styled.div`
	width: 43px;
	height: 26px;

	display: inline-flex;
	align-items: center;
	justify-content: center;

	background-color: #2f5a8c;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #ffffff;
`;

export default EmailNotificationList;
