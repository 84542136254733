import LoginType from '../constants/LoginType';
import UserRole from '../constants/UserRole';
import Day from '../constants/Day';

export const getUnitCategoryName = (unit, slug, depth, defaultName = '-') => {
	if (!unit) return '-';

	let categoryBySlug = unit.categories?.find((category) => category.slug === slug);
	let categoryItemByDepth = categoryBySlug?.category_items.find((categoryItem) => categoryItem.depth === depth);
	return categoryItemByDepth?.name ?? defaultName;
};

export const getCategoryFilterTitle = (categoryFilter, slug, depth) => {
	if (categoryFilter.length === 0) return '';
	let filter = categoryFilter
		.find((category) => category.slug === slug)
		.filters.find((categoryFilter) => categoryFilter.depth === depth);
	return filter.title;
};

export const convertDateToStr = (date) => {
	if (date === null) return null;

	return (
		`${date ? date.getFullYear() : '-'}`.padStart(2, '0') +
		'-' +
		`${date ? date.getMonth() + 1 : '-'}`.padStart(2, '0') +
		'-' +
		`${date ? date.getDate() : '-'}`.padStart(2, '0')
	);
};

export const getUserLoginStr = (user) => {
	if (user.login_type === LoginType.COMMON) {
		return user.user_login;
	}

	if (user.user_login.indexOf('gn') === 0) {
		return user.user_login.substring(2);
	}

	return user.user_login;
};

export const checkRoomAdminRole = (user, unitId) => {
	if (!user) {
		return false;
	}

	// 전체 관리자
	if (user && UserRole.isSystemAdminRole(user.user_role)) {
		return true;
	}

	// 시설 관리자
	if (user && UserRole.isRoomAdminRole(user.user_role)) {
		return true;
	}

	// 예약 항목 관리자
	if (user && UserRole.isTargetRoomUnitAdmin(user.user_role, user.unit_admins, unitId)) {
		return true;
	}

	return false;
};

export const checkProductAdminRole = (user, unitId) => {
	if (!user) {
		return false;
	}

	// 전체 관리자
	if (user && UserRole.isSystemAdminRole(user.user_role)) {
		return true;
	}

	// 시설 관리자
	if (user && UserRole.isProductAdminRole(user.user_role)) {
		return true;
	}

	// 예약 항목 관리자
	if (user && UserRole.isTargetProductUnitAdmin(user.user_role, user.unit_admins, unitId)) {
		return true;
	}

	return false;
};

export const checkConsultAdminRole = (user, unitId) => {
	if (!user) {
		return false;
	}

	// 전체 관리자
	if (user && UserRole.isSystemAdminRole(user.user_role)) {
		return true;
	}

	// 상담 관리자
	if (user && UserRole.isConsultAdminRole(user.user_role)) {
		return true;
	}

	// 예약 항목 관리자
	if (user && UserRole.isTargetConsultUnitAdmin(user.user_role, user.unit_admins, unitId)) {
		return true;
	}

	return false;
};

/**
 * 두 개의 date가 며칠 차이인지 반환한다.
 * @param {Date} date1
 * @param {Date} date2
 */
export const getDayDifference = (date1, date2) => {
	const oneDay = 1000 * 60 * 60 * 24;
	const diffInTime = date1.getTime() - date2.getTime();
	const diffInDays = Math.ceil(diffInTime / oneDay);

	return Math.abs(diffInDays);
};

/**
 * 사용자의 선택한 날짜가 예약 신청 가능한 날짜가 맞는지 확인한다.
 * @param {Date} selectedDate 사용자가 선택한 날짜
 * @param {string} enableRequestDay 예약 신청 가능한 최대 일
 */
export const checkAvailableBookingDate = (selectedDate, enableRequestDay) => {
	// 오늘 날짜의 시작시간을 기준으로 날짜를 비교한다.
	const nowDate = new Date();
	nowDate.setHours(0);
	nowDate.setMinutes(0);
	nowDate.setSeconds(0);

	// 선택한 날짜는 UTC 시간 기준으로 되어 있으므로 시간을 0으로 맞춘다.
	const startOfBookingDate = new Date(selectedDate);
	startOfBookingDate.setHours(0);

	// 두 날짜의 차이를 구한다.
	const diffInDays = getDayDifference(startOfBookingDate, nowDate);

	// 두 날짜 차이를 기준으로 예약 가능여부를 판단한다.
	if (diffInDays < enableRequestDay) {
		return false;
	}

	return true;
};

/**
 * 예약한 날짜가 취소 가능한 날짜가 맞는지 확인한다.
 * @param {Date} bookingDate 예약한 날짜
 * @param {string} enableCancelDay 예약 취소 가능한 최대 일
 */
export const checkCancellableBookingDate = (bookingDate, enableCancelDay) => {
	// 오늘 날짜의 시작시간을 기준으로 날짜를 비교한다.
	const nowDate = new Date();
	nowDate.setHours(0);
	nowDate.setMinutes(0);
	nowDate.setSeconds(0);

	// 선택한 날짜는 UTC 시간 기준으로 되어 있으므로 시간을 0으로 맞춘다.
	const startOfBookingDate = new Date(bookingDate);
	startOfBookingDate.setHours(0);

	// 두 날짜의 차이를 구한다.
	const diffInDays = getDayDifference(startOfBookingDate, nowDate);

	// 두 날짜 차이를 기준으로 예약 가능여부를 판단한다.
	if (diffInDays < enableCancelDay) {
		return false;
	}

	return true;
};
/**
 * 텍스트에 있는 링크를 <a>로 감싸 하이퍼링크가 가능한 html로 변환해준다.
 * @param {string} text
 */
export const convertTextToLink = (text) => {
	const urlRegex = new RegExp(/(https?:\/\/[^\s]+)/, 'g');
	const convertLink = text.replace(urlRegex, (url) => {
		return '<a href="' + url + '" target="_blank">' + url + '</a>';
	});

	return { __html: convertLink };
};

export const getFirstDayOfMonth = (date) => {
	return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const getEndDayOfMonth = (date) => {
	return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export const convertParameterDate = (dateStr, timeStr) => {
	const date = new Date(dateStr);
	const [hours, minutes] = timeStr.split(':');

	date.setHours(hours);
	date.setMinutes(minutes);

	return date.toISOString();
};

/**
 * 요일에 해당하는 숫자 형식의 string array를 그에 맞는 요일로 변환하여 문자열 형식으로 반환
 * @param {Array<string>} weekdayStrArray ex. ["0", "1", "2", "3", "6"]
 * @returns {string} ex. 일, 월, 화, 수, 토
 */
export const getEnabledWeekdayStr = (weekdayStrArray) => {
	return (
		weekdayStrArray
			?.sort((a, b) => parseInt(a) - parseInt(b))
			?.map((weekday) => Day.toShortString(parseInt(weekday)))
			?.join(', ') ?? '-'
	);
};

/**
 * hh:mm 형식으로 된 문자열을 분단위 숫자로 변환해준다.
 * @param {string} timeStr
 */
export const timeToMinutes = (timeStr) => {
	const [hours, minutes] = timeStr.split(':').map(Number);
	return hours * 60 + minutes;
};

/**
 * 사용자가 예약하려는 시간대 범위가 시스템 상 예약이 불가능한 시간대 범위와 겹치는지에 대한 여부를 반환한다.
 * @param {string} startTime hh:mm 형식으로 된 문자열
 * @param {string} blockTime hh:mm 형식으로 된 문자열
 * @param {string} disableStartTime hh:mm 형식으로 된 문자열
 * @param {string} disableEndTime hh:mm 형식으로 된 문자열
 * @returns
 */
export const isBookingOverlappingWithDisabledTime = (startTime, blockTime, disableStartTime, disableEndTime) => {
	// 사용자가 예약하려는 시작 시간대(startMinutes), 종료 시간대(endMinutes)
	const startMinutes = timeToMinutes(startTime);
	const endMinutes = startMinutes + blockTime;

	// 시스템 상 예약이 불가능한 시작 시간대(disableStartMinutes), 종료 시간대(disableEndMinutes)
	const disableStartMinutes = timeToMinutes(disableStartTime);
	const disableEndMinutes = timeToMinutes(disableEndTime);

	return (
		// 사용자가 예약하려는 시작 시간대가 시스템 상 예약이 불가능한 범위에 속할 때
		(startMinutes >= disableStartMinutes && startMinutes < disableEndMinutes) ||
		// 사용자가 예약하려는 종료 시간대가 시스템 상 예약이 불가능한 범위에 속할 때
		(endMinutes > disableStartMinutes && endMinutes <= disableEndMinutes) ||
		// 사용자가 예약하려는 시간대가 시스템 상 예약이 불가능한 범위에 완전히 포함될 때
		(startMinutes <= disableStartMinutes && endMinutes >= disableEndMinutes)
	);
};
