import React from 'react';
import { Route } from 'react-router-dom';

import AdminLayout from '../layouts/AdminLayout';
import DashboardRoom from '../screens/admin/DashboardRoom';
import DashboardProduct from '../screens/admin/DashboardProduct';
import DashboardConsult from '../screens/admin/DashboardConsult';
import Calendar from '../screens/admin/Calendar';
import UnitRoomList from '../screens/admin/UnitRoomList';
import UnitProductList from '../screens/admin/UnitProductList';
import UnitConsultList from '../screens/admin/UnitConsultList';
import UnitRoom from '../screens/admin/UnitRoom';
import UnitProduct from '../screens/admin/UnitProduct';
import UnitConsult from '../screens/admin/UnitConsult';
import BookingRoomList from '../screens/admin/BookingRoomList';
import BookingProductList from '../screens/admin/BookingProductList';
import BookingConsultList from '../screens/admin/BookingConsultList';
import UserList from '../screens/admin/UserList';
import UserInfo from '../screens/admin/UserInfo';
import Setting from '../screens/admin/Setting';
import ItemCategorySetting from '../screens/admin/Setting/ItemCategorySetting';
import BoardCategorySetting from '../screens/admin/Setting/BoardCategorySetting';
import ScheduleSetting from '../screens/admin/Setting/ScheduleSetting';
import EmailNotificationSetting from '../screens/admin/Setting/EmailNotificationSetting';
import EmailNotificationList from '../screens/admin/Setting/EmailNotificationList';
import PopupSetting from '../screens/admin/Setting/PopupSetting';
import Popup from '../screens/admin/Setting/Popup';
import ConsultServiceSetting from '../screens/admin/Setting/ConsultServiceSetting';

import AdminSettingTab from '../constants/AdminSettingTab';
import AdminUnitListTab from '../constants/AdminUnitListTab';
import AdminBookingListTab from '../constants/AdminBookingListTab';
import AdminDashboardTab from '../constants/AdminDashboardTab';
import UnitType from '../constants/UnitType';

export default [
	<Route
		exact
		path="/admin"
		key="admin.dashboard"
		element={
			<AdminLayout tabs={AdminDashboardTab} tabIndex={0}>
				<DashboardRoom />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/dashboard/room"
		key="admin.dashboard.room"
		element={
			<AdminLayout tabs={AdminDashboardTab} tabIndex={0}>
				<DashboardRoom />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/dashboard/product"
		key="admin.dashboard.product"
		element={
			<AdminLayout tabs={AdminDashboardTab} tabIndex={1}>
				<DashboardProduct />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/dashboard/consult"
		key="admin.dashboard.consult"
		element={
			<AdminLayout tabs={AdminDashboardTab} tabIndex={2}>
				<DashboardConsult />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/calendar/room"
		key="admin.calendar.room"
		element={<Calendar unitType={UnitType.ROOM} />}
	/>,
	<Route
		exact
		path="/admin/calendar/product"
		key="admin.calendar.product"
		element={<Calendar unitType={UnitType.PRODUCT} />}
	/>,
	<Route
		exact
		path="/admin/calendar/consult"
		key="admin.calendar.consult"
		element={<Calendar unitType={UnitType.CONSULT} />}
	/>,
	<Route
		exact
		path="/admin/unit/rooms"
		key="admin.unit.rooms"
		element={
			<AdminLayout tabs={AdminUnitListTab} tabIndex={0}>
				<UnitRoomList />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/unit/products"
		key="admin.unit.products"
		element={
			<AdminLayout tabs={AdminUnitListTab} tabIndex={1}>
				<UnitProductList />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/unit/consults"
		key="admin.unit.consults"
		element={
			<AdminLayout tabs={AdminUnitListTab} tabIndex={2}>
				<UnitConsultList />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/unit/rooms/:room_id/edit"
		key="admin.unit.rooms.edit"
		element={
			<AdminLayout>
				<UnitRoom />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/unit/products/:product_id/edit"
		key="admin.unit.products.edit"
		element={
			<AdminLayout>
				<UnitProduct />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/unit/consults/:consult_id/edit"
		key="admin.unit.consults.edit"
		element={
			<AdminLayout>
				<UnitConsult />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/unit/rooms/create"
		key="admin.unit.rooms.create"
		element={
			<AdminLayout>
				<UnitRoom />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/unit/products/create"
		key="admin.unit.products.create"
		element={
			<AdminLayout>
				<UnitProduct />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/unit/consults/create"
		key="admin.unit.consults.create"
		element={
			<AdminLayout>
				<UnitConsult />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/booking/rooms"
		key="admin.booking.rooms"
		element={
			<AdminLayout tabs={AdminBookingListTab} tabIndex={0}>
				<BookingRoomList />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/booking/products"
		key="admin.booking.products"
		element={
			<AdminLayout tabs={AdminBookingListTab} tabIndex={1}>
				<BookingProductList />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/booking/consults"
		key="admin.booking.consults"
		element={
			<AdminLayout tabs={AdminBookingListTab} tabIndex={2}>
				<BookingConsultList />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/users"
		key="admin.users"
		element={
			<AdminLayout>
				<UserList />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/users/:user_id/edit"
		key="admin.users.edit"
		element={
			<AdminLayout>
				<UserInfo />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/users/create"
		key="admin.users.create"
		element={
			<AdminLayout>
				<UserInfo />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/settings"
		key="admin.setting"
		element={
			<AdminLayout>
				<Setting />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/settings/unit/room/categories"
		key="admin.setting.unit.room.categories"
		element={
			<AdminLayout tabs={AdminSettingTab} tabIndex={0}>
				<ItemCategorySetting unitType={UnitType.ROOM} />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/settings/unit/product/categories"
		key="admin.setting.unit.product.categories"
		element={
			<AdminLayout tabs={AdminSettingTab} tabIndex={1}>
				<ItemCategorySetting unitType={UnitType.PRODUCT} />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/settings/unit/consult/categories"
		key="admin.setting.unit.consult.categories"
		element={
			<AdminLayout tabs={AdminSettingTab} tabIndex={2}>
				<ItemCategorySetting unitType={UnitType.CONSULT} />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/settings/board/categories"
		key="admin.setting.board.categories"
		element={
			<AdminLayout tabs={AdminSettingTab} tabIndex={3}>
				<BoardCategorySetting />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/settings/schedules"
		key="admin.settings.schedules"
		element={
			<AdminLayout tabs={AdminSettingTab} tabIndex={4}>
				<ScheduleSetting />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/settings/notifications"
		key="admin.settings.notifications"
		element={
			<AdminLayout tabs={AdminSettingTab} tabIndex={5}>
				<EmailNotificationSetting />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/settings/notifications/:unit_type/list"
		key="admin.settings.notifications.list"
		element={
			<AdminLayout tabs={AdminSettingTab} tabIndex={5}>
				<EmailNotificationList />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/settings/popups"
		key="admin.settings.popups"
		element={
			<AdminLayout tabs={AdminSettingTab} tabIndex={6}>
				<PopupSetting />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/settings/popups/create"
		key="admin.settings.popups.create"
		element={
			<AdminLayout>
				<Popup />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/settings/popups/:popup_id/edit"
		key="admin.settings.popups.edit"
		element={
			<AdminLayout>
				<Popup />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/settings/consult"
		key="admin.settings.consult"
		element={
			<AdminLayout tabs={AdminSettingTab} tabIndex={7}>
				<ConsultServiceSetting />
			</AdminLayout>
		}
	/>,
];
