import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import useFilterHandler from '../../hooks/useFilterHandler';
import * as api from '../../apis';

import UnitType from '../../constants/UnitType';
import ApprovalMethod from '../../constants/ApprovalMethod';
import Day from '../../constants/Day';
import CategorySlug from '../../constants/CategorySlug';
import AdditionalMediaType from '../../constants/AdditionalMediaType';

import AdminSectionTitle from '../../components/admin/AdminSectionTitle';
import TimeSelect from '../../components/TimeSelect';
import AdditionalLinkModal from '../../components/modal/AdditionalLinkModal';
import AdminUserAddModal from '../../components/modal/AdminUserAddModal';
import LoadingIndicator from '../../components/LoadingIndicator';

import { Editor } from '@tinymce/tinymce-react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';

const UnitRoom = () => {
	const navigate = useNavigate();
	const { room_id } = useParams();
	const isEdit = room_id ? true : false;
	const unitType = UnitType.ROOM;
	const descriptionEditorRef = useRef();
	const locationEditorRef = useRef();
	const [additionalLinkModalData, setAdditionalLinkModalData] = useState({
		show: false,
	});
	const [adminUserAddModalData, setAdminUserAddModalData] = useState({ show: false });
	const [enableName, setEnableName] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		api.getSystemSchedules()
			.then((res) => {
				if (res && res.data) {
					let systemBlockScheduleList = res.data.filter((schedule) => schedule.allow_status === false);
					setSystemBlockDateList(systemBlockScheduleList);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	const onEditorImageUpload = (callback, value, meta) => {
		let input = document.createElement('input');
		input.setAttribute('type', 'file');
		input.setAttribute('accept', 'image/*');
		input.onchange = function () {
			let file = this.files[0];
			(async () => {
				let formData = new FormData();
				formData.append('file', file);
				api.createFile(formData)
					.then((res) => {
						let url = `${process.env.REACT_APP_API_URL}/storage/files/${res.data.filename}`;
						callback(url, { alt: file.name, title: file.name });
					})
					.catch((err) => {
						console.error(err);
					});
			})();
		};
		input.click();
	};

	const { categoryFilter, publishStatusFilter } = useFilterHandler({
		onFetchCompleted: () => {
			if (isEdit) {
				api.getUnit(room_id)
					.then((res) => {
						if (res && res.data) {
							setEditData(res.data);
						}
					})
					.catch((err) => {
						console.error(err);
						alert('시설 정보에 문제가 발생하였습니다.');
						navigate(-1);
					});
				return;
			}

			// Filter를 받아온 다음 기본 분류를 설정한다.
			// 대학, 캠퍼스, 건물
			let defaultPlaceCategoryIdDepth1 = setDefaultCategoryId(
				CategorySlug.PLACE,
				1,
				null,
				setPlaceCategoryIdDepth1
			);
			let defaultPlaceCategoryIdDepth2 = setDefaultCategoryId(
				CategorySlug.PLACE,
				2,
				defaultPlaceCategoryIdDepth1,
				setPlaceCategoryIdDepth2
			);
			setDefaultCategoryId(CategorySlug.PLACE, 3, defaultPlaceCategoryIdDepth2, setPlaceCategoryIdDepth3);

			// 시설구분, 시설유형
			let defaultTypeCategoryIdDepth1 = setDefaultCategoryId(CategorySlug.TYPE, 1, null, setTypeCategoryIdDepth1);
			setDefaultCategoryId(CategorySlug.TYPE, 2, defaultTypeCategoryIdDepth1, setTypeCategoryIdDepth2);
		},
	});

	// 분류: 대학 > 캠퍼스 > 건물
	const [placeCategoryIdDepth1, setPlaceCategoryIdDepth1] = useState(null);
	const [placeCategoryIdDepth2, setPlaceCategoryIdDepth2] = useState(null);
	const [placeCategoryIdDepth3, setPlaceCategoryIdDepth3] = useState(null);

	// 분류: 시설 구분 > 시설 유형
	const [typeCategoryIdDepth1, setTypeCategoryIdDepth1] = useState(null);
	const [typeCategoryIdDepth2, setTypeCategoryIdDepth2] = useState(null);

	// 시설명
	const [name, setName] = useState('');

	// 호실 관리
	const [roomItemList, setRoomItemList] = useState([]);

	// 공개 상태
	const [publishStatus, setPublishStatus] = useState(publishStatusFilter[0].id);

	// 승인 방식
	const [approvalMethod, setApprovalMethod] = useState(ApprovalMethod.AUTO);

	// 최대 사용 인원 설정
	const [maxUsageCount, setMaxUsageCount] = useState(1);

	// 예약 문의 번호
	const [contactNumber, setContactNumber] = useState('');

	// 이용 기간 단위 설정
	const [minUsageTime, setMinUsageTime] = useState(30);
	const [minUsageTimeUnit, setMinUsageTimeUnit] = useState(1);
	const [maxUsageTime, setMaxUsageTime] = useState(2);
	const [maxUsageTimeUnit, setMaxUsageTimeUnit] = useState(60);
	const [blockTime, setBlockTime] = useState(30);
	const [blockTimeUnit, setBlockTimeUnit] = useState(1);
	const [refreshTime, setRefreshTime] = useState(30);
	const [refreshTimeUnit, setRefreshTimeUnit] = useState(1);

	// 이용 기간 설정 > 기본 기간 설정
	const [usageStartDate, setUsageStartDate] = useState(null);
	const [usageEndDate, setUsageEndDate] = useState(null);
	const [selectedBookingDayList, setSelectedBookingDayList] = useState([]);

	// 이용 기간 설정 > 제외 기간 설정
	const [blockStartDate, setBlockStartDate] = useState(null);
	const [blockEndDate, setBlockEndDate] = useState(null);
	const [blockDateReason, setBlockDateReason] = useState('');
	const [blockDateList, setBlockDateList] = useState([]);
	const [systemBlockDateList, setSystemBlockDateList] = useState([]);

	// 이용 시간 설정 > 기본 시간 설정
	const [bookingStartTime, setBookingStartTime] = useState(null);
	const [bookingEndTime, setBookingEndTime] = useState(null);

	// 이용 기간 설정 > 제외 시간 설정
	const [blockStartTime, setBlockStartTime] = useState(null);
	const [blockEndTime, setBlockEndTime] = useState(null);
	const [blockTimeReason, setBlockTimeReason] = useState('');
	const [blockTimeList, setBlockTimeList] = useState([]);

	// 이용 기간 설정 > 신청 가능 시간 설정
	const [bookingApplicableDate, setBookingApplicableDate] = useState(1);

	// 이용 기간 설정 > 취소 가능 시간 설정
	const [bookingCancellableDate, setBookingCancellableDate] = useState(1);

	// 대표 이미지
	const [thumbnailImage, setThumbnailImage] = useState(null);
	const [thumbnailImageFile, setThumbnailImageFile] = useState(null);

	// 시설소개
	const [shortDescription, setShortDescription] = useState('');

	// 사용용도
	const [purpose, setPurpose] = useState('');

	// 설치장비
	const [equipment, setEquipment] = useState('');

	// 상세 설명
	const [description, setDescription] = useState('');
	const [defaultDescription, setDefaultDescription] = useState('');

	// 위치
	const [location, setLocation] = useState('');
	const [defaultLocation, setDefaultLocation] = useState('');

	// 추가 이미지, 영상 링크
	const [additionalMediaIndex, setAdditionalMediaIndex] = useState(0);
	const [additionalMediaList, setAdditionalMediaList] = useState([]);
	const [additionalMediaImageFileList, setAdditionalMediaImageFileList] = useState([]);

	// 시설 관리자
	const [adminList, setAdminList] = useState([]);

	const setEditData = (data) => {
		let unitCategories = data?.categories;
		setPlaceCategoryIdDepth1(getCategoryItemId(unitCategories, CategorySlug.PLACE, 1));
		setPlaceCategoryIdDepth2(getCategoryItemId(unitCategories, CategorySlug.PLACE, 2));
		setPlaceCategoryIdDepth3(getCategoryItemId(unitCategories, CategorySlug.PLACE, 3));
		setTypeCategoryIdDepth1(getCategoryItemId(unitCategories, CategorySlug.TYPE, 1));
		setTypeCategoryIdDepth2(getCategoryItemId(unitCategories, CategorySlug.TYPE, 2));

		setName(data?.name);
		setEnableName(true);
		setRoomItemList(data?.room_items);
		setPublishStatus(data?.published ? 1 : 2);
		setApprovalMethod(data?.approval_method);
		setMaxUsageCount(data?.room_detail?.max_usage_count);
		setContactNumber(data?.contact_number ?? '');
		let minUsageTime = data?.enable_schedule?.min_usage_time;
		let minUsageTimeUnit = minUsageTime < 60 ? 1 : minUsageTime < 1440 ? 60 : 1440;
		minUsageTime = parseInt(minUsageTime / minUsageTimeUnit);
		setMinUsageTime(minUsageTime);
		setMinUsageTimeUnit(minUsageTimeUnit);
		let maxUsageTime = data?.enable_schedule?.max_usage_time;
		let maxUsageTimeUnit = maxUsageTime < 60 ? 1 : maxUsageTime < 1440 ? 60 : 1440;
		maxUsageTime = parseInt(maxUsageTime / maxUsageTimeUnit);
		setMaxUsageTime(maxUsageTime);
		setMaxUsageTimeUnit(maxUsageTimeUnit);
		let blockTime = data?.enable_schedule?.block_time;
		let blockTimeUnit = blockTime < 60 ? 1 : blockTime < 1440 ? 60 : 1440;
		blockTime = parseInt(blockTime / blockTimeUnit);
		setBlockTime(blockTime);
		setBlockTimeUnit(blockTimeUnit);
		let refreshTime = data?.enable_schedule?.refresh_time;
		let refreshTimeUnit = refreshTime < 60 ? 1 : refreshTime < 1440 ? 60 : 1440;
		refreshTime = parseInt(refreshTime / refreshTimeUnit);
		setRefreshTime(refreshTime);
		setRefreshTimeUnit(refreshTimeUnit);
		setUsageStartDate(data?.enable_schedule?.usage_start_date);
		setUsageEndDate(data?.enable_schedule?.usage_end_date);
		setSelectedBookingDayList(data?.enable_schedule?.booking_weekday.map((val) => parseInt(val)));
		setBlockDateList(data?.disable_schedules);
		setBookingStartTime(data?.enable_schedule?.booking_start_time);
		setBookingEndTime(data?.enable_schedule?.booking_end_time);
		setBlockTimeList(
			data?.disable_schedule_times.map((scheduleTime) => {
				return {
					name: scheduleTime.name,
					start_time: scheduleTime.start_time,
					end_time: scheduleTime.end_time,
				};
			})
		);
		setBookingApplicableDate(data?.restrict_day?.enable_request_day_before ?? 0);
		setBookingCancellableDate(data?.restrict_day?.enable_cancel_day_before ?? 0);
		setThumbnailImage(data?.thumbnail);
		setShortDescription(data?.short_description ?? '');
		setPurpose(data?.purpose ?? '');
		setEquipment(data?.equipment ?? '');
		setDescription(data?.description ?? '');
		setDefaultDescription(data?.description ?? '');
		setLocation(data?.location ?? '');
		setDefaultLocation(data?.location ?? '');
		setAdditionalMediaList(
			data?.additional_medias.map((media, idx) => {
				return {
					...media,
					index: idx,
				};
			})
		);
		setAdditionalMediaIndex(data?.additional_medias.length);
		setAdminList(data?.unit_admins);
	};

	const getCategoryItemId = (categories, slug, depth) => {
		let targetCategory = categories.find((category) => category.slug === slug);
		let categoryItemId =
			targetCategory.category_items.find((categoryItem) => categoryItem.depth === depth)?.id ?? null;
		return categoryItemId;
	};

	const setDefaultCategoryId = (slug, depth, parentItemId, setter) => {
		let targetCategory = categoryFilter.find((category) => category.slug === slug);
		let targetCategoryFilter = targetCategory.filters.find((filter) => filter.depth === depth);
		let defaultCategoryItemId =
			targetCategoryFilter.category_items.find((categoryItem) => categoryItem.parent_item_id === parentItemId)
				?.id ?? null;
		setter(defaultCategoryItemId);
		return defaultCategoryItemId;
	};

	const onClickNameCheck = () => {
		if (!name) {
			alert('시설명을 입력하여 주십시오.');
			return;
		}
		api.checkUnitExists({ unit_type: UnitType.ROOM, unit_id: room_id ?? null, name })
			.then((res) => {
				if (res && res.status === 204) {
					setEnableName(true);
					alert('사용 가능한 이름입니다.');
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const onClickAddRoomItem = () => {
		setRoomItemList([...roomItemList, { name: '' }]);
	};

	const onChangeThumbnailImageFile = (e) => {
		if (e.currentTarget.files.length > 0) {
			setThumbnailImageFile(e.currentTarget.files[0]);
		}
	};

	const onClickRemoveThumbnail = () => {
		setThumbnailImage(null);
		setThumbnailImageFile(null);
	};

	const onClickDayItem = (selected) => {
		if (selectedBookingDayList.some((val) => val === selected)) {
			setSelectedBookingDayList(selectedBookingDayList.filter((val) => val !== selected));
		} else {
			setSelectedBookingDayList([...selectedBookingDayList, selected]);
		}
	};

	const onClickBlockDateItemAddBtn = () => {
		if (blockStartDate && blockEndDate && blockDateReason) {
			if (blockStartDate > blockEndDate) {
				alert('제외기간 시작일은 종료일 이후일 수 없습니다.');
				return;
			}
			blockDateList.push({ name: blockDateReason, start_date: blockStartDate, end_date: blockEndDate });
			setBlockStartDate(null);
			setBlockEndDate(null);
			setBlockDateReason('');
		} else {
			alert('정보를 모두 입력해 주세요.');
		}
	};

	const onClickBlockTimeItemAddBtn = () => {
		if (blockStartTime && blockEndTime && blockTimeReason) {
			if (blockStartTime > blockEndTime) {
				alert('제외시간 시작 시간은 종료 시간 이후일 수 없습니다.');
				return;
			}
			blockTimeList.push({ name: blockTimeReason, start_time: blockStartTime, end_time: blockEndTime });
			setBlockStartTime(null);
			setBlockEndTime(null);
			setBlockTimeReason('');
		} else {
			alert('정보를 모두 입력해 주세요.');
		}
	};

	const onChangeAdditionalImageFile = (e) => {
		if (e.currentTarget.files.length > 0) {
			let newAdditionalMediaImageFileList = [...additionalMediaImageFileList];
			newAdditionalMediaImageFileList.push({
				index: additionalMediaIndex,
				imagefile: e.currentTarget.files[0],
			});
			setAdditionalMediaImageFileList(newAdditionalMediaImageFileList);

			let newAdditionalMediaList = [...additionalMediaList];
			newAdditionalMediaList.push({
				index: additionalMediaIndex,
				type: AdditionalMediaType.IMAGE,
				imagefile: e.currentTarget.files[0],
				link: null,
			});
			setAdditionalMediaList(newAdditionalMediaList);
			setAdditionalMediaIndex(additionalMediaIndex + 1);
		}
	};

	const onClickRemoveAdditionalImage = (mediaIndex) => {
		setAdditionalMediaImageFileList(additionalMediaImageFileList.filter((media) => media.index !== mediaIndex));
		setAdditionalMediaList(additionalMediaList.filter((media) => media.index !== mediaIndex));
	};

	const onAddAdditionalLink = (link) => {
		let newAdditionalMediaList = [...additionalMediaList];
		newAdditionalMediaList.push({
			index: additionalMediaIndex,
			type: AdditionalMediaType.LINK,
			imagefile: null,
			link: link,
		});
		setAdditionalMediaList(newAdditionalMediaList);
		setAdditionalMediaIndex(additionalMediaIndex + 1);
	};

	const onClickRemoveAdditionalLink = (mediaIndex) => {
		setAdditionalMediaList(additionalMediaList.filter((media) => media.index !== mediaIndex));
	};

	const onClickSave = () => {
		const selectedCategoryItems = [
			placeCategoryIdDepth1,
			placeCategoryIdDepth2,
			placeCategoryIdDepth3,
			typeCategoryIdDepth1,
			typeCategoryIdDepth2,
		].filter((categoryItem) => categoryItem !== null);

		const data = {
			unit_type: unitType,
			name: name,
			published: publishStatus === 1 ? true : false,
			approval_method: approvalMethod,
			contact_number: contactNumber,
			description: description ?? '',
			short_description: shortDescription ?? '',
			purpose: purpose ?? '',
			equipment: equipment ?? '',
			location: location ?? '',
			category_items: selectedCategoryItems,
			enable_schedule: {
				min_usage_time: minUsageTime * minUsageTimeUnit,
				max_usage_time: maxUsageTime * maxUsageTimeUnit,
				block_time: blockTime * blockTimeUnit,
				refresh_time: refreshTime * refreshTimeUnit,
				usage_start_date: usageStartDate,
				usage_end_date: usageEndDate,
				booking_start_time: bookingStartTime,
				booking_end_time: bookingEndTime,
				booking_weekday: selectedBookingDayList.join(','),
			},
			enable_request_day_before: Number(bookingApplicableDate),
			enable_cancel_day_before: Number(bookingCancellableDate),
			room_detail: {
				max_usage_count: maxUsageCount,
			},
			room_items: roomItemList,
			disable_schedules: blockDateList.map((blockItem) => {
				return {
					name: blockItem.name,
					start_date: blockItem.start_date,
					end_date: blockItem.end_date,
				};
			}),
			disable_schedule_times: blockTimeList.map((blockItem) => {
				return {
					name: blockItem.name,
					start_time: blockItem.start_time,
					end_time: blockItem.end_time,
				};
			}),
			thumbnail_file: thumbnailImageFile,
			additional_medias: additionalMediaList.map((media) => {
				return {
					id: media.id ?? null,
					type: media.type,
					link: media.link,
					imagefile: media.imagefile,
				};
			}),
			unit_admins: adminList.map((adminUser) => {
				return {
					id: adminUser.id,
				};
			}),
		};

		if (!validateData(data, isEdit)) {
			return;
		}

		if (isEdit) {
			setIsLoading(true);
			api.updateUnit(room_id, data)
				.then((res) => {
					if (res && res.data) {
						alert('수정되었습니다.');
						navigate('/admin/unit/rooms');
					}
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			setIsLoading(true);
			api.createUnit(data)
				.then((res) => {
					if (res && res.data) {
						alert('저장되었습니다.');
						navigate('/admin/unit/rooms');
					}
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	const validateData = (data, isEdit) => {
		if (!data.name) {
			alert('시설명을 입력하여 주십시오.');
			return false;
		}
		if (!enableName) {
			alert('시설명 중복확인을 진행하여 주십시오.');
			return false;
		}
		if (!data.room_items || data.room_items.length < 1) {
			alert('호실을 추가하여 주십시오.');
			return false;
		}
		if (data.room_items.some((roomItem) => roomItem.name == '')) {
			alert('호실 관리 항목에서 호실명을 입력해주세요.');
			return false;
		}
		if (!data.enable_schedule.usage_start_date) {
			alert('예약가능 시작일을 입력하여 주십시오.');
			return false;
		}
		if (!data.enable_schedule.usage_end_date) {
			alert('예약가능 종료일을 입력하여 주십시오.');
			return false;
		}
		if (data.enable_schedule.usage_start_date > data.enable_schedule.usage_end_date) {
			alert('예약가능 시작일은 종료일 이후일 수 없습니다.');
			return;
		}
		if (!data.enable_schedule.booking_weekday || data.enable_schedule.booking_weekday.length < 1) {
			alert('예약가능 요일을 선택하여 주십시오.');
			return false;
		}
		if (!data.enable_schedule.booking_start_time) {
			alert('예약가능 시작 시간을 입력하여 주십시오.');
			return false;
		}
		if (!data.enable_schedule.booking_end_time) {
			alert('예약가능 마침 시간을 입력하여 주십시오.');
			return false;
		}
		if (data.enable_schedule.booking_start_time > data.enable_schedule.booking_end_time) {
			alert('예약가능 시작 시간은 마침 시간 이후일 수 없습니다.');
			return;
		}
		if (!isEdit && !data.thumbnail_file) {
			alert('대표이미지를 선택하여 주십시오.');
			return false;
		}

		return true;
	};

	const onAddAdminUser = (adminUser) => {
		setAdminList([...adminList, adminUser]);
	};

	const onClickDeleteAdmin = (userLogin) => {
		setAdminList(adminList.filter((admin) => admin.user_login !== userLogin));
	};

	const renderCategory = () => {
		return (
			<>
				{renderCategorySelect(CategorySlug.PLACE, 1, placeCategoryIdDepth1, null, true, (categoryId) => {
					setPlaceCategoryIdDepth1(categoryId);
					let defaultPlaceCategoryIdDepth2 = setDefaultCategoryId(
						CategorySlug.PLACE,
						2,
						categoryId,
						setPlaceCategoryIdDepth2
					);

					// 2depth 카테고리가 없으면 3depth는 없다.
					if (!defaultPlaceCategoryIdDepth2) {
						setPlaceCategoryIdDepth3(null);
					} else {
						setDefaultCategoryId(
							CategorySlug.PLACE,
							3,
							defaultPlaceCategoryIdDepth2,
							setPlaceCategoryIdDepth3
						);
					}
				})}
				{renderCategorySelect(
					CategorySlug.PLACE,
					2,
					placeCategoryIdDepth2,
					placeCategoryIdDepth1,
					false,
					(categoryId) => {
						setPlaceCategoryIdDepth2(categoryId);
						setDefaultCategoryId(CategorySlug.PLACE, 3, categoryId, setPlaceCategoryIdDepth3);
					}
				)}
				{renderCategorySelect(
					CategorySlug.PLACE,
					3,
					placeCategoryIdDepth3,
					placeCategoryIdDepth2,
					false,
					(categoryId) => {
						setPlaceCategoryIdDepth3(categoryId);
					}
				)}
				{renderCategorySelect(CategorySlug.TYPE, 1, typeCategoryIdDepth1, null, true, (categoryId) => {
					setTypeCategoryIdDepth1(categoryId);
					setDefaultCategoryId(CategorySlug.TYPE, 2, categoryId, setTypeCategoryIdDepth2);
				})}
				{renderCategorySelect(
					CategorySlug.TYPE,
					2,
					typeCategoryIdDepth2,
					typeCategoryIdDepth1,
					true,
					(categoryId) => {
						setTypeCategoryIdDepth2(categoryId);
					}
				)}
			</>
		);
	};

	const renderCategorySelect = (slug, depth, value, parentItemId, required, onCategoryChange) => {
		if (categoryFilter.length === 0) return;
		let targetCategory = categoryFilter.find((category) => category.slug === slug);
		let targetCategoryFilter = targetCategory.filters.find((filter) => filter.depth === depth);
		let targetCategoryItems = targetCategoryFilter.category_items.filter(
			(categoryItem) => categoryItem.parent_item_id === parentItemId
		);

		return (
			<BasicInfoRow>
				<BasicInfoTitle required={required}>{targetCategoryFilter.title}</BasicInfoTitle>
				<BasicInfoContents>
					<BasicInfoSelect
						value={value ?? ''}
						onChange={(e) => onCategoryChange(parseInt(e.currentTarget.value))}
					>
						{targetCategoryItems.length === 0 && <option>-</option>}
						{targetCategoryItems.length !== 0 && renderCategoryItems(targetCategoryItems, value)}
					</BasicInfoSelect>
				</BasicInfoContents>
			</BasicInfoRow>
		);
	};

	const renderCategoryItems = (targetCategoryItems, value) => {
		return (
			<>
				{value === null && <option value={null}>-</option>}
				{targetCategoryItems.map((categoryItem, idx) => {
					return (
						<option key={idx} value={categoryItem.id}>
							{categoryItem.name ?? '-'}
						</option>
					);
				})}
			</>
		);
	};

	const renderRadioGroup = (id, data, key, value, valueSetter) => {
		return (
			<RadioBoxGroup key={key}>
				<input
					type="radio"
					id={id}
					name={id}
					checked={data.id === value}
					onChange={(e) => {
						valueSetter(data.id);
					}}
				/>
				<label htmlFor={id}>{data.name ?? '-'}</label>
			</RadioBoxGroup>
		);
	};

	const renderAvailableUnitInput = (val, setter, unitVal, unitSetter) => {
		return (
			<AvailableUnitInputGroup>
				<input type="number" value={val} onChange={(e) => setter(e.currentTarget.value)} />
				<select value={unitVal} onChange={(e) => unitSetter(e.currentTarget.value)}>
					<option value={1}>분</option>
					<option value={60}>시간</option>
					<option value={24 * 60}>일</option>
				</select>
			</AvailableUnitInputGroup>
		);
	};

	const renderBlockDateList = (list, setter) => {
		return list.map((item, idx) => {
			return (
				<BlockItemContainer key={idx}>
					<BlockItemText>{`${idx + 1}. ${item.name ?? '-'} / ${item.start_date ?? '-'} ~ ${
						item.end_date ?? '-'
					}`}</BlockItemText>
					{setter && (
						<BlockItemDeleteButton
							onClick={() => {
								setter(list.filter((val, i) => i !== idx));
							}}
						>
							삭제
						</BlockItemDeleteButton>
					)}
				</BlockItemContainer>
			);
		});
	};

	const renderBlockTimeList = (list, setter) => {
		return list.map((item, idx) => {
			let timeStr = `${item.start_time} ~ ${item.end_time}`;
			return (
				<BlockItemContainer key={idx}>
					<BlockItemText>{`${idx + 1}. ${item.name ?? '-'} / ${timeStr}`}</BlockItemText>
					<BlockItemDeleteButton
						onClick={() => {
							setter(list.filter((val, i) => i !== idx));
						}}
					>
						삭제
					</BlockItemDeleteButton>
				</BlockItemContainer>
			);
		});
	};

	const renderThumbnail = () => {
		if (thumbnailImage) {
			return (
				<>
					<ThumbnailImageContainer>
						<ThumbnailImage
							src={`${process.env.REACT_APP_API_URL}/storage/files/${thumbnailImage.filename}`}
						/>
						<StyledCloseIcon onClick={onClickRemoveThumbnail} />
					</ThumbnailImageContainer>
					<ThumbnailImageDescContainer>
						<ImageTitle>대표이미지</ImageTitle>
						<ImageDescription>5MB 이하 / 704px*396px</ImageDescription>
					</ThumbnailImageDescContainer>
				</>
			);
		}

		if (thumbnailImageFile) {
			return (
				<>
					<ThumbnailImageContainer>
						<ThumbnailImage src={URL.createObjectURL(thumbnailImageFile)} />
						<StyledCloseIcon onClick={onClickRemoveThumbnail} />
					</ThumbnailImageContainer>
					<ThumbnailImageDescContainer>
						<ImageTitle>대표이미지</ImageTitle>
						<ImageDescription>5MB 이하 / 704px*396px</ImageDescription>
					</ThumbnailImageDescContainer>
				</>
			);
		}

		return (
			<>
				<ImageAddButton onClick={() => document.getElementById('thumbnailFile').click()}>
					<ImageAddButtonPlus>+</ImageAddButtonPlus>
				</ImageAddButton>
				<ThumbnailImageDescContainer>
					<ImageTitle>대표이미지</ImageTitle>
					<ImageDescription>5MB 이하 / 704px*396px</ImageDescription>
				</ThumbnailImageDescContainer>
				<input id="thumbnailFile" type="file" accept="image/*" hidden onChange={onChangeThumbnailImageFile} />
			</>
		);
	};

	const renderAdditionalImage = (media) => {
		if (media.image) {
			return (
				<>
					<ThumbnailImageContainer>
						<ThumbnailImage
							src={`${process.env.REACT_APP_API_URL}/storage/files/${media.image.filename}`}
						/>
						<StyledCloseIcon onClick={() => onClickRemoveAdditionalImage(media.index)} />
					</ThumbnailImageContainer>
					<ImageTitle className="mt-5px">이미지</ImageTitle>
					<ImageDescription>5MB 이하 / 704px*396px</ImageDescription>
				</>
			);
		}

		return (
			<>
				<ThumbnailImageContainer>
					<ThumbnailImage src={URL.createObjectURL(media.imagefile)} />
					<StyledCloseIcon onClick={() => onClickRemoveAdditionalImage(media.index)} />
				</ThumbnailImageContainer>
				<ImageTitle className="mt-5px">이미지</ImageTitle>
				<ImageDescription>5MB 이하 / 704px*396px</ImageDescription>
			</>
		);
	};

	const renderAdditionalLink = (media) => {
		return (
			<>
				<AdditionalLinkContainer>
					<LinkText>{media.link}</LinkText>
					<StyledCloseIcon onClick={() => onClickRemoveAdditionalLink(media.index)} />
				</AdditionalLinkContainer>
				<ImageTitle className="mt-5px">동영상</ImageTitle>
				<ImageDescription>{media.link}</ImageDescription>
			</>
		);
	};

	return (
		<>
			<LoadingIndicator size={50} loading={isLoading} />
			<Content>
				<TitleText>{isEdit ? '시설 수정' : '시설 등록'}</TitleText>
				<section className="mt-40px">
					<AdminSectionTitle title="시설 기본 정보" />
				</section>
				<section>
					<BasicInfoContainer className="mt-24px">
						<BasicInfoRow>
							<BasicInfoTitle>예약 유형</BasicInfoTitle>
							<BasicInfoContents>시설</BasicInfoContents>
						</BasicInfoRow>
						{renderCategory()}
						<BasicInfoRow>
							<BasicInfoTitle required>시설명</BasicInfoTitle>
							<BasicInfoContents>
								<BasicInfoTextInput
									type="text"
									value={name}
									onChange={(e) => {
										setName(e.currentTarget.value);
										setEnableName(false);
									}}
								/>
								<NameCheckButton onClick={onClickNameCheck}>중복확인</NameCheckButton>
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle required>호실 관리</BasicInfoTitle>
							<BasicInfoContents>
								<RoomItemListContainer>
									{roomItemList.map((data, idx) => {
										return (
											<RoomItemContainer key={idx}>
												<input
													type="text"
													value={data.name}
													onChange={(e) =>
														setRoomItemList(
															roomItemList.map((val, valIdx) => {
																if (idx === valIdx) {
																	return { ...val, name: e.currentTarget.value };
																} else {
																	return val;
																}
															})
														)
													}
												/>
												<RoomItemDeleteButton
													onClick={() =>
														setRoomItemList(
															roomItemList.filter((val, valIdx) => idx !== valIdx)
														)
													}
												>
													x
												</RoomItemDeleteButton>
											</RoomItemContainer>
										);
									})}
								</RoomItemListContainer>
								<AddRoomItemButton onClick={onClickAddRoomItem}>호실 추가</AddRoomItemButton>
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle required>공개상태</BasicInfoTitle>
							<BasicInfoContents>
								<RadioContainer>
									{publishStatusFilter.map((data, idx) => {
										const id = `publish_${data.id}_${idx}`;
										return renderRadioGroup(id, data, idx, publishStatus, setPublishStatus);
									})}
								</RadioContainer>
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle required>승인방식</BasicInfoTitle>
							<BasicInfoContents>
								<RadioContainer>
									{renderRadioGroup(
										`${ApprovalMethod.AUTO}_0`,
										{ id: ApprovalMethod.AUTO, name: ApprovalMethod.toString(ApprovalMethod.AUTO) },
										0,
										approvalMethod,
										setApprovalMethod
									)}
									{renderRadioGroup(
										`${ApprovalMethod.MANUAL}_1`,
										{
											id: ApprovalMethod.MANUAL,
											name: ApprovalMethod.toString(ApprovalMethod.MANUAL),
										},
										1,
										approvalMethod,
										setApprovalMethod
									)}
								</RadioContainer>
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle required>최대 사용 인원 설정</BasicInfoTitle>
							<BasicInfoContents>
								<MaxUsageCountContainer>
									<input
										type="number"
										value={maxUsageCount}
										onChange={(e) => setMaxUsageCount(e.currentTarget.value)}
									/>
									명
								</MaxUsageCountContainer>
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle>예약관련 문의번호</BasicInfoTitle>
							<BasicInfoContents>
								<ContactNumberContainer>
									<TextInput
										type="text"
										value={contactNumber}
										onChange={(e) => setContactNumber(e.currentTarget.value)}
									/>
								</ContactNumberContainer>
							</BasicInfoContents>
						</BasicInfoRow>
					</BasicInfoContainer>
				</section>
				<section className="mt-50px">
					<AdminSectionTitle title="이용 기간 및 시간 정보" />
				</section>
				<section className="mt-34px">
					<BasicInfoContainer>
						<BasicInfoRow>
							<BasicInfoTitle required>이용기간 단위 설정</BasicInfoTitle>
							<BasicInfoContents flexCol>
								<Row>
									<AvailableUnitCol>
										<AvailableUnitTitle>최소사용시간</AvailableUnitTitle>
										{renderAvailableUnitInput(
											minUsageTime,
											setMinUsageTime,
											minUsageTimeUnit,
											setMinUsageTimeUnit
										)}
									</AvailableUnitCol>
									<AvailableUnitCol>
										<AvailableUnitTitle>최대사용시간</AvailableUnitTitle>
										{renderAvailableUnitInput(
											maxUsageTime,
											setMaxUsageTime,
											maxUsageTimeUnit,
											setMaxUsageTimeUnit
										)}
									</AvailableUnitCol>
								</Row>
								<Row className="mt-15px">
									<AvailableUnitCol>
										<AvailableUnitTitle>시간추가단위</AvailableUnitTitle>
										{renderAvailableUnitInput(
											blockTime,
											setBlockTime,
											blockTimeUnit,
											setBlockTimeUnit
										)}
									</AvailableUnitCol>
									<AvailableUnitCol>
										<AvailableUnitTitle>재정비시간</AvailableUnitTitle>
										{renderAvailableUnitInput(
											refreshTime,
											setRefreshTime,
											refreshTimeUnit,
											setRefreshTimeUnit
										)}
									</AvailableUnitCol>
								</Row>
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle required>기본 기간 설정</BasicInfoTitle>
							<BasicInfoContents flexCol>
								<Row>
									<UnitScheduleTitle required>예약 가능 기간</UnitScheduleTitle>
									<DateContainer>
										<DateWrapper>
											<DateContainerText>시작일</DateContainerText>
											<DateInput
												type="date"
												value={usageStartDate ?? ''}
												onChange={(e) => setUsageStartDate(e.currentTarget.value)}
											/>
										</DateWrapper>
										<DateInputSeparator>~</DateInputSeparator>
										<DateWrapper>
											<DateContainerText>종료일</DateContainerText>
											<DateInput
												type="date"
												value={usageEndDate ?? ''}
												onChange={(e) => setUsageEndDate(e.currentTarget.value)}
											/>
										</DateWrapper>
									</DateContainer>
								</Row>
								<Row className="mt-10px">
									<UnitScheduleTitle required>예약 가능 요일</UnitScheduleTitle>
									<DayContainer>
										{Array.from(Array(7).keys()).map((val, idx) => {
											return (
												<DayCircle
													key={idx}
													onClick={(e) => onClickDayItem(val)}
													selected={selectedBookingDayList.some((data) => data === val)}
												>
													{Day.toShortString(val)}
												</DayCircle>
											);
										})}
									</DayContainer>
								</Row>
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle>제외 기간 설정</BasicInfoTitle>
							<BasicInfoContents>
								<BlockDateContainer>
									<DateInput
										type="date"
										value={blockStartDate ?? ''}
										onChange={(e) => setBlockStartDate(e.currentTarget.value)}
									/>
									<DateInputSeparator>~</DateInputSeparator>
									<DateInput
										type="date"
										value={blockEndDate ?? ''}
										onChange={(e) => setBlockEndDate(e.currentTarget.value)}
									/>
									<TextInput
										className="ml-10px"
										type="text"
										value={blockDateReason}
										onChange={(e) => setBlockDateReason(e.currentTarget.value)}
										placeholder="사유"
									/>
									<AddBlockDateButton onClick={onClickBlockDateItemAddBtn}>추가</AddBlockDateButton>
								</BlockDateContainer>
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle>추가된 제외 기간</BasicInfoTitle>
							<BasicInfoContents>
								<BlockListContainer>
									{renderBlockDateList(blockDateList, setBlockDateList)}
								</BlockListContainer>
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle>시스템 설정 제외 기간</BasicInfoTitle>
							<BasicInfoContents>
								<BlockListContainer>
									{renderBlockDateList(systemBlockDateList, null)}
								</BlockListContainer>
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle required>기본 시간 설정</BasicInfoTitle>
							<BasicInfoContents>
								<UnitScheduleTitle required>예약 가능 시간</UnitScheduleTitle>
								<DateContainer>
									<DateWrapper>
										<TimeSelect
											time={bookingStartTime}
											onChange={(value) => setBookingStartTime(value)}
										></TimeSelect>
									</DateWrapper>
									<DateInputSeparator>~</DateInputSeparator>
									<DateWrapper>
										<TimeSelect
											time={bookingEndTime}
											onChange={(value) => setBookingEndTime(value)}
										></TimeSelect>
									</DateWrapper>
								</DateContainer>
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle>제외 시간 설정</BasicInfoTitle>
							<BasicInfoContents>
								<BlockDateContainer>
									<TimeSelect
										time={blockStartTime}
										onChange={(value) => setBlockStartTime(value)}
									></TimeSelect>
									<DateInputSeparator>~</DateInputSeparator>
									<TimeSelect
										time={blockEndTime}
										onChange={(value) => setBlockEndTime(value)}
									></TimeSelect>
									<TextInput
										className="ml-10px"
										type="text"
										value={blockTimeReason}
										onChange={(e) => setBlockTimeReason(e.currentTarget.value)}
										placeholder="사유"
									/>
									<AddBlockDateButton onClick={onClickBlockTimeItemAddBtn}>추가</AddBlockDateButton>
								</BlockDateContainer>
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle>추가된 제외 시간</BasicInfoTitle>
							<BasicInfoContents>
								<BlockListContainer>
									{renderBlockTimeList(blockTimeList, setBlockTimeList)}
								</BlockListContainer>
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle required>신청 가능 시간 설정</BasicInfoTitle>
							<BasicInfoContents>
								<AvailableTimeContainer>
									<AvailableTimeText>예약시작일 당일 기준</AvailableTimeText>
									<input
										type="number"
										min={0}
										value={bookingApplicableDate}
										onChange={(e) => setBookingApplicableDate(e.currentTarget.value)}
									></input>
									<AvailableTimeText>일 전까지 허용 (지정 일자의 자정까지 허용됨)</AvailableTimeText>
								</AvailableTimeContainer>
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle required>취소 가능 시간 설정</BasicInfoTitle>
							<BasicInfoContents>
								<AvailableTimeContainer>
									<AvailableTimeText>예약시작일 당일 기준</AvailableTimeText>
									<input
										type="number"
										min={0}
										value={bookingCancellableDate}
										onChange={(e) => setBookingCancellableDate(e.currentTarget.value)}
									></input>
									<AvailableTimeText>일 전까지 허용 (지정 일자의 자정까지 허용됨)</AvailableTimeText>
								</AvailableTimeContainer>
							</BasicInfoContents>
						</BasicInfoRow>
					</BasicInfoContainer>
				</section>
				<section className="mt-50px">
					<AdminSectionTitle title="시설 세부 정보" />
				</section>
				<section className="mt-34px">
					<BasicInfoContainer>
						<BasicInfoRow>
							<BasicInfoTitle required>대표이미지 등록</BasicInfoTitle>
							<BasicInfoContents>
								<RegisterImageContainer>{renderThumbnail()}</RegisterImageContainer>
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle>시설소개</BasicInfoTitle>
							<BasicInfoContents flexGrow>
								<DescriptionContainer>
									<textarea
										value={shortDescription}
										rows={2}
										onChange={(e) => setShortDescription(e.currentTarget.value)}
									/>
								</DescriptionContainer>
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle>사용용도</BasicInfoTitle>
							<BasicInfoContents flexGrow>
								<DescriptionContainer>
									<textarea
										value={purpose}
										rows={2}
										onChange={(e) => setPurpose(e.currentTarget.value)}
									/>
								</DescriptionContainer>
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle>설치장비</BasicInfoTitle>
							<BasicInfoContents flexGrow>
								<DescriptionContainer>
									<textarea
										value={equipment}
										rows={2}
										onChange={(e) => setEquipment(e.currentTarget.value)}
									/>
								</DescriptionContainer>
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle>상세설명</BasicInfoTitle>
							<BasicInfoContents flexGrow>
								<DescriptionEditorContainer>
									<Editor
										apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
										init={{
											language: 'ko_KR',
											language_url: '/libs/tinymce/langs/ko_KR.js',
											height: '100%',
											menubar: false,
											branding: false,
											plugins: [
												'advlist autolink lists link image charmap print preview anchor',
												'searchreplace visualblocks code fullscreen',
												'insertdatetime media table paste code help wordcount',
											],
											toolbar:
												'undo redo | formatselect | ' +
												'bold italic underline forecolor backcolor | alignleft aligncenter ' +
												'alignright alignjustify | bullist numlist outdent indent | ' +
												'image | help',
											content_style:
												'body { font-family:"Noto Sans"; Helvetica,Arial,sans-serif; font-size:16px }',
											image_title: false,
											automatic_uploads: true,
											convert_urls: false,
											file_picker_types: 'image',
											file_picker_callback: onEditorImageUpload,
										}}
										initialValue={defaultDescription}
										value={description}
										onInit={(evt, editor) => (descriptionEditorRef.current = editor)}
										onEditorChange={(newValue, editor) => setDescription(newValue)}
									/>
								</DescriptionEditorContainer>
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle>위치</BasicInfoTitle>
							<BasicInfoContents flexGrow>
								<LocationEditorContainer>
									<Editor
										apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
										init={{
											language: 'ko_KR',
											language_url: '/libs/tinymce/langs/ko_KR.js',
											height: '100%',
											menubar: false,
											branding: false,
											plugins: [
												'advlist autolink lists link image charmap print preview anchor',
												'searchreplace visualblocks code fullscreen',
												'insertdatetime media table paste code help wordcount',
											],
											toolbar:
												'undo redo | formatselect | ' +
												'bold italic underline forecolor backcolor | alignleft aligncenter ' +
												'alignright alignjustify | bullist numlist outdent indent | ' +
												'image | help',
											content_style:
												'body { font-family:"Noto Sans"; Helvetica,Arial,sans-serif; font-size:16px }',
											image_title: false,
											automatic_uploads: true,
											convert_urls: false,
											file_picker_types: 'image',
											file_picker_callback: onEditorImageUpload,
										}}
										initialValue={defaultLocation}
										value={location}
										onInit={(evt, editor) => (locationEditorRef.current = editor)}
										onEditorChange={(newValue, editor) => setLocation(newValue)}
									/>
								</LocationEditorContainer>
							</BasicInfoContents>
						</BasicInfoRow>
						<BasicInfoRow>
							<BasicInfoTitle>이미지 및 영상 등록</BasicInfoTitle>
							<BasicInfoContents flexCol>
								<AdditionalMediaAddContainer>
									<AddAdditionalMediaButton
										onClick={() => document.getElementById('additionalImageFile').click()}
									>
										<span>이미지 추가 (파일첨부)</span>
										<StyledAddCircleIcon />
									</AddAdditionalMediaButton>
									<AddAdditionalMediaInfo>5MB 이하 / 704px*396px</AddAdditionalMediaInfo>
									<input
										id="additionalImageFile"
										type="file"
										accept="image/*"
										hidden
										onChange={onChangeAdditionalImageFile}
									/>
								</AdditionalMediaAddContainer>
								<AdditionalMediaAddContainer className="mt-5px">
									<AddAdditionalMediaButton
										onClick={() => setAdditionalLinkModalData({ show: true })}
									>
										<span>동영상 추가 (링크입력)</span>
										<StyledAddCircleIcon />
									</AddAdditionalMediaButton>
									<AddAdditionalMediaInfo>Commons, Youtube 링크</AddAdditionalMediaInfo>
								</AdditionalMediaAddContainer>
								<AdditionalMediaContainer>
									{additionalMediaList.map((media, index) => {
										return (
											<AdditionalMedia key={index}>
												{media.type === AdditionalMediaType.IMAGE
													? renderAdditionalImage(media)
													: renderAdditionalLink(media)}
											</AdditionalMedia>
										);
									})}
								</AdditionalMediaContainer>
							</BasicInfoContents>
						</BasicInfoRow>
					</BasicInfoContainer>
				</section>
				<section className="mt-50px">
					<AdminSectionTitle title="관리자" />
				</section>
				<section className="mt-34px">
					<AdminAddButton onClick={() => setAdminUserAddModalData({ show: true })}>
						관리자 추가
					</AdminAddButton>
					<AdminListTable>
						<thead>
							<tr>
								<th style={{ minWidth: '200px' }}>이름</th>
								<th style={{ minWidth: '200px' }}>아이디</th>
								<th style={{ minWidth: '100px' }}>관리</th>
							</tr>
						</thead>
						<tbody>
							{adminList?.map((adminUser, idx) => {
								return (
									<tr key={idx}>
										<td>{adminUser.name ?? '-'}</td>
										<td>{adminUser.user_login ?? '-'}</td>
										<td>
											<AdminDeleteButton onClick={() => onClickDeleteAdmin(adminUser.user_login)}>
												삭제
											</AdminDeleteButton>
										</td>
									</tr>
								);
							})}
						</tbody>
					</AdminListTable>
				</section>
				<section className="mt-30px">
					<FooterContainer>
						<CancelButton onClick={() => navigate(-1)}>취소</CancelButton>
						<OkButton onClick={onClickSave}>저장</OkButton>
					</FooterContainer>
				</section>
				{additionalLinkModalData.show && (
					<AdditionalLinkModal
						onConfirm={(link) => {
							onAddAdditionalLink(link);
							setAdditionalLinkModalData({ show: false });
						}}
						onClose={() => setAdditionalLinkModalData({ show: false })}
					/>
				)}
				{adminUserAddModalData.show && (
					<AdminUserAddModal
						onConfirm={(adminUser) => {
							onAddAdminUser(adminUser);
							setAdminUserAddModalData({ show: false });
						}}
						onClose={() => setAdminUserAddModalData({ show: false })}
					/>
				)}
			</Content>
		</>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 40px;
	background-color: white;
`;

const TitleText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;
`;

const BasicInfoContainer = styled.div`
	width: 100%;
	border-top: 2px solid #333333;
`;

const BasicInfoRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;

	min-height: 45px;
	border-bottom: 1px solid #dddddd;
	border-left: 1px solid #dddddd;
	border-right: 1px solid #dddddd;
`;

const BasicInfoTitle = styled.div`
	width: 150px;
	flex-shrink: 0;
	padding-left: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	background-color: #f7f7f7;
	border-right: 1px solid #dddddd;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #333333;

	${(props) =>
		props.required &&
		css`
			::after {
				content: '*';
				color: #dc3545;
				margin-left: 2px;
			}
		`}
`;

const BasicInfoContents = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	padding: 8px 15px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #333333;

	${(props) =>
		props.flexCol &&
		css`
			flex-direction: column;
			align-items: flex-start;
		`}

	${(props) =>
		props.flexGrow &&
		css`
			flex-grow: 1;
		`}
`;

const BasicInfoSelect = styled.select`
	width: 200px;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;

	appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, #000000 50%),
		linear-gradient(135deg, #000000 50%, transparent 50%);
	background-position: calc(100% - 20px) calc(1em + 0px), calc(100% - 15px) calc(1em + 0px), calc(100% - 2.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1px 1.5em;
	background-repeat: no-repeat;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const BasicInfoTextInput = styled.input`
	width: 200px;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const NameCheckButton = styled.div`
	width: 70px;
	height: 30px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: 10px;
	border: 1px solid #333333;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	display: flex;
	align-items: center;

	&:hover {
		border: 1px solid #2b62ab;
		color: #2b62ab;
	}
`;

const Row = styled.div`
	display: flex;
	align-items: center;
`;

const AddRoomItemButton = styled.div`
	width: 70px;
	height: 30px;

	display: flex;
	align-items: center;
	justify-content: center;

	border: 1px solid #333333;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;

	&:hover {
		border: 1px solid #2b62ab;
		color: #2b62ab;
	}
`;

const RoomItemListContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const RoomItemContainer = styled.div`
	display: flex;
	width: 200px;
	position: relative;
	margin-right: 10px;

	& + & {
		margin-top: 5px;
	}

	input {
		width: 200px;
		height: 35px;
		border: 1px solid #dddddd;
		padding: 8px;
		color: #666666;
		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 500;
		font-size: 13px;
		line-height: 16px;
	}
`;

const RoomItemDeleteButton = styled(CloseIcon)`
	position: absolute;
	top: 9px;
	right: 5px;

	cursor: pointer;
	font-size: 16px !important;
	color: #275ba6;
`;

const RadioContainer = styled.div`
	display: flex;
	align-items: center;
`;

const RadioBoxGroup = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	& + & {
		margin-left: 20px;
	}

	input {
		cursor: pointer;
		width: 15px;
		height: 15px;
	}

	label {
		cursor: pointer;
		margin-left: 6px;
		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #666666;
	}
`;

const MaxUsageCountContainer = styled.div`
	display: flex;
	align-items: center;

	input {
		width: 60px;
		height: 35px;
		padding: 8px;

		margin-right: 9px;
		border: 1px solid #dddddd;
		background-color: #ffffff;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #666666;
	}

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const ContactNumberContainer = styled.div`
	display: flex;
	align-items: center;

	input {
		width: 200px;
	}
`;

const AvailableUnitCol = styled.div`
	width: 380px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	& + & {
		margin-left: 48px;
	}
`;

const AvailableUnitTitle = styled.div`
	width: 85px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
`;

const AvailableUnitInputGroup = styled.div`
	display: flex;

	input {
		width: 130px;
		height: 35px;
		padding: 8px;

		margin-left: 28px;
		border: 1px solid #dddddd;
		background-color: #ffffff;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #666666;
	}

	select {
		width: 130px;
		height: 35px;

		margin-left: 10px;
		padding: 8px;
		border: 1px solid #dddddd;

		appearance: none;
		background-image: linear-gradient(45deg, transparent 50%, #000000 50%),
			linear-gradient(135deg, #000000 50%, transparent 50%);
		background-position: calc(100% - 20px) calc(1em + 0px), calc(100% - 15px) calc(1em + 0px),
			calc(100% - 2.5em) 0.5em;
		background-size: 5px 5px, 5px 5px, 1px 1.5em;
		background-repeat: no-repeat;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #666666;
	}
`;

const UnitScheduleTitle = styled.div`
	width: 100px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #333333;

	${(props) =>
		props.required &&
		css`
			::after {
				content: '*';
				color: #dc3545;
				margin-left: 2px;
			}
		`}
`;

const DateContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 17px;
`;

const DateWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const DateInputSeparator = styled.div`
	margin-left: 10px;
	margin-right: 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const DateContainerText = styled.div`
	display: flex;
	align-items: center;
	margin-right: 12px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #0e498f;
`;

const DateInput = styled.input`
	width: 150px;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const TextInput = styled.input`
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const DayContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-left: 26px;
`;

const DayCircle = styled.div`
	width: 30px;
	height: 30px;
	border-radius: 15px;
	border: 1px solid #999999;

	display: flex;
	justify-content: center;
	align-items: center;

	color: #999999;
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.42px;
	line-height: 100%;

	& + & {
		margin-left: 5px;
	}
	${(props) =>
		props.selected &&
		css`
			background-color: #0e498f;
			border: 1px solid #0e498f;
			color: white;
		`}
	cursor: pointer;
`;

const BlockDateContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	input[type='text'] {
		width: 210px;
	}
`;

const AddBlockDateButton = styled.div`
	width: 50px;
	height: 30px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: 10px;
	border: 1px solid #333333;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	display: flex;
	align-items: center;

	&:hover {
		border: 1px solid #2b62ab;
		color: #2b62ab;
	}
`;

const BlockListContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const BlockItemContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	& + & {
		margin-top: 5px;
	}
`;

const BlockItemText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
`;

const BlockItemDeleteButton = styled.div`
	width: 40px;
	height: 25px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: 10px;
	border: 1px solid #333333;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	display: flex;
	align-items: center;

	&:hover {
		border: 1px solid #2b62ab;
		color: #2b62ab;
	}
`;

const RegisterImageContainer = styled.div`
	display: flex;
	align-items: center;
`;

const ImageAddButton = styled.div`
	width: 180px;
	height: 180px;
	background-color: #e8e8e8;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-radius: 4px;
`;

const ImageAddButtonPlus = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 36px;
	height: 36px;

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 125px;
	font-weight: 400;
	font-style: normal;
`;

const ThumbnailImageContainer = styled.div`
	position: relative;
	width: 180px;
	height: 180px;
`;

const ThumbnailImageDescContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 10px;
`;

const ThumbnailImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: contain;
`;

const StyledCloseIcon = styled(CloseIcon)`
	cursor: pointer;
	width: 32px;
	height: 32px;
	position: absolute;
	top: 5px;
	right: 5px;
`;

const ImageTitle = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
`;

const ImageDescription = styled.div`
	width: 180px;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-top: 7px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
`;

const DescriptionContainer = styled.div`
	width: 100%;

	textarea {
		width: 100%;
		border: 1px solid #dddddd;

		padding: 8px;
		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #666666;
	}
`;

const DescriptionEditorContainer = styled.div`
	width: 100%;
	height: 600px;
`;

const LocationEditorContainer = styled.div`
	width: 100%;
	height: 400px;
`;

const AdditionalMediaAddContainer = styled.div`
	display: flex;
	align-items: center;
`;

const AddAdditionalMediaButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 200px;
	height: 35px;
	padding: 0 13px;
	cursor: pointer;

	border-radius: 4px;
	border: 1px solid #dddddd;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const AddAdditionalMediaInfo = styled.div`
	margin-left: 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const StyledAddCircleIcon = styled(AddCircleIcon)`
	font-size: 16px !important;
	color: #666666;
`;

const AdditionalMediaContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const AdditionalMedia = styled.div`
	margin: 12px;
`;

const AdditionalLinkContainer = styled.div`
	position: relative;
	width: 180px;
	height: 180px;
	background-color: #f5f5f5;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-radius: 4px;
`;

const LinkText = styled.div`
	word-break: break-all;
	padding: 8px;
	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const AdminAddButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 122px;
	height: 36px;

	background-color: #454655;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #ffffff;

	&:hover {
		background-color: #1d1e31;
	}
`;

const AdminListTable = styled.table`
	width: 500px;

	border-collapse: collapse;
	border-top: 2px solid #333333;

	text-align: center;
	margin-top: 15px;
	thead th {
		height: 45px;
		background-color: #f6f8fb;
		border-right: 1px solid #dddddd;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #0e498f;

		:first-child {
			border-left: 1px solid #dddddd;
		}
	}
	thead tr {
		border-bottom: 1px solid #dcdcdc;
	}

	tbody td {
		height: 45px;

		border-right: 1px solid #dddddd;
		padding: 6px 2px;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #333333;

		word-break: break-all;
		:first-child {
			border-left: 1px solid #dddddd;
		}
	}
	tbody tr {
		border-bottom: 1px solid #dddddd;
	}

	tfoot {
		height: 45px;
		background: #f7f7f7;
		border-bottom: 1px solid #dddddd;
	}
`;

const AdminDeleteButton = styled.div`
	width: 43px;
	height: 26px;

	display: inline-flex;
	align-items: center;
	justify-content: center;

	background-color: #2f5a8c;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #ffffff;
`;

const FooterContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const CancelButton = styled.div`
	width: 100px;
	height: 40px;

	border: 1px solid #00b1a6;
	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #009289;
`;

const OkButton = styled.div`
	width: 100px;
	height: 40px;
	margin-left: 10px;

	background-color: #00b1a6;
	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #ffffff;

	&:hover {
		background-color: #008f87;
	}
`;

const AvailableTimeContainer = styled.div`
	display: flex;
	align-items: center;

	input {
		width: 130px;
		height: 35px;
		padding: 8px;

		margin: 0 10px;
		border: 1px solid #dddddd;
		background-color: #ffffff;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #666666;
	}
`;

const AvailableTimeText = styled.div``;

export default UnitRoom;
