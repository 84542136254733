import React, { useState } from 'react';
import styled from 'styled-components';
import UnitType from '../constants/UnitType';
import BookingCalendar from '../components/BookingCalendar';
import ApprovalStatusLegend from '../components/ApprovalStatusLegend';
import AdminSectionTitle from '../components/admin/AdminSectionTitle';

const MyCalendar = () => {
	const [unitType, setUnitType] = useState(UnitType.ROOM);

	return (
		<Container>
			<Content>
				<AdminSectionTitle title="예약 캘린더" />
				<CalendarTopContainer>
					<ApprovalStatusLegend />
					<UnitSelect onChange={(e) => setUnitType(e.currentTarget.value)}>
						<option value={UnitType.ROOM}>{UnitType.toString(UnitType.ROOM)}</option>
						{JSON.parse(process.env.REACT_APP_USE_TYPE_PRODUCT ?? false) && (
							<option value={UnitType.PRODUCT}>{UnitType.toString(UnitType.PRODUCT)}</option>
						)}
						{JSON.parse(process.env.REACT_APP_USE_TYPE_CONSULT ?? false) && (
							<option value={UnitType.CONSULT}>{UnitType.toString(UnitType.CONSULT)}</option>
						)}
					</UnitSelect>
				</CalendarTopContainer>
				<BookingCalendar standalone={true} unitType={unitType} />
			</Content>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	height: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	background-color: #f5f5f5;
`;

const Content = styled.div`
	width: 100%;
	height: 100%;
	max-width: 1240px;
	padding: 20px 20px;
	border-radius: 8px;
	background-color: white;
`;

const CalendarTopContainer = styled.div`
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const UnitSelect = styled.select`
	width: 124px;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	border-radius: 5px;

	appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, #000000 50%),
		linear-gradient(135deg, #000000 50%, transparent 50%);
	background-position: calc(100% - 20px) calc(1em + 0px), calc(100% - 15px) calc(1em + 0px), calc(100% - 2.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1px 1.5em;
	background-repeat: no-repeat;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
`;

export default MyCalendar;
