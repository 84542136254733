import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import * as api from '../apis';

const UserContext = React.createContext({
	user: {},
	login: (user_login, password) => {},
	logout: () => {},
});

const UserProvider = ({ children }) => {
	const cookieUser = Cookies.get('user');
	const [user, setUser] = useState(cookieUser ? JSON.parse(cookieUser) : null);

	useEffect(() => {
		// 최초 실행 시 api_token이 있으면 정상 토큰인지 확인한다.
		if (Cookies.get('api_token')) {
			api.checkToken()
				.then((res) => {
					if (res && res.status !== 204) {
						logout();
						window.location.href = '/login';
					}
				})
				.catch((err) => {
					logout();
					window.location.href = '/login';
				});
		}
	}, []);

	const login = (data) => {
		if (data.token) {
			Cookies.set('api_token', data.token, { expires: 30 });
		}

		if (data.user) {
			Cookies.set('user', JSON.stringify(data.user), { expires: 30 });
			setUser(data.user);
		}
	};

	const logout = async () => {
		Cookies.remove('api_token');
		Cookies.remove('user');
		setUser(null);
	};

	return <UserContext.Provider value={{ user, login, logout }}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
