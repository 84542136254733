import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { UserContext } from '../contexts/UserContext';

const Login = () => {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);

	useEffect(() => {
		if (user) {
			loginCompleted();
		}
	}, [user]);

	const onClickLoginGeneral = () => {
		navigate('/login/general');
	};

	const loginCompleted = () => {
		navigate('/');
	};

	const onClickSSOLogin = () => {
		window.location =
			'https://www.rec.ac.kr/xn-sso/gw.php?login_type=standalone&site=studio.gnu&prefix=gn&callback_url=https%3A%2F%2Fstudio.gnu.ac.kr%2Flogin%2Fcallback';
	};

	return (
		<Container>
			<Title>로그인</Title>
			<Wrapper>
				<TopLine />
				<LoginContainer>
					<LoginWrapper>
						<LoginTitle>교내 통합 로그인</LoginTitle>
						<SsoLoginButtonContainer>
							<SsoLoginButton onClick={onClickSSOLogin}>교내 통합 로그인</SsoLoginButton>
							<SsoLoginDescription>경상국립대학교 통합로그인 계정으로 로그인합니다.</SsoLoginDescription>
						</SsoLoginButtonContainer>
					</LoginWrapper>
					<LoginWrapper>
						<GeneralLoginButton onClick={onClickLoginGeneral}>외부 사용자 로그인</GeneralLoginButton>
						<GeneralLoginDescription>
							※ 외부 사용자는 외부 사용자 로그인 페이지로 이동하여 로그인 해주시기 바랍니다.
						</GeneralLoginDescription>
					</LoginWrapper>
				</LoginContainer>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 80px 0 0 0;

	@media only screen and (max-width: 767.98px) {
		padding: 60px 15px 20px 15px;
	}
`;

const Title = styled.span`
	color: #214e9e;
	font-size: 30px;
	font-weight: 700;
	line-height: 30px;
	letter-spacing: -0.9px;
`;

const Wrapper = styled.div`
	width: 380px;
	background-color: white;
	margin-top: 24px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
	}
`;

const TopLine = styled.div`
	width: 100%;
	height: 5px;
	background-color: #344e9b;
`;

const LoginContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const LoginTitle = styled.div`
	font-family: 'Noto Sans';
	font-size: 20px;
	font-weight: 700;
`;

const SsoLoginDescription = styled.div`
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 400;
	margin-top: 24px;
`;

const GeneralLoginDescription = styled.div`
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 400;
	margin: 24px 0;
	text-align: center;
`;

const LoginWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: stretch;
	padding: 24px 35px 0 35px;

	@media only screen and (max-width: 767.98px) {
		padding: 24px 24px 0 24px;
	}
`;

const SsoLoginButtonContainer = styled.div`
	width: 100%;
	margin-top: 24px;
	padding-bottom: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-bottom: 1px solid #dddddd;
`;

const SsoLoginButton = styled.button`
	width: 100%;
	height: 87px;
	color: white;
	outline: none;
	border: none;
	background-color: #265fa7;
	border-radius: 6px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 0;
	}
`;

const GeneralLoginButton = styled.button`
	padding: 0 24px;
	height: 47px;
	color: #11a7b0;
	background-color: white;
	border: 1px solid #11a7b0;
	border-radius: 6px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 0;
	}
`;

export default Login;
