import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useLocation, useParams } from 'react-router-dom';
import useSizeDetector from '../hooks/useSizeDetector';
import CategorySlug from '../constants/CategorySlug';
import AdditionalMediaType from '../constants/AdditionalMediaType';
import BookingDateSelector from '../components/BookingDateSelector';

import TabArrowDownActiveIconImage from '../assets/images/screens/Booking/tab_arrow_down_active.svg';
import TabArrowDownNormalIconImage from '../assets/images/screens/Booking/tab_arrow_down_normal.svg';

import * as api from '../apis';
import * as utils from '../utils';

const BookingSchedule = () => {
	const { id } = useParams();
	const location = useLocation();
	const { isDesktop } = useSizeDetector();

	const [tabIndex, setTabIndex] = useState(0);
	const [unitData, setUnitData] = useState(null);
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		api.getUnit(id)
			.then((res) => {
				if (res && res.data) {
					setUnitData(res.data);
					setInitialized(true);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(() => {
		if (!initialized) return;

		// 데스크톱 메뉴 스크롤 감지
		const option = {
			root: null, //viewport
			rootMargin: '0px',
			threshold: 0.45, // 45%가 viewport에 들어와 있어야 callback 실행
		};
		const callback = (entries, observer) => {
			entries.forEach((entry) => {
				let targetElemId = null;
				if (entry.isIntersecting) {
					targetElemId = entry.target.id;
				}
				if (targetElemId) {
					setTabIndex(parseInt(targetElemId.replace('section', '')));
				}
			});
		};
		const observer = new IntersectionObserver(callback, option);
		const sections = document.getElementsByClassName('tab-section');
		for (let section of sections) {
			observer.observe(section);
		}
	}, [initialized]);

	const getUnitCategoryFullName = () => {
		return `${utils.getUnitCategoryName(unitData, CategorySlug.PLACE, 1)} | ${utils.getUnitCategoryName(
			unitData,
			CategorySlug.PLACE,
			2
		)} | ${utils.getUnitCategoryName(unitData, CategorySlug.PLACE, 3)} | ${utils.getUnitCategoryName(
			unitData,
			CategorySlug.TYPE,
			2
		)}`;
	};

	const onTabChange = (idx) => {
		setTabIndex(idx);

		const element = document.getElementById(`section${idx}`);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	const renderAdditionalImage = (media) => {
		return <AdditionalImage src={`${process.env.REACT_APP_API_URL}/storage/files/${media.image.filename}`} />;
	};

	const iframeStyle = {
		position: 'absolute',
		border: 0,
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
	};

	const renderAdditionalLink = (media) => {
		return (
			<IframeWrapper>
				<AdditionalLink src={media.link} style={iframeStyle} />
			</IframeWrapper>
		);
	};

	if (!initialized) return null;

	return (
		<Content>
			<section>
				<Row>
					<UnitDataColumn>
						<ThumbnailContainer>
							{unitData?.thumbnail ? (
								<UnitThumbnail
									src={`${process.env.REACT_APP_API_URL}/storage/files/${unitData.thumbnail.filename}`}
								/>
							) : (
								<EmptyUnitThumbnail />
							)}
						</ThumbnailContainer>
						<TabContainer>
							<TabItemRow>
								<TabItem selected={tabIndex === 0} onClick={() => onTabChange(0)} topBorder>
									<span>기본정보</span>
									{tabIndex === 0 ? <TabArrowDownActiveIcon /> : <TabArrowDownNormalIcon />}
								</TabItem>
								<TabItem
									selected={tabIndex === 1}
									onClick={() => onTabChange(1)}
									topBorder
									rightBorder={!isDesktop}
								>
									<span>상세설명</span>
									{tabIndex === 1 ? <TabArrowDownActiveIcon /> : <TabArrowDownNormalIcon />}
								</TabItem>
							</TabItemRow>
							<TabItemRow>
								<TabItem selected={tabIndex === 2} onClick={() => onTabChange(2)} topBorder={isDesktop}>
									<span>이미지 및 영상</span>
									{tabIndex === 2 ? <TabArrowDownActiveIcon /> : <TabArrowDownNormalIcon />}
								</TabItem>
								<TabItem
									selected={tabIndex === 3}
									onClick={() => onTabChange(3)}
									topBorder={isDesktop}
									rightBorder
								>
									<span>위치</span>
									{tabIndex === 3 ? <TabArrowDownActiveIcon /> : <TabArrowDownNormalIcon />}
								</TabItem>
							</TabItemRow>
						</TabContainer>
						<TabSection id="section0" className="tab-section">
							<UnitDetailInfoContainer>
								<UnitDetailInfoSectionTitle>기본정보</UnitDetailInfoSectionTitle>
								<SectionSeparator>
									<SectionSeparatorHead />
								</SectionSeparator>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitDetailInfoTitle>시설소개</UnitDetailInfoTitle>
								<UnitDetailInfo>{unitData?.short_description ?? '-'}</UnitDetailInfo>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitDetailInfoTitle>사용 용도</UnitDetailInfoTitle>
								<UnitDetailInfo>{unitData?.purpose ?? '-'}</UnitDetailInfo>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitDetailInfoTitle>제작 장비</UnitDetailInfoTitle>
								<UnitDetailInfo>{unitData?.equipment ?? '-'}</UnitDetailInfo>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitDetailInfoTitle>예약 정보</UnitDetailInfoTitle>
								<UnitDetailInfoRow>
									<UnitDetailInfo>
										{utils.getEnabledWeekdayStr(unitData?.enable_schedule?.booking_weekday)}
									</UnitDetailInfo>
									<UnitDetailInfoSeparator>|</UnitDetailInfoSeparator>
									<UnitDetailInfo>
										{unitData?.enable_schedule?.booking_start_time ?? '-'} ~{' '}
										{unitData?.enable_schedule?.booking_end_time ?? '-'}
									</UnitDetailInfo>
								</UnitDetailInfoRow>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitDetailInfoTitle>호실 정보</UnitDetailInfoTitle>
								<UnitDetailInfo>
									{unitData?.room_items?.map((roomItem) => roomItem.name).join(', ')}
								</UnitDetailInfo>
							</UnitDetailInfoContainer>
						</TabSection>
						<TabSection id="section1" className="tab-section">
							<UnitDetailInfoContainer>
								<UnitDetailInfoSectionTitle>상세설명</UnitDetailInfoSectionTitle>
								<SectionSeparator>
									<SectionSeparatorHead />
								</SectionSeparator>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitDescriptionViewer
									dangerouslySetInnerHTML={{ __html: unitData?.description ?? '-' }}
								/>
							</UnitDetailInfoContainer>
						</TabSection>
						<TabSection id="section2" className="tab-section">
							<UnitDetailInfoContainer>
								<UnitDetailInfoSectionTitle>이미지 및 영상</UnitDetailInfoSectionTitle>
								<SectionSeparator>
									<SectionSeparatorHead />
								</SectionSeparator>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitAdditionalMediaContainer>
									{unitData?.additional_medias.map((media, _) => {
										return (
											<div key={_}>
												{media.type === AdditionalMediaType.IMAGE
													? renderAdditionalImage(media)
													: renderAdditionalLink(media)}
											</div>
										);
									})}
								</UnitAdditionalMediaContainer>
							</UnitDetailInfoContainer>
						</TabSection>
						<TabSection id="section3" className="tab-section">
							<UnitDetailInfoContainer>
								<UnitDetailInfoSectionTitle>위치</UnitDetailInfoSectionTitle>
								<SectionSeparator>
									<SectionSeparatorHead />
								</SectionSeparator>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitDescriptionViewer
									dangerouslySetInnerHTML={{ __html: unitData?.location ?? '-' }}
								/>
							</UnitDetailInfoContainer>
						</TabSection>
					</UnitDataColumn>
					<UnitScheduleColumn>
						<UnitInfo>
							<UnitName>{unitData?.name}</UnitName>
							<UnitCategoryName>{getUnitCategoryFullName()}</UnitCategoryName>
							<UnitContactNumber>{`문의 : ${unitData?.contact_number ?? '-'}`}</UnitContactNumber>
						</UnitInfo>
						<BookingDateSelector
							unitData={unitData}
							bookingDate={location.state?.filter?.usage_start_date ?? null}
						/>
					</UnitScheduleColumn>
				</Row>
			</section>
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	margin-top: 59px;
	margin-bottom: 106px;

	@media only screen and (max-width: 767.98px) {
		padding: 10px;
		margin: 0;
	}
`;

const Row = styled.div`
	display: flex;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column-reverse;
	}
`;

const UnitDataColumn = styled.div`
	width: 800px;
	background-color: #ffffff;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding: 15px 0;
		margin-top: 10px;
	}
`;

const ThumbnailContainer = styled.div`
	padding: 45px 50px;
	box-sizing: border-box;

	@media only screen and (max-width: 767.98px) {
		padding: 0 10px;
	}
`;

const UnitThumbnail = styled.img`
	width: 100%;
	height: 440px;
	object-fit: contain;

	@media only screen and (max-width: 767.98px) {
		height: auto;
	}
`;

const EmptyUnitThumbnail = styled.div`
	width: 100%;
	height: 440px;
	background-color: #dddddd;

	@media only screen and (max-width: 767.98px) {
		height: auto;
	}
`;

const TabContainer = styled.div`
	display: flex;
	position: sticky;
	top: 90px;
	z-index: 50;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		width: 100%;
		margin-top: 10px;
		top: 60px;
	}
`;

const TabItemRow = styled.div`
	display: flex;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
	}
`;

const TabItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 200px;
	height: 60px;
	padding: 20px;
	box-sizing: border-box;
	cursor: pointer;

	span {
		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
		color: #000000;
	}

	border-left: 1px solid #a0a8b8;
	border-bottom: 1px solid #123277;
	background-color: #fdfeff;

	${(props) =>
		props.topBorder &&
		css`
			border-top: 1px solid #a0a8b8;
		`}

	${(props) =>
		props.rightBorder &&
		css`
			border-right: 1px solid #a0a8b8;
		`}
		
	${(props) =>
		props.selected &&
		css`
			span {
				color: #ffffff;
			}
			background-color: #203970;
		`}

	
	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: 45px;
		padding: 10px;
		span {
			font-size: 16px;
			line-height: 19px;
		}
	}
`;

const TabArrowDownActiveIcon = styled.i`
	width: 24px;
	height: 24px;
	background-image: url(${TabArrowDownActiveIconImage});
`;

const TabArrowDownNormalIcon = styled.i`
	width: 24px;
	height: 24px;
	background-image: url(${TabArrowDownNormalIconImage});
`;

const TabSection = styled.section`
	scroll-margin-top: 150px;
	padding: 30px 30px 25px 30px;
	box-sizing: border-box;

	@media only screen and (max-width: 767.98px) {
		padding: 10px;
		scroll-margin-top: 150px;
	}
`;

const UnitDetailInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	& + & {
		margin-top: 20px;
	}
`;

const UnitDetailInfoTitle = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 17px;
	line-height: 17px;
	color: #333333;

	@media only screen and (max-width: 767.98px) {
		font-size: 15px;
		line-height: 18px;
	}
`;

const UnitDetailInfoSectionTitle = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		font-size: 16px;
		line-height: 19px;
	}
`;

const UnitDetailInfoRow = styled.div`
	display: flex;
`;

const UnitDetailInfo = styled.div`
	margin-top: 12px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #333333;
	white-space: pre-line;

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
		line-height: 17px;
	}
`;

const UnitDetailInfoSeparator = styled(UnitDetailInfo)`
	margin-left: 10px;
	margin-right: 10px;
`;

const UnitAdditionalMediaContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const UnitDescriptionViewer = styled.div`
	width: 100%;
	font-size: 16px;
	margin-top: 10px;
	overflow-x: auto;
`;

const AdditionalImage = styled.img`
	width: 100%;
	margin-top: 12px;
`;

const AdditionalLink = styled.iframe``;

const IframeWrapper = styled.div`
	margin-top: 12px;
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
`;

const SectionSeparator = styled.div`
	position: relative;
	margin-top: 16px;
	width: 100%;
	height: 1px;
	border-bottom: 1px solid #cccccc;
`;

const SectionSeparatorHead = styled.div`
	position: absolute;
	top: -1px;
	left: 0;
	width: 18px;
	height: 2px;
	background-color: #123277;
`;

const UnitScheduleColumn = styled.div`
	width: 412px;
	margin-left: 32px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		margin-left: 0;
	}
`;

const UnitInfo = styled.div`
	width: 100%;
	background-color: #ffffff;
	border: 5px solid #cad1e3;
	padding: 30px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: 125px;
		padding: 15px 16px 16px 16px;
	}
`;

const UnitName = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	padding-bottom: 19px;
	border-bottom: 1px solid #dddddd;
	word-break: break-all;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 28px;
	line-height: 34px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		display: block;
		height: 34px;
		padding-bottom: 10px;
		font-size: 20px;
		line-height: 24px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		word-break: break-all;
	}
`;

const UnitCategoryName = styled.div`
	margin-top: 18px;
	height: 20px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #22499d;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	word-break: break-all;

	@media only screen and (max-width: 767.98px) {
		margin-top: 10px;
		font-size: 14px;
		line-height: 17px;
	}
`;

const UnitContactNumber = styled.div`
	margin-top: 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #000000;
	word-break: break-all;

	@media only screen and (max-width: 767.98px) {
		margin-top: 5px;
		font-size: 14px;
		line-height: 17px;
	}
`;

export default BookingSchedule;
