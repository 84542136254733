import React from 'react';
import styled, { css } from 'styled-components';

const DashboardSummaryCard = ({ title, background, border, color, count, large, xlarge, noGrow, onClick }) => {
	return (
		<CardContainer
			background={background}
			border={border}
			large={large}
			xlarge={xlarge}
			noGrow={noGrow}
			onClick={onClick}
		>
			<Title white={large || xlarge}>{title}</Title>
			<Count color={color} large={large} xlarge={xlarge}>
				{count}
			</Count>
		</CardContainer>
	);
};

const CardContainer = styled.div`
	flex-grow: 1;
	flex-basis: 170px;
	height: 102px;
	border-radius: 10px;
	cursor: pointer;

	background: ${(props) => props.background};
	border: 2px solid ${(props) => props.border};

	${(props) =>
		props.large &&
		css`
			flex-basis: 255px;
		`}

	${(props) =>
		props.xlarge &&
		css`
			flex-basis: 303px;
		`}

	@media only screen and (max-width: 1023.98px) {
		${(props) =>
			props.large &&
			css`
				flex-basis: 170px;
			`}

		${(props) =>
			props.xlarge &&
			css`
				flex-basis: 170px;
			`}
	}

	@media only screen and (max-width: 767.98px) {
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: 30%;

		${(props) =>
			props.noGrow &&
			css`
				flex-grow: 0;
				flex-basis: calc(32% + 2px);
			`}
	}

	@media only screen and (max-width: 479.98px) {
		flex-basis: 45%;

		${(props) =>
			props.noGrow &&
			css`
				flex-basis: 49%;
			`}
	}
`;

const Title = styled.div`
	margin-top: 16px;
	margin-left: 19px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #000000;

	${(props) =>
		props.white &&
		css`
			color: #ffffff;
		`}
`;

const Count = styled.div`
	margin-top: 7px;
	padding-right: 30px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 37px;
	line-height: 44px;
	text-align: right;
	color: ${(props) => props.color};
`;

export default DashboardSummaryCard;
