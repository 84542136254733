import React, { useEffect, useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import DashboardSummaryCard from '../../components/admin/DashboardSummaryCard';
import DashboardCalendar from '../../components/admin/DashboardCalendar';
import { UserContext } from '../../contexts/UserContext';
import UnitType from '../../constants/UnitType';
import ApprovalStatus from '../../constants/ApprovalStatus';
import RentalStatus from '../../constants/RentalStatus';
import UserRole from '../../constants/UserRole';
import * as api from '../../apis';
import * as utils from '../../utils';

const DashboardProduct = () => {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);
	const [data, setData] = useState(null);
	const [bookingTotalCount, setBookingTotalCount] = useState(0);
	const graphColorList = ['#acc4e5', '#aaacaf', '#afdde0', '#c4c5e8', '#e18a9f', '#899cba'];

	useEffect(() => {
		api.getDashboard(UnitType.PRODUCT)
			.then((res) => {
				if (res && res.data) {
					const statistics = res.data?.product_type?.statistics;
					let totalCount = 0;
					if (statistics) {
						Object.keys(statistics).forEach((key) => {
							totalCount += statistics[key];
						});
						setBookingTotalCount(totalCount);
					}
					setData(res.data);
				}
			})
			.catch((err) => console.error(err));
	}, []);

	const getTodayTotal = () => {
		return (
			(data?.today?.pending ?? 0) +
			(data?.today?.approved ?? 0) +
			(data?.today?.rejected ?? 0) +
			(data?.today?.canceled ?? 0)
		);
	};

	const onClickCard = (status, today) => {
		navigate(`/admin/booking/products`, {
			state: {
				filter: {
					approval_status: status,
					usage_date: today ? utils.convertDateToStr(new Date()) : null,
				},
			},
		});
	};

	const onClickRentalCard = (status, today) => {
		navigate(`/admin/booking/products`, {
			state: {
				filter: {
					rental_status: status,
					usage_date: today ? utils.convertDateToStr(new Date()) : null,
				},
			},
		});
	};

	return (
		<Content>
			<section>
				<SectionTitle>오늘의 예약 현황</SectionTitle>
				<Row className="mt-20px">
					<DashboardSummaryCard
						title="승인대기(누적)"
						background="#19B4B5"
						border="#19B4B5"
						color="#FFFFFF"
						count={data?.total?.pending ?? 0}
						large
						onClick={(e) => onClickCard(ApprovalStatus.PENDING, false)}
					/>
					<DashboardSummaryCard
						title="신청"
						background="#FFFFFF"
						border="#BCD1EE"
						color="#2460B1"
						count={getTodayTotal()}
						onClick={(e) => onClickCard(null, true)}
					/>
					<DashboardSummaryCard
						title="승인대기"
						background="#FFFFFF"
						border="#BFC3E8"
						color="#6B64B5"
						count={data?.today?.pending ?? 0}
						onClick={(e) => onClickCard(ApprovalStatus.PENDING, true)}
					/>
					<DashboardSummaryCard
						title="승인완료"
						background="#FFFFFF"
						border="#ACD7EB"
						color="#306D8A"
						count={data?.today?.approved ?? 0}
						onClick={(e) => onClickCard(ApprovalStatus.APPROVED, true)}
					/>
					<DashboardSummaryCard
						title="승인거부"
						background="#FFFFFF"
						border="#F6CBC6"
						color="#D2674F"
						count={data?.today?.rejected ?? 0}
						onClick={(e) => onClickCard(ApprovalStatus.REJECTED, true)}
					/>
					<DashboardSummaryCard
						title="예약취소"
						background="#FFFFFF"
						border="#EFC3CD"
						color="#C34B66"
						count={data?.today?.canceled ?? 0}
						onClick={(e) => onClickCard(ApprovalStatus.CANCELED, true)}
					/>
				</Row>
			</section>
			<section className="mt-50px">
				<SectionTitle>오늘의 대여/반납현황</SectionTitle>
				<Row className="mt-20px">
					<DashboardSummaryCard
						title="수령지연(누적)"
						background="#EF950E"
						border="#EF950E"
						color="#FFFFFF"
						count={data?.total?.rental_delay ?? 0}
						xlarge
						onClick={(e) => onClickRentalCard(RentalStatus.RENTAL_DELAY, false)}
					/>
					<DashboardSummaryCard
						title="반납연체(누적)"
						background="#D8352A"
						border="#D8352A"
						color="#FFFFFF"
						count={data?.total?.return_delay ?? 0}
						xlarge
						onClick={(e) => onClickRentalCard(RentalStatus.RETURN_DELAY, false)}
					/>
					<DashboardSummaryCard
						title="수령대기"
						background="#FFFFFF"
						border="#BCD1EE"
						color="#2460B1"
						count={data?.today_rental?.rental_ready ?? 0}
						onClick={(e) => onClickRentalCard(RentalStatus.RENTAL_READY, true)}
					/>
					<DashboardSummaryCard
						title="수령완료"
						background="#FFFFFF"
						border="#BFC3E8"
						color="#6B64B5"
						count={data?.today_rental?.rental_complete ?? 0}
						noGrow
						onClick={(e) => onClickRentalCard(RentalStatus.RENTAL_COMPLETE, true)}
					/>
					<DashboardSummaryCard
						title="반납완료"
						background="#FFFFFF"
						border="#ACD7EB"
						color="#306D8A"
						count={data?.today_rental?.return_complete ?? 0}
						noGrow
						onClick={(e) => onClickRentalCard(RentalStatus.RETURN_COMPLETE, true)}
					/>
				</Row>
			</section>
			<section>
				<DashboardCalendarContainer>
					<SectionTitle>예약 캘린더</SectionTitle>
					<DashboardCalendar unitType={UnitType.PRODUCT} />
				</DashboardCalendarContainer>
			</section>
			{UserRole.isAdminRole(user?.user_role) && (
				<section className="mt-50px">
					<SectionTitle>전체 예약 현황</SectionTitle>
					<Row className="mt-20px">
						<Table col={6}>
							<thead>
								<tr>
									<th>상태</th>
									<th>신청</th>
									<th>승인대기</th>
									<th>승인완료</th>
									<th>승인거부</th>
									<th>예약취소</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>이번주</th>
									<td>{data?.overall?.weekly_status_total ?? '0'}</td>
									<td>{data?.overall?.weekly_status?.pending ?? '0'}</td>
									<td>{data?.overall?.weekly_status?.approved ?? '0'}</td>
									<td>{data?.overall?.weekly_status?.rejected ?? '0'}</td>
									<td>{data?.overall?.weekly_status?.canceled ?? '0'}</td>
								</tr>
								<tr>
									<th>이번달</th>
									<td>{data?.overall?.monthly_status_total ?? '0'}</td>
									<td>{data?.overall?.monthly_status?.pending ?? '0'}</td>
									<td>{data?.overall?.monthly_status?.approved ?? '0'}</td>
									<td>{data?.overall?.monthly_status?.rejected ?? '0'}</td>
									<td>{data?.overall?.monthly_status?.canceled ?? '0'}</td>
								</tr>
							</tbody>
						</Table>
					</Row>
				</section>
			)}
			{UserRole.isAdminRole(user?.user_role) && (
				<section className="mt-50px">
					<SectionTitle>전체 대여/반납 현황</SectionTitle>
					<Row className="mt-20px">
						<Table col={7}>
							<thead>
								<tr>
									<th>상태</th>
									<th>신청</th>
									<th>수령대기</th>
									<th>수령완료</th>
									<th>수령지연</th>
									<th>반납완료</th>
									<th>반납연체</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>이번주</th>
									<td>{data?.overall?.weekly_rental_status_total ?? '0'}</td>
									<td>{data?.overall?.weekly_rental_status?.rental_ready ?? '0'}</td>
									<td>{data?.overall?.weekly_rental_status?.rental_complete ?? '0'}</td>
									<td>{data?.overall?.weekly_rental_status?.rental_delay ?? '0'}</td>
									<td>{data?.overall?.weekly_rental_status?.return_complete ?? '0'}</td>
									<td>{data?.overall?.weekly_rental_status?.return_delay ?? '0'}</td>
								</tr>
								<tr>
									<th>이번달</th>
									<td>{data?.overall?.monthly_rental_status_total ?? '0'}</td>
									<td>{data?.overall?.monthly_rental_status_total?.rental_ready ?? '0'}</td>
									<td>{data?.overall?.monthly_rental_status_total?.rental_complete ?? '0'}</td>
									<td>{data?.overall?.monthly_rental_status_total?.rental_delay ?? '0'}</td>
									<td>{data?.overall?.monthly_rental_status_total?.return_complete ?? '0'}</td>
									<td>{data?.overall?.monthly_rental_status_total?.return_delay ?? '0'}</td>
								</tr>
							</tbody>
						</Table>
					</Row>
				</section>
			)}
			{UserRole.isAdminRole(user?.user_role) && (
				<section>
					<StatisticsContainer>
						<SectionTitleContainer>
							<SectionTitle>장비 유형별 이달의 신청 현황</SectionTitle>
							<SectionSubTitle>{data?.product_type?.date ?? '-'}</SectionSubTitle>
						</SectionTitleContainer>
						{data?.product_type?.statistics && (
							<Row className="mt-20px">
								<StatisticsGraphContainer>
									{Object.keys(data?.product_type?.statistics).map((key, idx) => {
										const graphColor = graphColorList[idx % graphColorList.length];
										const barWidth = String(
											(parseInt(data?.product_type?.statistics[key]) / bookingTotalCount) * 100
										);
										return (
											<StatisticsGraphRow key={idx}>
												<StatisticsTitle title={key}>{key}</StatisticsTitle>
												<StatisticsBarContainer>
													<StatisticsBar width={barWidth ?? '0'} color={graphColor} />
													<StatisticsCount color={graphColor}>
														{data?.product_type?.statistics[key] ?? 0}
													</StatisticsCount>
												</StatisticsBarContainer>
											</StatisticsGraphRow>
										);
									})}
								</StatisticsGraphContainer>
							</Row>
						)}
					</StatisticsContainer>
				</section>
			)}
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 40px;
	background-color: white;

	@media only screen and (max-width: 1023.98px) {
		padding: 40px 20px;
	}

	@media only screen and (max-width: 479.98px) {
		padding: 20px 10px;
	}
`;

const SectionTitleContainer = styled.div`
	display: flex;
	align-items: center;
`;

const SectionTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;
`;

const SectionSubTitle = styled.span`
	margin-left: 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
	color: #666666;
`;

const Row = styled.div`
	display: flex;
	width: 100%;
	gap: 10px;

	@media only screen and (max-width: 767.98px) {
		flex-wrap: wrap;
	}
`;

const DashboardCalendarContainer = styled.div`
	margin-top: 50px;

	@media only screen and (max-width: 767.98px) {
		display: none;
	}
`;

const Table = styled.table`
	width: 100%;

	border-collapse: collapse;
	border: 1px solid #dddddd;
	border-top: 2px solid #0e498f;
	text-align: center;
	thead th {
		width: calc(100% / ${(props) => props.col});
		height: 45px;
		border: 1px solid #dddddd;
		background-color: #f6f8fb;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #0e498f;
	}
	tbody tr th {
		height: 45px;
		border: 1px solid #dddddd;
		background-color: #f8f8f8;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #0e498f;
	}
	tbody tr td {
		height: 45px;
		border: 1px solid #dddddd;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #333333;
	}
`;

const StatisticsContainer = styled.div`
	margin-top: 50px;

	@media only screen and (max-width: 767.98px) {
		display: none;
	}
`;

const StatisticsGraphContainer = styled.div`
	width: 100%;
	height: 100%;
	padding: 20px 38px 20px 38px;

	border: 1px solid #dddddd;
`;

const StatisticsGraphRow = styled.div`
	display: flex;
	align-items: center;
	height: 45px;
`;

const StatisticsTitle = styled.div`
	display: -webkit-box;
	width: 130px;

	box-sizing: content-box;
	padding-right: 34px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	text-align: right;
	color: #000000;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	word-break: break-all;
`;

const StatisticsBarContainer = styled.div`
	height: 100%;
	flex-grow: 1;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border-left: 1px solid #dddddd;
`;

const StatisticsBar = styled.div`
	height: 30px;
	background-color: ${(props) => props.color};
	${(props) =>
		props.width &&
		css`
			width: ${props.width}%;
		`}
`;

const StatisticsCount = styled.div`
	width: 20px;
	margin-left: 9px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: ${(props) => props.color};
`;

export default DashboardProduct;
