import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import useSizeDetector from '../hooks/useSizeDetector';
import { UserContext } from '../contexts/UserContext';
import TablePagination from '../components/TablePagination';
import UnitTypeTab from '../components/UnitTypeTab';
import UserRole from '../constants/UserRole';

import LockIcon from '@mui/icons-material/Lock';
import BreadcrumbHomeImg from '../assets/images/common/breadcrumb_home.svg';
import BreadcrumbSeparatorImg from '../assets/images/common/breadcrumb_separator.svg';
import LeftTopMenuBackgroundImg from '../assets/images/common/lefttop_menu_background.png';
import LeftTopMenuSelectedImg from '../assets/images/common/lefttop_menu_right_arrow.svg';

import * as api from '../apis';

const Board = () => {
	const { slug } = useParams();
	const navigate = useNavigate();
	const { user } = useContext(UserContext);
	const { Desktop, Mobile } = useSizeDetector();

	const [pageIndex, setPageIndex] = useState(1);
	const [currentBoard, setCurrentBoard] = useState(null);
	const [boardList, setBoardList] = useState(null);
	const [postList, setPostList] = useState(null);

	useEffect(() => {
		api.getBoards()
			.then((res) => {
				if (res && res.data) {
					setBoardList(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(() => {
		if (!boardList) return;
		setCurrentBoard(boardList.find((board) => board.slug === slug || board.id == slug));
	}, [boardList, slug]);

	useEffect(() => {
		if (!boardList) return;
		getPostsFromApi();
	}, [currentBoard, pageIndex]);

	const getPostsFromApi = () => {
		let filterParams = [`page=${pageIndex}`];
		api.getPosts(currentBoard.id, filterParams).then((res) => {
			if (res && res.data) {
				setPostList(res.data);
			}
		});
	};

	const hasWritePermission = () => {
		if (!user) {
			return false;
		}

		if (user && UserRole.isAdminRole(user.user_role)) {
			return true;
		}

		if (user && UserRole.isUnitAdminRole(user.user_role)) {
			return true;
		}

		if (currentBoard && !currentBoard.readonly) {
			return true;
		}

		return false;
	};

	const hasReadPermission = (post) => {
		if (!post.secret) {
			return true;
		}

		if (!user) {
			return false;
		}

		if (user && UserRole.isAdminRole(user.user_role)) {
			return true;
		}

		if (user && UserRole.isUnitAdminRole(user.user_role)) {
			return true;
		}

		if (user && user.id === post.user.id) {
			return true;
		}

		return false;
	};

	const getCurrentSlug = () => {
		const currentBoard = boardList?.find((board) => board.slug === slug || board.id == slug);
		return currentBoard?.slug;
	};

	const onClickHome = () => {
		navigate('/');
	};

	const onClickChangeBoard = (slug) => {
		navigate(`/boards/${slug}`);
	};

	const onClickNewPost = () => {
		navigate(`/boards/${currentBoard.id}/posts/create`);
	};

	const onClickPost = (post) => {
		if (!hasReadPermission(post)) {
			alert('게시글에 대한 접근 권한이 없습니다.');
			return;
		}
		navigate(`/boards/${currentBoard.id}/posts/${post.id}`);
	};

	const onPageChange = (page) => {
		setPageIndex(page);
	};

	return (
		<Content>
			<TitleContainer>
				<Title>게시판</Title>
				<Desktop>
					<Breadcrumb>
						<HomeIcon src={BreadcrumbHomeImg} onClick={onClickHome} />
						<BreadcrumbSeparator src={BreadcrumbSeparatorImg} />
						<BreadcrumbText current>게시판</BreadcrumbText>
					</Breadcrumb>
				</Desktop>
			</TitleContainer>
			<Row>
				<Desktop>
					<BoardListColumn>
						<BoardMenuTop>
							<BoardMenuTopTitle>게시판</BoardMenuTopTitle>
						</BoardMenuTop>
						<MenuContainer>
							<Menu>
								{boardList?.map((board, _) => {
									return (
										<MainMenu
											key={_}
											selected={board.slug === slug || board.id == slug}
											onClick={(e) => onClickChangeBoard(board.slug)}
										>
											<span>{board.name}</span>
											<LeftTopMenuSelectedIcon />
										</MainMenu>
									);
								})}
							</Menu>
						</MenuContainer>
					</BoardListColumn>
				</Desktop>
				<Mobile>
					<UnitTypeTab
						tabs={boardList?.map((board) => {
							return { key: board.slug, text: board.name };
						})}
						value={getCurrentSlug()}
						onChange={(slug) => onClickChangeBoard(slug)}
					/>
				</Mobile>
				<PostListColumn>
					<Desktop>
						<BoardInfo>
							<BoardTitle>게시판</BoardTitle>
							<BoardName>{currentBoard?.name ?? '-'}</BoardName>
						</BoardInfo>
					</Desktop>
					{hasWritePermission() && (
						<PostAddButtonContainer>
							<PostAddButton onClick={onClickNewPost}>게시글 작성</PostAddButton>
						</PostAddButtonContainer>
					)}
					<Desktop>
						<PostListTable>
							<thead>
								<tr>
									<th style={{ minWidth: '80px' }}>번호</th>
									<th style={{ minWidth: '120px' }}>분류</th>
									<th style={{ minWidth: '500px' }}>제목</th>
									<th style={{ minWidth: '120px' }}>작성자</th>
									<th style={{ minWidth: '100px' }}>작성일</th>
								</tr>
							</thead>
							<tbody>
								{postList?.items.map((post, idx) => {
									return (
										<tr key={idx}>
											<td>
												{postList?.pagination.total -
													idx -
													(pageIndex - 1) * postList?.pagination.per_page}
											</td>
											<td>{post.category?.name ?? '미분류'}</td>
											<PostTitle className="text-left" onClick={(e) => onClickPost(post)}>
												{`${post.title} [${post.comments.length}]`}
												{post.secret && <StyledLockIcon />}
											</PostTitle>
											<td>{post.user.name}</td>
											<td>
												{post.created_at ? new Date(post.created_at).toLocaleDateString() : '-'}
											</td>
										</tr>
									);
								})}
							</tbody>
						</PostListTable>
					</Desktop>
					<Mobile>
						<MobilePostList>
							{postList?.items.map((post, idx) => {
								return (
									<MobilePost key={idx}>
										<MobilePostCategory>{post.category?.name ?? '미분류'}</MobilePostCategory>
										<MobilePostTitle onClick={(e) => onClickPost(post)}>
											{`${post.title} [${post.comments.length}]`}
											{post.secret && <StyledLockIcon />}
										</MobilePostTitle>
										<MobilePostInfo>
											<span>{post.user.name}</span>
											<span>
												{post.created_at ? new Date(post.created_at).toLocaleDateString() : '-'}
											</span>
										</MobilePostInfo>
									</MobilePost>
								);
							})}
						</MobilePostList>
					</Mobile>
					<div className="mt-24px">
						<TablePagination
							currentPage={postList?.pagination.current_page}
							lastPage={postList?.pagination.last_page}
							onPageChange={onPageChange}
						/>
					</div>
				</PostListColumn>
			</Row>
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	margin-top: 50px;
	margin-bottom: 115px;
	padding: 40px 30px;
	box-sizing: border-box;
	background-color: #ffffff;

	@media only screen and (max-width: 767.98px) {
		width: calc(100% - 20px);
		margin: 10px;
		padding: 15px 0 40px 0;
	}
`;

const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media only screen and (max-width: 767.98px) {
		padding: 0 10px;
	}
`;

const Title = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	line-height: 43px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		font-size: 24px;
		line-height: 29px;
	}
`;

const Breadcrumb = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const HomeIcon = styled.img`
	cursor: pointer;
`;

const BreadcrumbSeparator = styled.img`
	margin: 0 10px;
`;

const BreadcrumbText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;

	${(props) =>
		props.current &&
		css`
			color: #22499d;
		`}
`;

const Row = styled.div`
	display: flex;
	margin-top: 30px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 20px;
		flex-direction: column;
	}
`;

const BoardListColumn = styled.div`
	width: 181px;
`;

const BoardMenuTop = styled.div`
	width: 181px;
	height: 89px;
	background-image: url(${LeftTopMenuBackgroundImg});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	display: flex;
	align-items: center;
`;

const BoardMenuTopTitle = styled.div`
	padding-left: 20px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	color: #ffffff;
`;

const MenuContainer = styled.div``;

const Menu = styled.ul`
	margin-block-start: 0;
	margin-block-end: 0;
	padding-inline-start: 0;
`;

const LeftTopMenuSelectedIcon = styled.i`
	display: none;
	width: 8px;
	height: 12px;
	background-image: url(${LeftTopMenuSelectedImg});
`;

const MainMenu = styled.li`
	list-style: none;
	width: 181px;
	height: 50px;
	border: 1px solid #dddddd;

	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
	cursor: pointer;

	span {
		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		color: #000000;
		cursor: pointer;
	}

	${(props) =>
		props.selected &&
		css`
			border: 2px solid #0e498f;

			& > ${LeftTopMenuSelectedIcon} {
				display: block;
			}
		`}
`;

const PostListColumn = styled.div`
	flex-grow: 1;
	margin-left: 30px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		margin-left: 0;
		margin-top: 20px;
		padding: 0 10px;
	}
`;

const BoardInfo = styled.div`
	display: flex;
	align-items: center;
`;

const BoardTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 36px;
	color: #000000;
`;

const BoardName = styled.span`
	margin-left: 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	line-height: 26px;
	color: #22499d;
`;

const PostAddButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const PostAddButton = styled.div`
	width: 104px;
	height: 36px;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: #454655;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #ffffff;

	&:hover {
		background-color: #1d1e31;
	}

	@media only screen and (max-width: 767.98px) {
		width: 93px;
		height: 30px;
		font-size: 14px;
		line-height: 17px;
	}
`;

const PostListTable = styled.table`
	width: 100%;

	border-collapse: collapse;
	border-top: 2px solid #333333;
	text-align: center;
	margin-top: 12px;

	thead th {
		height: 44px;
		background-color: #f7f7f7;
		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #000000;
	}
	thead th.text-left {
		text-align: left;
	}
	thead tr {
		border-bottom: 1px solid #dddddd;
	}

	tbody td {
		height: 44px;
		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #666666;
	}
	tbody td.text-left {
		text-align: left;
	}
	tbody tr {
		border-bottom: 1px solid #dddddd;
	}

	tfoot {
		height: 45px;
		background: #f7f7f7;
		border-bottom: 1px solid #dddddd;
	}

	@media only screen and (max-width: 767.98px) {
		thead th {
			font-size: 14px;
		}

		tbody td {
			font-size: 14px;
			word-break: break-all;
		}
	}
`;

const PostTitle = styled.td`
	cursor: pointer;
`;

const MobilePostList = styled.div`
	margin-top: 10px;
	border-top: 2px solid #333333;
`;

const MobilePost = styled.div`
	display: flex;
	flex-direction: column;
	padding: 12px 10px;
	border-bottom: 1px solid #dddddd;
`;

const MobilePostCategory = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #000000;
`;

const MobilePostTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const MobilePostInfo = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	span {
		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 500;
		font-size: 13px;
		line-height: 16px;
		color: #666666;
	}

	span + span {
		margin-left: 5px;
	}
`;

const StyledLockIcon = styled(LockIcon)`
	font-size: 14px !important;
	margin-left: 8px;
	margin-bottom: 1px;
`;

export default Board;
